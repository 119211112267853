import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 713 724">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">


<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M714.000000,5.000000 
	C714.000000,244.953613 714.000000,484.907227 714.000000,724.930420 
	C476.425690,724.930420 238.851334,724.930420 1.000000,724.930420 
	C1.000000,485.305359 1.000000,245.610641 1.457952,5.457241 
	C239.277267,4.999050 476.638641,4.999525 714.000000,5.000000 
M349.532013,520.001099 
	C349.532013,520.001099 349.929840,519.959045 350.588959,520.232178 
	C351.272827,520.362427 351.956696,520.492615 353.483551,520.765564 
	C367.457733,520.814636 381.431915,520.868469 395.406128,520.906921 
	C397.234161,520.911926 399.126770,521.110168 400.866516,520.698792 
	C401.917908,520.450195 403.314148,519.307068 403.488831,518.367493 
	C403.662476,517.433289 402.501495,516.250977 401.262085,514.809692 
	C398.917694,514.360779 396.573273,513.911804 394.288574,513.195129 
	C394.288574,513.195129 394.070007,513.029419 394.201599,512.095154 
	C394.141205,497.722107 394.080841,483.349091 394.263702,468.554443 
	C394.263702,468.554443 394.219055,468.069733 394.843170,467.892395 
	C397.610474,467.786407 400.377777,467.680420 403.667328,468.086029 
	C407.981415,470.796906 408.056274,475.283234 408.301056,479.580811 
	C408.782928,488.039948 408.624329,496.560333 409.566010,504.961761 
	C410.591766,514.113525 416.427277,519.548462 425.464142,521.005676 
	C428.248444,521.454712 431.108032,522.037781 433.880798,521.836914 
	C440.298920,521.371643 447.646729,521.737976 451.277618,515.283081 
	C454.251007,509.997070 455.521606,503.753235 457.509094,498.042999 
	C452.185181,495.760468 450.168762,496.868866 449.484802,501.390808 
	C449.062103,504.185394 447.475159,506.803864 446.410858,509.501373 
	C445.662506,509.390869 444.914124,509.280365 444.165771,509.169861 
	C443.657898,506.722839 442.829498,504.291656 442.707520,501.825562 
	C442.436523,496.345612 442.711060,490.837708 442.412994,485.360260 
	C441.968964,477.201324 439.689941,469.939270 431.514404,466.056366 
	C431.001038,465.812561 430.689880,465.143036 429.780975,464.085449 
	C438.701080,461.119141 444.274384,455.460358 445.704620,446.363190 
	C447.024536,437.967712 447.272491,429.759888 441.001007,422.217957 
	C440.343048,421.791229 439.685089,421.364532 438.855133,420.470551 
	C438.629456,420.186707 438.335724,420.057373 437.787018,419.470428 
	C436.880005,418.960541 435.973022,418.450684 435.063507,417.496246 
	C435.063507,417.496246 434.976471,417.060272 434.844147,416.279602 
	C433.383484,415.921143 431.922821,415.562714 430.227844,415.397583 
	C430.227844,415.397583 429.954071,415.265961 429.919739,414.482239 
	C426.705536,410.118713 422.734222,409.628723 419.080200,413.285706 
	C417.047852,413.141968 415.015503,412.998230 412.813721,412.557007 
	C412.550079,412.645172 412.286407,412.733337 411.343384,412.966858 
	C410.886963,412.908539 410.430542,412.850189 409.808105,412.515259 
	C409.489746,412.313293 409.232819,412.385895 408.211761,412.834717 
	C402.806946,412.568420 397.402100,412.302155 391.854950,411.295319 
	C389.620422,409.293701 387.591644,408.921692 385.074249,412.168274 
	C379.382263,412.119354 373.690277,412.070404 367.844910,411.266388 
	C365.360626,409.672882 362.920227,408.477753 360.242279,412.149109 
	C358.833923,412.434174 357.425537,412.719238 355.267914,412.865082 
	C354.270599,412.956146 353.273315,413.047211 351.504272,413.043793 
	C350.727478,413.018097 349.950714,412.992371 349.061798,412.998260 
	C349.061798,412.998260 349.026184,412.887390 348.852051,412.138428 
	C345.911591,411.522552 342.979645,410.852264 340.015564,410.386749 
	C339.758636,410.346405 339.289429,411.657715 338.101715,412.632446 
	C336.098755,412.546417 334.095825,412.460388 331.983276,411.636475 
	C329.996857,408.430359 328.980469,410.615662 327.078949,412.666168 
	C325.095459,412.565826 323.111969,412.465485 320.620270,411.647247 
	C314.625793,411.447601 308.631958,411.206696 302.635834,411.118164 
	C302.092712,411.110138 301.535156,412.082825 300.705719,413.006592 
	C300.705719,413.006592 300.221710,413.099457 299.403931,413.034180 
	C297.875641,413.705841 296.347351,414.377502 294.819061,415.049133 
	C295.012451,415.587799 295.205872,416.126434 295.399261,416.665070 
	C296.823364,416.783020 298.247437,416.900940 300.135345,417.644012 
	C302.672516,418.442200 305.209656,419.240387 307.271454,420.474243 
	C306.286621,421.370667 305.301819,422.267090 303.797577,423.636322 
	C305.702087,423.804260 306.828186,423.903564 308.188354,424.914764 
	C308.089935,436.607422 307.991516,448.300049 307.305450,459.983185 
	C306.529938,460.384735 305.754456,460.786285 304.096313,461.482880 
	C297.395721,461.304138 290.695129,461.125366 283.800598,460.522095 
	C283.615265,460.252441 283.363617,460.078979 282.777740,459.097107 
	C282.763367,447.184814 282.704285,435.272156 282.790314,423.360596 
	C282.801239,421.846313 283.571075,420.337463 284.532257,419.024567 
	C284.698669,418.927490 284.865112,418.830383 285.927551,418.806519 
	C289.942169,419.114014 292.342316,418.135834 289.891052,412.793304 
	C285.593231,412.540466 281.295380,412.287598 276.821198,411.236938 
	C271.053864,411.044250 265.286774,410.840118 259.517975,410.717773 
	C259.355957,410.714355 259.175415,411.584564 258.257507,412.180878 
	C257.504272,412.131165 256.751007,412.081451 255.866867,411.305908 
	C253.947647,409.532959 252.197342,408.902710 250.129791,412.161896 
	C247.783127,412.139954 245.436447,412.118042 243.013046,411.595001 
	C242.886459,411.306061 242.759872,411.017090 242.633301,410.728149 
	C242.509979,410.826874 242.274338,411.005646 242.279449,411.012848 
	C242.544205,411.384277 242.829391,411.741180 243.028549,412.582367 
	C243.028549,412.582367 243.147064,413.057800 242.474197,413.126068 
	C241.889053,413.746216 241.303909,414.366333 240.030670,415.170105 
	C239.883240,416.557312 239.735794,417.944489 239.588364,419.331696 
	C240.162537,419.006409 240.736694,418.681152 242.136139,418.523224 
	C244.085907,418.755920 246.035675,418.988586 247.977966,419.986511 
	C248.539352,421.664856 249.100723,423.343170 249.816895,425.871307 
	C249.771896,427.595917 249.726898,429.320496 249.296326,431.443024 
	C249.424179,432.279877 249.552032,433.116699 249.823914,434.886108 
	C249.774063,455.941803 249.724228,476.997498 249.225876,498.700684 
	C249.375198,502.115845 249.524521,505.530975 249.818115,509.747070 
	C249.735901,510.820587 249.653687,511.894135 248.779709,512.970520 
	C246.833481,513.368530 244.887253,513.766541 242.410080,514.445740 
	C241.828903,514.958435 241.247726,515.471130 240.054901,516.120422 
	C240.315842,517.086365 240.576782,518.052246 241.002991,518.861450 
	C241.002991,518.861450 241.213348,518.948792 241.201843,519.566406 
	C241.726028,519.835083 242.250214,520.103699 243.519836,520.734863 
	C246.663986,520.728760 249.808136,520.722595 253.865570,520.969543 
	C262.922607,520.892029 271.979645,520.814575 281.915466,520.833862 
	C290.896912,520.843018 290.896912,520.843018 290.850464,514.405762 
	C288.269562,513.935303 285.688629,513.464905 282.765350,512.112610 
	C282.874084,498.407104 282.982819,484.701569 283.420380,470.873535 
	C283.494659,470.784546 283.648346,470.668243 283.628876,470.611450 
	C283.559387,470.409149 283.431030,470.227112 283.392303,469.475555 
	C283.920471,469.283447 284.448639,469.091370 285.487030,469.189484 
	C285.663910,469.105835 285.840790,469.022217 286.946625,469.052673 
	C293.621796,469.178040 300.296997,469.303406 307.017761,469.933868 
	C307.343262,470.289215 307.668793,470.644592 308.228455,471.651031 
	C308.154205,472.100464 308.079956,472.549896 307.455841,473.452698 
	C307.646454,474.969543 307.837097,476.486359 308.278564,478.911438 
	C308.153290,490.284393 308.027985,501.657379 307.107483,512.997742 
	C305.064545,513.409058 303.021576,513.820374 300.499207,514.776855 
	C300.408875,515.222046 300.318542,515.667236 300.013397,516.763855 
	C299.924072,517.672363 299.834747,518.580872 299.125061,519.206726 
	C298.754120,519.277649 298.383179,519.348511 298.012238,519.419373 
	C298.033966,519.553711 298.055725,519.688049 298.077454,519.822327 
	C298.641357,519.739685 299.205261,519.657043 300.261444,519.601685 
	C300.545868,519.985352 300.830322,520.369019 301.139099,521.476562 
	C302.704773,523.832642 304.005554,523.810181 305.869324,520.862244 
	C308.230652,520.815002 310.591980,520.767761 313.862457,520.989258 
	C322.920624,520.904175 331.978790,520.819031 341.538361,520.844482 
	C341.694763,520.913940 341.851196,520.983398 342.431335,521.556641 
	C343.617157,521.397156 344.803009,521.237671 346.612427,520.906738 
	C347.028015,520.802734 347.443604,520.698730 348.681793,520.489990 
	C348.894928,520.313965 349.108063,520.137939 349.311676,520.091980 
	C349.311676,520.091980 349.139465,520.078430 349.532013,520.001099 
M378.134186,559.848877 
	C377.770569,559.502869 377.406921,559.156799 377.222412,558.868347 
	C377.222412,558.868347 377.097534,558.883057 376.615906,558.257935 
	C376.134857,558.051086 375.653809,557.844177 374.969360,557.018066 
	C373.537567,555.896973 372.243805,554.504272 370.653076,553.695984 
	C350.570129,543.492126 330.575134,544.052063 310.878754,554.699463 
	C308.228577,556.132080 306.234009,558.777588 303.306274,561.105286 
	C302.912079,561.635376 302.517883,562.165405 301.822021,562.547668 
	C301.822021,562.547668 301.585144,562.784302 301.585144,562.784302 
	C301.585144,562.784302 301.744690,563.078918 301.202728,563.473816 
	C300.906128,564.060242 300.609497,564.646667 299.714844,565.564636 
	C299.222137,566.226135 298.653870,566.844421 298.247742,567.555359 
	C288.011475,585.474609 287.039825,604.536255 291.969513,624.042236 
	C295.633118,638.538696 304.541260,648.868469 319.012817,653.569763 
	C332.835510,658.060364 346.927917,658.130432 360.735870,653.734558 
	C371.729004,650.234924 380.357178,643.425720 385.749847,632.074219 
	C387.724487,624.440186 390.687866,616.904602 391.459625,609.150879 
	C392.888794,594.791565 392.088959,580.485413 383.733887,567.185364 
	C382.723572,565.803650 381.713287,564.421997 380.583801,562.464294 
	C379.858673,561.827637 379.133545,561.190918 378.134186,559.848877 
M531.848755,417.730194 
	C531.848755,417.730194 531.748718,417.642426 531.088623,417.673737 
	C530.068909,418.511627 529.049133,419.349487 527.567261,420.147797 
	C527.306641,420.479584 527.046021,420.811340 526.126099,421.446472 
	C524.088745,424.298309 522.051392,427.150116 519.559143,430.490356 
	C519.053833,431.699707 518.548523,432.909058 517.765930,434.270111 
	C517.630188,434.510040 517.689758,434.682434 517.548340,435.609406 
	C512.350159,451.794586 519.210266,470.915070 537.214417,477.398987 
	C545.004639,480.204529 552.932434,482.628174 560.719238,485.442749 
	C566.706116,487.606781 572.432312,490.280212 574.743469,497.445801 
	C574.920654,497.933746 575.097839,498.421692 575.159729,499.739105 
	C575.198914,500.852997 575.238098,501.966858 574.895264,503.474487 
	C574.878845,504.016724 574.862366,504.558929 574.293945,505.903992 
	C574.186279,506.210419 574.078674,506.516815 574.021851,506.659912 
	C574.021851,506.659912 574.005188,506.615082 573.655457,507.282501 
	C572.861572,508.530090 572.067627,509.777679 570.683472,511.001556 
	C570.403503,511.266052 570.123535,511.530518 569.096130,512.014587 
	C560.528992,516.521912 552.288513,514.889099 543.911316,510.405945 
	C543.677673,510.269958 543.428589,510.228851 542.860168,509.666473 
	C541.037354,508.326843 539.214600,506.987213 537.176453,505.181396 
	C536.897583,504.992889 536.618652,504.804382 535.922241,504.084717 
	C531.556641,497.913605 527.312317,491.649628 522.719482,485.652527 
	C521.839783,484.503906 519.588135,484.405975 517.255432,483.466736 
	C516.891418,486.309998 516.527405,489.153290 515.944458,492.858551 
	C516.043396,495.219391 516.142334,497.580200 516.057495,500.487854 
	C516.139038,500.662048 516.220520,500.836243 516.193787,501.846313 
	C516.504028,506.889374 516.814209,511.932404 516.936951,517.742188 
	C517.631836,519.033081 518.326782,520.323914 519.021667,521.614807 
	C520.731506,520.422485 522.441284,519.230225 524.811707,517.936096 
	C526.571106,517.866089 528.330505,517.796082 530.608826,518.052307 
	C531.406189,518.163696 532.203491,518.275085 533.701660,518.809937 
	C534.783936,518.923584 535.866211,519.037292 537.337830,519.558105 
	C537.525269,519.546021 537.712646,519.533936 538.588135,519.798828 
	C540.061768,520.052246 541.530884,520.336914 543.009827,520.554260 
	C554.332397,522.218384 565.512451,522.614197 576.152893,517.239990 
	C586.917419,511.803070 595.283630,499.485779 594.086853,487.146149 
	C594.114441,485.333160 594.003235,483.505554 594.192749,481.709656 
	C595.210876,472.060577 590.041992,462.701569 581.531494,457.631073 
	C580.022339,456.668762 578.628052,455.389282 576.985718,454.794769 
	C565.013855,450.460876 552.992126,446.264740 540.565369,441.468231 
	C535.687927,437.887207 534.274414,433.050140 535.881653,427.409821 
	C537.387146,422.126587 541.834717,419.870544 546.630798,418.892761 
	C553.062134,417.581573 559.731995,418.392792 564.371765,423.163605 
	C570.548157,429.514526 575.663025,436.887207 581.458313,443.628113 
	C582.651550,445.015991 584.832520,445.554657 586.558228,446.484741 
	C587.264587,444.852509 588.587402,443.214813 588.572876,441.589020 
	C588.501709,433.617065 588.163269,425.643188 587.699280,417.682831 
	C587.605591,416.075867 586.560608,414.524384 585.954224,412.947296 
	C584.266846,413.909973 582.579468,414.872620 580.096741,415.934021 
	C572.452576,414.555206 564.854736,412.515045 557.151794,411.976379 
	C548.809204,411.392944 540.202759,411.430328 532.388123,417.002167 
	C532.167419,417.261963 531.946716,417.521759 531.848755,417.730194 
M528.878479,206.316666 
	C528.241272,205.227615 527.604065,204.138580 527.362671,202.481567 
	C527.640869,201.252472 528.529114,199.333374 528.075317,198.932053 
	C526.994690,197.976562 525.243591,197.209457 523.827576,197.306213 
	C518.900940,197.642868 514.005005,198.823944 509.091095,198.862015 
	C501.260162,198.922684 493.413513,198.476532 485.594757,197.937149 
	C481.846985,197.678619 480.362946,198.855484 481.299744,201.687973 
	C478.970612,203.210953 476.021057,204.076294 475.572235,205.653381 
	C474.623535,208.986710 472.717377,209.140747 470.088074,209.022171 
	C466.830231,208.875259 465.360748,210.372345 466.386322,213.639709 
	C467.582458,217.450607 466.150513,217.798157 462.692749,217.215561 
	C458.681274,216.539673 456.095978,219.134613 456.008392,223.066452 
	C455.971436,224.724854 456.010925,226.384979 455.369293,228.080566 
	C454.938416,228.102325 454.507507,228.124084 453.751587,227.483459 
	C452.575439,226.589127 451.399292,225.694794 451.359100,225.664230 
	C448.666656,227.176682 446.745728,229.125961 445.606140,228.744019 
	C440.882629,227.160873 439.245758,229.434738 438.876740,231.669540 
	C435.956116,233.717560 433.881104,234.640732 432.609070,236.213364 
	C431.324219,237.801895 430.835327,240.034180 429.338257,242.019547 
	C428.898407,242.030579 428.458588,242.041611 427.930847,241.329498 
	C423.445068,238.379608 426.700470,234.069489 426.838959,229.461502 
	C425.083038,230.770309 424.374756,231.416611 423.562836,231.882080 
	C420.659882,233.546494 417.626740,236.653610 414.815918,236.475983 
	C408.091522,236.051025 406.039368,232.825928 406.527863,225.764664 
	C406.901855,225.761459 407.275848,225.758255 408.306396,226.194870 
	C410.340851,228.356506 412.372986,231.920074 414.239136,226.006546 
	C414.464264,225.293182 415.563538,224.683243 416.388733,224.360336 
	C420.943268,222.578140 425.962036,221.575302 430.021698,219.029602 
	C436.300354,215.092422 436.026520,214.655716 440.380371,219.829239 
	C446.525482,216.908936 446.580200,216.753494 444.965179,206.433701 
	C444.965179,206.433701 445.086182,206.007477 445.774414,205.925934 
	C447.019867,204.665070 448.301880,203.437927 449.503326,202.136459 
	C452.141724,199.278412 454.047546,196.546509 448.925629,194.074860 
	C448.000183,193.628281 447.314087,191.856735 447.230957,190.647842 
	C446.974854,186.921860 447.027710,183.172134 447.026703,179.431610 
	C447.024048,169.761566 446.995331,160.090775 447.111664,150.421906 
	C447.148346,147.372910 447.843414,144.326752 447.813141,141.283493 
	C447.783752,138.332901 447.912231,134.994247 446.575623,132.607697 
	C445.916443,131.430634 442.109100,132.016678 440.299805,131.855911 
	C438.369385,130.815872 436.685303,129.908554 434.676788,128.356689 
	C433.035950,123.666733 430.201324,120.860367 424.788666,121.977196 
	C423.989563,122.142075 423.018280,121.748604 422.315887,122.051788 
	C420.517029,122.828262 417.719482,123.524048 417.327332,124.846817 
	C416.583466,127.356018 418.348389,128.949310 421.319000,129.202499 
	C422.278351,129.284241 423.628418,131.015366 423.817169,132.154755 
	C424.006256,133.295990 423.134460,135.622650 422.441162,135.740219 
	C417.034363,136.657043 416.494141,140.150177 417.183472,144.972382 
	C415.782318,144.284637 414.169220,143.371323 414.211273,142.541901 
	C414.539886,136.059158 410.484650,135.599457 405.701080,136.007401 
	C402.295837,136.297791 400.955078,134.868546 400.921021,131.388550 
	C400.881470,127.339966 398.510071,125.960815 394.635590,127.340691 
	C393.442139,127.765755 392.348175,128.470276 391.208679,129.046829 
	C391.249725,128.546616 391.290802,128.046387 391.331879,127.546165 
	C388.989349,126.980179 384.760803,125.588768 384.592102,125.974182 
	C382.613800,130.494415 379.210632,128.793320 376.020233,128.546082 
	C372.900574,128.304337 369.632904,127.779312 366.654480,128.420441 
	C362.146667,129.390762 360.946198,127.720421 360.942505,123.624596 
	C360.939728,120.512314 360.652069,117.353966 360.059509,114.299850 
	C359.255859,110.157433 358.039886,106.095001 357.641266,102.276215 
	C358.983887,102.119003 360.537994,102.342041 361.630188,101.734573 
	C365.172791,99.764244 368.546082,97.489494 372.470032,95.027351 
	C373.038452,94.190498 374.102844,92.623459 375.167206,91.056404 
	C376.568359,92.439896 377.969513,93.823395 379.803131,95.633934 
	C380.419952,94.350868 381.404327,92.911018 381.806946,91.323608 
	C382.414581,88.927750 382.616241,86.428925 383.655823,83.940872 
	C384.099792,83.930115 384.543762,83.919350 385.073120,84.656532 
	C387.914856,89.335335 391.135864,85.501556 394.050293,84.870453 
	C396.995911,84.232597 399.850800,82.691429 402.005737,86.082886 
	C404.039825,89.284203 406.210999,89.711365 409.410431,87.436134 
	C412.620331,85.153465 415.031433,85.562485 416.314697,90.063324 
	C417.303406,93.531052 418.830414,97.426842 424.859894,95.999718 
	C427.840485,96.635139 430.797668,97.433105 433.807343,97.866798 
	C438.020813,98.473961 442.348694,99.292007 446.438446,96.216225 
	C446.711914,96.415283 446.899109,96.676239 446.921783,97.730400 
	C447.145782,100.445549 447.369781,103.160698 447.527435,105.071732 
	C447.126953,108.363213 446.806671,110.995575 446.486389,113.627945 
	C448.753815,113.792168 451.064240,114.308220 453.278595,114.037872 
	C456.572906,113.635681 459.799377,112.677757 463.006439,111.976685 
	C463.006439,111.976685 463.057739,111.945717 463.018433,112.778305 
	C463.452759,116.237518 462.885345,120.137474 467.018402,121.980507 
	C467.018402,121.980507 467.047729,121.961838 466.997162,122.729378 
	C464.429688,126.486259 465.079651,129.962540 467.615814,133.516922 
	C469.858124,136.659454 471.770416,140.037476 473.990875,143.570007 
	C474.001892,143.560776 474.814178,143.195862 475.109039,142.582321 
	C477.027618,138.589935 479.067932,137.173462 482.813141,141.055771 
	C484.786041,143.100891 489.290131,140.643509 489.886566,137.408356 
	C490.153351,135.961258 489.951294,134.427734 490.343109,132.406189 
	C491.530457,131.885361 492.717804,131.364532 494.321228,131.411774 
	C496.531219,129.924942 498.741180,128.438095 500.979340,126.998085 
	C500.979340,126.998085 500.964691,126.950508 501.737091,126.983772 
	C503.145844,125.966553 504.554596,124.949326 506.246185,123.373444 
	C507.122864,122.890945 507.999542,122.408447 509.669128,121.986717 
	C511.537933,122.002663 513.965088,122.780708 515.173523,121.895332 
	C519.219238,118.931091 522.829529,115.372421 525.744202,112.801842 
	C525.027100,110.043282 524.080688,108.070587 524.067688,106.091736 
	C523.951416,88.457352 524.044800,70.821594 523.929504,53.187202 
	C523.918213,51.456146 522.923279,49.731525 522.126770,47.195057 
	C522.210999,45.795555 522.295288,44.396053 523.045044,42.787888 
	C523.620483,37.447201 524.547546,32.111580 524.624573,26.763720 
	C524.663574,24.050863 522.290894,22.613331 519.320435,22.987049 
	C517.083130,23.268536 513.916870,22.148243 514.407349,26.429062 
	C514.407349,26.429062 514.450745,26.466013 513.605042,26.112146 
	C506.853271,23.855595 505.168335,24.468376 503.943420,29.194252 
	C503.597473,30.529161 502.354156,31.631491 501.522400,32.840492 
	C500.699524,31.721367 499.923218,30.563938 499.042511,29.492313 
	C497.800659,27.981188 496.375916,25.190094 495.212891,25.281500 
	C488.703644,25.793091 482.249603,27.007442 475.455017,28.030178 
	C475.222137,27.496689 474.738770,26.389282 474.016449,24.734552 
	C470.446808,29.324520 467.039093,28.098675 463.761078,24.896042 
	C462.285614,26.215309 461.073822,27.298840 459.080048,28.464828 
	C458.052368,28.297688 457.024658,28.130545 455.861755,27.243208 
	C455.373138,26.855473 454.915466,26.178795 454.391357,26.122730 
	C444.255524,25.038506 434.130981,23.664322 423.963135,23.130110 
	C417.382446,22.784367 410.711121,23.441299 404.126556,24.105888 
	C398.095245,24.714634 392.137878,26.025366 386.122589,26.839727 
	C384.051147,27.120163 381.903320,26.812954 379.790436,26.816166 
	C376.428497,26.821278 372.526520,25.790188 369.835571,27.146677 
	C366.116608,29.021378 360.576721,28.675964 359.015015,34.182137 
	C358.864349,34.713306 356.933899,34.761669 355.820953,34.992447 
	C354.378876,35.291489 352.910004,35.477371 351.484650,35.838264 
	C348.240143,36.659756 345.015167,37.558304 342.446045,38.247471 
	C340.811401,35.110233 339.730682,33.036083 338.649963,30.961931 
	C337.881775,31.168631 337.113586,31.375328 336.345398,31.582027 
	C336.941589,37.444565 337.537781,43.307098 338.003235,49.112904 
	C338.003235,49.112904 338.144867,49.128658 337.304596,49.036610 
	C335.529083,49.404408 333.768738,49.925537 331.974396,50.103413 
	C329.398621,50.358765 326.267517,51.326317 324.328461,50.257416 
	C321.170593,48.516701 319.985199,50.254574 318.656708,52.058159 
	C317.514343,53.609093 316.840485,55.505135 315.918518,57.329575 
	C317.541443,58.501568 318.784241,59.399025 320.444885,60.598267 
	C318.618958,61.805367 317.231293,62.722748 316.890900,62.947777 
	C314.826843,61.284569 313.206573,59.154968 312.321472,59.426003 
	C310.011566,60.133369 307.330597,61.424870 306.048920,63.326710 
	C303.233490,67.504463 306.916870,69.560890 309.799896,71.187988 
	C306.137329,74.526375 302.682190,77.675690 298.895081,81.127594 
	C301.042145,82.162537 302.078705,82.662193 302.995819,83.699562 
	C302.658386,84.130730 302.320984,84.561890 301.383057,84.999313 
	C300.913116,85.330696 300.443176,85.662079 299.276062,85.879990 
	C297.679443,84.343636 296.169220,82.699638 294.448059,81.318520 
	C293.645264,80.674324 292.444885,80.332611 291.402313,80.277504 
	C290.998413,80.256157 290.065857,81.531654 290.191132,81.995895 
	C290.460724,82.995087 291.097229,84.048721 291.892792,84.705498 
	C292.566559,85.261742 293.675201,85.291260 293.846039,85.340233 
	C294.296570,88.289185 294.658539,90.658600 295.010651,93.680550 
	C294.987549,94.115707 294.964447,94.550858 294.083649,94.994232 
	C292.415375,95.582771 290.747101,96.171310 289.078827,96.759865 
	C289.730377,98.507751 290.381897,100.255646 291.009918,102.678261 
	C290.673065,103.453865 290.336182,104.229469 289.265350,105.002907 
	C288.311035,105.226082 287.356689,105.449257 286.402374,105.672432 
	C286.416534,105.936363 286.430725,106.200294 286.444885,106.464218 
	C287.311249,106.644363 288.177643,106.824509 289.019348,107.733788 
	C288.347748,109.489822 287.676147,111.245850 286.342804,113.064598 
	C285.908875,114.388611 285.474945,115.712624 285.034729,117.839523 
	C284.699554,119.566238 284.364410,121.292961 283.315460,123.080345 
	C277.748291,127.578529 277.427124,131.061539 282.025085,137.889465 
	C282.013245,139.592972 282.001404,141.296494 281.231384,143.093185 
	C273.381989,144.859955 265.979858,151.107849 258.143311,145.739166 
	C256.045135,147.003464 254.387299,148.860611 252.737244,148.853699 
	C243.813431,148.816315 235.319214,153.368942 226.236664,151.261642 
	C226.025192,151.212585 225.711182,151.605484 225.052414,152.067673 
	C224.712296,158.260773 224.272827,164.742767 224.033112,171.232147 
	C223.901810,174.786606 223.176193,178.643112 226.958923,181.715942 
	C225.110184,184.186386 223.007080,186.509674 221.472427,189.161865 
	C219.180862,193.122208 217.289688,197.314255 215.226685,201.735962 
	C215.226685,201.735962 215.046082,202.010040 214.354187,202.075455 
	C209.283875,205.277069 208.515839,210.974487 206.645767,215.899414 
	C202.369186,227.162018 198.150528,238.441650 198.770615,250.900253 
	C199.319366,261.925903 198.282959,273.127930 199.854691,283.975586 
	C201.209122,293.323395 204.934128,302.400574 208.275879,311.349152 
	C209.493668,314.610107 212.629944,317.154602 215.170059,320.441040 
	C215.253128,320.760834 215.336182,321.080597 215.196121,322.174194 
	C218.438446,326.807892 221.680771,331.441620 224.989944,336.787903 
	C227.567505,339.267517 230.145065,341.747131 232.898178,344.913574 
	C234.527557,346.360352 236.156921,347.807098 237.912445,349.952271 
	C240.537155,352.033386 243.161880,354.114532 246.007721,356.846588 
	C248.190826,358.114014 250.373947,359.381439 252.841232,360.657104 
	C252.841232,360.657104 253.060150,360.838501 253.088715,361.553345 
	C255.904984,363.459259 258.536987,365.752289 261.572601,367.197449 
	C270.157501,371.284363 278.633026,375.937469 287.666870,378.654419 
	C297.555328,381.628448 308.011536,382.735107 318.248749,384.489777 
	C319.062836,384.629303 320.098419,383.476654 321.821716,382.879730 
	C322.865265,383.013611 323.908813,383.147491 325.179962,383.991821 
	C327.789673,383.992737 330.399384,383.993652 333.858398,383.907532 
	C335.558563,384.003204 337.258759,384.098877 339.192719,384.952728 
	C346.657135,385.065460 354.121490,385.183319 361.586304,385.254364 
	C361.735596,385.255768 361.890076,384.710205 362.680145,384.221191 
	C363.120667,384.258026 363.561218,384.294861 364.205780,384.600220 
	C364.473175,384.491150 364.740570,384.382080 365.862305,384.071411 
	C367.908783,384.051910 369.955261,384.032379 372.539093,384.599182 
	C375.373047,384.130676 378.207031,383.662201 381.665192,382.982269 
	C382.102051,382.974548 382.538879,382.966827 383.496124,383.554108 
	C389.123260,385.506226 394.293549,382.981842 399.709503,382.175903 
	C414.993073,379.901581 429.535248,375.081512 444.143585,370.390839 
	C449.097961,368.799988 451.098938,365.607574 451.045624,360.348419 
	C450.859924,342.028168 450.985443,323.704865 450.970947,305.382721 
	C450.968719,302.569519 450.852234,299.756409 450.820953,296.198242 
	C451.045471,295.498016 451.270020,294.797821 451.859070,294.073975 
	C451.859070,294.073975 452.215485,293.986755 452.795654,294.382843 
	C460.839874,299.150085 465.824890,296.559967 467.522369,286.580109 
	C472.397430,284.446289 480.295593,285.644836 479.822723,276.420502 
	C477.926697,276.646820 476.472900,276.820374 474.794189,276.481201 
	C474.527283,275.990631 474.260406,275.500031 473.984070,274.217438 
	C474.313690,270.465363 474.643311,266.713287 475.011200,262.383148 
	C475.025513,261.997742 475.039825,261.612305 475.656860,260.836700 
	C476.122498,260.762726 476.588104,260.688721 477.015625,261.499176 
	C476.736359,266.060455 479.683624,265.007599 482.263794,264.733368 
	C482.482452,263.399445 482.668121,262.266602 482.982269,260.349762 
	C484.356323,261.838531 485.156281,262.705292 486.311737,263.957214 
	C487.487732,259.969666 489.635406,258.119751 493.679565,259.856995 
	C494.346191,260.143372 495.641785,259.555573 496.370850,259.005920 
	C499.807678,256.414795 503.046143,253.548920 506.576263,251.099335 
	C507.897766,250.182312 509.825531,249.725891 511.464783,249.754379 
	C517.274658,249.855392 520.603394,246.930069 521.055847,241.193771 
	C521.164917,239.810913 521.545349,237.817291 522.493469,237.238098 
	C527.625854,234.102829 531.248108,230.457581 528.843689,223.874619 
	C528.463501,222.833664 527.666077,221.945129 526.816956,220.534454 
	C526.537537,220.350143 526.258057,220.165833 525.828369,219.076477 
	C525.911011,215.714233 525.993652,212.351990 526.578491,209.037003 
	C526.746460,209.028625 526.914429,209.020264 527.708435,208.910049 
	C528.131287,208.261139 528.554077,207.612213 528.878479,206.316666 
M284.310211,623.307190 
	C284.191925,622.873108 284.073639,622.438965 284.497742,621.537659 
	C284.392609,620.143066 284.287506,618.748474 284.198975,617.574097 
	C279.676483,616.459839 277.630005,618.246643 275.957031,621.759644 
	C273.756256,626.380859 271.127106,630.854492 268.214294,635.068176 
	C262.591400,643.202209 255.265808,648.767456 244.825745,648.956543 
	C242.691910,648.995178 240.773865,648.869934 240.807297,645.121094 
	C240.756317,616.062134 240.705338,587.003235 240.908813,557.246094 
	C240.933197,556.502869 240.957581,555.759705 241.784607,554.692627 
	C246.575195,554.130737 251.365784,553.568848 256.546692,552.889343 
	C256.593231,552.761169 256.710114,552.604797 256.672272,552.510437 
	C256.580200,552.280640 256.416351,552.079590 256.315765,551.105530 
	C255.534424,550.081909 254.753067,549.058289 253.347961,547.510986 
	C245.910049,547.424744 238.471313,547.226562 231.034409,547.280457 
	C221.639465,547.348511 212.245987,547.622070 202.389557,547.574524 
	C201.886948,547.873413 201.384338,548.172241 200.264832,548.566589 
	C200.107376,548.702637 199.949905,548.838623 199.306107,548.950806 
	C199.284027,549.606201 199.261948,550.261536 199.247009,551.729248 
	C200.380630,552.644043 201.385132,553.932373 202.680206,554.379944 
	C204.341217,554.954041 206.235901,554.852112 207.940842,555.714355 
	C208.109009,556.123291 208.277161,556.532227 208.274841,557.870605 
	C208.308746,579.260254 208.342636,600.649841 208.082565,622.738281 
	C208.181610,623.475586 208.280670,624.212952 208.241165,625.883423 
	C208.298416,632.255676 208.355667,638.627991 208.149033,645.506775 
	C208.243195,645.672241 208.337372,645.837646 208.271866,646.670532 
	C208.242630,647.126404 208.213409,647.582336 207.537796,648.545593 
	C205.374817,648.789978 203.211823,649.034363 200.277008,649.306030 
	C197.845184,653.492188 198.712784,655.851074 203.130630,655.869812 
	C228.270035,655.976379 253.410522,655.948059 278.549072,655.755066 
	C279.654388,655.746643 280.743896,653.681641 282.137848,652.158447 
	C282.092804,651.106140 282.047760,650.053894 282.246674,648.225464 
	C282.438049,645.798889 282.629395,643.372253 283.114685,640.337280 
	C283.144928,638.533813 283.175171,636.730347 283.435883,634.490234 
	C283.521088,634.142639 283.448730,633.831726 283.753937,632.952637 
	C283.861359,629.957886 283.968811,626.963074 284.310211,623.307190 
M544.578857,546.892822 
	C542.353699,546.836426 540.128540,546.779968 537.211670,546.813477 
	C533.520996,546.255493 532.895142,549.091980 531.986572,551.568787 
	C524.310608,572.494141 516.625732,593.416199 508.987610,614.355408 
	C504.770966,625.914856 500.638824,637.505249 495.654205,649.162781 
	C493.891571,649.742310 492.128967,650.321777 489.402588,650.930481 
	C487.650452,654.302002 488.879425,655.891968 492.473602,655.916931 
	C498.431091,655.958252 504.390045,655.864075 510.346588,655.949341 
	C514.226257,656.004822 514.962830,654.207703 513.153442,650.513550 
	C510.532990,650.145203 507.912506,649.776855 504.855469,649.347168 
	C507.980499,640.288452 510.852142,631.840393 513.850464,623.437561 
	C514.205078,622.443665 515.253784,621.697449 516.907410,620.994507 
	C524.934448,621.000793 532.961426,621.007080 541.398438,621.629272 
	C544.495056,630.650330 547.591675,639.671387 549.912903,648.899475 
	C547.948914,649.299133 545.984863,649.698730 543.150818,650.023682 
	C542.696594,651.405334 541.485352,653.580261 541.937744,654.011963 
	C543.057434,655.080566 544.935120,655.856018 546.513733,655.866089 
	C564.880859,655.982727 583.249146,655.932312 601.617065,655.932556 
	C624.417603,655.932861 647.218079,655.948059 670.018616,655.927185 
	C671.964355,655.925415 673.909851,655.701233 676.046021,655.568604 
	C676.725891,645.114807 677.377441,635.095703 678.348511,624.540955 
	C678.375488,623.133057 678.402466,621.725098 679.105347,619.733398 
	C678.391785,619.121887 677.678162,618.510315 676.471802,617.456787 
	C673.168518,616.535522 672.225891,618.908447 671.058655,621.189758 
	C666.699829,629.709534 662.290222,638.231445 654.232605,644.005676 
	C648.688232,647.978882 642.726868,650.362366 635.824341,647.081787 
	C635.766907,617.067261 635.709473,587.052673 636.011169,556.416138 
	C637.132996,555.720764 638.254761,555.025391 639.916626,554.455383 
	C640.269226,554.374817 640.621826,554.294189 641.599731,554.710693 
	C643.255676,554.750061 644.911926,554.778015 646.567444,554.831299 
	C649.270203,554.918335 651.708496,554.592407 651.642029,551.052063 
	C651.574158,547.442627 648.976929,547.484619 646.365662,547.486572 
	C630.614990,547.498108 614.864075,547.443542 599.113892,547.524292 
	C597.625854,547.531921 596.141846,548.322266 594.117615,549.326904 
	C592.295288,557.636047 600.016357,552.963928 602.913086,555.838562 
	C602.913086,586.261780 602.913086,617.062256 602.540955,648.173401 
	C602.337219,648.424011 602.133972,648.675049 601.050476,648.860840 
	C598.760925,649.158997 596.471375,649.457153 593.325256,649.594360 
	C590.864929,649.099243 588.404663,648.604065 585.706360,647.384155 
	C585.497681,646.920410 585.289062,646.456665 585.284485,645.703247 
	C585.287598,645.412292 585.144348,645.251526 584.641602,644.480164 
	C578.536255,627.638733 572.564636,610.747314 566.284241,593.971436 
	C561.064880,580.029907 555.482361,566.224426 550.354065,551.682007 
	C548.513428,547.101501 548.513428,547.101501 544.578857,546.892822 
M477.705933,555.691406 
	C477.234863,555.379211 476.763794,555.066956 476.036377,554.112122 
	C469.134857,552.125000 462.314575,548.846985 455.315704,548.406311 
	C438.151001,547.325562 420.904266,547.528015 403.689423,547.296936 
	C402.148071,547.276184 400.598663,547.856812 398.283813,548.101013 
	C397.514648,549.334717 396.170013,550.576355 396.187042,551.799133 
	C396.197693,552.561768 398.179535,553.678589 399.427887,553.955750 
	C401.471985,554.409546 403.639191,554.308899 406.264435,554.474182 
	C406.264435,556.452332 406.263702,558.078430 406.264526,559.704590 
	C406.274811,580.333069 406.257111,600.961548 406.309204,621.589905 
	C406.331451,630.393372 406.499451,639.196350 406.277130,648.558350 
	C406.195740,648.757751 406.114380,648.957092 405.175385,649.252136 
	C403.166412,649.794678 401.157471,650.337158 398.728912,650.163818 
	C397.896637,650.975037 396.223083,652.180664 396.384918,652.525757 
	C396.940521,653.710815 398.138824,654.594543 399.928436,655.865479 
	C402.273102,655.845398 404.617737,655.825317 407.496582,655.979553 
	C407.675354,655.923645 407.854126,655.867737 408.850037,655.943420 
	C410.225952,655.897766 411.601837,655.852173 413.768555,656.018921 
	C414.850830,655.948608 415.933136,655.878296 417.938141,655.944275 
	C422.950653,655.904541 427.963135,655.864807 433.837097,656.034424 
	C435.896149,655.961121 437.955231,655.887817 440.933777,655.924133 
	C447.648193,655.342896 454.362579,654.761719 461.587830,654.300964 
	C461.733276,654.203735 461.878723,654.106567 462.681885,654.067749 
	C463.108459,653.968262 463.535004,653.868774 464.542938,653.896423 
	C464.691132,653.764587 464.839325,653.632751 465.543457,653.387939 
	C466.340729,652.909363 467.137970,652.430725 468.480713,652.036255 
	C471.209381,651.326782 474.062134,650.919434 476.646576,649.858582 
	C493.270905,643.034912 497.082275,616.572266 482.927246,605.597473 
	C479.978149,603.310974 476.104401,602.249878 472.830597,600.328003 
	C471.918457,599.792542 471.629028,598.196350 471.783875,597.073425 
	C473.367706,596.185364 475.010895,595.387878 476.525879,594.394714 
	C487.402283,587.264893 489.210846,569.822388 481.342621,559.271484 
	C481.342621,559.271484 481.025574,558.980896 480.973663,558.368774 
	C480.338562,557.898987 479.703430,557.429199 478.726257,556.649841 
	C478.467743,556.481201 478.194763,556.340698 477.705933,555.691406 
M179.277878,498.376312 
	C179.163712,498.249298 179.049545,498.122284 178.883835,497.152649 
	C174.503479,479.100739 175.356857,460.855530 177.191116,442.632965 
	C177.615067,438.421326 178.976044,434.140594 180.715683,430.254150 
	C187.006470,416.200043 201.556549,413.908630 211.390732,425.797699 
	C216.518448,431.996918 220.076508,439.477295 224.613434,446.196808 
	C225.522568,447.543274 227.611679,448.593262 229.240341,448.721985 
	C229.950211,448.778046 231.616074,446.143463 231.576828,444.767059 
	C231.293427,434.828979 230.684402,424.900146 230.594955,414.566406 
	C228.825684,411.730194 227.157043,410.339996 225.244995,414.923553 
	C224.721436,415.262054 224.197876,415.600525 222.929199,415.845886 
	C221.460297,415.633057 219.936783,415.601715 218.531158,415.178650 
	C207.587997,411.884979 196.494553,410.374939 185.102554,412.157715 
	C176.672211,413.476990 168.514603,415.497925 161.283356,421.755005 
	C154.383255,427.076782 148.903030,433.454285 145.963715,442.628845 
	C140.889832,456.471680 140.403885,470.615875 143.489410,484.904053 
	C147.348160,502.772827 157.979034,514.545349 175.738800,519.376282 
	C186.317642,522.253845 197.094696,522.439270 207.896622,520.514038 
	C213.259445,519.558167 218.624893,518.617126 224.213562,518.264771 
	C224.767273,518.522827 225.320984,518.780884 226.511841,519.222229 
	C227.001999,519.612000 227.492172,520.001770 227.995605,521.029907 
	C228.291992,521.545898 228.588364,522.061951 228.884766,522.577942 
	C229.249420,521.965637 229.614059,521.353333 230.327667,520.351135 
	C230.705627,519.617981 231.083588,518.884766 232.232941,517.695129 
	C232.384933,510.890106 232.536926,504.085114 232.349442,496.374481 
	C232.160034,492.894592 231.970612,489.414734 232.028809,485.445801 
	C229.381012,483.168335 227.146515,483.664307 225.495102,486.599152 
	C223.866989,489.492584 222.683304,492.667206 220.829773,495.395935 
	C217.579178,500.181488 214.400589,505.140472 210.410583,509.272430 
	C202.992737,516.954102 195.622009,516.643372 184.737885,508.596283 
	C184.393875,508.302155 184.049881,508.008026 183.501099,506.983337 
	C182.095184,504.285095 180.689270,501.586853 179.277878,498.376312 
M157.832153,615.661926 
	C157.925339,616.106750 158.018524,616.551636 157.909760,617.915161 
	C157.980774,625.274902 158.051804,632.634644 157.913086,640.493774 
	C157.972824,640.664124 158.032547,640.834534 157.896896,641.495605 
	C157.932480,641.667969 157.968079,641.840393 157.723740,642.552734 
	C157.621857,643.357971 157.519974,644.163147 157.040222,645.528564 
	C157.052643,645.753479 157.065048,645.978333 156.730530,646.805054 
	C154.357422,650.791687 150.470367,652.122070 146.528610,650.734680 
	C140.802979,648.719299 136.119751,644.983948 133.896866,638.927002 
	C132.817535,635.986084 131.746887,633.041931 130.727081,629.300781 
	C130.331741,627.499268 129.936401,625.697693 129.722916,623.495728 
	C129.722916,623.495728 129.475891,623.131958 129.637207,622.235413 
	C129.326569,617.799927 129.015930,613.364441 128.937958,608.032654 
	C128.858353,603.049805 128.778763,598.067017 128.901123,592.271912 
	C129.200455,583.202576 129.355667,574.015686 133.640640,565.773865 
	C135.555664,562.090454 138.226547,558.300842 141.540070,555.947021 
	C148.543655,550.971985 157.828476,553.114990 164.829239,561.546692 
	C169.873444,567.621948 173.623215,574.757446 178.255844,581.200317 
	C179.275162,582.617920 181.586472,583.106445 183.302841,584.022766 
	C183.995239,582.398499 185.297195,580.767029 185.278275,579.151123 
	C185.177124,570.512085 184.746201,561.877014 184.652222,552.583435 
	C183.786667,550.829468 182.921112,549.075500 182.055557,547.321533 
	C180.761597,548.253601 179.467636,549.185730 178.056091,550.044617 
	C178.056091,550.044617 178.144867,550.150940 177.415512,550.303650 
	C176.151154,550.351929 174.849487,550.619812 173.628586,550.412354 
	C166.437515,549.190674 159.310669,547.256470 152.075562,546.710754 
	C142.323318,545.975159 132.535339,546.513489 123.118629,551.859009 
	C122.410927,552.243713 121.703224,552.628357 120.385880,552.979736 
	C119.944031,553.329407 119.502182,553.679138 118.397202,554.201782 
	C108.283844,560.971497 99.830627,568.930664 98.022339,582.722961 
	C98.031883,583.301514 98.041435,583.880127 97.449646,584.764221 
	C96.915108,585.708679 95.963432,586.630920 95.911598,587.601135 
	C95.472771,595.816345 94.266533,604.130920 95.055397,612.244019 
	C97.109032,633.364502 108.104980,650.007935 131.593643,655.143860 
	C145.355515,658.152954 158.683197,656.417908 172.423431,652.421814 
	C172.933136,652.205872 173.442841,651.989929 174.798523,651.801880 
	C177.513367,652.540222 180.228210,653.278503 182.975754,654.525085 
	C183.676376,654.851685 184.376999,655.178345 185.837784,655.776184 
	C186.593170,655.577637 187.348557,655.379089 188.814240,654.882263 
	C189.035446,652.423218 189.428787,649.965393 189.447403,647.504822 
	C189.519043,638.027832 189.482193,628.549866 189.459656,619.072266 
	C189.453659,616.543396 189.098022,614.093811 192.931061,613.778381 
	C193.817184,613.705444 195.067505,611.095703 195.062592,609.657043 
	C195.059418,608.730652 193.080414,607.021606 191.971634,607.008972 
	C178.672409,606.857666 165.370193,606.996765 152.069046,606.959778 
	C149.493851,606.952637 147.990173,608.116516 148.667786,610.457153 
	C149.080994,611.884460 150.758133,613.304138 152.214310,613.977966 
	C153.926422,614.770264 156.014008,614.751038 158.008682,615.067749 
	C158.008682,615.067749 158.023727,614.997681 157.832153,615.661926 
M502.329803,412.313690 
	C489.346100,412.246979 476.362213,412.134186 463.379089,412.194092 
	C462.241425,412.199341 461.109039,413.337708 459.768616,414.742920 
	C459.872620,415.816772 459.976624,416.890625 460.209991,418.700806 
	C462.572144,418.949951 464.934265,419.199127 467.608917,420.281555 
	C467.592743,451.412476 467.576538,482.543396 466.873077,513.815063 
	C465.580566,514.167969 464.288055,514.520935 462.104034,514.682617 
	C461.264648,515.941833 459.906677,517.153687 459.785858,518.478455 
	C459.716492,519.238953 461.646240,520.921448 462.688965,520.932129 
	C476.951782,521.078979 491.218567,521.096313 505.478119,520.820679 
	C506.647980,520.798035 507.772186,518.413940 508.917603,517.127075 
	C507.654724,516.264160 506.456238,515.279053 505.111908,514.570801 
	C503.600861,513.774658 501.958160,513.228455 500.494141,511.767273 
	C500.513000,483.009033 500.521545,454.250793 500.571411,425.492615 
	C500.575104,423.349182 500.874664,421.206268 501.905426,419.127075 
	C504.595612,418.567780 509.022858,420.856049 508.834442,415.436340 
	C508.702881,411.653748 505.694946,412.394531 502.329803,412.313690 
M479.456848,272.415924 
	C481.554413,272.562958 483.651978,272.709991 485.749542,272.857056 
	C485.964874,272.165863 486.180237,271.474701 486.395569,270.783508 
	C484.307678,269.686890 482.219788,268.590271 480.131897,267.493652 
	C479.751160,268.926788 479.370422,270.359955 479.456848,272.415924 
M507.481537,256.309875 
	C508.864746,256.073273 510.247955,255.836655 511.631195,255.600067 
	C511.433167,255.031403 511.235138,254.462753 511.037109,253.894089 
	C509.846497,254.478027 508.655884,255.061951 507.481537,256.309875 
M509.077209,128.792480 
	C510.217896,128.677429 511.358551,128.562378 512.499268,128.447327 
	C512.352905,127.911789 512.206543,127.376251 512.060242,126.840706 
	C510.889954,127.338074 509.719666,127.835449 509.077209,128.792480 
M527.696960,238.351837 
	C527.348938,239.062668 527.000916,239.773483 526.652954,240.484314 
	C527.089661,240.572006 527.526306,240.659698 527.963013,240.747406 
	C528.088806,239.983978 528.214600,239.220535 527.696960,238.351837 
M297.758698,410.822296 
	C297.589172,410.878632 297.419617,410.934967 297.250092,410.991302 
	C297.420654,411.046173 297.591217,411.101013 297.758698,410.822296 
M286.516327,422.409271 
	C286.516327,422.409271 286.584076,422.522034 286.516327,422.409271 
z"/>
<path fill="#0D0D0D" opacity="1.000000" stroke="none" 
	d="
M714.000000,4.500122 
	C476.638641,4.999525 239.277267,4.999050 1.457952,4.999288 
	C1.000000,3.667592 1.000000,2.335183 1.000000,1.002081 
	C238.666672,1.001387 476.333344,1.001387 714.000000,1.001858 
	C714.000000,2.001633 714.000000,3.000939 714.000000,4.500122 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M349.173889,412.966644 
	C349.950714,412.992371 350.727478,413.018097 351.807953,413.506622 
	C351.204407,417.485504 352.319611,419.254883 356.186859,418.889618 
	C357.785736,418.738586 359.414032,418.898346 361.106323,419.288025 
	C361.407166,421.424072 361.630554,423.188873 361.727478,425.341736 
	C361.615417,454.808563 361.629822,483.887299 361.400635,513.287598 
	C361.079346,514.137390 361.001648,514.665771 360.477173,515.130188 
	C357.462738,515.066284 354.895142,515.066284 351.722931,515.066284 
	C352.081329,517.236450 352.360962,518.929626 352.640564,520.622803 
	C351.956696,520.492615 351.272827,520.362427 350.241943,519.817627 
	C349.831909,519.228149 349.768951,519.053345 349.826874,518.529541 
	C349.305023,517.136719 348.662354,516.093018 347.699677,514.820801 
	C345.537170,514.468323 343.694611,514.344299 341.697296,513.800293 
	C341.450470,510.726959 341.280731,508.073578 341.278442,505.420044 
	C341.255615,479.138641 341.260132,452.857269 341.257629,426.575867 
	C341.256958,419.247162 341.258026,419.247162 348.431580,418.553772 
	C348.556732,417.658569 348.685394,416.763885 348.804138,415.867859 
	C348.932220,414.901398 349.051056,413.933777 349.173889,412.966644 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M357.000763,101.998260 
	C358.039886,106.095001 359.255859,110.157433 360.059509,114.299850 
	C360.652069,117.353966 360.939728,120.512314 360.942505,123.624596 
	C360.946198,127.720421 362.146667,129.390762 366.654480,128.420441 
	C369.632904,127.779312 372.900574,128.304337 376.020233,128.546082 
	C379.210632,128.793320 382.613800,130.494415 384.592102,125.974182 
	C384.760803,125.588768 388.989349,126.980179 391.331879,127.546165 
	C391.290802,128.046387 391.249725,128.546616 391.208679,129.046829 
	C392.348175,128.470276 393.442139,127.765755 394.635590,127.340691 
	C398.510071,125.960815 400.881470,127.339966 400.921021,131.388550 
	C400.955078,134.868546 402.295837,136.297791 405.701080,136.007401 
	C410.484650,135.599457 414.539886,136.059158 414.211273,142.541901 
	C414.169220,143.371323 415.782318,144.284637 417.183472,144.972382 
	C416.494141,140.150177 417.034363,136.657043 422.441162,135.740219 
	C423.134460,135.622650 424.006256,133.295990 423.817169,132.154755 
	C423.628418,131.015366 422.278351,129.284241 421.319000,129.202499 
	C418.348389,128.949310 416.583466,127.356018 417.327332,124.846817 
	C417.719482,123.524048 420.517029,122.828262 422.315887,122.051788 
	C423.018280,121.748604 423.989563,122.142075 424.788666,121.977196 
	C430.201324,120.860367 433.035950,123.666733 434.877869,129.043839 
	C434.663574,131.507156 434.632721,134.603455 433.772919,134.853897 
	C425.777863,137.182770 427.009003,144.550171 424.575867,150.234192 
	C423.112915,150.403687 422.032867,150.437988 420.761963,150.120651 
	C418.382263,149.460968 416.193481,149.152924 413.958618,148.502960 
	C412.731689,147.158127 411.550842,146.155167 410.369995,145.152206 
	C409.913086,146.087936 409.456146,147.023651 408.618408,147.962692 
	C405.823792,147.322861 403.409973,146.679718 400.951660,145.656555 
	C398.833527,144.136078 396.759888,142.995605 394.285431,141.634689 
	C396.282654,141.137390 398.011688,140.706879 399.974670,140.218124 
	C398.029938,137.036285 396.406952,134.380844 395.103455,132.248108 
	C392.625183,134.423782 391.113708,136.211304 389.206360,137.314026 
	C386.543762,138.853363 383.585480,139.881241 380.458099,141.148331 
	C379.774567,141.098755 379.386688,141.028824 378.962646,140.611511 
	C376.885895,135.347244 373.839355,137.755463 370.560852,139.177368 
	C369.461456,139.092346 368.731140,138.995895 367.961182,138.603241 
	C367.246033,138.289642 366.570435,138.272263 365.486023,138.259827 
	C363.716400,138.188736 362.355621,138.112701 360.941193,137.676239 
	C359.895203,135.362442 359.028717,133.329239 357.859863,131.487961 
	C357.235321,130.504135 356.165436,129.349335 355.156219,129.196136 
	C354.223419,129.054535 353.061371,130.080765 352.077148,130.712387 
	C350.996857,131.405655 350.004364,132.235794 348.933502,132.606247 
	C348.940887,131.136765 348.988373,130.067337 349.329468,128.960724 
	C349.938751,128.604446 350.254395,128.285370 350.570068,127.966278 
	C350.067596,127.644585 349.565094,127.322891 349.018097,126.624313 
	C349.063721,125.497673 349.153839,124.747932 349.552734,123.934937 
	C349.837677,122.874016 349.813843,121.876358 349.784912,120.516548 
	C349.855438,119.435089 349.931030,118.715767 350.365448,117.932640 
	C350.757446,116.220695 350.790649,114.572571 350.813904,112.555855 
	C350.905060,111.458923 351.006195,110.730568 351.401184,109.962563 
	C351.722107,109.251320 351.749176,108.579735 351.777069,107.542084 
	C351.869751,106.451317 351.961548,105.726608 352.396301,104.963028 
	C353.490845,104.137833 354.166870,103.252037 355.022247,102.602394 
	C355.537476,102.211067 356.331848,102.187233 357.000763,101.998260 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M405.968658,225.818268 
	C406.039368,232.825928 408.091522,236.051025 414.815918,236.475983 
	C417.626740,236.653610 420.659882,233.546494 423.562836,231.882080 
	C424.374756,231.416611 425.083038,230.770309 426.838959,229.461502 
	C426.700470,234.069489 423.445068,238.379608 428.051117,242.031769 
	C429.435364,243.394394 430.699341,244.054749 432.030060,244.749985 
	C430.855408,245.009598 429.758820,245.251968 428.243256,245.526978 
	C408.683746,243.384964 391.696686,236.356262 377.331757,223.310165 
	C366.435944,213.414658 358.314880,201.770920 354.725677,187.588867 
	C360.101990,190.904175 365.204712,193.899139 369.983124,197.343918 
	C375.113647,201.042572 379.939453,205.163864 384.948486,209.456146 
	C386.710968,212.571304 388.515228,215.278778 390.118652,218.100342 
	C395.420227,227.429565 395.801819,227.645187 405.968658,225.818268 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M522.379578,42.996552 
	C522.295288,44.396053 522.210999,45.795555 522.111938,48.048512 
	C522.071289,57.530277 522.098267,66.159142 522.006836,74.786758 
	C521.884033,86.372795 521.669678,97.957863 521.136169,109.428711 
	C519.887207,109.018585 518.996704,108.723083 518.384766,108.125458 
	C519.136292,105.929070 520.013245,104.036354 520.020508,102.140289 
	C520.108215,79.411095 520.101501,56.681252 519.996521,33.952148 
	C519.987671,32.035553 519.093689,30.123049 518.610962,28.208643 
	C518.122314,28.146204 517.633728,28.083765 517.145081,28.021326 
	C515.678711,29.935196 514.212402,31.849068 512.465332,33.953064 
	C507.046417,39.248791 500.592499,39.421978 493.786377,38.962807 
	C493.593048,38.926098 493.199493,38.922535 492.924652,38.623756 
	C488.059265,37.556725 483.468750,36.788471 478.716736,35.995766 
	C478.555298,35.971321 478.229645,35.946621 477.972717,35.641792 
	C473.793365,34.598053 469.870941,33.859142 465.747131,33.073509 
	C465.545776,33.026787 465.136108,32.971203 464.872864,32.652489 
	C459.368347,31.550669 454.127106,30.767559 448.574097,29.978897 
	C447.849243,30.019314 447.436127,30.065283 446.612732,30.035648 
	C441.644501,29.301693 437.106232,28.329365 432.524994,28.042025 
	C416.819580,27.056965 401.354797,29.529303 385.978973,32.173473 
	C379.131409,33.351048 372.529724,35.958549 365.547546,37.936043 
	C364.830078,38.212414 364.381470,38.469788 363.559235,38.767921 
	C360.413269,40.123837 357.640930,41.438988 354.521759,42.828125 
	C347.760956,47.185120 341.346954,51.468124 334.626770,55.807419 
	C333.862274,56.229965 333.403992,56.596226 332.957153,56.964653 
	C332.968536,56.966824 332.948059,56.933167 332.638306,57.066433 
	C319.638336,66.487503 309.527252,78.136887 301.007996,91.267517 
	C299.890656,92.989609 298.393890,94.465523 296.982727,95.690369 
	C296.991058,94.582085 297.089539,93.838875 297.420624,92.926163 
	C297.903809,92.046211 298.154388,91.335770 298.709045,90.466362 
	C300.003265,88.535950 300.993439,86.764511 301.983582,84.993057 
	C302.320984,84.561890 302.658386,84.130730 303.065491,83.453300 
	C303.135132,83.207039 303.183563,83.190056 303.489960,83.124863 
	C304.203705,82.373543 304.611053,81.687401 305.281372,80.797623 
	C312.109741,73.639687 318.505554,66.513718 325.305511,59.796883 
	C329.249634,55.900974 333.843414,52.662807 338.144867,49.128654 
	C338.144867,49.128658 338.003235,49.112904 338.406677,49.093960 
	C344.665741,45.688557 350.321960,41.881527 356.413696,38.992916 
	C376.283661,29.570894 397.595032,26.431158 419.303528,26.203037 
	C431.527161,26.074591 443.765350,27.330912 455.996979,27.963406 
	C457.024658,28.130545 458.052368,28.297688 459.776794,28.658562 
	C471.619843,31.211344 482.760406,33.597740 493.914062,35.921535 
	C504.476654,38.122181 511.547058,34.838257 514.450745,26.466013 
	C514.450745,26.466013 514.407349,26.429062 514.766113,26.297615 
	C520.299438,24.019016 521.634155,24.766474 521.936279,30.191555 
	C522.173645,34.454899 522.238220,38.727863 522.379578,42.996552 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M441.078430,422.830902 
	C447.272491,429.759888 447.024536,437.967712 445.704620,446.363190 
	C444.274384,455.460358 438.701080,461.119141 429.780975,464.085449 
	C430.689880,465.143036 431.001038,465.812561 431.514404,466.056366 
	C439.689941,469.939270 441.968964,477.201324 442.412994,485.360260 
	C442.711060,490.837708 442.436523,496.345612 442.707520,501.825562 
	C442.829498,504.291656 443.657898,506.722839 444.165771,509.169861 
	C444.914124,509.280365 445.662506,509.390869 446.410858,509.501373 
	C447.475159,506.803864 449.062103,504.185394 449.484802,501.390808 
	C450.168762,496.868866 452.185181,495.760468 457.509094,498.042999 
	C455.521606,503.753235 454.251007,509.997070 451.277618,515.283081 
	C447.646729,521.737976 440.298920,521.371643 433.880798,521.836914 
	C431.108032,522.037781 428.248444,521.454712 425.464142,521.005676 
	C416.427277,519.548462 410.591766,514.113525 409.566010,504.961761 
	C408.624329,496.560333 408.782928,488.039948 408.301056,479.580811 
	C408.056274,475.283234 407.981415,470.796906 403.565247,467.626678 
	C403.955719,466.559967 404.448334,465.952606 405.269958,465.392120 
	C409.621185,468.112244 410.833771,472.197021 410.946686,476.664581 
	C411.106537,482.987885 410.709320,489.339569 411.148499,495.637390 
	C411.581055,501.840393 411.832336,508.308502 416.998138,513.003540 
	C417.000000,513.000000 416.992767,513.003662 417.131592,513.326294 
	C427.958252,521.192200 445.960907,519.973877 449.378845,511.476013 
	C440.015106,509.588928 440.013458,509.588928 439.971466,499.599274 
	C439.951874,494.936340 440.025543,490.271210 439.907593,485.610870 
	C439.642334,475.127869 435.138611,469.210266 425.317932,466.176941 
	C423.937225,465.750488 422.613281,465.140320 421.263062,464.615234 
	C421.204132,464.200806 421.145233,463.786407 421.086304,463.372009 
	C433.955475,460.597656 443.214783,454.429016 443.906433,439.729675 
	C443.931854,438.160553 443.906281,437.018219 443.854736,435.501343 
	C442.816132,431.514465 441.803467,427.902161 440.851562,424.044434 
	C440.917816,423.630493 440.923309,423.462036 440.998627,423.188416 
	C441.068481,423.083252 441.078430,422.830902 441.078430,422.830902 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M98.050980,584.458679 
	C98.041435,583.880127 98.031883,583.301514 98.600388,582.346558 
	C99.815483,581.340271 100.787170,580.821960 101.039803,580.064026 
	C105.359055,567.104858 114.817383,558.806152 126.546585,552.135437 
	C131.762558,551.432800 136.566238,550.758545 141.256714,550.419312 
	C138.520142,553.637207 135.385040,556.203552 133.369003,559.462158 
	C127.237862,569.372253 126.464981,580.529480 126.648743,591.982971 
	C126.256454,592.362366 126.177475,592.639465 126.188309,593.424316 
	C126.180336,599.617676 126.222458,605.343079 126.197868,611.347290 
	C126.373901,612.118164 126.616646,612.610291 126.866280,613.531738 
	C127.276680,617.986328 127.680183,622.011475 128.124313,626.274780 
	C128.295364,626.820496 128.500671,627.065247 128.769531,627.624451 
	C128.844727,629.003174 128.931198,630.004822 128.994324,631.405029 
	C129.923370,638.782898 133.060562,644.447449 138.618362,648.911743 
	C139.811157,649.869934 140.017670,652.056030 140.341187,653.680298 
	C139.106232,653.659363 138.208603,653.634583 137.192825,653.371094 
	C136.385590,653.058289 135.696503,652.984131 134.629959,652.871338 
	C108.744240,647.313110 97.269173,628.120178 97.170921,604.708435 
	C97.142609,597.959900 97.740288,591.208679 98.050980,584.458679 
M100.991089,592.585693 
	C99.541954,603.821533 99.751793,614.650879 104.400322,625.506348 
	C107.810867,633.470825 112.422607,639.619629 119.486450,644.412781 
	C122.481575,646.445068 125.019630,646.320862 126.859283,643.880737 
	C128.060516,642.287415 128.453140,639.521851 128.105225,637.468201 
	C127.687859,635.004578 125.379623,632.807800 125.149178,630.376099 
	C124.442955,622.923950 124.030632,615.412903 124.072601,607.927979 
	C124.137650,596.325256 124.653191,584.724487 125.038322,573.124939 
	C125.075310,572.010864 125.503395,570.900818 125.812416,569.806580 
	C126.611267,566.977844 127.445091,564.158997 128.264954,561.336243 
	C128.002838,561.300903 127.740715,561.265625 127.478592,561.230347 
	C129.102737,559.690430 130.726898,558.150574 132.351044,556.610657 
	C132.117844,556.128662 131.884628,555.646729 131.651428,555.164734 
	C130.096512,555.164734 128.313263,554.661987 127.024261,555.247437 
	C119.397972,558.711243 112.642365,563.893188 108.486557,570.896606 
	C104.791939,577.122803 103.405121,584.718506 100.991089,592.585693 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M227.042725,181.019226 
	C223.176193,178.643112 223.901810,174.786606 224.033112,171.232147 
	C224.272827,164.742767 224.712296,158.260773 225.052414,152.067673 
	C225.711182,151.605484 226.025192,151.212585 226.236664,151.261642 
	C235.319214,153.368942 243.813431,148.816315 252.737244,148.853699 
	C254.387299,148.860611 256.045135,147.003464 258.143311,145.739166 
	C265.979858,151.107849 273.381989,144.859955 281.611633,143.430222 
	C281.018799,144.886200 280.261475,146.453537 279.034149,147.044067 
	C274.457886,149.245926 269.484192,150.705338 265.110962,153.226288 
	C258.301392,157.151642 250.510193,159.815384 245.477142,166.855789 
	C244.439270,166.953751 243.742691,166.987747 242.761078,166.777084 
	C240.902664,162.286880 239.399826,162.043945 232.072296,164.850708 
	C228.846741,166.086243 228.840973,166.100327 230.133850,169.592270 
	C234.601639,166.485168 234.595642,166.497559 239.566803,168.905777 
	C239.981003,169.106430 240.550293,168.986954 241.028931,169.325531 
	C240.957397,170.043365 240.904434,170.451920 240.497971,170.904846 
	C238.187790,172.383728 236.202057,173.780563 234.281723,175.262161 
	C231.840988,177.145248 229.453293,179.097046 227.042725,181.019226 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M349.117859,412.982452 
	C349.051056,413.933777 348.932220,414.901398 348.804138,415.867859 
	C348.685394,416.763885 348.556732,417.658569 348.431580,418.553772 
	C341.258026,419.247162 341.256958,419.247162 341.257629,426.575867 
	C341.260132,452.857269 341.255615,479.138641 341.278442,505.420044 
	C341.280731,508.073578 341.450470,510.726959 341.668701,514.138672 
	C342.461884,515.445862 343.128906,515.994812 343.822510,516.805298 
	C343.908081,517.417175 343.967133,517.767578 343.740417,518.361023 
	C341.296326,518.426453 339.138031,518.248840 337.342834,517.948242 
	C337.853485,515.239990 338.124664,512.654907 338.129120,510.069336 
	C338.165161,489.250763 338.144806,468.432098 338.154999,447.613464 
	C338.159454,438.486633 337.821930,429.336700 338.385864,420.243500 
	C338.638916,416.163452 337.824738,414.716431 333.814240,414.797607 
	C327.654724,414.922272 321.484406,414.972168 315.330536,414.739685 
	C311.747406,414.604370 310.857025,415.941589 310.915436,419.398712 
	C311.137634,432.552216 311.131012,445.712128 310.963531,458.866516 
	C310.941010,460.636139 309.512543,462.387878 308.533142,463.892426 
	C308.239502,462.780731 308.147797,461.924500 308.080566,460.789551 
	C308.034363,460.338074 307.963745,460.165405 307.893097,459.992706 
	C307.991516,448.300049 308.089935,436.607422 308.205750,424.067200 
	C308.064362,422.159271 307.905609,421.098938 307.746826,420.038574 
	C305.209656,419.240387 302.672516,418.442200 300.022705,416.952209 
	C300.013977,415.206757 300.117859,414.153107 300.221710,413.099457 
	C300.221710,413.099457 300.705719,413.006592 301.304382,412.909180 
	C308.311493,412.662903 314.720001,412.514038 321.128479,412.365173 
	C323.111969,412.465485 325.095459,412.565826 327.730408,412.728455 
	C329.618896,412.651917 330.855865,412.513123 332.092865,412.374329 
	C334.095825,412.460388 336.098755,412.546417 338.850220,412.708252 
	C342.741211,412.818481 345.883698,412.852936 349.026184,412.887390 
	C349.026184,412.887390 349.061798,412.998260 349.117859,412.982452 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M385.248199,632.574829 
	C380.357178,643.425720 371.729004,650.234924 360.735870,653.734558 
	C346.927917,658.130432 332.835510,658.060364 319.012817,653.569763 
	C304.541260,648.868469 295.633118,638.538696 291.969513,624.042236 
	C287.039825,604.536255 288.011475,585.474609 298.247742,567.555359 
	C298.653870,566.844421 299.222137,566.226135 300.187317,565.605103 
	C300.784424,565.774353 300.909058,565.903137 301.021606,566.404785 
	C292.799225,582.528442 289.826233,599.173401 293.090057,616.680603 
	C294.791504,625.806946 297.356079,634.720520 305.048340,641.424683 
	C312.618439,649.256592 321.841064,652.437012 332.455658,653.237183 
	C338.407562,653.258423 344.069672,653.026245 350.144104,652.847839 
	C353.595978,652.240234 356.957825,652.188965 359.607452,650.789429 
	C364.980133,647.951416 369.967255,644.383545 375.445923,641.046753 
	C377.893585,638.306641 380.008698,635.636292 382.316711,632.863281 
	C382.748230,632.572266 382.903992,632.330505 383.060944,631.801697 
	C383.309113,631.272156 383.532471,631.031189 384.130432,631.062622 
	C384.713226,631.711609 384.980713,632.143250 385.248199,632.574829 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M128.083679,626.036621 
	C127.680183,622.011475 127.276680,617.986328 126.902473,613.248047 
	C126.709381,612.046082 126.486984,611.557251 126.264580,611.068481 
	C126.222458,605.343079 126.180336,599.617676 126.362228,593.288574 
	C126.791573,592.439026 126.870193,592.157715 126.822128,591.840820 
	C126.464981,580.529480 127.237862,569.372253 133.369003,559.462158 
	C135.385040,556.203552 138.520142,553.637207 141.681885,550.396606 
	C148.469620,550.538330 154.719009,551.037720 160.956558,551.934265 
	C161.650467,553.413269 162.267227,554.567322 163.076645,555.565186 
	C167.739731,561.313660 172.494324,566.988586 177.112106,572.772888 
	C178.402420,574.389160 179.334717,576.291260 180.431229,578.062256 
	C180.947220,577.874268 181.463196,577.686218 181.979187,577.498230 
	C181.979187,571.088501 182.215973,564.665588 181.858902,558.275818 
	C181.722229,555.830078 180.227997,553.460083 179.129944,550.934570 
	C178.653275,550.592712 178.399063,550.371826 178.144867,550.150940 
	C178.144867,550.150940 178.056091,550.044617 178.476807,550.117065 
	C182.742783,547.577942 183.211517,551.088257 184.429291,553.240540 
	C184.746201,561.877014 185.177124,570.512085 185.278275,579.151123 
	C185.297195,580.767029 183.995239,582.398499 183.302826,584.022766 
	C181.586472,583.106445 179.275162,582.617920 178.255844,581.200317 
	C173.623215,574.757446 169.873444,567.621948 164.829239,561.546692 
	C157.828476,553.114990 148.543655,550.971985 141.540070,555.947021 
	C138.226547,558.300842 135.555664,562.090454 133.640640,565.773865 
	C129.355667,574.015686 129.200455,583.202576 128.568832,593.007568 
	C128.392792,598.805115 128.549042,603.867065 128.705292,608.928955 
	C129.015930,613.364441 129.326569,617.799927 129.466171,622.765991 
	C129.203354,623.561707 129.285324,623.761536 129.541077,623.896179 
	C129.936401,625.697693 130.331741,627.499268 130.486908,629.804565 
	C129.837051,630.541016 129.427368,630.773682 129.017670,631.006409 
	C128.931198,630.004822 128.844727,629.003174 128.761261,627.370422 
	C128.537415,626.505066 128.310547,626.270813 128.083679,626.036621 
z"/>
<path fill="#F7E2C0" opacity="1.000000" stroke="none" 
	d="
M493.905151,130.843689 
	C492.717804,131.364532 491.530457,131.885361 489.786987,132.721008 
	C486.982880,134.009811 484.720856,134.952774 482.489624,135.963791 
	C476.481171,138.686295 472.642761,137.044891 470.486053,130.901901 
	C469.428894,127.890663 468.199280,124.939941 467.047729,121.961838 
	C467.047729,121.961838 467.018402,121.980507 467.033325,121.578690 
	C465.718079,118.099815 464.387909,115.022766 463.057739,111.945717 
	C463.057739,111.945717 463.006439,111.976685 462.995117,111.632004 
	C461.666351,109.521423 460.348907,107.755531 458.998108,105.644432 
	C456.321259,102.522354 453.677765,99.745491 451.017151,96.984314 
	C451.000000,97.000000 451.030914,96.965866 450.881104,96.662674 
	C445.825562,93.225929 440.919861,90.092384 435.647736,86.938644 
	C433.531982,86.248444 431.782623,85.578438 429.831665,84.653954 
	C428.422089,83.934204 427.214111,83.468933 425.634705,82.987061 
	C424.521118,82.957802 423.778961,82.945145 422.765564,82.656433 
	C409.872559,79.955910 397.319611,79.219246 384.987732,83.908585 
	C384.543762,83.919350 384.099792,83.930115 382.956940,83.992401 
	C374.983917,88.341507 367.746948,92.704460 360.401489,96.876450 
	C359.077972,97.628166 357.308136,97.594009 355.783752,97.539429 
	C355.828125,96.128906 355.833130,95.101639 356.127258,94.034744 
	C356.602661,93.943436 356.788940,93.891769 357.343750,93.922440 
	C359.569214,93.051445 361.451874,92.144623 363.278442,91.136223 
	C369.219086,87.856644 374.781860,83.200539 381.138489,81.504692 
	C396.313263,77.456291 411.878998,76.475410 427.301208,81.003998 
	C449.206024,87.436127 464.237213,101.150696 471.415222,123.261169 
	C471.536072,124.017860 471.774658,124.506271 471.992676,125.368553 
	C472.420319,127.158249 472.868530,128.574081 473.232910,130.355896 
	C474.866333,135.178909 476.767334,135.823364 480.704315,133.171814 
	C481.669800,132.825424 482.349792,132.493607 483.404724,132.143234 
	C487.505127,130.041306 491.230591,127.957932 495.290955,125.870132 
	C497.090790,124.923798 498.555756,123.981895 500.332794,123.010223 
	C501.179138,122.399910 501.713409,121.819374 502.604553,121.193466 
	C505.078857,119.860062 507.196320,118.572029 509.659515,117.235733 
	C511.669647,115.792107 513.333984,114.396759 515.282410,112.884338 
	C516.313660,112.541298 517.060852,112.315323 517.865173,112.446175 
	C517.022705,114.279053 516.376099,116.022034 515.165222,117.169662 
	C513.269287,118.966576 510.991974,120.361015 508.876221,121.925949 
	C507.999542,122.408447 507.122864,122.890945 505.753174,123.661499 
	C503.828339,124.949867 502.396515,125.950195 500.964691,126.950516 
	C500.964691,126.950508 500.979340,126.998085 500.595581,127.015579 
	C498.109558,128.303284 496.007355,129.573471 493.905151,130.843689 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M230.178543,414.968384 
	C230.684402,424.900146 231.293427,434.828979 231.576828,444.767059 
	C231.616074,446.143463 229.950211,448.778046 229.240341,448.721985 
	C227.611679,448.593262 225.522568,447.543274 224.613434,446.196808 
	C220.076508,439.477295 216.518448,431.996918 211.390732,425.797699 
	C201.556549,413.908630 187.006470,416.200043 180.715683,430.254150 
	C178.976044,434.140594 177.615067,438.421326 177.191116,442.632965 
	C175.356857,460.855530 174.503479,479.100739 178.602203,497.573059 
	C177.910568,497.996918 177.500565,498.000366 176.930466,497.691467 
	C176.519028,496.263855 176.267700,495.148621 176.149185,493.968201 
	C176.178619,493.926788 176.075256,493.950531 175.713501,493.685486 
	C175.251205,490.991547 175.047302,488.586304 174.889862,485.781281 
	C174.632202,483.930267 174.328094,482.479065 173.983765,480.648254 
	C173.958054,479.505859 173.972534,478.743073 174.243500,477.795441 
	C174.297119,476.729675 174.094269,475.848816 173.836975,474.570312 
	C173.856125,466.441132 173.929749,458.709534 174.271347,450.737915 
	C174.433304,448.698273 174.327286,446.898712 174.215912,444.713898 
	C174.412125,442.542358 174.613678,440.756042 174.878448,438.644623 
	C174.977493,437.879791 175.013336,437.440033 175.271698,436.985107 
	C175.494202,436.969879 175.930359,436.876556 176.108032,436.831360 
	C176.354248,436.686340 176.498077,436.517853 176.479507,436.497589 
	C176.323547,436.326599 176.137802,436.182800 175.932373,435.637024 
	C177.169983,428.697388 180.492706,423.359894 185.497833,419.036102 
	C187.081833,417.667755 188.755905,416.403656 190.824432,415.063721 
	C196.060242,415.361572 200.860352,415.687592 205.794464,416.343323 
	C208.609100,418.799530 211.289780,420.926025 214.073181,423.266876 
	C214.364258,423.751373 214.614609,423.938599 215.057648,424.359192 
	C217.454422,427.788208 219.720474,430.900940 222.017853,434.251068 
	C222.049210,434.488495 222.166351,434.952911 222.150253,435.305969 
	C223.811325,438.393585 225.488510,441.128143 227.738541,444.796753 
	C228.465256,442.721863 228.873291,442.117950 228.853561,441.528351 
	C228.595123,433.804749 228.350235,426.078583 227.869965,418.367493 
	C227.815720,417.496613 226.348404,416.713684 225.069214,415.840393 
	C224.292847,415.840240 223.983582,415.889648 223.674316,415.939026 
	C224.197876,415.600525 224.721436,415.262054 225.904572,414.926239 
	C227.768951,414.942078 228.973740,414.955231 230.178543,414.968384 
z"/>
<path fill="#69191E" opacity="1.000000" stroke="none" 
	d="
M231.781204,485.934845 
	C231.970612,489.414734 232.160034,492.894592 232.094147,496.923462 
	C231.713089,504.365479 231.587326,511.258545 231.461548,518.151611 
	C231.083588,518.884766 230.705627,519.617981 229.924988,520.380615 
	C229.008987,520.403931 228.495651,520.397766 227.982330,520.391602 
	C227.492172,520.001770 227.001999,519.612000 226.115417,518.810913 
	C225.142380,518.156189 224.565765,517.912781 223.989151,517.669373 
	C218.624893,518.617126 213.259445,519.558167 207.896622,520.514038 
	C197.094696,522.439270 186.317642,522.253845 175.738800,519.376282 
	C157.979034,514.545349 147.348160,502.772827 143.489410,484.904053 
	C140.403885,470.615875 140.889832,456.471680 146.403442,442.142853 
	C147.435654,441.443451 148.028137,441.230042 148.455154,441.412292 
	C144.943298,453.845551 142.977646,465.945190 144.903824,478.538971 
	C146.611435,489.703674 150.332184,499.739624 158.951447,507.367798 
	C166.124298,513.715820 174.814682,516.801453 184.250000,518.277832 
	C184.311890,518.409912 184.551376,518.568848 184.551376,518.568848 
	C184.551376,518.568848 184.832993,518.510010 185.274872,518.588440 
	C187.814636,518.689087 189.912537,518.711365 192.455048,518.800476 
	C197.520615,518.277466 202.141541,517.687683 207.218109,517.094482 
	C214.687668,517.520142 221.428741,511.986877 229.882874,517.321167 
	C229.882874,507.107422 229.882874,498.066956 229.916138,488.681396 
	C230.560013,487.535828 231.170609,486.735352 231.781204,485.934845 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M455.929382,27.603306 
	C443.765350,27.330912 431.527161,26.074591 419.303528,26.203037 
	C397.595032,26.431158 376.283661,29.570894 356.413696,38.992916 
	C350.321960,41.881527 344.665741,45.688557 338.472046,49.122326 
	C337.537781,43.307098 336.941589,37.444565 336.345398,31.582027 
	C337.113586,31.375328 337.881775,31.168631 338.649963,30.961931 
	C339.730682,33.036083 340.811401,35.110233 342.446045,38.247471 
	C345.015167,37.558304 348.240143,36.659756 351.484650,35.838264 
	C352.910004,35.477371 354.378876,35.291489 355.820953,34.992447 
	C356.933899,34.761669 358.864349,34.713306 359.015015,34.182137 
	C360.576721,28.675964 366.116608,29.021378 369.835571,27.146677 
	C372.526520,25.790188 376.428497,26.821278 379.790436,26.816166 
	C381.903320,26.812954 384.051147,27.120163 386.122589,26.839727 
	C392.137878,26.025366 398.095245,24.714634 404.126556,24.105888 
	C410.711121,23.441299 417.382446,22.784367 423.963135,23.130110 
	C434.130981,23.664322 444.255524,25.038506 454.391357,26.122730 
	C454.915466,26.178795 455.373138,26.855473 455.929382,27.603306 
z"/>
<path fill="#69191E" opacity="1.000000" stroke="none" 
	d="
M137.310974,653.609741 
	C138.208603,653.634583 139.106232,653.659363 140.827194,653.781250 
	C146.834183,653.530151 152.017838,653.181885 157.569397,652.779602 
	C161.598907,651.821594 165.224258,650.403259 168.931137,650.142212 
	C173.692932,649.806763 178.519150,650.441162 183.320038,650.562134 
	C184.358063,650.588318 185.406372,650.207336 186.719238,650.015686 
	C187.352432,650.022522 187.716324,650.027588 188.092972,650.461548 
	C188.105148,652.320435 188.104538,653.750549 188.103943,655.180542 
	C187.348557,655.379089 186.593170,655.577637 185.323318,655.312927 
	C184.186920,654.572021 183.564987,654.294434 182.943054,654.016846 
	C180.228210,653.278503 177.513367,652.540222 174.242249,651.598572 
	C173.084259,651.678284 172.482544,651.961243 171.880814,652.244263 
	C158.683197,656.417908 145.355515,658.152954 131.593643,655.143860 
	C108.104980,650.007935 97.109032,633.364502 95.055397,612.244019 
	C94.266533,604.130920 95.472771,595.816345 95.911598,587.601135 
	C95.963432,586.630920 96.915108,585.708679 97.750305,584.611450 
	C97.740288,591.208679 97.142609,597.959900 97.170921,604.708435 
	C97.269173,628.120178 108.744240,647.313110 134.823853,653.142944 
	C136.033768,653.505371 136.672379,653.557556 137.310974,653.609741 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M676.964600,617.898804 
	C677.678162,618.510315 678.391785,619.121887 678.566772,620.259033 
	C678.028442,622.215332 678.028748,623.645935 678.029053,625.076599 
	C677.377441,635.095703 676.725891,645.114807 676.046021,655.568604 
	C673.909851,655.701233 671.964355,655.925415 670.018616,655.927185 
	C647.218079,655.948059 624.417603,655.932861 601.617065,655.932556 
	C583.249146,655.932312 564.880859,655.982727 546.513733,655.866089 
	C544.935120,655.856018 543.057434,655.080566 541.937744,654.011963 
	C541.485352,653.580261 542.696594,651.405334 543.551270,650.397461 
	C544.627258,651.518921 545.302734,652.266541 546.231812,653.299683 
	C549.764099,653.587830 553.042786,653.590576 556.617432,653.589966 
	C557.499268,653.549500 558.085144,653.512268 559.141602,653.614319 
	C568.902588,653.403442 578.192932,653.053223 587.820557,652.716431 
	C590.175293,652.160522 592.192810,651.591187 594.565369,651.014404 
	C596.912048,651.582947 598.903625,652.158936 601.367554,652.831848 
	C624.267029,652.978210 646.695251,652.928711 669.120483,653.161011 
	C673.582764,653.207214 674.556458,650.611328 674.843384,647.325745 
	C675.605103,638.602478 676.130371,629.858582 676.820496,620.723389 
	C676.915771,619.515564 676.940186,618.707214 676.964600,617.898804 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M580.892090,415.835266 
	C582.579468,414.872620 584.266846,413.909973 585.954224,412.947327 
	C586.560608,414.524384 587.605591,416.075867 587.699280,417.682831 
	C588.163269,425.643188 588.501709,433.617065 588.572876,441.589020 
	C588.587402,443.214813 587.264587,444.852509 586.558228,446.484741 
	C584.832520,445.554657 582.651550,445.015991 581.458313,443.628113 
	C575.663025,436.887207 570.548157,429.514526 564.371765,423.163605 
	C559.731995,418.392792 553.062134,417.581573 546.630798,418.892761 
	C541.834717,419.870544 537.387146,422.126587 535.881653,427.409821 
	C534.274414,433.050140 535.687927,437.887207 540.575012,441.952850 
	C540.044128,442.588104 539.503601,442.738739 538.751953,442.658691 
	C537.034363,441.170197 535.527954,439.912415 533.994507,438.436615 
	C533.745911,437.856689 533.524475,437.494781 533.181396,436.732819 
	C530.842468,431.079071 532.419800,426.386658 535.464844,421.693176 
	C539.438171,417.269257 544.124390,415.017090 549.744446,415.112549 
	C553.379395,415.174286 557.006714,415.685272 560.797119,416.317871 
	C571.141235,422.485962 577.649231,432.050537 584.934387,442.484009 
	C584.934387,433.728180 585.062378,426.875092 584.769531,420.040009 
	C584.733704,419.202179 582.444214,418.460876 581.170166,417.364624 
	C581.058167,416.648041 580.975098,416.241638 580.892090,415.835266 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M477.053741,260.614716 
	C476.588104,260.688721 476.122498,260.762726 474.987457,260.971619 
	C471.874512,262.071350 469.430969,263.036102 466.641663,264.001282 
	C465.883606,264.194916 465.471283,264.388153 464.708435,264.459839 
	C461.810547,265.177643 459.263214,266.016998 456.523682,266.851135 
	C456.331482,266.845917 456.002869,267.045593 455.627136,266.962830 
	C453.827545,267.242554 452.403717,267.605042 450.647034,267.906372 
	C449.887756,267.966461 449.461334,268.087738 448.615906,268.124268 
	C444.445435,268.647583 440.694000,269.255676 436.615112,269.823303 
	C435.864685,269.890686 435.441681,269.998566 434.560150,269.993561 
	C418.390991,269.305969 402.680389,268.731262 386.657959,268.037903 
	C385.902985,267.922424 385.459839,267.925568 384.760864,267.652496 
	C377.946075,265.619141 371.197021,264.365997 364.874908,261.981079 
	C356.407532,258.786835 348.270752,254.716171 339.889771,250.898880 
	C339.787476,250.775940 339.468689,250.750183 339.338348,250.424438 
	C330.922943,243.534225 322.637878,236.969742 314.365295,230.266937 
	C314.377777,230.128632 314.170563,229.943680 314.172607,229.609558 
	C314.135712,228.837097 314.096802,228.398758 314.408630,227.965973 
	C315.550018,228.557312 316.416840,229.063950 317.120026,229.740601 
	C330.348267,242.470276 345.019684,252.958099 362.442535,259.264465 
	C376.534027,264.364990 391.015350,267.426025 406.336731,267.975708 
	C407.097748,267.987274 407.532776,267.974487 408.398254,268.011230 
	C412.583588,268.096802 416.338501,268.132843 420.497986,268.144836 
	C422.254059,268.090912 423.605530,268.060974 425.341248,268.059662 
	C426.494415,268.060547 427.263367,268.032806 428.441071,268.018280 
	C430.212616,268.007233 431.575409,267.982971 433.357483,268.002808 
	C437.207428,267.379425 440.638031,266.711975 444.376099,266.049805 
	C445.093170,266.024750 445.502777,265.994415 446.294006,265.999329 
	C447.891754,265.427887 449.107910,264.821167 450.632355,264.215393 
	C451.556458,264.186462 452.172211,264.156586 452.981110,264.339508 
	C453.791107,264.373810 454.408020,264.195343 455.034668,263.993286 
	C455.044403,263.969666 455.007446,263.934418 455.321808,263.979156 
	C456.056610,263.995819 456.477081,263.967743 457.269531,263.944153 
	C459.468079,263.347809 461.294617,262.746979 463.423065,262.116547 
	C464.126007,262.035065 464.527039,261.983154 465.277588,261.834717 
	C467.871704,260.576508 470.116302,259.414825 472.688171,258.248810 
	C473.667572,258.192078 474.319794,258.139679 475.348694,258.059570 
	C478.537354,256.698944 481.349335,255.366043 484.427612,253.999908 
	C485.124207,253.676498 485.554474,253.386307 486.347961,253.052795 
	C489.855316,251.029892 492.999420,249.050308 496.386108,246.945007 
	C497.111420,246.281082 497.594147,245.742874 498.323669,245.159485 
	C498.734985,245.084305 498.899536,245.054306 499.422668,245.012726 
	C500.494232,244.971878 501.207214,244.942596 501.903168,245.227997 
	C501.294739,246.371567 500.703278,247.200455 499.971436,248.105255 
	C499.831055,248.181168 499.761261,248.492630 499.409393,248.564056 
	C496.795837,250.255783 494.534149,251.876099 492.037964,253.463684 
	C491.475250,253.468552 491.197998,253.607681 490.624390,253.827438 
	C489.614044,254.377350 488.950928,254.948135 488.048645,255.470642 
	C487.461823,255.446014 487.182983,255.596848 486.614136,255.892853 
	C484.431641,256.903778 482.607971,257.896667 480.576538,258.905396 
	C480.368805,258.921173 480.028381,259.161469 479.700317,259.105194 
	C478.683655,259.541412 477.995026,260.033936 477.241791,260.544373 
	C477.177185,260.562347 477.053741,260.614716 477.053741,260.614716 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M490.366333,650.901306 
	C492.128967,650.321777 493.891571,649.742310 496.413239,649.104004 
	C498.041168,648.179932 499.332001,647.469910 499.716278,646.426086 
	C506.566711,627.817505 513.243713,609.145081 520.102112,590.539490 
	C522.895325,582.961975 526.031006,575.510742 529.025757,568.412476 
	C527.570923,572.903931 527.963135,576.677185 530.575989,579.980225 
	C528.840149,580.594116 526.533142,580.934082 526.153076,581.976562 
	C524.117981,587.558350 522.563538,593.315247 520.815613,599.180054 
	C520.788330,599.348450 520.807373,599.689148 520.601013,599.831970 
	C520.181763,600.230469 519.968811,600.486145 519.518311,601.018127 
	C517.992188,604.607483 516.636475,607.896484 515.438232,611.241821 
	C514.614441,613.541748 514.788330,613.710815 517.233032,614.010132 
	C517.458191,613.983704 517.909241,614.028748 518.306274,614.154907 
	C525.215332,614.473389 531.727295,614.665649 538.682800,614.878662 
	C539.715759,612.979248 540.305176,611.059204 541.210938,609.320068 
	C542.292114,612.684753 543.057068,615.868591 543.487366,619.033081 
	C542.483887,618.974548 541.814941,618.935181 540.664062,618.854736 
	C532.060181,618.838501 523.938232,618.863403 515.448730,618.898682 
	C514.002136,619.992981 512.442200,620.886780 511.926331,622.192932 
	C509.506348,628.320618 507.048309,634.469666 505.259827,640.796509 
	C504.182068,644.609253 504.305328,648.761475 503.497437,652.810303 
	C498.494873,654.578430 494.411957,652.861084 490.366333,650.901306 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M386.969788,268.156525 
	C402.680389,268.731262 418.390991,269.305969 434.460968,270.380493 
	C433.297485,271.249817 431.781372,271.902100 430.250610,271.938416 
	C424.603363,272.072357 418.950836,271.968353 413.301483,272.043396 
	C412.375488,272.055664 411.455505,272.518829 409.116241,273.164673 
	C411.269440,273.681000 411.880005,273.958771 412.488464,273.954285 
	C431.668549,273.812836 450.848267,273.613159 470.028534,273.546509 
	C471.348480,273.541901 472.671783,274.499390 473.993530,275.009460 
	C474.260406,275.500031 474.527283,275.990631 474.899994,277.103668 
	C474.169250,278.836639 473.332703,279.947144 472.422241,280.698547 
	C473.688873,275.965057 471.600555,275.205750 467.453979,276.066406 
	C459.668884,276.003998 452.342804,275.999390 445.016693,276.012817 
	C419.044067,276.060455 393.071442,276.114258 366.739227,275.991577 
	C365.134705,276.069519 363.889771,276.321533 362.264191,276.624390 
	C359.625549,276.429535 357.367584,276.183777 355.171936,275.549316 
	C360.783051,274.382477 366.310913,273.238708 371.885956,272.919586 
	C378.724213,272.528168 385.601929,272.826263 392.462585,272.826263 
	C392.501526,272.515930 392.540466,272.205597 392.579437,271.895294 
	C390.709534,270.649048 388.839661,269.402771 386.969788,268.156525 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M456.015656,228.044312 
	C456.010925,226.384979 455.971436,224.724854 456.008392,223.066452 
	C456.095978,219.134613 458.681274,216.539673 462.692749,217.215561 
	C466.150513,217.798157 467.582458,217.450607 466.386322,213.639709 
	C465.360748,210.372345 466.830231,208.875259 470.088074,209.022171 
	C472.717377,209.140747 474.623535,208.986710 475.572235,205.653381 
	C476.021057,204.076294 478.970612,203.210953 481.299744,201.687973 
	C480.362946,198.855484 481.846985,197.678619 485.594757,197.937149 
	C493.413513,198.476532 501.260162,198.922684 509.091095,198.862015 
	C514.005005,198.823944 518.900940,197.642868 523.827576,197.306213 
	C525.243591,197.209457 526.994690,197.976562 528.075317,198.932053 
	C528.529114,199.333374 527.640869,201.252472 526.845154,202.747208 
	C525.900696,202.987488 525.473755,202.962128 524.779541,202.684937 
	C522.452576,202.093002 520.346252,201.289047 518.341064,201.490555 
	C507.903198,202.539566 497.466278,203.675766 487.089508,205.194626 
	C484.858551,205.521179 482.877075,207.552185 480.415924,208.854034 
	C478.618896,209.787170 477.060577,210.510971 475.775513,211.567245 
	C469.150360,217.012680 462.595215,222.543365 456.015656,228.044312 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M424.958801,150.098999 
	C427.009003,144.550171 425.777863,137.182770 433.772919,134.853897 
	C434.632721,134.603455 434.663574,131.507156 435.040100,129.366104 
	C436.685303,129.908554 438.369385,130.815872 440.299805,131.855911 
	C442.109100,132.016678 445.916443,131.430634 446.575623,132.607697 
	C447.912231,134.994247 447.783752,138.332901 447.813141,141.283493 
	C447.843414,144.326752 447.148346,147.372910 447.111664,150.421906 
	C446.995331,160.090775 447.024048,169.761566 447.026703,179.431610 
	C447.027710,183.172134 446.974854,186.921860 447.230957,190.647842 
	C447.314087,191.856735 448.000183,193.628281 448.925629,194.074860 
	C454.047546,196.546509 452.141724,199.278412 449.503326,202.136459 
	C448.301880,203.437927 447.019867,204.665070 445.371429,205.500092 
	C444.970184,185.778931 444.994141,166.483566 444.935883,147.188416 
	C444.930603,145.448166 444.394653,143.709518 444.103424,141.638580 
	C444.082092,140.865341 444.062500,140.423676 444.294006,139.791382 
	C442.861542,135.171997 439.874878,136.508194 437.934357,138.790146 
	C436.031891,141.027328 435.200897,144.175674 433.752167,147.028046 
	C433.604675,147.131943 433.306305,147.334763 432.983582,147.272324 
	C432.006012,147.704117 431.351105,148.198349 430.368958,148.754990 
	C428.943573,149.385895 427.845490,149.954391 426.499084,150.560181 
	C425.820129,150.431335 425.389465,150.265167 424.958801,150.098999 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M587.483276,652.703064 
	C578.192932,653.053223 568.902588,653.403442 559.376953,653.316650 
	C565.050110,652.618713 570.956360,652.228394 576.867432,652.130432 
	C583.800354,652.015503 584.256714,651.681030 581.868896,645.061279 
	C573.669189,622.328552 565.328674,599.646545 557.022705,576.656372 
	C556.703491,575.903381 556.403442,575.439209 556.060425,574.648926 
	C555.971375,573.886536 555.925232,573.450317 555.826294,572.790039 
	C555.630554,572.275391 555.407349,572.073669 555.047974,571.665894 
	C554.692566,570.867126 554.393188,570.363220 554.078735,569.465698 
	C553.129395,566.692505 552.195190,564.312927 551.191528,561.580994 
	C550.764038,560.131836 550.405884,559.035034 550.024170,557.652832 
	C549.712585,556.898621 549.424622,556.429871 549.121216,555.944336 
	C549.105896,555.927612 549.090759,555.884888 549.077942,555.526611 
	C549.055908,554.449890 549.046692,553.731445 549.187500,552.973389 
	C549.612671,552.789062 549.853760,552.597351 550.060669,552.358643 
	C555.482361,566.224426 561.064880,580.029907 566.284241,593.971436 
	C572.564636,610.747314 578.536255,627.638733 584.682983,644.995361 
	C584.729431,645.755188 584.848145,645.916016 585.080444,645.992920 
	C585.289062,646.456665 585.497681,646.920410 585.778442,648.098511 
	C586.394836,650.109619 586.939026,651.406311 587.483276,652.703064 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M574.866516,496.912720 
	C572.432312,490.280212 566.706116,487.606781 560.719238,485.442749 
	C552.932434,482.628174 545.004639,480.204529 537.214417,477.398987 
	C519.210266,470.915070 512.350159,451.794586 517.835571,435.105530 
	C518.201660,434.423157 518.175110,434.262054 518.043213,434.118378 
	C518.548523,432.909058 519.053833,431.699707 520.063782,430.399109 
	C520.842590,430.624054 521.116821,430.940216 521.296692,431.575714 
	C520.863464,432.941284 520.524597,433.987518 520.126526,435.324951 
	C520.034119,436.009460 520.000916,436.402771 519.708130,437.092712 
	C516.392212,445.781342 519.077759,453.533020 522.978088,460.673889 
	C525.095154,464.549957 528.470764,468.607269 532.306519,470.498993 
	C540.857971,474.716400 550.106506,477.498657 558.967896,481.118103 
	C563.670959,483.039093 569.023010,484.567963 572.559509,487.893188 
	C579.445557,494.367859 579.654907,502.542328 574.332031,511.023132 
	C573.168579,510.974365 572.393860,510.912231 571.512329,510.853668 
	C571.405579,510.857208 571.273743,511.025269 571.273743,511.025269 
	C572.067627,509.777679 572.861572,508.530090 573.802368,506.975403 
	C573.949341,506.668335 573.971130,506.823242 573.971130,506.823242 
	C574.078674,506.516815 574.186279,506.210419 574.790894,505.429169 
	C575.284302,504.329803 575.280823,503.705292 575.277344,503.080750 
	C575.238098,501.966858 575.198914,500.852997 575.407837,499.200043 
	C575.392822,498.078217 575.129700,497.495483 574.866516,496.912720 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M499.761261,248.492630 
	C499.761261,248.492630 499.831055,248.181168 500.330811,248.110611 
	C505.274872,244.417374 509.719147,240.794693 514.462097,237.066757 
	C515.167419,236.299805 515.574036,235.638123 516.094971,234.693802 
	C517.080322,233.577103 517.951355,232.743027 519.198730,231.941254 
	C520.704102,230.629211 521.833130,229.284866 523.039429,227.717239 
	C523.304199,227.224518 523.554749,227.041489 524.253052,226.930420 
	C525.445618,224.939346 526.253540,222.962738 527.061523,220.986130 
	C527.666077,221.945129 528.463501,222.833664 528.843689,223.874619 
	C531.248108,230.457581 527.625854,234.102829 522.493469,237.238098 
	C521.545349,237.817291 521.164917,239.810913 521.055847,241.193771 
	C520.603394,246.930069 517.274658,249.855392 511.464783,249.754379 
	C509.825531,249.725891 507.897766,250.182312 506.576263,251.099335 
	C503.046143,253.548920 499.807678,256.414795 496.370850,259.005920 
	C495.641785,259.555573 494.346191,260.143372 493.679565,259.856995 
	C489.635406,258.119751 487.487732,259.969666 486.311737,263.957214 
	C485.156281,262.705292 484.356323,261.838531 482.982269,260.349762 
	C482.668121,262.266602 482.482452,263.399445 482.263794,264.733368 
	C479.683624,265.007599 476.736359,266.060455 477.034668,261.056946 
	C477.053741,260.614716 477.177185,260.562347 477.603333,260.670990 
	C478.695770,260.240234 479.362091,259.700867 480.028381,259.161469 
	C480.028381,259.161469 480.368805,258.921173 480.980896,258.953583 
	C483.386322,257.948944 485.179657,256.911896 486.972961,255.874847 
	C487.182983,255.596848 487.461823,255.446014 488.435425,255.593597 
	C489.698120,255.126022 490.334930,254.487183 490.971680,253.848328 
	C491.197998,253.607681 491.475250,253.468552 492.439941,253.600525 
	C495.544891,252.315262 499.728943,253.033401 499.761261,248.492630 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M530.089905,517.726074 
	C528.330505,517.796082 526.571106,517.866089 524.129517,517.982788 
	C521.339722,517.678223 519.232117,517.326843 517.124451,516.975464 
	C516.814209,511.932404 516.504028,506.889374 516.521240,501.461121 
	C517.521484,501.343872 518.194336,501.611816 518.926636,502.342529 
	C519.323364,507.044128 519.660767,511.282990 519.937500,514.760132 
	C524.968018,514.997986 529.322388,515.067444 533.649292,515.448486 
	C537.578674,515.794556 541.478210,516.479858 545.777466,517.089111 
	C556.018799,518.572327 565.791504,519.257385 575.222290,513.857300 
	C582.305359,508.958984 588.194214,503.437500 590.736694,494.935791 
	C591.255859,494.106598 591.488037,493.376068 591.726685,492.310333 
	C591.810730,491.308868 591.888367,490.642609 592.290405,489.942749 
	C593.083618,489.281982 593.552307,488.654877 594.020996,488.027771 
	C595.283630,499.485779 586.917419,511.803070 576.152893,517.239990 
	C565.512451,522.614197 554.332397,522.218384 543.009827,520.554260 
	C541.530884,520.336914 540.061768,520.052246 538.217651,519.432373 
	C537.547546,519.094238 537.248047,519.122620 536.948486,519.151001 
	C535.866211,519.037292 534.783936,518.923584 533.264893,518.337402 
	C531.915344,517.818542 531.002625,517.772278 530.089905,517.726074 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M253.971710,548.034668 
	C254.753067,549.058289 255.534424,550.081909 256.174805,551.760742 
	C256.074707,552.612915 256.115540,552.809937 256.156372,553.006958 
	C251.365784,553.568848 246.575195,554.130737 241.137222,555.010376 
	C240.544678,556.200134 240.599503,557.072205 240.654343,557.944275 
	C240.705338,587.003235 240.756317,616.062134 240.533691,645.800842 
	C239.878128,647.376282 239.496170,648.272034 238.827362,648.838989 
	C238.385468,647.203369 238.096390,645.896606 238.095093,644.589539 
	C238.066360,615.499756 238.045288,586.409973 238.116470,557.320374 
	C238.121643,555.213623 238.841721,553.108582 239.681061,550.981628 
	C243.608047,550.961365 247.111755,551.214661 250.543549,550.828735 
	C251.775421,550.690125 252.834015,549.010803 253.971710,548.034668 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M514.027893,26.289080 
	C511.547058,34.838257 504.476654,38.122181 493.914062,35.921535 
	C482.760406,33.597740 471.619843,31.211344 460.167786,28.617332 
	C461.073822,27.298840 462.285614,26.215309 463.761078,24.896042 
	C467.039093,28.098675 470.446808,29.324520 474.016449,24.734552 
	C474.738770,26.389282 475.222137,27.496689 475.455017,28.030178 
	C482.249603,27.007442 488.703644,25.793091 495.212891,25.281500 
	C496.375916,25.190094 497.800659,27.981188 499.042511,29.492313 
	C499.923218,30.563938 500.699524,31.721367 501.522400,32.840492 
	C502.354156,31.631491 503.597473,30.529161 503.943420,29.194252 
	C505.168335,24.468376 506.853271,23.855595 514.027893,26.289080 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M460.080658,417.964478 
	C459.976624,416.890625 459.872620,415.816772 460.212097,414.492920 
	C463.649048,414.779694 466.642517,415.316467 469.805176,416.260345 
	C470.283203,418.781403 470.856720,420.894867 470.860901,423.009460 
	C470.918304,452.062073 470.922699,481.114868 470.841492,510.167389 
	C470.835693,512.237122 470.060150,514.304749 469.281677,516.280396 
	C468.467346,515.349731 468.013855,514.512024 467.560364,513.674316 
	C467.576538,482.543396 467.592743,451.412476 467.303650,419.524719 
	C464.692505,418.500092 462.386566,418.232300 460.080658,417.964478 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M529.010864,568.002075 
	C526.031006,575.510742 522.895325,582.961975 520.102112,590.539490 
	C513.243713,609.145081 506.566711,627.817505 499.716278,646.426086 
	C499.332001,647.469910 498.041168,648.179932 496.820435,649.063477 
	C500.638824,637.505249 504.770966,625.914856 508.987610,614.355408 
	C516.625732,593.416199 524.310608,572.494141 531.986572,551.568787 
	C532.895142,549.091980 533.520996,546.255493 537.578003,546.743042 
	C537.944397,546.672668 537.948486,546.606140 537.571899,546.694336 
	C536.428833,548.818970 535.662292,550.855347 534.915894,552.916382 
	C534.935974,552.941101 534.872437,552.945007 534.645020,553.124512 
	C534.274414,553.854004 534.131226,554.403931 533.943848,555.188721 
	C533.899597,555.423523 533.815918,555.894043 533.534912,556.094604 
	C531.903442,559.900024 530.552917,563.504822 529.156799,567.333252 
	C529.111145,567.556885 529.010864,568.002075 529.010864,568.002075 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M337.724731,49.082634 
	C333.843414,52.662807 329.249634,55.900974 325.305511,59.796883 
	C318.505554,66.513718 312.109741,73.639687 304.941833,80.832527 
	C303.954102,81.777405 303.568848,82.483734 303.183563,83.190056 
	C303.183563,83.190056 303.135132,83.207039 303.125183,83.184441 
	C302.078705,82.662193 301.042145,82.162537 298.895081,81.127594 
	C302.682190,77.675690 306.137329,74.526375 309.799896,71.187988 
	C306.916870,69.560890 303.233490,67.504463 306.048920,63.326710 
	C307.330597,61.424870 310.011566,60.133369 312.321472,59.426003 
	C313.206573,59.154968 314.826843,61.284569 316.890900,62.947777 
	C317.231293,62.722748 318.618958,61.805367 320.444885,60.598267 
	C318.784241,59.399025 317.541443,58.501568 315.918518,57.329575 
	C316.840485,55.505135 317.514343,53.609093 318.656708,52.058159 
	C319.985199,50.254574 321.170593,48.516701 324.328461,50.257416 
	C326.267517,51.326317 329.398621,50.358765 331.974396,50.103413 
	C333.768738,49.925537 335.529083,49.404408 337.724731,49.082634 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M635.652039,557.038147 
	C635.709473,587.052673 635.766907,617.067261 635.589233,647.658813 
	C635.158203,648.496582 635.053589,648.789856 634.728271,648.927490 
	C634.073792,647.469360 633.436829,646.200562 633.432251,644.929443 
	C633.328064,615.720154 633.236145,586.510315 633.382751,557.301819 
	C633.391724,555.515991 634.989868,552.951721 636.561157,552.154907 
	C638.625610,551.107910 641.397644,551.456238 643.827148,551.528503 
	C642.850769,552.639465 641.912598,553.426514 640.974426,554.213562 
	C640.621826,554.294189 640.269226,554.374817 639.276489,554.233276 
	C636.526611,553.920898 634.127380,553.545288 635.652039,557.038147 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M451.494537,294.097595 
	C451.270020,294.797821 451.045471,295.498016 450.513367,296.817627 
	C449.722839,300.866058 448.858948,304.291077 448.822693,307.724823 
	C448.662689,322.873779 448.728485,338.025299 448.736572,353.175873 
	C448.745056,369.111237 449.438354,365.961273 436.713806,370.681000 
	C435.933655,370.970398 435.191010,371.389771 434.391357,371.593292 
	C426.577362,373.582062 418.754883,375.537750 411.110229,377.154724 
	C415.562408,375.208527 419.839203,373.610474 424.453857,371.884979 
	C427.176697,371.197906 429.561646,370.638245 432.331146,370.069397 
	C434.424377,369.436951 436.163025,368.884735 437.836639,368.178436 
	C446.021545,364.724243 445.900940,364.707672 446.059174,355.906982 
	C446.195862,348.302521 446.774994,340.705872 446.902771,333.101440 
	C447.093933,321.725067 446.701202,310.325348 447.318085,298.976959 
	C447.534210,295.001190 448.845917,290.354950 454.013123,289.322510 
	C454.012146,290.095459 453.982422,290.537018 453.685669,291.117920 
	C452.969116,291.901703 452.519592,292.546143 451.851257,293.236938 
	C451.361481,293.516449 451.315491,293.787903 451.494537,294.097595 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M501.036682,419.063110 
	C500.874664,421.206268 500.575104,423.349182 500.571411,425.492615 
	C500.521545,454.250793 500.513000,483.009033 500.262756,512.486511 
	C499.455780,513.845093 498.880157,514.484436 498.188354,514.649841 
	C498.029327,481.585327 497.986450,448.994659 498.382507,416.382263 
	C499.687500,416.229004 500.553497,416.097504 501.312866,416.480469 
	C501.149719,417.684357 501.093201,418.373749 501.036682,419.063110 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M473.988800,274.613464 
	C472.671783,274.499390 471.348480,273.541901 470.028534,273.546509 
	C450.848267,273.613159 431.668549,273.812836 412.488464,273.954285 
	C411.880005,273.958771 411.269440,273.681000 409.116241,273.164673 
	C411.455505,272.518829 412.375488,272.055664 413.301483,272.043396 
	C418.950836,271.968353 424.603363,272.072357 430.250610,271.938416 
	C431.781372,271.902100 433.297485,271.249817 434.919525,270.493347 
	C435.441681,269.998566 435.864685,269.890686 436.874756,270.139587 
	C441.624023,271.274506 445.686218,271.546783 449.034912,268.208984 
	C449.461334,268.087738 449.887756,267.966461 450.851807,268.182556 
	C452.927216,268.028473 454.465027,267.537018 456.002869,267.045593 
	C456.002869,267.045593 456.331482,266.845917 456.953552,266.904541 
	C460.070099,266.169281 462.564545,265.375336 465.058990,264.581421 
	C465.471283,264.388153 465.883606,264.194916 466.849548,264.261963 
	C469.926422,264.001923 472.449677,263.481567 474.972931,262.961212 
	C474.643311,266.713287 474.313690,270.465363 473.988800,274.613464 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M188.459091,655.031433 
	C188.104538,653.750549 188.105148,652.320435 188.096008,649.989319 
	C188.087646,639.294250 187.940735,629.496399 188.198151,619.709106 
	C188.259903,617.361816 189.762024,615.074463 190.369476,612.700134 
	C190.486069,612.244324 189.516083,611.510620 188.927429,610.521362 
	C187.293076,609.744934 185.783554,609.036987 184.263367,609.013306 
	C177.135452,608.902222 170.003235,608.907776 162.875748,609.041260 
	C161.149323,609.073608 159.434952,609.749939 157.451141,610.129639 
	C156.835388,610.125854 156.483566,610.122559 155.951599,609.894043 
	C155.145477,609.823547 154.519470,609.978210 153.633759,610.377686 
	C153.899628,611.426147 154.302078,612.360596 154.981705,613.000793 
	C155.850464,613.819214 156.942780,614.400391 157.938766,615.083801 
	C156.014008,614.751038 153.926422,614.770264 152.214310,613.977966 
	C150.758133,613.304138 149.080994,611.884460 148.667786,610.457153 
	C147.990173,608.116516 149.493851,606.952637 152.069046,606.959778 
	C165.370193,606.996765 178.672409,606.857666 191.971634,607.008972 
	C193.080414,607.021606 195.059418,608.730652 195.062592,609.657043 
	C195.067505,611.095703 193.817184,613.705444 192.931061,613.778381 
	C189.098022,614.093811 189.453659,616.543396 189.459656,619.072266 
	C189.482193,628.549866 189.519043,638.027832 189.447403,647.504822 
	C189.428787,649.965393 189.035446,652.423218 188.459091,655.031433 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M410.934845,377.502899 
	C418.754883,375.537750 426.577362,373.582062 434.391357,371.593292 
	C435.191010,371.389771 435.933655,370.970398 436.713806,370.681000 
	C449.438354,365.961273 448.745056,369.111237 448.736572,353.175873 
	C448.728485,338.025299 448.662689,322.873779 448.822693,307.724823 
	C448.858948,304.291077 449.722839,300.866058 450.497192,297.190125 
	C450.852234,299.756409 450.968719,302.569519 450.970947,305.382721 
	C450.985443,323.704865 450.859924,342.028168 451.045624,360.348419 
	C451.098938,365.607574 449.097961,368.799988 444.143585,370.390839 
	C429.535248,375.081512 414.993073,379.901581 399.709503,382.175903 
	C394.293549,382.981842 389.123260,385.506226 383.284821,383.021362 
	C383.317535,382.234589 383.402374,381.935364 383.550232,381.648987 
	C383.772430,381.707001 384.236023,381.724335 384.236023,381.724335 
	C384.236023,381.724335 384.696320,381.666565 385.063660,381.851562 
	C389.949432,381.323700 394.467926,380.610809 399.300079,379.907166 
	C400.022766,379.840210 400.431732,379.763947 401.235199,379.797607 
	C404.244476,379.155640 406.859283,378.403687 409.719421,377.646301 
	C410.293213,377.630676 410.616577,377.584656 410.934845,377.502899 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M284.976807,468.899292 
	C284.448639,469.091370 283.920471,469.283447 283.151093,469.959778 
	C282.970428,470.628021 283.030975,470.812042 283.091522,470.996063 
	C282.982819,484.701569 282.874084,498.407104 282.812012,512.800781 
	C282.849518,513.673340 282.840363,513.857666 282.595520,514.166260 
	C281.981262,514.872375 281.602631,515.454224 281.103760,515.577515 
	C280.903412,500.077393 280.799133,485.035889 280.795868,469.994385 
	C280.795624,468.959351 281.549316,467.924164 282.276062,466.902954 
	C283.099426,466.564972 283.599091,466.213074 284.480072,465.862244 
	C285.878296,465.861877 286.895172,465.860413 288.198425,466.179749 
	C294.685211,466.293030 300.885651,466.085541 307.364441,465.872620 
	C308.013763,465.874634 308.384766,465.882111 308.861694,466.148987 
	C309.026062,466.585846 309.084473,466.763275 309.209290,467.161530 
	C309.452332,467.666168 309.707611,467.817902 310.190796,468.138855 
	C310.515076,469.299316 310.689941,470.158447 310.644287,471.207092 
	C310.552094,472.255432 310.680420,473.114288 310.857910,474.314148 
	C310.876617,475.112427 310.846161,475.569702 310.604675,476.223328 
	C310.541595,477.271576 310.689606,478.123383 310.895081,479.442200 
	C310.925873,490.927155 310.899170,501.945129 310.589661,512.975159 
	C309.740601,512.947144 309.174316,512.907166 308.417267,512.845459 
	C308.226471,512.823853 307.902679,513.030334 307.902679,513.030334 
	C308.027985,501.657379 308.153290,490.284393 308.298309,478.056396 
	C308.213928,475.800659 308.109833,474.399994 308.005737,472.999329 
	C308.079956,472.549896 308.154205,472.100464 308.231476,471.033936 
	C307.813721,470.087494 307.392944,469.758148 306.972168,469.428772 
	C300.296997,469.303406 293.621796,469.178040 286.412415,468.805298 
	C285.577728,468.671722 285.277252,468.785492 284.976807,468.899292 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M214.890961,320.025970 
	C212.629944,317.154602 209.493668,314.610107 208.275879,311.349152 
	C204.934128,302.400574 201.209122,293.323395 199.854691,283.975586 
	C198.282959,273.127930 199.319366,261.925903 198.770615,250.900253 
	C198.150528,238.441650 202.369186,227.162018 206.645767,215.899414 
	C208.515839,210.974487 209.283875,205.277069 214.711029,202.408676 
	C212.682175,207.869980 209.738998,212.821472 208.045090,218.168716 
	C205.667862,225.672958 204.219406,233.471436 202.265594,241.446884 
	C202.138397,242.176773 202.119308,242.603836 201.776169,243.284637 
	C201.001526,249.643723 200.361923,255.743561 200.183838,261.856873 
	C200.100616,264.713745 200.909821,267.596619 201.324890,270.777832 
	C201.377991,271.706970 201.420715,272.325928 201.256714,273.287231 
	C201.796829,278.706604 201.867065,284.003235 203.429123,288.815674 
	C206.848206,299.349457 211.024124,309.637543 214.890961,320.025970 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M471.053894,597.086792 
	C471.629028,598.196350 471.918457,599.792542 472.830597,600.328003 
	C476.104401,602.249878 479.978149,603.310974 482.927246,605.597473 
	C497.082275,616.572266 493.270905,643.034912 476.646576,649.858582 
	C474.062134,650.919434 471.209381,651.326782 468.219910,652.003540 
	C467.959076,651.970825 467.933838,651.947510 467.972290,651.580627 
	C469.013947,650.304321 469.865479,649.009521 471.044067,648.545654 
	C481.819885,644.304077 488.322723,636.829163 488.422913,624.950500 
	C488.474091,618.882446 487.771942,612.580872 482.026825,608.808044 
	C477.538177,605.860413 472.661499,603.503662 468.001587,600.540039 
	C469.048584,599.159424 470.051239,598.123108 471.053894,597.086792 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M399.053009,548.158203 
	C400.598663,547.856812 402.148071,547.276184 403.689423,547.296936 
	C420.904266,547.528015 438.151001,547.325562 455.315704,548.406311 
	C462.314575,548.846985 469.134857,552.125000 475.765564,554.532654 
	C474.951141,555.008606 474.407471,555.064026 473.480896,555.080017 
	C467.370667,553.714966 461.681793,552.159363 455.894592,551.192566 
	C453.955444,550.868591 451.765533,552.045593 449.391663,552.383911 
	C445.132141,551.789734 441.171143,551.348206 436.785706,550.842529 
	C427.147583,550.820801 417.933899,550.863098 408.379059,550.910339 
	C407.345703,550.915710 406.653503,550.916138 405.604675,550.862671 
	C403.183014,549.925232 401.118011,549.041687 399.053009,548.158203 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M303.935852,560.861450 
	C306.234009,558.777588 308.228577,556.132080 310.878754,554.699463 
	C330.575134,544.052063 350.570129,543.492126 370.653076,553.695984 
	C372.243805,554.504272 373.537567,555.896973 375.001404,557.513184 
	C375.033447,558.008301 374.824738,558.344055 374.480225,558.231506 
	C369.781738,556.399841 365.427765,554.680664 360.879639,552.733215 
	C360.121399,552.328003 359.557281,552.151123 358.743164,551.693359 
	C355.254486,551.288513 352.015869,551.164490 348.335510,551.019653 
	C342.663757,551.241882 337.433716,551.484863 332.076904,551.392944 
	C331.570404,550.764099 331.059326,550.134888 330.830048,550.224731 
	C323.827820,552.968262 316.838898,555.747620 309.885040,558.611511 
	C308.263580,559.279358 306.775787,560.271851 304.897400,561.084351 
	C304.357452,560.989685 304.146637,560.925598 303.935852,560.861450 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M367.998291,412.021454 
	C373.690277,412.070404 379.382263,412.119354 385.960938,412.184296 
	C388.564209,412.145508 390.280731,412.090698 391.997284,412.035889 
	C397.402100,412.302155 402.806946,412.568420 408.853485,412.881439 
	C409.495209,412.928162 409.974121,412.791840 409.974121,412.791840 
	C410.430542,412.850189 410.886963,412.908539 411.920532,412.977112 
	C412.659515,412.943115 412.821320,412.898804 412.983154,412.854492 
	C415.015503,412.998230 417.047852,413.141968 419.822205,413.496948 
	C423.694153,414.227417 426.824097,414.746704 429.954071,415.265961 
	C429.954071,415.265961 430.227844,415.397583 430.604858,415.556763 
	C432.313416,416.164062 433.644958,416.612183 434.976471,417.060272 
	C434.976471,417.060272 435.063507,417.496246 434.820831,417.867096 
	C434.067474,418.492432 433.556763,418.746918 432.631287,418.966553 
	C423.683167,417.955750 415.149811,416.979767 406.227295,415.993347 
	C401.340027,416.183807 396.841858,416.384735 392.017029,416.361084 
	C382.393463,416.042023 373.096527,415.947479 363.430023,415.856018 
	C361.380432,415.742981 359.700409,415.626892 357.688354,415.359558 
	C356.909912,414.473633 356.463562,413.738983 356.017181,413.004303 
	C357.425537,412.719238 358.833923,412.434174 361.052246,412.174866 
	C363.907562,412.140900 365.952911,412.081177 367.998291,412.021454 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M398.668396,548.129639 
	C401.118011,549.041687 403.183014,549.925232 405.662720,551.216797 
	C407.054016,553.427063 408.030609,555.229248 409.035950,557.355408 
	C409.039062,558.111450 409.013428,558.543518 408.687775,559.270386 
	C408.249939,561.368103 407.993988,563.170898 407.992188,564.973999 
	C407.966339,590.864807 408.001770,616.755737 407.915802,642.646301 
	C407.909882,644.432129 407.059082,646.215210 406.601196,647.999573 
	C406.499451,639.196350 406.331451,630.393372 406.309204,621.589905 
	C406.257111,600.961548 406.274811,580.333069 406.264526,559.704590 
	C406.263702,558.078430 406.264435,556.452332 406.264435,554.474182 
	C403.639191,554.308899 401.471985,554.409546 399.427887,553.955750 
	C398.179535,553.678589 396.197693,552.561768 396.187042,551.799133 
	C396.170013,550.576355 397.514648,549.334717 398.668396,548.129639 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M385.030426,84.282562 
	C397.319611,79.219246 409.872559,79.955910 422.575714,82.935867 
	C421.878326,84.424767 420.275055,85.587006 420.459869,86.252991 
	C421.381378,89.573227 422.772766,92.763054 423.998779,95.998779 
	C418.830414,97.426842 417.303406,93.531052 416.314697,90.063324 
	C415.031433,85.562485 412.620331,85.153465 409.410431,87.436134 
	C406.210999,89.711365 404.039825,89.284203 402.005737,86.082886 
	C399.850800,82.691429 396.995911,84.232597 394.050293,84.870453 
	C391.135864,85.501556 387.914856,89.335335 385.030426,84.282562 
z"/>
<path fill="#69191E" opacity="1.000000" stroke="none" 
	d="
M602.913086,647.862732 
	C602.913086,617.062256 602.913086,586.261780 602.913086,555.838562 
	C600.016357,552.963928 592.295288,557.636047 594.694519,549.236206 
	C596.476379,549.796692 597.681396,550.447937 599.171631,551.112671 
	C599.646179,551.133789 599.835388,551.141357 600.113770,551.268860 
	C600.334351,551.551392 600.504639,551.632874 601.133545,551.722412 
	C604.660339,552.081604 605.102783,554.146545 605.100098,556.754578 
	C605.069763,586.206543 605.112305,615.658569 605.012573,645.110229 
	C605.008179,646.413635 603.824768,647.713074 603.166382,648.715576 
	C603.065857,648.232117 602.989441,648.047424 602.913086,647.862732 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M239.114212,649.167725 
	C239.496170,648.272034 239.878128,647.376282 240.470764,646.269104 
	C240.773865,648.869934 242.691910,648.995178 244.825745,648.956543 
	C255.265808,648.767456 262.591400,643.202209 268.214294,635.068176 
	C271.127106,630.854492 273.756256,626.380859 275.957031,621.759644 
	C277.630005,618.246643 279.676483,616.459839 284.198975,617.574097 
	C284.287506,618.748474 284.392609,620.143066 283.968994,621.818970 
	C283.266418,622.115906 283.092590,622.131531 282.542908,622.094238 
	C276.369598,622.299316 276.777069,627.518982 274.744934,631.186035 
	C273.958923,632.551270 273.434113,633.727112 272.596405,634.967957 
	C271.858307,635.690552 271.433075,636.348145 271.003906,637.002930 
	C271.000000,637.000000 271.006012,637.006470 270.695374,637.134460 
	C265.067139,642.074341 260.398743,647.716736 253.160004,650.029419 
	C252.209351,650.333130 251.776184,652.256592 250.648834,653.554199 
	C246.112778,653.609070 242.031250,653.539673 238.034668,653.173584 
	C238.451157,651.640442 238.782684,650.404114 239.114212,649.167725 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M231.904999,485.690308 
	C231.170609,486.735352 230.560013,487.535828 229.569366,488.685852 
	C227.834076,490.709503 226.478851,492.383606 225.071182,494.284546 
	C225.018738,494.511414 224.977310,494.975281 224.727432,495.141357 
	C224.318832,495.869873 224.160110,496.432251 224.000397,496.997253 
	C223.999390,496.999847 223.995499,497.003174 223.700592,497.131714 
	C222.932495,498.168915 222.459320,499.077637 221.993073,499.993164 
	C222.000000,500.000000 221.985931,499.989807 221.682190,500.132874 
	C219.924118,502.521179 218.469818,504.766418 217.008057,507.005829 
	C217.000610,507.000000 217.011414,507.013000 216.735229,507.117157 
	C215.987152,507.824188 215.515289,508.427063 214.777100,509.155334 
	C214.021240,509.877106 213.531708,510.473480 212.720001,511.175293 
	C210.519363,513.219788 208.640915,515.158875 206.762466,517.097900 
	C202.141541,517.687683 197.520615,518.277466 192.444733,518.457031 
	C191.431778,517.345398 190.947678,516.565613 190.302170,515.957092 
	C188.220520,513.994629 186.079422,512.095154 184.037415,509.938843 
	C184.248688,509.384491 184.481659,509.168243 184.812531,509.057465 
	C195.622009,516.643372 202.992737,516.954102 210.410583,509.272430 
	C214.400589,505.140472 217.579178,500.181488 220.829773,495.395935 
	C222.683304,492.667206 223.866989,489.492584 225.495102,486.599152 
	C227.146515,483.664307 229.381012,483.168335 231.904999,485.690308 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M406.962402,655.805237 
	C404.617737,655.825317 402.273102,655.845398 399.426758,655.441589 
	C398.999573,653.638367 399.074036,652.259033 399.148499,650.879700 
	C401.157471,650.337158 403.166412,649.794678 405.608673,649.604492 
	C406.048218,652.372009 406.494507,654.344727 410.079285,653.589233 
	C419.149719,653.697144 427.814819,653.785400 436.879883,653.903809 
	C442.182159,653.869385 437.588928,650.183167 439.559357,649.015747 
	C445.198883,651.059875 449.429413,648.980347 453.517761,645.663025 
	C453.726929,645.741211 454.087585,646.004761 453.963806,646.244324 
	C453.591949,646.955078 453.343842,647.426208 452.838623,648.156006 
	C452.182709,649.298279 451.783905,650.181946 451.094330,651.071777 
	C450.365204,651.429993 449.926849,651.782104 449.488495,652.134155 
	C450.492279,652.378967 451.496033,652.623718 452.828888,652.789795 
	C454.276947,652.771729 455.395935,652.832275 456.876190,652.910034 
	C457.837036,652.615967 458.436615,652.304749 459.480835,651.959961 
	C462.594940,651.933411 465.264404,651.940491 467.933838,651.947510 
	C467.933838,651.947510 467.959076,651.970825 467.947144,651.961426 
	C467.137970,652.430725 466.340729,652.909363 465.141418,653.323120 
	C464.480133,653.428650 464.220856,653.598999 463.961578,653.769348 
	C463.535004,653.868774 463.108459,653.968262 462.256958,653.866760 
	C461.580353,653.837341 461.328674,654.008911 461.076996,654.180542 
	C454.362579,654.761719 447.648193,655.342896 440.184265,655.640259 
	C437.281738,655.512634 435.128693,655.668823 432.975647,655.825012 
	C427.963135,655.864807 422.950653,655.904541 417.243256,655.659119 
	C415.358154,655.518127 414.167969,655.662292 412.977753,655.806519 
	C411.601837,655.852173 410.225952,655.897766 408.342834,655.725098 
	C407.544525,655.606262 407.253479,655.705750 406.962402,655.805237 
M444.519501,652.435913 
	C444.519501,652.435913 444.573578,652.533630 444.519501,652.435913 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M223.301758,415.892456 
	C223.983582,415.889648 224.292847,415.840240 224.812820,416.182922 
	C222.366516,417.017639 219.709488,417.460205 216.709290,417.836273 
	C215.238449,417.494995 214.110779,417.220215 212.626770,416.767609 
	C210.067123,416.397736 207.863800,416.205658 205.660477,416.013580 
	C200.860352,415.687592 196.060242,415.361572 190.356461,415.007629 
	C186.960831,415.267944 184.468842,415.556152 181.651276,415.861420 
	C180.895599,415.940704 180.465500,416.002930 179.650238,416.046204 
	C174.732635,418.286011 170.200211,420.544739 165.496246,422.807678 
	C165.324707,422.811890 164.998932,422.919800 164.649384,422.906799 
	C163.607712,422.897583 162.915588,422.901367 162.139664,422.547028 
	C162.019272,421.946503 161.982651,421.704071 161.946045,421.461639 
	C168.514603,415.497925 176.672211,413.476990 185.102554,412.157715 
	C196.494553,410.374939 207.587997,411.884979 218.531158,415.178650 
	C219.936783,415.601715 221.460297,415.633057 223.301758,415.892456 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M199.792450,548.974670 
	C199.949905,548.838623 200.107376,548.702637 200.594315,548.917786 
	C202.039474,550.019043 203.155151,550.769104 204.644318,551.565369 
	C206.020264,551.587708 207.022720,551.563782 208.124878,551.859253 
	C208.887894,552.695251 209.551193,553.211914 210.202637,554.213745 
	C210.201660,563.002258 210.243195,571.305847 210.217545,579.609131 
	C210.145721,602.854736 210.046143,626.100220 209.722244,649.196045 
	C209.052704,648.710266 208.618439,648.374268 208.184189,648.038208 
	C208.213409,647.582336 208.242630,647.126404 208.518890,646.258789 
	C208.648239,645.564758 208.530579,645.282532 208.412918,645.000244 
	C208.355667,638.627991 208.298416,632.255676 208.521698,625.235107 
	C208.660324,623.737671 208.518433,622.888550 208.376541,622.039490 
	C208.342636,600.649841 208.308746,579.260254 208.558197,557.235474 
	C208.571014,556.080688 208.300461,555.561096 208.029907,555.041443 
	C206.235901,554.852112 204.341217,554.954041 202.680206,554.379944 
	C201.385132,553.932373 200.380630,552.644043 199.458374,551.150757 
	C199.710632,550.039734 199.751526,549.507202 199.792450,548.974670 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M498.304504,515.123718 
	C498.880157,514.484436 499.455780,513.845093 500.202057,512.889648 
	C501.958160,513.228455 503.600861,513.774658 505.111908,514.570801 
	C506.456238,515.279053 507.654724,516.264160 508.917603,517.127075 
	C507.772186,518.413940 506.647980,520.798035 505.478119,520.820679 
	C491.218567,521.096313 476.951782,521.078979 462.688965,520.932129 
	C461.646240,520.921448 459.716492,519.238953 459.785858,518.478455 
	C459.906677,517.153687 461.264648,515.941833 462.582336,515.152710 
	C465.853455,516.396484 468.646301,517.170166 471.948730,517.956177 
	C479.414795,517.974731 486.379333,518.163818 493.321838,517.856018 
	C495.019775,517.780701 496.645538,516.078125 498.304504,515.123718 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M509.272675,121.956329 
	C510.991974,120.361015 513.269287,118.966576 515.165222,117.169662 
	C516.376099,116.022034 517.022705,114.279053 518.131226,112.225327 
	C519.391602,110.946213 520.443115,110.244781 521.494629,109.543343 
	C521.669678,97.957863 521.884033,86.372795 522.006836,74.786758 
	C522.098267,66.159142 522.071289,57.530277 522.241028,48.452942 
	C522.923279,49.731525 523.918213,51.456146 523.929504,53.187202 
	C524.044800,70.821594 523.951416,88.457352 524.067688,106.091736 
	C524.080688,108.070587 525.027100,110.043282 525.744202,112.801842 
	C522.829529,115.372421 519.219238,118.931091 515.173523,121.895332 
	C513.965088,122.780708 511.537933,122.002663 509.272675,121.956329 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M424.429321,95.999252 
	C422.772766,92.763054 421.381378,89.573227 420.459869,86.252991 
	C420.275055,85.587006 421.878326,84.424767 422.846985,83.211914 
	C423.778961,82.945145 424.521118,82.957802 425.677368,83.313705 
	C427.405396,84.074104 428.719330,84.491272 430.033264,84.908432 
	C431.782623,85.578438 433.531982,86.248444 435.685669,87.311111 
	C437.430725,90.185806 438.771515,92.667839 440.572021,96.000916 
	C441.763336,96.000916 443.881653,96.000916 446.000000,96.000916 
	C442.348694,99.292007 438.020813,98.473961 433.807343,97.866798 
	C430.797668,97.433105 427.840485,96.635139 424.429321,95.999252 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M560.637451,415.994934 
	C557.006714,415.685272 553.379395,415.174286 549.744446,415.112549 
	C544.124390,415.017090 539.438171,417.269257 535.134766,421.591919 
	C533.683716,421.354950 532.828979,421.040466 531.985352,420.341309 
	C531.913940,419.185211 531.831299,418.413818 531.748718,417.642426 
	C531.748718,417.642426 531.848755,417.730194 532.043091,417.800232 
	C532.470825,417.565582 532.704285,417.260925 532.937744,416.956299 
	C540.202759,411.430328 548.809204,411.392944 557.151794,411.976379 
	C564.854736,412.515045 572.452576,414.555206 580.494385,415.884644 
	C580.975098,416.241638 581.058167,416.648041 580.825562,417.577393 
	C579.254578,418.191284 577.971008,418.496704 576.748779,418.336060 
	C571.368652,417.629120 566.006287,416.787659 560.637451,415.994934 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M385.499023,632.324524 
	C384.980713,632.143250 384.713226,631.711609 384.213440,630.714172 
	C384.588806,628.078552 385.196442,626.008789 385.873230,623.619507 
	C385.968506,622.865173 385.994659,622.430359 386.306824,621.754395 
	C387.398895,617.139526 388.585449,612.796387 388.940155,608.386414 
	C390.029358,594.844421 388.360413,581.658020 383.121704,568.782776 
	C383.505249,568.234863 383.638428,568.001953 383.771576,567.769104 
	C392.088959,580.485413 392.888794,594.791565 391.459625,609.150879 
	C390.687866,616.904602 387.724487,624.440186 385.499023,632.324524 
z"/>
<path fill="#F7E2C0" opacity="1.000000" stroke="none" 
	d="
M455.692474,228.062439 
	C462.595215,222.543365 469.150360,217.012680 475.775513,211.567245 
	C477.060577,210.510971 478.618896,209.787170 480.503540,208.935242 
	C480.955933,208.960449 481.125366,209.123245 481.054047,209.454987 
	C472.646576,219.023621 463.366364,227.098450 452.363831,233.057190 
	C447.444031,235.721603 442.531677,238.399765 437.615967,241.071686 
	C437.732086,241.676498 437.848175,242.281296 437.964294,242.886108 
	C439.492554,243.576538 441.020782,244.266953 442.169739,245.224396 
	C437.999420,245.768051 434.208435,246.044693 430.110596,246.209015 
	C429.423248,245.895920 429.042725,245.695129 428.662231,245.494339 
	C429.758820,245.251968 430.855408,245.009598 432.030060,244.749985 
	C430.699341,244.054749 429.435364,243.394394 428.095062,242.393341 
	C428.458588,242.041611 428.898407,242.030579 430.026978,241.977570 
	C432.334747,240.660690 433.789368,239.041382 435.603607,238.175400 
	C441.059387,235.571167 446.686523,233.327225 452.164215,230.766052 
	C453.022430,230.364761 453.449951,229.042328 454.076630,228.145844 
	C454.507507,228.124084 454.938416,228.102325 455.692474,228.062439 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M177.780182,550.227295 
	C178.399063,550.371826 178.653275,550.592712 178.949875,551.262268 
	C177.134186,552.316956 175.235565,553.528198 173.426178,553.406982 
	C169.250565,553.127319 165.118256,552.201172 160.968384,551.537231 
	C154.719009,551.037720 148.469620,550.538330 141.795074,550.061646 
	C136.566238,550.758545 131.762558,551.432800 126.139694,552.086243 
	C124.788635,551.963074 124.256783,551.860718 123.724930,551.758301 
	C132.535339,546.513489 142.323318,545.975159 152.075562,546.710754 
	C159.310669,547.256470 166.437515,549.190674 173.628586,550.412354 
	C174.849487,550.619812 176.151154,550.351929 177.780182,550.227295 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M237.949707,653.470276 
	C242.031250,653.539673 246.112778,653.609070 251.100540,653.662231 
	C258.435852,653.677124 264.873688,653.915588 271.289978,653.644287 
	C274.133820,653.524048 276.934082,652.373108 280.050140,651.754578 
	C280.744690,651.884399 281.143219,651.949707 281.599976,652.163696 
	C281.658173,652.312439 281.840759,652.574524 281.840759,652.574524 
	C280.743896,653.681641 279.654388,655.746643 278.549072,655.755066 
	C253.410522,655.948059 228.270035,655.976379 203.130630,655.869812 
	C198.712784,655.851074 197.845184,653.492188 200.631409,649.661499 
	C202.735596,655.934631 207.280701,653.561646 211.527985,653.531372 
	C220.637863,653.586304 229.293793,653.528259 237.949707,653.470276 
z"/>
<path fill="#E8C59B" opacity="1.000000" stroke="none" 
	d="
M406.248260,225.791473 
	C395.801819,227.645187 395.420227,227.429565 390.118652,218.100342 
	C388.515228,215.278778 386.710968,212.571304 385.053589,209.353302 
	C385.108490,208.895035 385.318115,208.690430 385.610657,208.808243 
	C386.854706,209.373306 387.806244,209.820572 388.797363,210.465897 
	C388.836914,210.663971 388.963684,211.047546 388.986816,211.366455 
	C389.666595,212.459686 390.323273,213.233994 390.988739,214.005371 
	C390.997559,214.002441 390.991608,214.020142 391.116547,214.349396 
	C395.212402,223.677658 397.009399,226.965881 406.790833,222.269272 
	C411.893524,219.819153 417.104797,217.595154 422.477936,215.243774 
	C422.688843,215.218536 423.098480,215.105865 423.463074,215.064148 
	C427.344391,213.134598 430.861053,211.246796 434.564301,209.331818 
	C434.750854,209.304642 435.099152,209.160324 435.425842,209.109589 
	C436.772888,208.713577 437.793274,208.368286 438.915649,208.269012 
	C439.080658,208.681000 439.143616,208.846985 439.105835,209.353973 
	C437.851196,210.814407 436.883118,212.311111 435.510803,212.987289 
	C429.923096,215.740433 424.214691,218.249252 418.544434,220.834167 
	C414.918640,222.487076 411.281738,224.115570 407.649841,225.755035 
	C407.275848,225.758255 406.901855,225.761459 406.248260,225.791473 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M208.025162,551.539795 
	C207.022720,551.563782 206.020264,551.587708 204.549438,551.299133 
	C203.671326,549.926758 203.261597,548.866882 202.851883,547.807007 
	C212.245987,547.622070 221.639465,547.348511 231.034409,547.280457 
	C238.471313,547.226562 245.910049,547.424744 253.659821,547.772827 
	C252.834015,549.010803 251.775421,550.690125 250.543549,550.828735 
	C247.111755,551.214661 243.608047,550.961365 239.277069,550.799683 
	C228.857071,550.702454 219.292694,550.766113 209.489868,550.850708 
	C208.842667,551.094299 208.433914,551.317017 208.025162,551.539795 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M407.978119,225.974945 
	C411.281738,224.115570 414.918640,222.487076 418.544434,220.834167 
	C424.214691,218.249252 429.923096,215.740433 435.510803,212.987289 
	C436.883118,212.311111 437.851196,210.814407 439.449097,209.331818 
	C441.575348,208.271149 443.257629,207.573593 444.939880,206.876038 
	C446.580200,216.753494 446.525482,216.908936 440.380371,219.829239 
	C436.026520,214.655716 436.300354,215.092422 430.021698,219.029602 
	C425.962036,221.575302 420.943268,222.578140 416.388733,224.360336 
	C415.563538,224.683243 414.464264,225.293182 414.239136,226.006546 
	C412.372986,231.920074 410.340851,228.356506 407.978119,225.974945 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M459.031494,105.989632 
	C460.348907,107.755531 461.666351,109.521423 463.019226,111.621826 
	C459.799377,112.677757 456.572906,113.635681 453.278595,114.037872 
	C451.064240,114.308220 448.753815,113.792168 446.486389,113.627945 
	C446.806671,110.995575 447.126953,108.363213 447.527435,105.071732 
	C447.369781,103.160698 447.145782,100.445549 447.363586,97.364799 
	C448.880585,96.988083 449.955750,96.976974 451.030914,96.965866 
	C451.030914,96.965866 451.000000,97.000000 451.005341,97.370964 
	C451.321106,102.789330 455.630219,103.948044 459.031494,105.989632 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M452.070068,293.190582 
	C452.519592,292.546143 452.969116,291.901703 454.004395,291.103088 
	C455.112061,290.357697 455.634003,289.766449 456.524048,289.117859 
	C461.283783,286.719635 465.675323,284.378784 470.430725,281.786957 
	C471.361786,281.376556 471.928955,281.217102 472.496155,281.057648 
	C473.332703,279.947144 474.169250,278.836639 475.012451,277.360016 
	C476.472900,276.820374 477.926697,276.646820 479.822723,276.420502 
	C480.295593,285.644836 472.397430,284.446289 467.522369,286.580109 
	C465.824890,296.559967 460.839874,299.150085 452.460999,293.986938 
	C452.126312,293.591003 452.070068,293.190582 452.070068,293.190582 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M538.963074,442.889404 
	C539.503601,442.738739 540.044128,442.588104 540.785400,442.231262 
	C552.992126,446.264740 565.013855,450.460876 576.985718,454.794769 
	C578.628052,455.389282 580.022339,456.668762 581.302002,458.377167 
	C581.061523,459.416107 581.050537,459.708954 580.663635,459.984009 
	C578.054688,458.955017 575.889160,457.741821 573.576538,456.968781 
	C565.151733,454.152740 556.588623,451.724365 548.265930,448.641541 
	C544.914551,447.400177 542.049500,444.846100 538.963074,442.889404 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M353.062073,520.694214 
	C352.360962,518.929626 352.081329,517.236450 351.722931,515.066284 
	C354.895142,515.066284 357.462738,515.066284 360.459595,515.468506 
	C359.636566,516.874634 358.384247,517.878540 356.385681,519.480652 
	C360.107605,518.943237 363.069824,518.515503 366.494751,518.035522 
	C373.306244,518.020081 379.655121,518.056885 386.191284,518.332642 
	C387.253662,518.407166 388.128693,518.242798 389.336182,518.031555 
	C390.115570,517.995483 390.562500,518.006287 391.018616,518.040283 
	C391.027802,518.063538 391.076202,518.076111 391.340057,518.360962 
	C394.392303,518.447144 397.180695,518.248413 400.222778,517.897705 
	C400.959564,516.889709 401.442657,516.033569 401.925751,515.177429 
	C402.501495,516.250977 403.662476,517.433289 403.488831,518.367493 
	C403.314148,519.307068 401.917908,520.450195 400.866516,520.698792 
	C399.126770,521.110168 397.234161,520.911926 395.406128,520.906921 
	C381.431915,520.868469 367.457733,520.814636 353.062073,520.694214 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M355.744354,97.922691 
	C357.308136,97.594009 359.077972,97.628166 360.401489,96.876450 
	C367.746948,92.704460 374.983917,88.341507 382.624268,84.008896 
	C382.616241,86.428925 382.414581,88.927750 381.806946,91.323608 
	C381.404327,92.911018 380.419952,94.350868 379.803131,95.633934 
	C377.969513,93.823395 376.568359,92.439896 375.167236,91.056412 
	C374.102844,92.623459 373.038452,94.190498 372.470032,95.027351 
	C368.546082,97.489494 365.172791,99.764244 361.630188,101.734573 
	C360.537994,102.342041 358.983887,102.119003 357.321014,102.137238 
	C356.331848,102.187233 355.537476,102.211067 355.022247,102.602394 
	C354.166870,103.252037 353.490845,104.137833 352.402344,104.603912 
	C353.291718,102.163345 354.518036,100.043022 355.744354,97.922691 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M598.886353,551.099121 
	C597.681396,550.447937 596.476379,549.796692 594.963745,548.947388 
	C596.141846,548.322266 597.625854,547.531921 599.113892,547.524292 
	C614.864075,547.443542 630.614990,547.498108 646.365662,547.486572 
	C648.976929,547.484619 651.574158,547.442627 651.642029,551.052063 
	C651.708496,554.592407 649.270203,554.918335 646.567444,554.831299 
	C644.911926,554.778015 643.255676,554.750061 641.287109,554.462158 
	C641.912598,553.426514 642.850769,552.639465 644.196289,551.460083 
	C645.233521,550.788208 645.863220,550.508606 646.654541,550.157288 
	C646.095215,549.647217 645.868958,549.259644 645.640564,549.258423 
	C631.023804,549.177124 616.406738,549.088440 601.790466,549.161743 
	C600.819214,549.166565 599.854248,550.424194 598.886353,551.099121 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M321.031036,382.924561 
	C320.098419,383.476654 319.062836,384.629303 318.248749,384.489777 
	C308.011536,382.735107 297.555328,381.628448 287.666870,378.654419 
	C278.633026,375.937469 270.157501,371.284363 261.572601,367.197449 
	C258.536987,365.752289 255.904984,363.459259 253.435562,361.231323 
	C255.789749,361.691803 257.797089,362.474304 259.981842,363.356567 
	C260.159241,363.456268 260.551605,363.565887 260.703918,363.864410 
	C262.933380,365.062256 265.010529,365.961578 267.322205,367.002075 
	C267.895935,367.275757 268.235168,367.408264 268.684509,367.860168 
	C269.810425,368.537384 270.826202,368.895233 272.023010,369.329163 
	C272.203979,369.405243 272.591553,369.468140 272.733459,369.790710 
	C280.657806,372.557861 288.440247,375.002411 296.512390,377.551819 
	C297.207275,377.706512 297.612457,377.756348 298.226471,378.122498 
	C302.301422,379.075928 306.167511,379.713013 310.342163,380.411377 
	C311.276855,380.530090 311.902985,380.587524 312.683563,380.927429 
	C314.228363,381.284088 315.618774,381.358185 317.343689,381.483826 
	C318.804108,381.622131 319.929962,381.708923 321.035339,382.077637 
	C321.020203,382.547821 321.025604,382.736176 321.031036,382.924561 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M283.989319,418.826111 
	C283.571075,420.337463 282.801239,421.846313 282.790314,423.360596 
	C282.704285,435.272156 282.763367,447.184814 282.770386,459.778290 
	C282.785858,460.637390 282.808685,460.815277 282.623749,461.216125 
	C282.312256,462.042755 282.208496,462.646393 281.715393,463.096741 
	C281.082855,462.724640 280.625946,462.504822 280.626953,462.287140 
	C280.696655,446.972931 280.802338,431.658875 280.913879,416.344879 
	C280.915009,416.191742 281.034058,416.039490 281.524780,415.870728 
	C284.684937,415.070404 287.418365,414.286163 290.151794,413.501892 
	C292.342316,418.135834 289.942169,419.114014 285.401550,418.573059 
	C284.580109,418.501770 284.284698,418.663940 283.989319,418.826111 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M471.418884,597.080078 
	C470.051239,598.123108 469.048584,599.159424 467.638550,600.590820 
	C466.502411,600.916443 465.773651,600.847046 465.024170,600.405273 
	C464.997253,599.027954 464.991089,598.022949 465.310425,596.908569 
	C468.901978,594.909546 472.415100,593.343018 475.387909,591.069885 
	C484.392456,584.184753 483.941559,574.717468 481.978210,564.629028 
	C481.690979,563.533142 481.394043,562.764709 481.074677,561.978516 
	C481.052246,561.960815 481.049011,561.903809 481.042480,561.587769 
	C481.022217,560.849670 481.008484,560.427551 481.084595,559.928406 
	C481.174469,559.851318 481.357666,559.701294 481.357666,559.701294 
	C489.210846,569.822388 487.402283,587.264893 476.525879,594.394714 
	C475.010895,595.387878 473.367706,596.185364 471.418884,597.080078 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M290.021423,413.147583 
	C287.418365,414.286163 284.684937,415.070404 281.103027,415.808441 
	C271.205719,415.558807 262.157227,415.336578 253.107330,415.210510 
	C252.678192,415.204498 252.237152,416.052307 251.416458,416.549744 
	C250.243652,416.657593 249.456039,416.718170 248.602112,416.794739 
	C248.535797,416.810760 248.541473,416.947052 248.313538,416.642029 
	C247.355148,416.195801 246.624680,416.054626 245.649597,415.758911 
	C244.652344,414.755493 243.899704,413.906647 243.147064,413.057800 
	C243.147064,413.057800 243.028549,412.582367 243.049454,412.287628 
	C243.070358,411.992889 243.089783,412.096100 243.089783,412.096100 
	C245.436447,412.118042 247.783127,412.139954 250.976685,412.176392 
	C253.214981,412.137848 254.606369,412.084778 255.997772,412.031738 
	C256.751007,412.081451 257.504272,412.131165 259.092102,412.198578 
	C265.617004,412.155762 271.307281,412.095245 276.997559,412.034729 
	C281.295380,412.287598 285.593231,412.540466 290.021423,413.147583 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M635.040344,649.115845 
	C635.053589,648.789856 635.158203,648.496582 635.564453,648.127991 
	C642.726868,650.362366 648.688232,647.978882 654.232605,644.005676 
	C662.290222,638.231445 666.699829,629.709534 671.058655,621.189758 
	C672.225891,618.908447 673.168518,616.535522 676.718201,617.677795 
	C676.940186,618.707214 676.915771,619.515564 676.474121,620.697510 
	C674.423584,622.931580 672.361572,624.563599 671.227539,626.690369 
	C664.592407,639.134216 656.271667,649.347778 640.892334,650.486572 
	C638.985962,650.627747 636.993103,649.601196 635.040344,649.115845 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M501.419525,415.965973 
	C500.553497,416.097504 499.687500,416.229004 498.107910,416.169159 
	C496.515106,415.613892 495.626434,414.913910 494.758057,414.938354 
	C486.382080,415.173676 478.009521,415.530914 469.635986,415.853271 
	C466.642517,415.316467 463.649048,414.779694 460.314880,414.096313 
	C461.109039,413.337708 462.241425,412.199341 463.379089,412.194092 
	C476.362213,412.134186 489.346100,412.246979 502.830688,412.739746 
	C505.557373,416.054199 501.848663,414.890381 501.419525,415.965973 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M467.022461,122.345612 
	C468.199280,124.939941 469.428894,127.890663 470.486053,130.901901 
	C472.642761,137.044891 476.481171,138.686295 482.489624,135.963791 
	C484.720856,134.952774 486.982880,134.009811 489.596436,132.984619 
	C489.951294,134.427734 490.153351,135.961258 489.886566,137.408356 
	C489.290131,140.643509 484.786041,143.100891 482.813141,141.055771 
	C479.067932,137.173462 477.027618,138.589935 475.109039,142.582321 
	C474.814178,143.195862 474.001892,143.560776 473.990875,143.570007 
	C471.770416,140.037476 469.858124,136.659454 467.615814,133.516922 
	C465.079651,129.962540 464.429688,126.486259 467.022461,122.345612 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M444.952515,206.654877 
	C443.257629,207.573593 441.575348,208.271149 439.549805,208.990845 
	C439.143616,208.846985 439.080658,208.681000 439.065704,208.050873 
	C439.213257,207.442215 439.312744,207.297653 439.784576,207.127136 
	C444.106537,206.988022 443.097870,204.275406 442.929504,201.501495 
	C442.998993,193.010834 442.990021,184.980911 443.049805,176.723755 
	C443.118591,176.496552 443.000763,176.036621 443.042694,175.567139 
	C443.029877,165.741425 442.975128,156.385178 443.016541,146.646454 
	C443.443512,144.832687 443.774323,143.401413 444.105164,141.970123 
	C444.394653,143.709518 444.930603,145.448166 444.935883,147.188416 
	C444.994141,166.483566 444.970184,185.778931 445.027313,205.540863 
	C445.086182,206.007477 444.965179,206.433701 444.952515,206.654877 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M157.201477,652.833618 
	C152.017838,653.181885 146.834183,653.530151 141.164520,653.777466 
	C140.017670,652.056030 139.811157,649.869934 138.618362,648.911743 
	C133.060562,644.447449 129.923370,638.782898 128.994324,631.405029 
	C129.427368,630.773682 129.837051,630.541016 130.459534,630.203796 
	C131.746887,633.041931 132.817535,635.986084 133.896866,638.927002 
	C136.119751,644.983948 140.802979,648.719299 146.528610,650.734680 
	C150.470367,652.122070 154.357422,650.791687 156.941956,646.825867 
	C157.459579,647.580872 157.765793,648.315002 158.020416,649.376892 
	C157.713058,650.747620 157.457260,651.790649 157.201477,652.833618 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M574.881226,513.910767 
	C565.791504,519.257385 556.018799,518.572327 545.671936,516.737305 
	C544.154724,514.870117 543.129700,513.428101 542.428467,511.949738 
	C543.229675,511.505371 543.707092,511.097412 544.184509,510.689453 
	C552.288513,514.889099 560.528992,516.521912 569.658264,512.158569 
	C570.686646,511.818420 571.152893,511.334259 571.619141,510.850098 
	C572.393860,510.912231 573.168579,510.974365 574.403198,511.381226 
	C574.869080,512.454224 574.875122,513.182495 574.881226,513.910767 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M503.888855,652.761108 
	C504.305328,648.761475 504.182068,644.609253 505.259827,640.796509 
	C507.048309,634.469666 509.506348,628.320618 511.926331,622.192932 
	C512.442200,620.886780 514.002136,619.992981 515.495972,619.223145 
	C515.934143,619.970276 515.957458,620.403381 515.980774,620.836487 
	C515.253784,621.697449 514.205078,622.443665 513.850464,623.437561 
	C510.852142,631.840393 507.980499,640.288452 504.855469,649.347168 
	C507.912506,649.776855 510.532990,650.145203 513.031433,650.773010 
	C510.159698,652.950378 507.626251,655.997131 503.888855,652.761108 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M249.681885,431.045105 
	C249.726898,429.320496 249.771896,427.595917 249.821838,425.032928 
	C249.904556,422.794250 249.982330,421.393982 250.355469,420.287537 
	C250.750656,422.400421 250.936188,424.219421 250.937515,426.038513 
	C250.957687,453.845886 250.937119,481.653259 250.991745,509.460541 
	C250.995514,511.382050 251.517654,513.302490 251.676544,515.499634 
	C252.259232,516.543518 252.964111,517.311340 253.390656,518.295715 
	C249.087219,518.022705 245.062134,517.533020 240.918243,516.787598 
	C240.755127,516.349182 240.710815,516.166443 240.666534,515.983765 
	C241.247726,515.471130 241.828903,514.958435 242.925201,514.510559 
	C246.010178,516.045898 248.251328,516.262878 249.571472,512.967651 
	C249.653687,511.894135 249.735901,510.820587 249.833069,508.900391 
	C249.790131,504.720215 249.732254,501.386719 249.674377,498.053192 
	C249.724228,476.997498 249.774063,455.941803 249.838135,434.061340 
	C249.795532,432.506104 249.738708,431.775604 249.681885,431.045105 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M123.421776,551.808655 
	C124.256783,551.860718 124.788635,551.963074 125.727386,552.114624 
	C114.817383,558.806152 105.359055,567.104858 101.039803,580.064026 
	C100.787170,580.821960 99.815483,581.340271 98.834412,581.978149 
	C99.830627,568.930664 108.283844,560.971497 119.030548,554.146606 
	C120.107758,553.731934 120.551628,553.372437 120.995514,553.013000 
	C121.703224,552.628357 122.410927,552.243713 123.421776,551.808655 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M158.071991,649.049194 
	C157.765793,648.315002 157.459579,647.580872 157.115417,646.524902 
	C157.065048,645.978333 157.052643,645.753479 157.507095,645.066650 
	C157.983871,643.740845 157.993790,642.876831 158.003693,642.012878 
	C157.968079,641.840393 157.932480,641.667969 158.160828,641.166260 
	C158.483475,640.728394 158.619431,640.569763 158.587280,640.520325 
	C158.461197,640.326355 158.282181,640.166748 158.122803,639.994385 
	C158.051804,632.634644 157.980774,625.274902 158.227188,617.302734 
	C158.370987,616.126160 158.197357,615.561890 158.023727,614.997681 
	C158.023727,614.997681 158.008682,615.067749 157.973724,615.075806 
	C156.942780,614.400391 155.850464,613.819214 154.981705,613.000793 
	C154.302078,612.360596 153.899628,611.426147 154.007568,610.377441 
	C155.137985,610.128052 155.634857,610.123657 156.131729,610.119263 
	C156.483566,610.122559 156.835388,610.125854 157.816223,610.264099 
	C159.247528,612.291565 160.689651,614.166504 160.742188,616.079590 
	C160.978653,624.690491 161.014511,633.319641 160.680511,641.925110 
	C160.587158,644.329956 158.983063,646.676086 158.071991,649.049194 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M215.030518,320.233521 
	C211.024124,309.637543 206.848206,299.349457 203.429123,288.815674 
	C201.867065,284.003235 201.796829,278.706604 201.628510,273.492737 
	C202.401199,273.805328 202.733780,274.244049 202.769531,274.705750 
	C204.033905,291.037872 209.639160,305.913544 217.815964,319.950989 
	C220.182144,324.013092 222.366791,328.180939 224.291901,332.262573 
	C221.105835,328.616699 218.262543,325.008545 215.419235,321.400391 
	C215.336182,321.080597 215.253128,320.760834 215.030518,320.233521 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M453.914124,227.814651 
	C453.449951,229.042328 453.022430,230.364761 452.164215,230.766052 
	C446.686523,233.327225 441.059387,235.571167 435.603607,238.175400 
	C433.789368,239.041382 432.334747,240.660690 430.356720,241.960022 
	C430.835327,240.034180 431.324219,237.801895 432.609070,236.213364 
	C433.881104,234.640732 435.956116,233.717560 438.876740,231.669540 
	C439.245758,229.434738 440.882629,227.160873 445.606140,228.744019 
	C446.745728,229.125961 448.666656,227.176682 451.359100,225.664230 
	C451.399292,225.694794 452.575439,226.589127 453.914124,227.814651 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M202.373718,241.144073 
	C204.219406,233.471436 205.667862,225.672958 208.045090,218.168716 
	C209.738998,212.821472 212.682175,207.869980 215.056976,202.375977 
	C215.046082,202.010040 215.226685,201.735962 215.555847,201.455536 
	C218.913879,197.439606 221.942764,193.704102 225.020538,190.359955 
	C225.010376,191.540634 224.951355,192.329956 224.846436,193.221222 
	C224.800507,193.323166 224.857864,193.539276 224.514130,193.653702 
	C223.468567,194.439514 222.624390,195.013153 222.086166,195.796906 
	C213.274826,208.628357 207.165680,222.624771 203.960144,237.881912 
	C203.718979,239.029724 202.915680,240.059448 202.373718,241.144073 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M336.979736,518.071228 
	C339.138031,518.248840 341.296326,518.426453 344.100464,518.314331 
	C345.218414,517.916565 345.690552,517.808533 346.460602,517.667419 
	C347.178925,516.772705 347.599274,515.910950 348.019653,515.049255 
	C348.662354,516.093018 349.305023,517.136719 349.549896,518.561096 
	C348.721100,519.492737 348.290131,520.043762 347.859192,520.594788 
	C347.443604,520.698730 347.028015,520.802734 345.914734,520.869507 
	C344.147217,520.905823 343.077423,520.979370 342.007599,521.052856 
	C341.851196,520.983398 341.694763,520.913940 340.948761,520.551392 
	C331.223877,520.412354 322.088593,520.566406 312.953308,520.720459 
	C310.591980,520.767761 308.230652,520.815002 305.058167,520.844971 
	C303.202911,520.802673 302.158844,520.777710 301.114777,520.752686 
	C300.830322,520.369019 300.545868,519.985352 299.974487,519.574341 
	C299.687531,519.546936 299.745453,519.489380 299.745453,519.489380 
	C299.834747,518.580872 299.924072,517.672363 300.459717,516.525513 
	C302.931213,516.870667 304.956360,517.454041 307.269684,518.305481 
	C310.362915,518.421021 313.167969,518.268616 316.441010,518.067139 
	C323.599213,518.035828 330.289490,518.053528 336.979736,518.071228 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M241.037048,517.043396 
	C245.062134,517.533020 249.087219,518.022705 253.897186,518.261719 
	C260.915985,518.053223 267.149933,518.095459 273.376526,518.216919 
	C273.369232,518.296204 273.600433,518.139099 274.069916,518.094727 
	C276.110687,518.047363 277.681976,518.044250 279.526123,518.264282 
	C283.177185,518.183472 287.522766,520.636719 289.402588,515.153564 
	C290.096344,515.125916 290.494934,515.166016 290.893555,515.206055 
	C290.896912,520.843018 290.896912,520.843018 281.140625,520.542236 
	C271.227936,520.405884 262.090118,520.561157 252.952286,520.716431 
	C249.808136,520.722595 246.663986,520.728760 243.109100,520.257080 
	C242.203369,519.502380 241.708359,519.225586 241.213348,518.948792 
	C241.213348,518.948792 241.002991,518.861450 240.982193,518.613892 
	C240.986618,517.925415 241.011841,517.484375 241.037048,517.043396 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M409.673981,653.569641 
	C406.494507,654.344727 406.048218,652.372009 406.037476,649.556641 
	C406.114380,648.957092 406.195740,648.757751 406.439148,648.278931 
	C407.059082,646.215210 407.909882,644.432129 407.915802,642.646301 
	C408.001770,616.755737 407.966339,590.864807 407.992188,564.973999 
	C407.993988,563.170898 408.249939,561.368103 408.732239,559.740173 
	C409.077362,588.856384 409.064636,617.797485 409.104309,646.738586 
	C409.107452,649.015869 409.475525,651.292664 409.673981,653.569641 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M245.818436,166.791840 
	C250.510193,159.815384 258.301392,157.151642 265.110962,153.226288 
	C269.484192,150.705338 274.457886,149.245926 279.034149,147.044067 
	C280.261475,146.453537 281.018799,144.886200 281.990723,143.383636 
	C282.001404,141.296494 282.013245,139.592972 282.461548,137.428711 
	C283.188629,136.953064 283.479279,136.938171 283.906281,137.287933 
	C284.072876,147.522369 284.059174,147.482315 274.950134,150.598740 
	C272.926453,151.291092 270.939545,152.211945 269.102234,153.307007 
	C261.424408,157.883163 253.810043,162.565750 246.083694,167.105148 
	C245.994965,167.000961 245.818436,166.791840 245.818436,166.791840 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M394.070007,513.029419 
	C394.070007,513.029419 394.288574,513.195129 394.165985,513.661499 
	C393.486938,514.749573 392.930450,515.371338 392.112732,515.644531 
	C391.605286,514.353333 391.148010,513.411499 391.144653,512.468140 
	C391.094147,498.272522 391.027649,484.075745 391.194397,469.881958 
	C391.215210,468.107849 392.543274,466.349091 393.546509,464.651917 
	C394.213501,464.769257 394.599579,464.817932 394.841278,465.143311 
	C394.537628,466.303284 394.378326,467.186523 394.219055,468.069733 
	C394.219055,468.069733 394.263702,468.554443 393.808716,468.830780 
	C393.294678,483.363556 393.228607,497.619965 393.214233,511.876434 
	C393.213837,512.260498 393.772217,512.645081 394.070007,513.029419 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M581.039551,460.001801 
	C581.050537,459.708954 581.061523,459.416107 581.109863,458.680786 
	C590.041992,462.701569 595.210876,472.060577 594.192749,481.709656 
	C594.003235,483.505554 594.114441,485.333160 594.053955,487.586975 
	C593.552307,488.654877 593.083618,489.281982 592.272217,489.492584 
	C592.035583,478.720001 591.012634,468.775879 581.879822,461.608002 
	C581.662048,461.261841 581.489624,461.110809 581.210327,460.754150 
	C581.106628,460.338318 581.073059,460.170044 581.039551,460.001801 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M541.145996,618.895813 
	C541.814941,618.935181 542.483887,618.974548 543.681641,619.344177 
	C544.846802,621.736816 545.483032,623.799194 546.080444,626.203857 
	C546.554749,627.390076 547.067871,628.233948 547.675415,629.360413 
	C547.810791,629.837463 547.851685,630.031860 547.913818,630.568115 
	C548.298950,631.930725 548.662964,632.951416 549.183594,634.293457 
	C549.887756,636.440063 550.435364,638.265320 551.045654,640.270996 
	C551.229675,640.680908 551.403198,640.859924 551.719604,641.286621 
	C551.845337,641.789551 551.880127,641.994385 551.932190,642.392822 
	C551.949402,642.586487 552.009521,642.970581 552.079712,643.268555 
	C552.420105,644.106262 552.690308,644.645935 552.959595,645.576660 
	C552.314026,647.632080 551.669373,649.296509 550.978271,650.580383 
	C550.850769,649.697327 550.769531,649.194824 550.688232,648.692383 
	C547.591675,639.671387 544.495056,630.650330 541.198730,620.967651 
	C541.048035,619.835938 541.097046,619.365845 541.145996,618.895813 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M282.104736,463.250031 
	C282.208496,462.646393 282.312256,462.042755 282.914612,461.206299 
	C283.606995,460.964478 283.800781,460.955536 283.994537,460.946625 
	C290.695129,461.125366 297.395721,461.304138 304.815063,461.503113 
	C306.374603,461.371704 307.215332,461.220001 308.056091,461.068298 
	C308.147797,461.924500 308.239502,462.780731 308.582092,464.180481 
	C308.807220,465.112518 308.781494,465.501068 308.755768,465.889587 
	C308.384766,465.882111 308.013763,465.874634 307.085266,465.590576 
	C302.485596,465.476532 298.444183,465.661621 294.400970,465.792725 
	C292.239594,465.862793 290.075134,465.839905 287.912079,465.858978 
	C286.895172,465.860413 285.878296,465.861877 284.321594,465.612335 
	C283.238800,465.146332 282.695801,464.931274 282.146973,464.470825 
	C282.113617,463.900879 282.101471,463.575745 282.104736,463.250031 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M517.969482,483.823303 
	C519.588135,484.405975 521.839783,484.503906 522.719482,485.652527 
	C527.312317,491.649628 531.556641,497.913605 535.978821,504.530304 
	C536.035400,504.975891 535.734680,505.338898 535.394287,505.262085 
	C530.867981,500.708771 526.741089,496.174530 522.456238,491.794739 
	C521.330322,490.643890 519.715271,489.971466 518.253845,488.704590 
	C518.111694,486.827698 518.040588,485.325500 517.969482,483.823303 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M224.971649,189.968597 
	C221.942764,193.704102 218.913879,197.439606 215.556595,201.291412 
	C217.289688,197.314255 219.180862,193.122208 221.472427,189.161865 
	C223.007080,186.509674 225.110184,184.186386 227.000824,181.367584 
	C229.453293,179.097046 231.840988,177.145248 234.281723,175.262161 
	C236.202057,173.780563 238.187790,172.383728 240.609650,171.012726 
	C241.074814,171.076233 241.293793,171.291840 241.213242,171.577789 
	C241.098862,172.250305 241.065033,172.636871 241.015594,173.011719 
	C241.000000,173.000000 241.023209,173.031342 240.674316,173.007935 
	C235.206390,178.647797 230.087341,184.311066 224.977997,189.983978 
	C224.987717,189.993622 224.971649,189.968597 224.971649,189.968597 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M503.497406,652.810303 
	C507.626251,655.997131 510.159698,652.950378 513.268738,650.990112 
	C514.962830,654.207703 514.226257,656.004822 510.346588,655.949341 
	C504.390045,655.864075 498.431091,655.958252 492.473602,655.916931 
	C488.879425,655.891968 487.650452,654.302002 489.884460,650.915894 
	C494.411957,652.861084 498.494873,654.578430 503.497406,652.810303 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M161.614700,421.608337 
	C161.982651,421.704071 162.019272,421.946503 161.970581,422.849487 
	C161.606995,424.352142 161.328690,425.194244 161.004120,426.246033 
	C160.957870,426.455688 160.892075,426.880005 160.533646,426.908173 
	C155.178619,429.361725 152.375763,433.312836 151.872498,438.902649 
	C151.867371,438.938385 151.820145,438.883728 151.529114,438.936462 
	C150.365585,439.665009 149.493103,440.340820 148.620605,441.016632 
	C148.028137,441.230042 147.435654,441.443451 146.556213,441.802521 
	C148.903030,433.454285 154.383255,427.076782 161.614700,421.608337 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M289.999298,105.005074 
	C290.336182,104.229469 290.673065,103.453865 291.277161,102.159744 
	C292.577362,100.412727 293.610291,99.184235 294.705261,98.314270 
	C291.534454,108.452194 288.301605,118.231575 284.773376,127.777130 
	C284.328400,126.035431 284.178833,124.527557 284.029266,123.019684 
	C284.364410,121.292961 284.699554,119.566238 285.337769,117.270828 
	C286.095398,115.468719 286.549957,114.235306 287.004517,113.001877 
	C287.676147,111.245850 288.347748,109.489822 289.268616,107.197891 
	C289.678345,106.109695 289.838837,105.557381 289.999298,105.005074 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M362.042145,384.419830 
	C361.890076,384.710205 361.735596,385.255768 361.586304,385.254364 
	C354.121490,385.183319 346.657135,385.065460 339.459503,384.458557 
	C341.503113,383.767975 343.279968,383.571503 345.518311,383.404297 
	C350.627869,383.421021 355.275909,383.408508 360.138611,383.607239 
	C360.916229,384.018951 361.479187,384.219391 362.042145,384.419830 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M450.881104,96.662674 
	C449.955750,96.976974 448.880585,96.988083 447.402710,96.999146 
	C446.899109,96.676239 446.711914,96.415283 446.219238,96.108566 
	C443.881653,96.000916 441.763336,96.000916 440.572021,96.000916 
	C438.771515,92.667839 437.430725,90.185806 436.052063,87.331314 
	C440.919861,90.092384 445.825562,93.225929 450.881104,96.662674 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M298.404999,90.625328 
	C298.154388,91.335770 297.903809,92.046211 297.059387,92.888428 
	C295.983887,93.022797 295.502197,93.025398 295.020508,93.028008 
	C294.658539,90.658600 294.296570,88.289185 293.846039,85.340233 
	C293.675201,85.291260 292.566559,85.261742 291.892792,84.705498 
	C291.097229,84.048721 290.460724,82.995087 290.191132,81.995895 
	C290.065857,81.531654 290.998413,80.256157 291.402313,80.277504 
	C292.444885,80.332611 293.645264,80.674324 294.448059,81.318520 
	C296.169220,82.699638 297.679443,84.343636 299.473755,86.264954 
	C299.249298,87.975052 298.827148,89.300186 298.404999,90.625328 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M522.712280,42.892220 
	C522.238220,38.727863 522.173645,34.454899 521.936279,30.191555 
	C521.634155,24.766474 520.299438,24.019016 514.776001,26.270874 
	C513.916870,22.148243 517.083130,23.268536 519.320435,22.987049 
	C522.290894,22.613331 524.663574,24.050863 524.624573,26.763720 
	C524.547546,32.111580 523.620483,37.447201 522.712280,42.892220 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M283.672363,123.050018 
	C284.178833,124.527557 284.328400,126.035431 284.801025,128.150482 
	C284.996002,129.492279 284.867920,130.226913 284.375854,131.077789 
	C283.831726,132.675766 283.651611,134.157471 283.582520,135.834839 
	C283.764557,136.324188 283.790039,136.621796 283.769897,136.923294 
	C283.479279,136.938171 283.188629,136.953064 282.463196,137.003067 
	C277.427124,131.061539 277.748291,127.578529 283.672363,123.050018 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M540.664062,618.854736 
	C541.097046,619.365845 541.048035,619.835938 540.993774,620.659668 
	C532.961426,621.007080 524.934448,621.000793 516.444092,620.915527 
	C515.957458,620.403381 515.934143,619.970276 515.863586,619.212708 
	C523.938232,618.863403 532.060181,618.838501 540.664062,618.854736 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M211.074005,653.418457 
	C207.280701,653.561646 202.735596,655.934631 201.017334,649.647949 
	C203.211823,649.034363 205.374817,648.789978 207.860992,648.291870 
	C208.618439,648.374268 209.052704,648.710266 209.734863,649.500122 
	C210.346497,651.108826 210.710251,652.263611 211.074005,653.418457 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M281.541748,652.014954 
	C281.143219,651.949707 280.744690,651.884399 280.069336,651.438721 
	C279.678741,650.648499 279.564972,650.238525 279.539124,649.495483 
	C279.614502,648.477905 279.601990,647.793518 279.714722,646.704102 
	C279.836517,645.175964 279.833008,644.052856 279.855164,642.605225 
	C279.876404,641.846619 279.872070,641.412659 280.178955,640.705383 
	C280.650635,638.026184 280.612671,635.584717 281.035217,633.225708 
	C281.365479,631.381958 282.267242,629.640564 282.939331,628.325989 
	C283.048676,630.384644 283.133789,631.971069 283.218872,633.557434 
	C283.448730,633.831726 283.521088,634.142639 283.022583,634.931580 
	C282.679779,637.230530 282.750275,639.088074 282.820770,640.945679 
	C282.629395,643.372253 282.438049,645.798889 282.064209,648.991089 
	C281.768433,650.509399 281.655090,651.262207 281.541748,652.014954 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M394.135803,512.562256 
	C393.772217,512.645081 393.213837,512.260498 393.214233,511.876434 
	C393.228607,497.619965 393.294678,483.363556 393.687073,469.041565 
	C394.080841,483.349091 394.141205,497.722107 394.135803,512.562256 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M501.312866,416.480469 
	C501.848663,414.890381 505.557373,416.054199 503.269287,412.947052 
	C505.694946,412.394531 508.702881,411.653748 508.834442,415.436340 
	C509.022858,420.856049 504.595612,418.567780 501.471069,419.095093 
	C501.093201,418.373749 501.149719,417.684357 501.312866,416.480469 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M231.847260,517.923340 
	C231.587326,511.258545 231.713089,504.365479 232.263885,497.376251 
	C232.536926,504.085114 232.384933,510.890106 231.847260,517.923340 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M320.874390,412.006226 
	C314.720001,412.514038 308.311493,412.662903 301.443665,412.705933 
	C301.535156,412.082825 302.092712,411.110138 302.635834,411.118164 
	C308.631958,411.206696 314.625793,411.447601 320.874390,412.006226 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M362.361145,384.320496 
	C361.479187,384.219391 360.916229,384.018951 360.389404,383.254333 
	C368.059723,382.323730 375.693848,381.957336 383.328003,381.590942 
	C383.402374,381.935364 383.317535,382.234589 383.024628,382.723877 
	C382.538879,382.966827 382.102051,382.974548 380.920807,382.996399 
	C377.451538,383.344666 374.726624,383.678772 372.001740,384.012878 
	C369.955261,384.032379 367.908783,384.051910 365.177979,384.091003 
	C364.329712,384.184265 364.165741,384.257965 364.001740,384.331665 
	C363.561218,384.294861 363.120667,384.258026 362.361145,384.320496 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M355.642548,412.934692 
	C356.463562,413.738983 356.909912,414.473633 357.773560,415.637299 
	C359.136871,417.016449 360.082916,417.966583 361.028931,418.916748 
	C359.414032,418.898346 357.785736,418.738586 356.186859,418.889618 
	C352.319611,419.254883 351.204407,417.485504 352.193817,413.553864 
	C353.273315,413.047211 354.270599,412.956146 355.642548,412.934692 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M521.391052,431.256378 
	C521.116821,430.940216 520.842590,430.624054 520.291260,430.154907 
	C522.051392,427.150116 524.088745,424.298309 526.659668,421.290680 
	C527.463440,421.097748 527.732422,421.052765 528.108398,421.107605 
	C528.216858,421.215210 528.433716,421.430389 528.332520,421.762329 
	C525.951294,425.148315 523.671143,428.202362 521.391052,431.256378 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M276.909363,411.635834 
	C271.307281,412.095245 265.617004,412.155762 259.465149,412.131836 
	C259.175415,411.584564 259.355957,410.714355 259.517975,410.717773 
	C265.286774,410.840118 271.053864,411.044250 276.909363,411.635834 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M215.307678,321.787292 
	C218.262543,325.008545 221.105835,328.616699 224.363358,332.443420 
	C224.777573,332.661987 224.997314,332.983765 224.941223,333.307556 
	C225.095062,334.248047 225.304993,334.864777 225.367081,335.630066 
	C225.219254,335.778656 224.923080,336.075317 224.923080,336.075317 
	C221.680771,331.441620 218.438446,326.807892 215.307678,321.787292 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M479.223267,272.104492 
	C479.370422,270.359955 479.751160,268.926788 480.131897,267.493652 
	C482.219788,268.590271 484.307678,269.686890 486.395569,270.783508 
	C486.180237,271.474701 485.964874,272.165863 485.749542,272.857056 
	C483.651978,272.709991 481.554413,272.562958 479.223267,272.104492 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M392.374023,515.993164 
	C392.930450,515.371338 393.486938,514.749573 394.136139,513.795349 
	C396.573273,513.911804 398.917694,514.360779 401.593933,514.993530 
	C401.442657,516.033569 400.959564,516.889709 399.777954,517.890503 
	C396.411713,518.048828 393.743958,518.062500 391.076202,518.076172 
	C391.076202,518.076111 391.027802,518.063538 391.150024,517.765991 
	C391.639496,516.976685 392.006775,516.484924 392.374023,515.993164 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M294.643219,97.955734 
	C293.610291,99.184235 292.577362,100.412727 291.288940,101.822380 
	C290.381897,100.255646 289.730377,98.507751 289.078827,96.759857 
	C290.747101,96.171310 292.415375,95.582771 294.524719,95.332947 
	C294.984711,96.128052 295.003601,96.584427 294.969482,97.286819 
	C294.825378,97.673805 294.734314,97.814766 294.643219,97.955734 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M242.810623,413.091919 
	C243.899704,413.906647 244.652344,414.755493 245.893433,416.047302 
	C247.101746,416.642517 247.821609,416.794800 248.541473,416.947083 
	C248.541473,416.947052 248.535797,416.810760 248.804184,417.083679 
	C249.344406,417.979187 249.616226,418.601837 249.563873,419.180298 
	C248.821609,419.164490 248.403519,419.192871 247.985443,419.221283 
	C246.035675,418.988586 244.085907,418.755920 241.677216,418.099792 
	C241.051788,416.779724 240.885269,415.883118 240.718750,414.986481 
	C241.303909,414.366333 241.889053,413.746216 242.810623,413.091919 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M394.531128,467.981079 
	C394.378326,467.186523 394.537628,466.303284 395.207581,465.058105 
	C396.718323,464.712097 397.718384,464.728027 399.114594,464.782654 
	C399.772247,464.776123 400.033722,464.730896 400.628967,464.674072 
	C401.826111,464.618683 402.689545,464.574860 403.903564,464.728455 
	C404.483063,465.065674 404.712006,465.205444 404.940918,465.345245 
	C404.448334,465.952606 403.955719,466.559967 403.304108,467.370880 
	C400.377777,467.680420 397.610474,467.786407 394.531128,467.981079 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M429.936890,414.874084 
	C426.824097,414.746704 423.694153,414.227417 420.258881,413.480408 
	C422.734222,409.628723 426.705536,410.118713 429.936890,414.874084 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M313.407898,520.854858 
	C322.088593,520.566406 331.223877,520.412354 340.698059,520.496094 
	C331.978790,520.819031 322.920624,520.904175 313.407898,520.854858 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M253.408936,520.843018 
	C262.090118,520.561157 271.227936,520.405884 280.701233,520.493896 
	C271.979645,520.814575 262.922607,520.892029 253.408936,520.843018 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M517.030701,517.358826 
	C519.232117,517.326843 521.339722,517.678223 523.799255,518.033752 
	C522.441284,519.230225 520.731506,520.422485 519.021667,521.614807 
	C518.326782,520.323914 517.631836,519.033081 517.030701,517.358826 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M587.820557,652.716431 
	C586.939026,651.406311 586.394836,650.109619 585.897461,648.460876 
	C588.404663,648.604065 590.864929,649.099243 593.760010,649.886108 
	C594.153381,650.460083 594.158569,650.741455 594.210327,651.021912 
	C592.192810,651.591187 590.175293,652.160522 587.820557,652.716431 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M232.722626,344.226746 
	C230.145065,341.747131 227.567505,339.267517 224.956512,336.431610 
	C224.923080,336.075317 225.219254,335.778656 225.616272,335.747498 
	C226.517212,335.920319 227.021118,336.124298 227.564804,336.352234 
	C227.604584,336.376129 227.632843,336.464539 227.692001,336.789429 
	C229.513657,339.396729 231.276154,341.679138 232.961548,344.030090 
	C232.884460,344.098663 232.722626,344.226746 232.722626,344.226746 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M594.565369,651.014404 
	C594.158569,650.741455 594.153381,650.460083 594.188232,649.966553 
	C596.471375,649.457153 598.760925,649.158997 601.589050,648.993591 
	C602.127625,649.126343 602.325195,649.325073 602.196533,649.608154 
	C601.676941,650.839111 601.286072,651.786987 600.895264,652.734924 
	C598.903625,652.158936 596.912048,651.582947 594.565369,651.014404 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M345.056793,383.375031 
	C343.279968,383.571503 341.503113,383.767975 339.342590,384.079468 
	C337.258759,384.098877 335.558563,384.003204 333.422058,383.674927 
	C332.993835,383.258301 333.001953,383.074219 333.302124,382.604614 
	C337.415039,382.671051 341.235901,383.023041 345.056793,383.375031 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M348.939117,412.512909 
	C345.883698,412.852936 342.741211,412.818481 339.258789,412.562592 
	C339.289429,411.657715 339.758636,410.346405 340.015564,410.386749 
	C342.979645,410.852264 345.911591,411.522552 348.939117,412.512909 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M179.283356,498.888611 
	C180.689270,501.586853 182.095184,504.285095 183.159500,507.439667 
	C182.520416,507.948517 182.222916,508.001068 181.667831,507.844360 
	C180.612823,506.081451 179.815384,504.527740 179.178070,502.891479 
	C179.207779,502.577087 179.077362,502.345276 178.921448,502.093262 
	C178.895950,502.073059 178.900055,502.008179 178.826202,501.794525 
	C178.588303,501.312134 178.357224,501.127045 178.003082,500.697876 
	C177.894852,499.929932 177.842667,499.489746 178.164368,499.015381 
	C178.786606,498.950378 179.034988,498.919495 179.283356,498.888611 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M550.300598,648.795898 
	C550.769531,649.194824 550.850769,649.697327 550.742188,650.851562 
	C549.027710,652.006958 547.502930,652.510620 545.978149,653.014221 
	C545.302734,652.266541 544.627258,651.518921 543.986328,650.434814 
	C545.984863,649.698730 547.948914,649.299133 550.300598,648.795898 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M471.439148,517.943787 
	C468.646301,517.170166 465.853455,516.396484 463.028076,515.248352 
	C464.288055,514.520935 465.580566,514.167969 467.216736,513.744690 
	C468.013855,514.512024 468.467346,515.349731 469.439667,516.473938 
	C470.452026,517.154846 470.945587,517.549316 471.439148,517.943787 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M245.786591,356.195679 
	C243.161880,354.114532 240.537155,352.033386 237.924835,349.514069 
	C237.937225,349.075867 238.096527,348.905334 238.391876,349.036438 
	C239.819153,349.747894 240.951096,350.328308 242.217102,351.023773 
	C242.351166,351.138824 242.667007,351.297211 242.694763,351.624573 
	C243.869980,353.235321 245.017456,354.518677 246.072433,355.902466 
	C245.979950,356.002869 245.786591,356.195679 245.786591,356.195679 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M463.038086,112.362015 
	C464.387909,115.022766 465.718079,118.099815 467.051697,121.565018 
	C462.885345,120.137474 463.452759,116.237518 463.038086,112.362015 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M526.076294,208.989746 
	C525.993652,212.351990 525.911011,215.714233 525.643188,219.543701 
	C525.285278,220.031464 525.112671,220.051971 524.696899,219.898895 
	C524.289368,219.165848 524.124878,218.606384 523.958740,217.617233 
	C523.951904,215.473541 523.946716,213.759552 523.960938,211.708801 
	C524.259094,210.547974 524.537781,209.723923 524.967407,208.914734 
	C525.118347,208.929596 525.420227,208.957596 525.581970,209.012405 
	C525.743713,209.067215 526.076294,208.989746 526.076294,208.989746 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M306.981506,518.037476 
	C304.956360,517.454041 302.931213,516.870667 300.567139,516.199829 
	C300.318542,515.667236 300.408875,515.222046 301.122986,514.650513 
	C303.844421,514.401978 305.942078,514.279724 308.098877,514.512207 
	C307.765869,515.923767 307.373688,516.980591 306.981506,518.037476 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M473.863831,555.119385 
	C474.407471,555.064026 474.951141,555.008606 475.893768,554.853943 
	C476.763794,555.066956 477.234863,555.379211 477.901184,556.204834 
	C478.420410,556.798645 478.744385,556.879028 479.068329,556.959412 
	C479.703430,557.429199 480.338562,557.898987 480.766235,558.799866 
	C480.350311,559.509644 480.141815,559.788269 479.579285,560.040894 
	C477.438110,558.383057 475.650970,556.751221 473.863831,555.119385 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M550.207397,552.020325 
	C549.853760,552.597351 549.612671,552.789062 549.145020,552.606201 
	C547.968994,550.702820 546.985352,549.127136 545.971680,547.496216 
	C545.941589,547.441040 545.749634,547.223877 545.749634,547.223877 
	C548.513428,547.101501 548.513428,547.101501 550.207397,552.020325 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M184.540756,552.911987 
	C183.211517,551.088257 182.742783,547.577942 178.535614,550.153625 
	C179.467636,549.185730 180.761597,548.253601 182.055557,547.321533 
	C182.921112,549.075500 183.786667,550.829468 184.540756,552.911987 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M299.812805,413.066833 
	C300.117859,414.153107 300.013977,415.206757 299.790833,416.639648 
	C298.247437,416.900940 296.823364,416.783020 295.399261,416.665070 
	C295.205872,416.126434 295.012451,415.587799 294.819061,415.049133 
	C296.347351,414.377502 297.875641,413.705841 299.812805,413.066833 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M259.804443,363.256836 
	C257.797089,362.474304 255.789749,361.691803 253.421280,360.873901 
	C253.060150,360.838501 252.841232,360.657104 252.604111,360.362915 
	C252.168304,359.497650 251.969635,358.926605 252.127640,358.267639 
	C254.924362,359.872101 257.364410,361.564453 259.804443,363.256836 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M494.113190,131.127716 
	C496.007355,129.573471 498.109558,128.303284 500.581482,126.992172 
	C498.741180,128.438095 496.531219,129.924942 494.113190,131.127716 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M372.270416,384.306030 
	C374.726624,383.678772 377.451538,383.344666 380.608704,383.102142 
	C378.207031,383.662201 375.373047,384.130676 372.270416,384.306030 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M517.612427,483.645020 
	C518.040588,485.325500 518.111694,486.827698 518.208496,489.078308 
	C518.213135,490.583282 518.192078,491.339813 517.869873,492.084991 
	C517.369141,492.050354 517.169617,492.027100 516.768433,492.009308 
	C516.566650,492.014801 516.163452,491.996552 516.163452,491.996552 
	C516.527405,489.153290 516.891418,486.309998 517.612427,483.645020 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M333.010071,382.890137 
	C333.001953,383.074219 332.993835,383.258301 332.997406,383.718445 
	C330.399384,383.993652 327.789673,383.992737 325.335052,383.519470 
	C326.017273,382.852356 326.544434,382.657593 327.336365,382.492371 
	C327.956757,382.524170 328.312347,382.526398 328.914124,382.756134 
	C329.796997,382.924744 330.433685,382.865845 331.391754,382.854492 
	C332.145416,382.898071 332.577759,382.894104 333.010071,382.890137 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M516.970154,492.003815 
	C517.169617,492.027100 517.369141,492.050354 518.127808,492.373718 
	C518.747009,495.742462 518.807129,498.811096 518.867188,501.879761 
	C518.194336,501.611816 517.521484,501.343872 516.575317,501.043152 
	C516.220520,500.836243 516.139038,500.662048 516.352600,499.942474 
	C516.755188,496.932678 516.862671,494.468231 516.970154,492.003815 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M251.770950,358.355530 
	C251.969635,358.926605 252.168304,359.497650 252.462021,360.358795 
	C250.373947,359.381439 248.190826,358.114014 245.897156,356.521118 
	C245.786591,356.195679 245.979950,356.002869 246.395615,355.974365 
	C247.458115,356.047333 248.104950,356.148743 248.855621,356.166992 
	C248.959457,356.083862 248.777588,356.278076 248.830490,356.604187 
	C249.821121,357.386902 250.758850,357.843536 251.712418,358.317719 
	C251.728256,358.335266 251.770950,358.355530 251.770950,358.355530 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M128.821625,608.480835 
	C128.549042,603.867065 128.392792,598.805115 128.467850,593.413696 
	C128.778763,598.067017 128.858353,603.049805 128.821625,608.480835 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M249.175598,512.969116 
	C248.251328,516.262878 246.010178,516.045898 243.190674,514.369934 
	C244.887253,513.766541 246.833481,513.368530 249.175598,512.969116 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M398.938690,650.521729 
	C399.074036,652.259033 398.999573,653.638367 399.008667,655.305420 
	C398.138824,654.594543 396.940521,653.710815 396.384918,652.525757 
	C396.223083,652.180664 397.896637,650.975037 398.938690,650.521729 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M290.872009,514.805908 
	C290.494934,515.166016 290.096344,515.125916 289.025360,514.989868 
	C286.512390,514.609985 284.671814,514.325989 282.831207,514.041992 
	C282.840363,513.857666 282.849518,513.673340 282.983215,513.241699 
	C285.688629,513.464905 288.269562,513.935303 290.872009,514.805908 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M524.940063,220.072479 
	C525.112671,220.051971 525.285278,220.031464 525.718262,219.996246 
	C526.258057,220.165833 526.537537,220.350143 526.939209,220.760284 
	C526.253540,222.962738 525.445618,224.939346 524.264526,226.547668 
	C524.148743,224.731842 524.406128,223.284302 524.760132,221.551453 
	C524.884521,220.868240 524.912292,220.470352 524.940063,220.072479 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M433.046051,419.001373 
	C433.556763,418.746918 434.067474,418.492432 434.822083,418.089355 
	C435.973022,418.450684 436.880005,418.960541 437.799103,420.022644 
	C437.552094,421.021942 437.292999,421.468994 436.687714,421.899475 
	C435.243042,420.922394 434.144531,419.961884 433.046051,419.001373 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M460.145325,418.332642 
	C462.386566,418.232300 464.692505,418.500092 467.147400,419.108093 
	C464.934265,419.199127 462.572144,418.949951 460.145325,418.332642 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M367.921600,411.643921 
	C365.952911,412.081177 363.907562,412.140900 361.431793,412.110229 
	C362.920227,408.477753 365.360626,409.672882 367.921600,411.643921 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M545.164246,547.058350 
	C545.749634,547.223877 545.941589,547.441040 545.547119,547.605591 
	C542.751282,547.382141 540.349915,546.994141 537.948486,546.606140 
	C537.948486,546.606140 537.944397,546.672668 537.923889,546.698120 
	C540.128540,546.779968 542.353699,546.836426 545.164246,547.058350 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M474.992065,262.672180 
	C472.449677,263.481567 469.926422,264.001923 467.195312,264.261566 
	C469.430969,263.036102 471.874512,262.071350 474.686096,261.166748 
	C475.039825,261.612305 475.025513,261.997742 474.992065,262.672180 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M283.486389,633.255005 
	C283.133789,631.971069 283.048676,630.384644 282.938416,628.047607 
	C282.915161,626.924561 282.917084,626.552185 282.971191,625.854736 
	C283.374298,625.009216 283.725281,624.488770 284.076263,623.968323 
	C283.968811,626.963074 283.861359,629.957886 283.486389,633.255005 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M303.621033,560.983398 
	C304.146637,560.925598 304.357452,560.989685 304.787140,561.410767 
	C303.681885,563.189148 302.357788,564.610535 301.033691,566.031982 
	C300.909058,565.903137 300.784424,565.774353 300.486328,565.439331 
	C300.609497,564.646667 300.906128,564.060242 301.582703,563.272583 
	C301.962646,563.071289 302.123657,562.695435 302.123657,562.695435 
	C302.517883,562.165405 302.912079,561.635376 303.621033,560.983398 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M537.391785,505.647583 
	C539.214600,506.987213 541.037354,508.326843 542.671082,510.161865 
	C542.279785,510.813690 542.077515,510.970123 541.650635,511.097839 
	C541.426086,511.069153 540.980408,510.975983 540.830017,510.751282 
	C540.184570,510.357422 539.689453,510.188263 539.002319,509.751343 
	C538.098877,508.410004 537.387512,507.336456 536.855713,506.109924 
	C537.035339,505.956909 537.391785,505.647583 537.391785,505.647583 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M308.039703,514.157532 
	C305.942078,514.279724 303.844421,514.401978 301.362701,514.377930 
	C303.021576,513.820374 305.064545,513.409058 307.505066,513.014038 
	C307.902679,513.030334 308.226471,512.823853 308.205261,513.137207 
	C308.135925,513.686279 308.087830,513.921875 308.039703,514.157532 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M282.967712,640.641479 
	C282.750275,639.088074 282.679779,637.230530 282.907349,635.149902 
	C283.175171,636.730347 283.144928,638.533813 282.967712,640.641479 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M247.981705,419.603882 
	C248.403519,419.192871 248.821609,419.164490 249.547791,419.385834 
	C249.855911,419.635559 250.060120,419.993683 250.060120,419.993683 
	C249.982330,421.393982 249.904556,422.794250 249.744431,424.608032 
	C249.100723,423.343170 248.539352,421.664856 247.981705,419.603882 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M327.071594,382.462830 
	C326.544434,382.657593 326.017273,382.852356 325.221252,383.164246 
	C323.908813,383.147491 322.865265,383.013611 321.426392,382.902130 
	C321.025604,382.736176 321.020203,382.547821 321.301697,381.818115 
	C323.416229,381.672089 325.243927,382.067474 327.071594,382.462830 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M391.926117,411.665588 
	C390.280731,412.090698 388.564209,412.145508 386.425232,412.117249 
	C387.591644,408.921692 389.620422,409.293701 391.926117,411.665588 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M525.046814,202.936768 
	C525.473755,202.962128 525.900696,202.987488 526.647217,203.031189 
	C527.604065,204.138580 528.241272,205.227615 528.609680,206.690704 
	C527.921387,207.713776 527.501892,208.362839 527.082397,209.011887 
	C526.914429,209.020264 526.746460,209.028625 526.327393,209.013367 
	C526.076294,208.989746 525.743713,209.067215 525.742676,208.681961 
	C525.461243,206.869385 525.180847,205.442047 524.938171,203.745377 
	C524.999512,203.296265 525.023193,203.116501 525.046814,202.936768 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M230.386749,414.767395 
	C228.973740,414.955231 227.768951,414.942078 226.233063,414.910950 
	C227.157043,410.339996 228.825684,411.730194 230.386749,414.767395 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M237.861755,349.164856 
	C236.156921,347.807098 234.527557,346.360352 232.810394,344.570160 
	C232.722626,344.226746 232.884460,344.098663 233.265381,344.148895 
	C235.129715,345.767853 236.613129,347.336578 238.096527,348.905334 
	C238.096527,348.905334 237.937225,349.075867 237.861755,349.164856 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M332.038086,412.005402 
	C330.855865,412.513123 329.618896,412.651917 328.134521,412.582092 
	C328.980469,410.615662 329.996857,408.430359 332.038086,412.005402 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M507.473389,255.977875 
	C508.655884,255.061951 509.846497,254.478027 511.037109,253.894089 
	C511.235138,254.462753 511.433167,255.031403 511.631195,255.600067 
	C510.247955,255.836655 508.864746,256.073273 507.473389,255.977875 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M249.450119,498.376953 
	C249.732254,501.386719 249.790131,504.720215 249.760925,508.499939 
	C249.524521,505.530975 249.375198,502.115845 249.450119,498.376953 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M202.620728,547.690796 
	C203.261597,548.866882 203.671326,549.926758 204.175934,551.252869 
	C203.155151,550.769104 202.039474,550.019043 200.902771,548.870056 
	C201.384338,548.172241 201.886948,547.873413 202.620728,547.690796 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M380.702972,563.040283 
	C381.713287,564.421997 382.723572,565.803650 383.752747,567.477234 
	C383.638428,568.001953 383.505249,568.234863 382.833618,568.606201 
	C381.098297,566.815857 379.901398,564.887024 379.038025,562.968567 
	C379.815369,562.999390 380.259155,563.019836 380.702972,563.040283 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M501.350891,126.967148 
	C502.396515,125.950195 503.828339,124.949867 505.611755,123.940826 
	C504.554596,124.949326 503.145844,125.966553 501.350891,126.967148 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M298.709045,90.466362 
	C298.827148,89.300186 299.249298,87.975052 299.822327,86.321686 
	C300.443176,85.662079 300.913116,85.330696 301.683319,84.996185 
	C300.993439,86.764511 300.003265,88.535950 298.709045,90.466362 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M516.768433,492.009308 
	C516.862671,494.468231 516.755188,496.932678 516.444458,499.669067 
	C516.142334,497.580200 516.043396,495.219391 516.053955,492.427551 
	C516.163452,491.996552 516.566650,492.014801 516.768433,492.009308 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M508.813293,128.562653 
	C509.719666,127.835449 510.889954,127.338074 512.060242,126.840706 
	C512.206543,127.376251 512.352905,127.911789 512.499268,128.447327 
	C511.358551,128.562378 510.217896,128.677429 508.813293,128.562653 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M678.188782,624.808777 
	C678.028748,623.645935 678.028442,622.215332 678.228760,620.550903 
	C678.402466,621.725098 678.375488,623.133057 678.188782,624.808777 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M307.509155,420.256409 
	C307.905609,421.098938 308.064362,422.159271 308.088684,423.611267 
	C306.828186,423.903564 305.702087,423.804260 303.797577,423.636322 
	C305.301819,422.267090 306.286621,421.370667 307.509155,420.256409 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M240.374710,415.078308 
	C240.885269,415.883118 241.051788,416.779724 241.264587,418.016113 
	C240.736694,418.681152 240.162537,419.006409 239.588364,419.331696 
	C239.735794,417.944489 239.883240,416.557312 240.374710,415.078308 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M531.418701,417.658081 
	C531.831299,418.413818 531.913940,419.185211 531.934814,420.408264 
	C531.873108,420.859924 531.716614,420.905762 531.441833,420.938507 
	C530.796692,420.987915 530.426331,421.004578 529.781433,421.080444 
	C529.149170,421.236603 528.791443,421.333496 528.433716,421.430420 
	C528.433716,421.430389 528.216858,421.215210 528.137939,420.905273 
	C528.058960,420.595306 528.029419,420.187378 528.029419,420.187378 
	C529.049133,419.349487 530.068909,418.511627 531.418701,417.658081 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M255.932312,411.668823 
	C254.606369,412.084778 253.214981,412.137848 251.412659,412.107971 
	C252.197342,408.902710 253.947647,409.532959 255.932312,411.668823 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M286.673676,113.033241 
	C286.549957,114.235306 286.095398,115.468719 285.340942,116.869392 
	C285.474945,115.712624 285.908875,114.388611 286.673676,113.033241 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M377.043304,558.810791 
	C377.406921,559.156799 377.770569,559.502869 378.107849,560.377075 
	C378.081482,560.905212 377.752960,561.253967 377.424683,561.182129 
	C376.339172,560.188171 375.581940,559.266113 374.824738,558.344055 
	C374.824738,558.344055 375.033447,558.008301 375.103088,557.822754 
	C375.653809,557.844177 376.134857,558.051086 376.856995,558.549316 
	C377.098053,558.840698 377.043304,558.810791 377.043304,558.810791 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M301.126953,521.114624 
	C302.158844,520.777710 303.202911,520.802673 304.624054,520.937073 
	C304.005554,523.810181 302.704773,523.832642 301.126953,521.114624 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M289.632324,105.003990 
	C289.838837,105.557381 289.678345,106.109695 289.280945,106.833328 
	C288.177643,106.824509 287.311249,106.644363 286.444885,106.464218 
	C286.430725,106.200294 286.416534,105.936363 286.402374,105.672432 
	C287.356689,105.449257 288.311035,105.226082 289.632324,105.003990 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M528.018677,238.404480 
	C528.214600,239.220535 528.088806,239.983978 527.963013,240.747406 
	C527.526306,240.659698 527.089661,240.572006 526.652954,240.484314 
	C527.000916,239.773483 527.348938,239.062668 528.018677,238.404480 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M433.406372,655.929688 
	C435.128693,655.668823 437.281738,655.512634 439.724548,655.585449 
	C437.955231,655.887817 435.896149,655.961121 433.406372,655.929688 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M295.022461,97.040802 
	C295.003601,96.584427 294.984711,96.128052 294.953583,95.328842 
	C294.964447,94.550858 294.987549,94.115707 295.015564,93.354279 
	C295.502197,93.025398 295.983887,93.022797 296.826782,93.057922 
	C297.089539,93.838875 296.991058,94.582085 296.965302,95.690514 
	C297.037994,96.055733 297.063660,96.070435 296.781738,96.126266 
	C296.007355,96.468338 295.514923,96.754570 295.022461,97.040802 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M635.831604,556.727173 
	C634.127380,553.545288 636.526611,553.920898 639.006470,554.170593 
	C638.254761,555.025391 637.132996,555.720764 635.831604,556.727173 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M530.349365,517.889160 
	C531.002625,517.772278 531.915344,517.818542 532.914429,518.125610 
	C532.203491,518.275085 531.406189,518.163696 530.349365,517.889160 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M240.918243,516.787598 
	C241.011841,517.484375 240.986618,517.925415 240.899567,518.692261 
	C240.576782,518.052246 240.315842,517.086365 240.360718,516.052124 
	C240.710815,516.166443 240.755127,516.349182 240.918243,516.787598 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M434.910309,416.669922 
	C433.644958,416.612183 432.313416,416.164062 430.722046,415.460114 
	C431.922821,415.562714 433.383484,415.921143 434.910309,416.669922 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M227.988953,520.710754 
	C228.495651,520.397766 229.008987,520.403931 229.750519,520.575562 
	C229.614059,521.353333 229.249420,521.965637 228.884766,522.577942 
	C228.588364,522.061951 228.291992,521.545898 227.988953,520.710754 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M308.080566,460.789551 
	C307.215332,461.220001 306.374603,461.371704 305.256409,461.355621 
	C305.754456,460.786285 306.529938,460.384735 307.599274,459.987946 
	C307.963745,460.165405 308.034363,460.338074 308.080566,460.789551 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M307.730774,473.226013 
	C308.109833,474.399994 308.213928,475.800659 308.172882,477.602264 
	C307.837097,476.486359 307.646454,474.969543 307.730774,473.226013 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M380.643372,562.752258 
	C380.259155,563.019836 379.815369,562.999390 378.847961,562.810547 
	C378.133881,562.179443 377.943420,561.716675 377.752960,561.253967 
	C377.752960,561.253967 378.081482,560.905212 378.244934,560.729736 
	C379.133545,561.190918 379.858673,561.827637 380.643372,562.752258 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M284.193237,623.637756 
	C283.725281,624.488770 283.374298,625.009216 282.732056,625.630188 
	C282.600098,624.536194 282.759430,623.341736 282.918732,622.147217 
	C283.092590,622.131531 283.266418,622.115906 283.697815,622.052612 
	C284.073639,622.438965 284.191925,622.873108 284.193237,623.637756 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M437.033875,421.916077 
	C437.292999,421.468994 437.552094,421.021942 437.892578,420.328735 
	C438.335724,420.057373 438.629456,420.186707 438.919495,421.063660 
	C438.990845,422.136841 438.997833,422.616943 438.738617,422.985565 
	C437.992889,422.554749 437.513397,422.235413 437.033875,421.916077 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M413.373169,655.912720 
	C414.167969,655.662292 415.358154,655.518127 416.781891,655.590942 
	C415.933136,655.878296 414.850830,655.948608 413.373169,655.912720 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M182.959412,654.270996 
	C183.564987,654.294434 184.186920,654.572021 184.943237,655.177307 
	C184.376999,655.178345 183.676376,654.851685 182.959412,654.270996 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M281.599976,652.163696 
	C281.655090,651.262207 281.768433,650.509399 281.942261,649.379150 
	C282.047760,650.053894 282.092804,651.106140 281.989319,652.366455 
	C281.840759,652.574524 281.658173,652.312439 281.599976,652.163696 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M172.152130,652.333008 
	C172.482544,651.961243 173.084259,651.678284 173.819260,651.584595 
	C173.442841,651.989929 172.933136,652.205872 172.152130,652.333008 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M527.395386,208.960968 
	C527.501892,208.362839 527.921387,207.713776 528.658875,207.014008 
	C528.554077,207.612213 528.131287,208.261139 527.395386,208.960968 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M574.804993,497.179260 
	C575.129700,497.495483 575.392822,498.078217 575.465454,498.785309 
	C575.097839,498.421692 574.920654,497.933746 574.804993,497.179260 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M199.549271,548.962769 
	C199.751526,549.507202 199.710632,550.039734 199.454803,550.744629 
	C199.261948,550.261536 199.284027,549.606201 199.549271,548.962769 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M439.004852,423.097046 
	C438.997833,422.616943 438.990845,422.136841 439.005493,421.297272 
	C439.685089,421.364532 440.343048,421.791229 441.039734,422.524414 
	C441.078430,422.830902 441.068481,423.083252 440.684326,423.103607 
	C440.083282,423.128937 439.866425,423.133942 439.487122,423.147614 
	C439.324707,423.156281 439.004852,423.097046 439.004852,423.097046 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M181.925400,508.053589 
	C182.222916,508.001068 182.520416,507.948517 183.261902,507.804932 
	C184.049881,508.008026 184.393875,508.302155 184.775208,508.826874 
	C184.481659,509.168243 184.248688,509.384491 183.774750,509.789856 
	C182.932388,509.266876 182.428894,508.660248 181.925400,508.053589 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M342.219482,521.304749 
	C343.077423,520.979370 344.147217,520.905823 345.602936,520.955200 
	C344.803009,521.237671 343.617157,521.397156 342.219482,521.304749 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M575.086304,503.277618 
	C575.280823,503.705292 575.284302,504.329803 575.066895,505.027771 
	C574.862366,504.558929 574.878845,504.016724 575.086304,503.277618 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M571.512329,510.853638 
	C571.152893,511.334259 570.686646,511.818420 570.031982,512.048828 
	C570.123535,511.530518 570.403503,511.266052 570.978638,511.013428 
	C571.273743,511.025269 571.405579,510.857208 571.512329,510.853638 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M208.229553,622.388916 
	C208.518433,622.888550 208.660324,623.737671 208.590973,624.768494 
	C208.280670,624.212952 208.181610,623.475586 208.229553,622.388916 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M240.781586,557.595215 
	C240.599503,557.072205 240.544678,556.200134 240.735901,555.172241 
	C240.957581,555.759705 240.933197,556.502869 240.781586,557.595215 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M157.863708,642.282837 
	C157.993790,642.876831 157.983871,643.740845 157.696030,644.786621 
	C157.519974,644.163147 157.621857,643.357971 157.863708,642.282837 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M249.489105,431.244080 
	C249.738708,431.775604 249.795532,432.506104 249.766113,433.595093 
	C249.552032,433.116699 249.424179,432.279877 249.489105,431.244080 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M348.270477,520.542358 
	C348.290131,520.043762 348.721100,519.492737 349.429047,518.910156 
	C349.768951,519.053345 349.831909,519.228149 349.912354,519.681030 
	C349.929840,519.959045 349.532013,520.001099 349.373413,520.037842 
	C349.214813,520.074524 349.321167,519.961914 349.321167,519.961914 
	C349.108063,520.137939 348.894928,520.313965 348.270477,520.542358 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M297.760254,410.989075 
	C297.591217,411.101013 297.420654,411.046173 297.250092,410.991302 
	C297.419617,410.934967 297.589172,410.878632 297.760254,410.989075 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M243.091400,412.046265 
	C242.829391,411.741180 242.544205,411.384277 242.279449,411.012848 
	C242.274338,411.005646 242.509979,410.826874 242.633301,410.728149 
	C242.759872,411.017090 242.886459,411.306061 243.051422,411.845551 
	C243.089783,412.096100 243.070358,411.992889 243.091400,412.046265 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M527.798340,420.167603 
	C528.029419,420.187378 528.058960,420.595306 528.029480,420.797668 
	C527.732422,421.052765 527.463440,421.097748 526.989319,421.139008 
	C527.046021,420.811340 527.306641,420.479584 527.798340,420.167603 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M157.927948,615.329834 
	C158.197357,615.561890 158.370987,616.126160 158.328156,616.843384 
	C158.018524,616.551636 157.925339,616.106750 157.927948,615.329834 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M179.280609,498.632446 
	C179.034988,498.919495 178.786606,498.950378 177.981094,498.894592 
	C177.208450,498.583221 177.097336,498.315216 177.090576,498.003845 
	C177.500565,498.000366 177.910568,497.996918 178.627960,497.994385 
	C179.049545,498.122284 179.163712,498.249298 179.280609,498.632446 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M306.994965,469.681335 
	C307.392944,469.758148 307.813721,470.087494 308.114380,470.708374 
	C307.668793,470.644592 307.343262,470.289215 306.994965,469.681335 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M532.662964,416.979248 
	C532.704285,417.260925 532.470825,417.565582 531.981689,417.825897 
	C531.946716,417.521759 532.167419,417.261963 532.662964,416.979248 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M544.047913,510.547699 
	C543.707092,511.097412 543.229675,511.505371 542.353882,511.739410 
	C541.955383,511.565491 541.875244,511.126526 541.875244,511.126526 
	C542.077515,510.970123 542.279785,510.813690 542.822998,510.469971 
	C543.428589,510.228851 543.677673,510.269958 544.047913,510.547699 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M299.435242,519.348022 
	C299.745453,519.489380 299.687531,519.546936 299.728333,519.560669 
	C299.205261,519.657043 298.641357,519.739685 298.077454,519.822327 
	C298.055725,519.688049 298.033966,519.553711 298.012238,519.419373 
	C298.383179,519.348511 298.754120,519.277649 299.435242,519.348022 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M120.690689,552.996338 
	C120.551628,553.372437 120.107758,553.731934 119.362114,554.060120 
	C119.502182,553.679138 119.944031,553.329407 120.690689,552.996338 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M241.207611,519.257568 
	C241.708359,519.225586 242.203369,519.502380 242.736389,520.075806 
	C242.250214,520.103699 241.726028,519.835083 241.207611,519.257568 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M207.985382,555.377930 
	C208.300461,555.561096 208.571014,556.080688 208.643433,556.770752 
	C208.277161,556.532227 208.109009,556.123291 207.985382,555.377930 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M224.101349,517.967041 
	C224.565765,517.912781 225.142380,518.156189 225.796844,518.719299 
	C225.320984,518.780884 224.767273,518.522827 224.101349,517.967041 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M208.280975,645.253540 
	C208.530579,645.282532 208.648239,645.564758 208.598724,645.925049 
	C208.337372,645.837646 208.243195,645.672241 208.280975,645.253540 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M517.904541,434.194244 
	C518.175110,434.262054 518.201660,434.423157 518.033691,434.694458 
	C517.689758,434.682434 517.630188,434.510040 517.904541,434.194244 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M585.182495,645.848083 
	C584.848145,645.916016 584.729431,645.755188 584.789551,645.365662 
	C585.144348,645.251526 585.287598,645.412292 585.182495,645.848083 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M537.143188,519.354553 
	C537.248047,519.122620 537.547546,519.094238 537.873535,519.293884 
	C537.712646,519.533936 537.525269,519.546021 537.143188,519.354553 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M602.727051,648.018066 
	C602.989441,648.047424 603.065857,648.232117 602.946167,648.783447 
	C602.750183,649.150024 602.325195,649.325073 602.325195,649.325073 
	C602.325195,649.325073 602.127625,649.126343 602.029419,649.026367 
	C602.133972,648.675049 602.337219,648.424011 602.727051,648.018066 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M286.550201,422.465637 
	C286.584076,422.522034 286.516327,422.409271 286.550201,422.465637 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M407.229492,655.892395 
	C407.253479,655.705750 407.544525,655.606262 407.934265,655.659302 
	C407.854126,655.867737 407.675354,655.923645 407.229492,655.892395 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M464.252258,653.832886 
	C464.220856,653.598999 464.480133,653.428650 464.863464,653.379578 
	C464.839325,653.632751 464.691132,653.764587 464.252258,653.832886 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M461.332397,654.240723 
	C461.328674,654.008911 461.580353,653.837341 461.928101,653.837524 
	C461.878723,654.106567 461.733276,654.203735 461.332397,654.240723 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M451.851257,293.236938 
	C452.070068,293.190582 452.126312,293.591003 452.170898,293.788879 
	C452.215485,293.986755 451.859070,294.073975 451.676819,294.085785 
	C451.315491,293.787903 451.361481,293.516449 451.851257,293.236938 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M284.260803,418.925354 
	C284.284698,418.663940 284.580109,418.501770 284.953552,418.536438 
	C284.865112,418.830383 284.698669,418.927490 284.260803,418.925354 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M256.351532,552.948181 
	C256.115540,552.809937 256.074707,552.612915 256.157654,552.141479 
	C256.416351,552.079590 256.580200,552.280640 256.672272,552.510437 
	C256.710114,552.604797 256.593231,552.761169 256.351532,552.948181 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M349.316406,520.026978 
	C349.321167,519.961914 349.214813,520.074524 349.177124,520.076477 
	C349.139465,520.078430 349.311676,520.091980 349.316406,520.026978 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M412.898438,412.705750 
	C412.821320,412.898804 412.659515,412.943115 412.260223,412.904449 
	C412.286407,412.733337 412.550079,412.645172 412.898438,412.705750 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M364.103760,384.465942 
	C364.165741,384.257965 364.329712,384.184265 364.750793,384.191833 
	C364.740570,384.382080 364.473175,384.491150 364.103760,384.465942 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M409.891113,412.653564 
	C409.974121,412.791840 409.495209,412.928162 409.266266,412.830627 
	C409.232819,412.385895 409.489746,412.313293 409.891113,412.653564 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M536.187622,504.795898 
	C536.618652,504.804382 536.897583,504.992889 537.284119,505.414490 
	C537.391785,505.647583 537.035339,505.956909 536.686157,505.969788 
	C536.116760,505.786255 535.916016,505.571655 535.734680,505.338898 
	C535.734680,505.338898 536.035400,504.975891 536.187622,504.795898 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M573.996460,506.741577 
	C573.971130,506.823242 573.949341,506.668335 573.977295,506.641724 
	C574.005188,506.615082 574.021851,506.659912 573.996460,506.741577 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M285.231934,469.044373 
	C285.277252,468.785492 285.577728,468.671722 285.947937,468.748260 
	C285.840790,469.022217 285.663910,469.105835 285.231934,469.044373 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M283.897583,460.734375 
	C283.800781,460.955536 283.606995,460.964478 283.122345,460.983276 
	C282.808685,460.815277 282.785858,460.637390 282.904358,460.230591 
	C283.363617,460.078979 283.615265,460.252441 283.897583,460.734375 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M478.897278,556.804626 
	C478.744385,556.879028 478.420410,556.798645 478.001892,556.473267 
	C478.194763,556.340698 478.467743,556.481201 478.897278,556.804626 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M377.132874,558.839600 
	C377.043304,558.810791 377.098053,558.840698 377.097778,558.861877 
	C377.097534,558.883057 377.222412,558.868347 377.132874,558.839600 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M158.017944,640.244080 
	C158.282181,640.166748 158.461197,640.326355 158.587280,640.520325 
	C158.619431,640.569763 158.483475,640.728394 158.258514,640.920898 
	C158.032547,640.834534 157.972824,640.664124 158.017944,640.244080 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M479.933289,560.066895 
	C480.141815,559.788269 480.350311,559.509644 480.792175,559.105957 
	C481.025574,558.980896 481.342621,559.271484 481.350159,559.486389 
	C481.357666,559.701294 481.174469,559.851318 480.820526,559.941406 
	C480.290466,560.039673 480.114380,560.047852 479.941345,560.045349 
	C479.944427,560.034729 479.933289,560.066895 479.933289,560.066895 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M283.255951,470.934814 
	C283.030975,470.812042 282.970428,470.628021 283.117401,470.240723 
	C283.431030,470.227112 283.559387,470.409149 283.628876,470.611450 
	C283.648346,470.668243 283.494659,470.784546 283.255951,470.934814 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M129.631989,623.695923 
	C129.285324,623.761536 129.203354,623.561707 129.385513,623.214294 
	C129.475891,623.131958 129.722916,623.495728 129.631989,623.695923 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M301.972839,562.621582 
	C302.123657,562.695435 301.962646,563.071289 301.853668,563.075073 
	C301.744690,563.078918 301.585144,562.784302 301.585144,562.784302 
	C301.585144,562.784302 301.822021,562.547668 301.972839,562.621582 
z"/>
<path fill="#601D21" opacity="1.000000" stroke="none" 
	d="
M361.644226,512.966064 
	C361.629822,483.887299 361.615417,454.808563 362.117615,425.385925 
	C362.894562,425.069336 363.154877,425.096558 363.394684,425.581482 
	C363.352142,452.212372 363.330139,478.385620 363.215210,504.997223 
	C362.629608,507.945740 362.136902,510.455902 361.644226,512.966064 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M361.400635,513.287598 
	C362.136902,510.455902 362.629608,507.945740 363.428955,505.386597 
	C363.820465,508.322296 363.712097,511.330933 364.077484,514.280884 
	C364.242126,515.610168 365.351410,516.822449 366.032074,518.087769 
	C363.069824,518.515503 360.107605,518.943237 356.385681,519.480652 
	C358.384247,517.878540 359.636566,516.874634 360.906403,515.532471 
	C361.001648,514.665771 361.079346,514.137390 361.400635,513.287598 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M363.415222,425.123810 
	C363.154877,425.096558 362.894562,425.069336 362.244080,424.997864 
	C361.630554,423.188873 361.407166,421.424072 361.106323,419.288025 
	C360.082916,417.966583 359.136871,417.016449 358.105652,415.788574 
	C359.700409,415.626892 361.380432,415.742981 363.470428,416.307159 
	C363.725372,419.544769 363.570312,422.334290 363.415222,425.123810 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M347.699646,514.820801 
	C347.599274,515.910950 347.178925,516.772705 346.355865,517.367798 
	C345.234100,516.915344 344.515015,516.729553 343.795929,516.543762 
	C343.128906,515.994812 342.461884,515.445862 341.823486,514.558594 
	C343.694611,514.344299 345.537170,514.468323 347.699646,514.820801 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M349.243988,123.998184 
	C349.153839,124.747932 349.063721,125.497673 348.956940,126.954697 
	C348.972137,128.107285 349.003998,128.552597 349.035858,128.997894 
	C348.988373,130.067337 348.940887,131.136765 348.885071,133.032959 
	C349.703857,134.948547 350.372406,136.770126 351.387543,136.989746 
	C354.518921,137.667236 357.782593,137.733185 360.994873,138.036682 
	C362.355621,138.112701 363.716400,138.188736 365.696289,138.458755 
	C366.877228,138.734970 367.439026,138.817215 368.000793,138.899445 
	C368.731140,138.995895 369.461456,139.092346 370.844666,139.416962 
	C373.997986,140.083038 376.498383,140.520966 378.998810,140.958893 
	C379.386688,141.028824 379.774567,141.098755 380.761658,141.397430 
	C387.905975,143.096298 394.451050,144.566437 400.996155,146.036575 
	C403.409973,146.679718 405.823792,147.322861 408.973297,148.057770 
	C411.140900,148.381317 412.572784,148.613098 414.004700,148.844864 
	C416.193481,149.152924 418.382263,149.460968 420.437439,150.332245 
	C417.843536,150.745071 415.333344,150.856293 412.931824,150.398071 
	C403.587494,148.615173 394.277649,146.651550 384.645294,144.722153 
	C383.714966,144.635086 383.094299,144.581024 382.284485,144.244598 
	C381.029877,143.755188 379.964478,143.548172 378.580505,143.264465 
	C377.825897,143.164062 377.389832,143.140350 376.776306,142.855164 
	C376.000122,142.615906 375.401398,142.638138 374.473419,142.520294 
	C373.439087,142.280304 372.733978,142.180374 371.757965,141.805267 
	C368.615051,141.156448 365.743073,140.782822 362.505707,140.322525 
	C361.400665,140.296646 360.661041,140.357422 359.577820,140.281723 
	C358.498932,140.084412 357.763580,140.023590 356.722595,139.710236 
	C353.649902,139.316605 350.882843,139.175491 347.879791,138.938156 
	C347.473907,138.837448 347.304047,138.832932 347.119568,138.616226 
	C347.104919,138.404022 346.928192,138.017059 346.956238,137.642654 
	C346.797913,135.840149 346.611481,134.412033 346.441467,132.592300 
	C346.502533,130.114059 346.547241,128.027466 346.732330,125.593521 
	C346.942810,124.500977 347.012909,123.755775 347.370148,122.983856 
	C347.849182,122.946220 348.041077,122.935310 348.458435,123.038826 
	C348.870575,123.434891 349.057281,123.716537 349.243988,123.998184 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M400.951660,145.656555 
	C394.451050,144.566437 387.905975,143.096298 381.057312,141.377075 
	C383.585480,139.881241 386.543762,138.853363 389.206360,137.314026 
	C391.113708,136.211304 392.625183,134.423782 395.103455,132.248108 
	C396.406952,134.380844 398.029938,137.036285 399.974670,140.218124 
	C398.011688,140.706879 396.282654,141.137390 394.285431,141.634689 
	C396.759888,142.995605 398.833527,144.136078 400.951660,145.656555 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M384.954956,144.755157 
	C394.277649,146.651550 403.587494,148.615173 412.931824,150.398071 
	C415.333344,150.856293 417.843536,150.745071 420.628326,150.683868 
	C422.032867,150.437988 423.112915,150.403687 424.575867,150.234207 
	C425.389465,150.265167 425.820129,150.431335 426.801331,150.756912 
	C427.570648,151.015488 427.789398,151.114670 427.923553,151.569519 
	C425.916840,152.309204 423.994690,152.693192 421.597717,153.047806 
	C414.780457,152.512833 408.369080,152.421280 402.115845,151.380157 
	C396.360901,150.421967 390.776062,148.442474 385.093811,146.553192 
	C385.032562,145.714050 384.993744,145.234604 384.954956,144.755157 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M360.941193,137.676224 
	C357.782593,137.733185 354.518921,137.667236 351.387543,136.989746 
	C350.372406,136.770126 349.703857,134.948547 348.925171,133.432999 
	C350.004364,132.235794 350.996857,131.405655 352.077148,130.712387 
	C353.061371,130.080765 354.223419,129.054535 355.156219,129.196136 
	C356.165436,129.349335 357.235321,130.504135 357.859863,131.487961 
	C359.028717,133.329239 359.895203,135.362442 360.941193,137.676224 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M355.783752,97.539429 
	C354.518036,100.043022 353.291718,102.163345 352.059387,104.642784 
	C351.961548,105.726608 351.869751,106.451317 351.573456,107.747314 
	C351.281769,108.879807 351.194519,109.441010 351.107300,110.002220 
	C351.006195,110.730568 350.905060,111.458923 350.577637,112.842064 
	C350.236389,114.996719 350.121521,116.496590 350.006622,117.996452 
	C349.931030,118.715767 349.855438,119.435089 349.546753,120.578674 
	C349.013641,121.170296 348.693054,121.233749 348.221619,120.749039 
	C349.413452,112.582443 350.735565,104.860130 352.323639,96.918289 
	C353.087860,95.454346 353.586060,94.209923 354.359192,93.020164 
	C355.035431,93.408005 355.436768,93.741196 355.838135,94.074379 
	C355.833130,95.101639 355.828125,96.128906 355.783752,97.539429 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M378.962646,140.611511 
	C376.498383,140.520966 373.997986,140.083038 371.213715,139.405548 
	C373.839355,137.755463 376.885895,135.347244 378.962646,140.611511 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M413.958618,148.502960 
	C412.572784,148.613098 411.140900,148.381317 409.354095,148.054443 
	C409.456146,147.023651 409.913086,146.087936 410.369995,145.152206 
	C411.550842,146.155167 412.731689,147.158127 413.958618,148.502960 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M350.365448,117.932640 
	C350.121521,116.496590 350.236389,114.996719 350.587585,113.210648 
	C350.790649,114.572571 350.757446,116.220695 350.365448,117.932640 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M348.351868,121.193314 
	C348.693054,121.233749 349.013641,121.170296 349.551819,120.940811 
	C349.813843,121.876358 349.837677,122.874016 349.552734,123.934937 
	C349.057281,123.716537 348.870575,123.434891 348.450500,122.749252 
	C348.262054,121.961281 348.306976,121.577301 348.351868,121.193314 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M349.329468,128.960724 
	C349.003998,128.552597 348.972137,128.107285 349.001465,127.331589 
	C349.565094,127.322891 350.067596,127.644585 350.570068,127.966278 
	C350.254395,128.285370 349.938751,128.604446 349.329468,128.960724 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M367.961182,138.603241 
	C367.439026,138.817215 366.877228,138.734970 366.105164,138.453796 
	C366.570435,138.272263 367.246033,138.289642 367.961182,138.603241 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M351.401184,109.962563 
	C351.194519,109.441010 351.281769,108.879807 351.572601,108.113373 
	C351.749176,108.579735 351.722107,109.251320 351.401184,109.962563 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M428.243256,245.526978 
	C429.042725,245.695129 429.423248,245.895920 429.986572,246.452621 
	C430.138824,247.169128 430.108215,247.529739 429.860260,247.963440 
	C429.642914,248.036530 429.185303,248.005661 428.961426,247.775681 
	C428.132812,247.425629 427.528076,247.305557 426.599945,247.170670 
	C425.846497,247.119232 425.416504,247.082596 424.682251,247.018097 
	C423.972626,246.973618 423.567230,246.956985 422.763367,246.935425 
	C421.571289,246.998505 420.777588,247.066498 419.670532,247.089294 
	C418.937073,247.010483 418.517029,246.976883 417.850220,246.664856 
	C412.127502,244.880173 406.651550,243.373932 401.144409,241.876038 
	C401.113251,241.884369 401.063202,241.925018 400.974274,241.678619 
	C400.584991,241.265686 400.284637,241.099121 399.794556,240.896057 
	C399.604858,240.859558 399.218658,240.871063 398.981140,240.610626 
	C395.507629,238.845093 392.271698,237.339981 388.859314,235.620453 
	C388.165985,235.225067 387.649017,235.044113 386.965576,234.571396 
	C385.423828,233.101410 384.102051,231.853210 382.665070,230.755875 
	C369.390289,220.618469 358.954865,208.300797 353.368469,192.332886 
	C351.890839,188.109299 348.814941,185.663803 344.804504,184.970245 
	C338.489899,183.878220 332.042938,183.571289 325.674805,182.754318 
	C324.128479,182.555923 322.671051,181.664551 321.612305,180.960663 
	C329.041656,181.214630 336.031433,181.599792 343.257446,182.251831 
	C346.285156,183.378433 349.076599,184.238159 352.015991,185.315552 
	C352.879028,186.177658 353.594177,186.822098 354.309296,187.466537 
	C358.314880,201.770920 366.435944,213.414658 377.331757,223.310165 
	C391.696686,236.356262 408.683746,243.384964 428.243256,245.526978 
z"/>
<path fill="#E8C59B" opacity="1.000000" stroke="none" 
	d="
M354.725677,187.588867 
	C353.594177,186.822098 352.879028,186.177658 352.060608,184.851059 
	C351.947906,183.858154 351.938507,183.547424 352.300537,183.347534 
	C354.136658,183.944885 355.601379,184.431381 357.167450,185.249008 
	C361.128296,187.755005 364.987793,189.929871 368.867065,192.120728 
	C368.886871,192.136688 368.936249,192.148987 369.123657,192.419556 
	C373.473022,195.845932 377.634949,199.001724 381.840942,202.139557 
	C381.884979,202.121597 381.843719,202.207291 381.907471,202.538132 
	C383.086823,204.809464 384.202484,206.749939 385.318115,208.690430 
	C385.318115,208.690430 385.108490,208.895035 385.003357,208.997879 
	C379.939453,205.163864 375.113647,201.042572 369.983124,197.343918 
	C365.204712,193.899139 360.101990,190.904175 354.725677,187.588867 
z"/>
<path fill="#0201F9" opacity="1.000000" stroke="none" 
	d="
M346.928192,138.017059 
	C346.928192,138.017059 347.104919,138.404022 347.039185,138.662872 
	C346.973419,138.921707 346.834686,139.044037 346.596252,139.037476 
	C346.119415,139.198807 345.880981,139.366699 345.642578,139.534592 
	C346.055237,139.711014 346.467896,139.887436 346.905884,140.303696 
	C346.931183,140.543533 346.988464,141.022446 347.028076,141.316254 
	C347.381958,141.932526 347.696259,142.254959 348.010559,142.577393 
	C348.367920,142.097717 348.725250,141.618042 349.325775,141.113312 
	C349.568939,141.088257 350.049103,140.996185 350.471802,140.991806 
	C352.579865,141.056229 354.265259,141.125031 355.954010,141.572998 
	C356.525909,147.327621 351.163330,145.914841 348.615601,148.097107 
	C347.872437,148.084290 347.447968,148.062836 346.945374,147.659058 
	C346.390381,146.887085 345.894165,146.138580 345.439667,146.163147 
	C340.728241,146.417618 336.018097,146.742859 331.324799,147.218292 
	C330.814789,147.269974 330.412842,148.388962 329.648712,149.045227 
	C328.918610,149.082733 328.500519,149.087891 327.861115,148.810638 
	C326.413422,149.024048 325.187042,149.519867 323.651245,150.055725 
	C322.926239,150.110229 322.510620,150.124680 321.864471,149.844604 
	C313.739624,149.160797 307.684723,153.849350 301.404480,157.432465 
	C299.155762,158.715424 294.510315,158.371307 294.832275,163.139130 
	C294.654968,163.180389 294.291351,163.162964 293.918488,163.132126 
	C292.401520,164.436142 291.257416,165.770996 290.059448,167.053070 
	C290.005615,167.000275 290.105164,167.112900 289.731964,167.105087 
	C286.759979,167.820877 284.514343,168.837585 284.979492,172.478012 
	C284.261810,173.507187 283.676544,174.272461 282.694336,175.022247 
	C276.609833,176.090149 276.926392,180.930069 276.019714,185.012436 
	C276.003632,185.010132 276.024719,185.035629 275.682343,185.067245 
	C274.608978,187.087296 273.877991,189.075729 273.096191,191.350128 
	C272.764252,192.124435 272.483154,192.612762 271.815308,193.098999 
	C270.615234,194.727676 269.801941,196.358475 268.981201,198.231888 
	C268.973785,198.474503 268.951477,198.959457 268.596924,199.036102 
	C267.805878,201.746353 267.369415,204.379944 266.900940,207.339355 
	C266.255981,208.774597 265.643005,209.884048 264.797211,211.161041 
	C265.026031,211.899521 265.487732,212.470444 265.955933,213.354462 
	C265.911346,214.081558 265.860168,214.495544 265.500000,214.930908 
	C264.782013,215.636322 264.373047,216.320374 263.966705,217.376923 
	C263.942841,218.493301 263.916321,219.237198 263.537476,220.060303 
	C262.777679,222.760696 262.370209,225.381866 261.966370,228.371735 
	C261.957855,229.476334 261.945740,230.212234 261.566895,231.040710 
	C260.232574,234.401611 256.870300,237.589859 260.895996,241.341797 
	C260.915161,242.083145 260.907654,242.507050 260.514099,243.049255 
	C260.067902,251.450546 260.007843,259.733551 259.958771,268.430267 
	C259.967163,270.225494 259.964508,271.606995 259.602905,273.061035 
	C258.020447,276.294586 258.896027,281.995667 260.935516,284.425415 
	C260.959412,286.223206 260.962311,287.606567 260.685608,289.270874 
	C259.396484,293.781952 259.780182,297.547485 263.929932,300.385376 
	C263.948029,301.138245 263.947235,301.569366 263.703125,302.202087 
	C263.924194,303.278137 264.388580,304.152527 264.884827,305.423645 
	C264.931152,306.881653 264.945709,307.942871 264.724487,309.200623 
	C265.298035,310.618225 266.107330,311.839294 266.929077,313.384369 
	C266.947113,314.140564 266.952728,314.572754 266.712830,315.203735 
	C266.951141,316.272339 267.434998,317.142181 267.938843,318.341064 
	C267.963013,319.109802 267.967224,319.549408 267.809235,320.083313 
	C267.765747,320.447693 267.884430,320.717834 268.001770,320.993103 
	C268.000458,320.998199 268.012756,320.997040 268.008545,321.401489 
	C268.325348,327.347412 271.881805,332.918640 275.908905,334.398987 
	C276.301788,335.489716 276.630829,336.269989 276.972504,337.413208 
	C278.664001,339.513855 280.342804,341.251495 282.011230,342.995361 
	C282.000824,343.001556 282.011383,342.980865 282.002960,343.367676 
	C284.438629,347.787354 286.619537,352.023285 289.481934,355.733490 
	C290.379120,356.896423 293.110779,356.644073 295.370087,357.039703 
	C296.228485,357.010559 296.719543,356.981262 297.387146,357.059937 
	C297.765533,357.363739 297.914825,357.593689 298.090271,358.115723 
	C298.453522,358.577148 298.738037,358.780579 299.011292,358.986816 
	C299.000000,358.989655 299.022827,358.971710 299.046204,359.334412 
	C301.009583,360.839172 302.949585,361.981232 305.087830,363.406006 
	C315.071320,369.270691 325.383698,373.421478 336.788208,373.576996 
	C346.721802,373.712524 356.663147,373.143982 366.602722,373.003204 
	C379.552490,372.819824 389.899048,364.220551 391.963013,351.554779 
	C392.642212,347.386841 393.139679,343.137512 393.155457,338.923279 
	C393.196930,327.844391 393.110657,316.756927 392.659973,305.689789 
	C392.540405,302.753906 390.920563,299.879120 389.956543,296.631592 
	C389.887390,295.594604 389.851227,294.902313 389.880188,293.822144 
	C389.436127,290.586517 388.602631,288.031403 384.963226,287.772644 
	C384.915710,287.580322 384.829468,287.193604 384.856476,286.834839 
	C383.651581,284.623932 382.419647,282.771759 380.638153,280.093262 
	C383.366089,279.655426 385.390015,279.020721 387.401123,279.059113 
	C398.759186,279.275970 410.112915,279.825897 421.470734,279.911133 
	C427.915619,279.959503 434.362610,279.213684 440.814209,279.061096 
	C444.901459,278.964386 449.002594,279.240143 453.089630,279.482086 
	C454.259155,279.551361 455.401093,280.085632 456.555695,280.406677 
	C455.938049,281.468536 455.583954,282.969788 454.661346,283.523224 
	C446.852203,288.207458 444.895752,295.469299 444.961487,303.932922 
	C445.093231,320.898987 445.033936,337.866913 444.981659,354.833832 
	C444.960266,361.786621 443.506805,363.126709 436.865631,365.213501 
	C434.909515,365.828156 433.569153,368.402435 431.946594,370.078613 
	C429.561646,370.638245 427.176697,371.197906 424.192688,371.597656 
	C422.628052,371.372833 421.575134,371.021484 420.709778,371.285309 
	C403.845825,376.427063 386.487640,378.992767 368.990265,379.805450 
	C345.930206,380.876556 322.938080,379.854218 300.246338,374.693268 
	C285.533417,371.346924 271.282043,366.598969 258.707489,358.463379 
	C249.804291,352.703094 242.023193,345.052521 234.306961,337.655579 
	C225.898026,329.594543 219.520828,319.724091 215.113037,309.074615 
	C210.928543,298.964630 207.852036,288.257812 205.593155,277.538483 
	C204.126205,270.577179 204.663239,263.110901 204.925125,255.893448 
	C205.145905,249.808807 206.346542,243.765137 206.966751,237.688370 
	C208.026031,227.309601 212.449463,218.189117 217.207336,209.132492 
	C219.897583,204.011597 224.631348,199.869522 224.857864,193.539276 
	C224.857864,193.539276 224.800507,193.323166 225.223358,193.182953 
	C227.766373,191.344864 230.089371,189.843246 231.963165,187.906937 
	C235.524582,184.226685 238.373245,179.714859 242.346588,176.608292 
	C248.362045,171.905136 255.634888,168.739456 261.363312,163.758713 
	C267.826538,158.139099 275.471222,155.457184 282.907288,151.968552 
	C285.271027,150.859619 288.487976,147.803009 288.371490,145.809555 
	C287.789154,135.840103 290.636627,126.639053 293.039978,117.237091 
	C297.583771,99.461334 307.191345,84.800179 320.293091,71.942825 
	C322.085724,70.942337 323.531647,69.957031 324.985504,68.985046 
	C324.993408,68.998367 324.970337,68.969612 325.334778,68.942238 
	C326.798553,66.941887 327.897858,64.968903 329.237305,62.983444 
	C329.477386,62.970970 329.958191,62.964085 330.353119,62.976234 
	C332.297333,62.045391 333.962189,61.244904 335.358124,60.112801 
	C336.411804,59.258259 336.905182,57.616920 338.021088,56.951271 
	C343.341278,53.777603 348.851868,50.920559 354.135681,47.690662 
	C355.132111,47.081543 355.406219,45.290779 356.378784,44.025536 
	C357.475220,43.988686 358.206543,43.971565 359.267517,43.931305 
	C360.060944,43.266094 360.524750,42.624020 361.205627,41.718937 
	C362.259399,40.546345 363.096130,39.636761 363.932861,38.727165 
	C364.381470,38.469788 364.830078,38.212414 365.776215,38.210304 
	C367.181427,38.639812 368.089081,38.814064 369.215027,39.255936 
	C371.292664,38.694260 373.152008,37.864956 375.403809,37.021576 
	C376.843292,36.989273 377.890320,36.971039 379.315796,36.930992 
	C381.466827,35.952187 383.239441,34.995197 385.385193,34.023949 
	C386.505127,34.004070 387.251953,33.998447 388.195007,34.248581 
	C389.266510,34.020973 390.141754,33.537613 391.419922,32.989788 
	C394.882172,32.605125 397.941528,32.284931 400.877014,32.277222 
	C400.951263,33.353642 400.916351,34.338020 401.391296,34.840019 
	C402.798615,36.327545 404.407013,37.624828 405.840515,39.241405 
	C405.466888,39.931782 405.189545,40.377243 404.592407,40.859604 
	C403.844849,40.879200 403.417023,40.861904 402.903290,40.482437 
	C397.306519,37.745594 391.246796,39.997158 389.729797,45.077877 
	C389.300659,45.815319 389.110596,46.369175 388.703979,46.965652 
	C388.487457,47.008282 388.046753,47.032440 387.823608,46.754829 
	C384.405914,46.506496 381.025787,46.261883 380.997314,50.978317 
	C381.003937,51.001663 380.956696,50.990692 380.589966,50.991127 
	C378.145874,52.657536 376.068512,54.323509 373.870789,56.256680 
	C373.155701,57.009998 372.561035,57.496120 371.580933,57.980003 
	C365.067902,59.075825 362.817627,61.612297 364.012207,66.337082 
	C363.674744,67.095123 363.364380,67.560303 362.691833,68.046776 
	C358.729218,69.526718 357.893616,72.493462 358.012207,75.994308 
	C358.011017,76.007271 357.986176,76.014969 357.633514,76.040352 
	C352.928253,78.990402 347.250610,91.996696 348.215088,96.952255 
	C348.296387,97.370026 348.800995,97.705414 349.112549,98.394714 
	C349.086151,99.129990 349.056122,99.549629 348.685303,100.024734 
	C347.945343,101.755470 347.546173,103.430740 347.121002,105.463394 
	C347.032227,106.534531 346.969452,107.248276 346.571960,108.035202 
	C346.196045,109.422211 346.154877,110.736038 346.097107,112.443634 
	C346.035736,113.887314 345.990967,114.937233 345.588959,116.058273 
	C344.515503,117.547340 343.385437,118.902206 343.157959,120.394638 
	C342.360870,125.624290 341.786530,130.894882 341.399139,136.169861 
	C341.357666,136.734161 343.095337,137.429092 344.270264,138.042847 
	C344.707245,138.002106 344.881683,137.981934 345.367554,137.994965 
	C346.095398,138.024475 346.511780,138.020767 346.928192,138.017059 
z"/>
<path fill="#0201F9" opacity="1.000000" stroke="none" 
	d="
M478.229645,35.946621 
	C478.229645,35.946621 478.555298,35.971321 478.832153,36.365650 
	C482.400513,37.521622 485.692017,38.283264 489.053619,39.407116 
	C492.097229,42.709560 495.386566,43.181400 499.409851,41.025429 
	C500.872345,41.010231 501.936035,41.004230 503.197021,41.253227 
	C504.267151,41.018188 505.139954,40.528152 506.384247,40.018974 
	C508.813324,39.314243 510.870911,38.628651 513.297058,37.997940 
	C515.086487,38.376778 516.507446,38.700737 517.943359,39.459232 
	C517.945740,41.922611 517.933105,43.951450 517.534180,46.084023 
	C516.963013,51.606968 516.764954,57.025906 516.651001,62.446621 
	C516.647217,62.626694 517.496033,62.824688 517.958618,63.481911 
	C517.961975,71.626381 517.954102,79.303268 517.570312,86.984947 
	C514.822571,85.651459 512.450806,84.313179 509.907593,82.739037 
	C509.204102,82.321022 508.672150,82.138870 508.118103,81.933350 
	C508.096008,81.909988 508.051117,81.863937 508.061646,81.596802 
	C508.053619,81.151642 508.035004,80.973633 508.176086,80.508942 
	C507.906158,79.141724 507.476562,78.061180 506.720703,76.969902 
	C505.960602,76.963982 505.526733,76.968796 505.069153,76.958969 
	C505.045441,76.944328 505.001038,76.910591 504.924316,76.693314 
	C504.669464,76.202065 504.419495,76.036690 503.885376,75.830322 
	C503.487305,75.391998 503.301544,75.103233 503.143250,74.621216 
	C503.170685,74.427956 503.282288,74.050026 503.569611,73.971512 
	C503.446899,71.425316 503.236542,68.892960 502.474152,66.539429 
	C502.302338,66.009033 500.205719,66.102180 498.677246,65.925377 
	C497.935608,65.949554 497.511841,65.956635 497.066833,65.956223 
	C497.045563,65.948730 497.014252,65.916283 496.931885,65.695312 
	C496.663300,65.197823 496.406464,65.028511 495.822449,64.772232 
	C494.742706,63.663624 493.919464,62.749191 492.987823,61.636692 
	C492.690094,61.186584 492.441406,61.027046 491.845886,60.748032 
	C490.285370,60.028320 489.012329,59.520584 487.594238,58.942184 
	C487.079254,58.754154 486.941742,58.536251 487.269287,58.192783 
	C487.757355,57.867123 488.012878,57.566494 488.268402,57.265869 
	C487.536987,57.177006 486.805542,57.088139 486.048523,57.047958 
	C486.022888,57.096645 485.915222,57.073853 485.907349,56.772572 
	C485.629364,55.933033 485.359253,55.394775 485.088013,54.540810 
	C485.063599,53.804367 485.040314,53.383636 484.940369,52.597603 
	C483.497833,51.861778 482.131927,51.491257 480.417603,51.119175 
	C479.017670,50.455013 477.966064,49.792408 476.956238,48.839516 
	C477.003387,48.353802 477.008789,48.158367 476.959900,47.628220 
	C475.588470,46.539909 474.271332,45.786308 472.612457,44.996639 
	C471.181335,44.638172 470.091888,44.315773 468.969177,43.648094 
	C469.625885,40.873230 470.315918,38.443649 471.337799,36.009392 
	C472.112213,36.009132 472.554749,36.013542 473.086761,36.182987 
	C473.451752,36.239574 473.727295,36.131134 474.422607,36.019569 
	C475.971436,35.993168 477.100555,35.969894 478.229645,35.946621 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M471.005920,36.014061 
	C470.315918,38.443649 469.625885,40.873230 468.671265,43.670837 
	C467.932709,44.349834 467.458710,44.660801 466.668762,44.968521 
	C465.637177,44.633259 464.921600,44.301243 464.350220,43.740997 
	C462.657227,42.992500 460.819977,42.472233 458.667542,41.944885 
	C457.936218,41.881714 457.520050,41.825626 456.997864,41.429150 
	C452.592896,40.353783 448.293945,39.618813 443.677277,38.895611 
	C442.937683,38.870678 442.515778,38.833981 441.981628,38.447807 
	C439.577881,38.035931 437.286346,37.973526 434.532898,37.937809 
	C430.052460,37.947887 426.033997,37.931274 421.735474,37.642075 
	C418.942352,37.858280 416.429260,38.347076 413.602356,38.874725 
	C412.867188,38.920853 412.445862,38.928120 411.790955,38.676186 
	C409.683838,38.610157 407.810303,38.803329 405.936798,38.996498 
	C404.407013,37.624828 402.798615,36.327545 401.391296,34.840019 
	C400.916351,34.338020 400.951263,33.353642 401.157898,31.996418 
	C404.043213,31.272715 406.523865,31.142300 408.966370,31.371769 
	C409.117950,33.031624 409.322418,35.462593 409.495087,35.460331 
	C411.747742,35.430847 414.182281,35.466450 416.154205,34.569378 
	C416.952332,34.206287 416.738495,31.618765 417.454285,30.027111 
	C425.043091,30.326784 432.160431,30.745817 439.283569,30.904268 
	C441.856201,30.961494 444.442780,30.393784 447.022980,30.111248 
	C447.436127,30.065283 447.849243,30.019314 448.685333,30.358482 
	C454.450897,31.486149 459.793488,32.228676 465.136108,32.971199 
	C465.136108,32.971203 465.545776,33.026787 465.922729,33.353111 
	C467.868408,34.457645 469.437164,35.235851 471.005920,36.014061 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M319.946442,71.957993 
	C307.191345,84.800179 297.583771,99.461334 293.039978,117.237091 
	C290.636627,126.639053 287.789154,135.840103 288.371490,145.809555 
	C288.487976,147.803009 285.271027,150.859619 282.907288,151.968552 
	C275.471222,155.457184 267.826538,158.139099 261.363312,163.758713 
	C255.634888,168.739456 248.362045,171.905136 242.346588,176.608292 
	C238.373245,179.714859 235.524582,184.226685 231.963165,187.906937 
	C230.089371,189.843246 227.766373,191.344864 225.269287,193.081024 
	C224.951355,192.329956 225.010376,191.540634 225.020523,190.359955 
	C224.971649,189.968597 224.987717,189.993622 225.327576,189.983856 
	C229.914703,185.650970 234.206451,181.370071 238.378784,176.975784 
	C239.453247,175.844193 240.151917,174.355820 241.023224,173.031342 
	C241.023209,173.031342 241.000000,173.000000 241.376892,173.051132 
	C242.375443,172.889679 243.116577,172.827499 243.601303,172.442520 
	C255.452240,163.030411 267.865356,154.656235 282.398407,149.743057 
	C283.998199,149.202209 285.309784,146.148972 285.728363,144.055649 
	C287.763855,133.876251 289.147095,123.560402 291.374969,113.427795 
	C292.675873,107.511116 295.128937,101.847763 297.063660,96.070435 
	C297.063660,96.070435 297.037994,96.055733 297.055420,96.055588 
	C298.393890,94.465523 299.890656,92.989609 301.007996,91.267517 
	C309.527252,78.136887 319.638336,66.487503 332.656586,57.427757 
	C331.975830,59.425240 330.967010,61.194660 329.958191,62.964081 
	C329.958191,62.964085 329.477386,62.970970 328.929077,63.129765 
	C327.243988,65.182243 326.107178,67.075928 324.970337,68.969612 
	C324.970337,68.969612 324.993408,68.998367 324.620422,68.990860 
	C322.813782,69.974907 321.380127,70.966454 319.946442,71.957993 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M517.946228,86.980148 
	C517.954102,79.303268 517.961975,71.626381 517.978271,63.013187 
	C517.964661,56.711349 517.942566,51.345821 517.920532,45.980293 
	C517.933105,43.951450 517.945740,41.922611 517.965210,39.060226 
	C518.296753,34.541206 516.244080,33.660755 513.000732,34.004524 
	C512.915894,33.923923 512.746033,33.762936 512.746033,33.762939 
	C514.212402,31.849068 515.678711,29.935196 517.145081,28.021326 
	C517.633728,28.083765 518.122314,28.146204 518.610962,28.208643 
	C519.093689,30.123049 519.987671,32.035553 519.996521,33.952148 
	C520.101501,56.681252 520.108215,79.411095 520.020508,102.140289 
	C520.013245,104.036354 519.136292,105.929070 518.055542,108.030716 
	C516.916382,107.888176 516.385071,107.538269 515.901733,106.782898 
	C515.304138,103.251213 514.658447,100.125000 514.401611,96.981888 
	C520.094177,94.980461 517.222107,90.412018 517.946228,86.980148 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M409.004486,31.011885 
	C406.523865,31.142300 404.043213,31.272715 401.281738,31.683935 
	C397.941528,32.284931 394.882172,32.605125 391.080994,32.975037 
	C389.558990,33.347443 388.778870,33.670132 387.998749,33.992821 
	C387.251953,33.998447 386.505127,34.004070 385.005493,34.018456 
	C382.480927,35.002411 380.709137,35.977608 378.937378,36.952805 
	C377.890320,36.971039 376.843292,36.989273 375.032104,37.014957 
	C372.510895,37.677711 370.753845,38.333015 368.996765,38.988316 
	C368.089081,38.814064 367.181427,38.639812 366.045105,38.191299 
	C372.529724,35.958549 379.131409,33.351048 385.978973,32.173473 
	C401.354797,29.529303 416.819580,27.056965 432.524994,28.042025 
	C437.106232,28.329365 441.644501,29.301693 446.612732,30.035646 
	C444.442780,30.393784 441.856201,30.961494 439.283569,30.904268 
	C432.160431,30.745817 425.043091,30.326784 417.058533,30.017096 
	C415.136993,30.041965 414.080444,30.060488 412.724854,30.074265 
	C411.949310,30.374531 411.472748,30.679541 410.664185,30.985561 
	C409.889618,30.995010 409.447052,31.003448 409.004486,31.011885 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M330.353119,62.976234 
	C330.967010,61.194660 331.975830,59.425240 332.966370,57.294495 
	C332.948059,56.933167 332.968536,56.966824 333.280334,56.955715 
	C334.039093,56.546776 334.486023,56.148945 334.932983,55.751122 
	C341.346954,51.468124 347.760956,47.185120 354.648376,43.097542 
	C355.419098,43.543739 355.716400,43.794506 356.013672,44.045273 
	C355.406219,45.290779 355.132111,47.081543 354.135681,47.690662 
	C348.851868,50.920559 343.341278,53.777603 338.021088,56.951271 
	C336.905182,57.616920 336.411804,59.258259 335.358124,60.112801 
	C333.962189,61.244904 332.297333,62.045391 330.353119,62.976234 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M515.853760,107.188362 
	C516.385071,107.538269 516.916382,107.888176 517.776978,108.332840 
	C518.996704,108.723083 519.887207,109.018585 521.136169,109.428711 
	C520.443115,110.244781 519.391602,110.946213 518.074097,111.868500 
	C517.060852,112.315323 516.313660,112.541298 515.148682,112.626984 
	C514.213806,112.251793 513.696655,112.016899 513.132202,111.511047 
	C512.760986,110.818680 512.437073,110.397278 512.072693,109.575638 
	C511.954956,107.838783 511.877655,106.502151 511.840668,104.800392 
	C511.235321,102.947762 510.589661,101.460266 509.925446,99.638199 
	C509.571838,98.242638 509.236847,97.181656 508.918365,95.751770 
	C508.022858,93.858109 507.110870,92.333344 506.170166,90.513123 
	C506.093506,89.824821 506.045563,89.431969 505.929077,88.791565 
	C505.607849,88.321213 505.355133,88.098419 505.086182,87.660507 
	C505.069916,87.445389 505.003448,87.019096 504.984619,86.653816 
	C504.396301,84.440575 503.826782,82.592613 503.593811,80.831039 
	C507.139557,88.377342 510.322693,95.848686 513.581604,103.286827 
	C514.181641,104.656303 515.088501,105.891296 515.853760,107.188362 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M512.928589,37.943058 
	C510.870911,38.628651 508.813324,39.314243 506.045349,40.008816 
	C504.556580,40.344608 503.778168,40.671421 502.999756,40.998230 
	C501.936035,41.004230 500.872345,41.010231 499.060303,40.977310 
	C496.867920,40.292099 495.423798,39.645805 493.979706,38.999516 
	C500.592499,39.421978 507.046417,39.248791 512.465332,33.953064 
	C512.746033,33.762936 512.915894,33.923923 512.976196,34.388424 
	C513.000488,35.882969 512.964539,36.913013 512.928589,37.943058 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M493.786377,38.962807 
	C495.423798,39.645805 496.867920,40.292099 498.661530,40.986511 
	C495.386566,43.181400 492.097229,42.709560 489.473328,39.391933 
	C490.948425,38.983871 492.073944,38.953201 493.199493,38.922535 
	C493.199493,38.922535 493.593048,38.926098 493.786377,38.962807 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M464.872864,32.652489 
	C459.793488,32.228676 454.450897,31.486149 448.997070,30.364035 
	C454.127106,30.767559 459.368347,31.550669 464.872864,32.652489 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M492.924652,38.623756 
	C492.073944,38.953201 490.948425,38.983871 489.403198,39.029720 
	C485.692017,38.283264 482.400513,37.521622 478.993622,36.390099 
	C483.468750,36.788471 488.059265,37.556725 492.924652,38.623756 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M471.337769,36.009392 
	C469.437164,35.235851 467.868408,34.457645 466.124084,33.399834 
	C469.870941,33.859142 473.793365,34.598053 477.972717,35.641792 
	C477.100555,35.969894 475.971436,35.993168 474.171265,36.010513 
	C473.332550,36.009041 473.164948,36.013496 472.997314,36.017956 
	C472.554749,36.013542 472.112213,36.009132 471.337769,36.009392 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M356.378784,44.025539 
	C355.716400,43.794506 355.419098,43.543739 354.995209,43.023556 
	C357.640930,41.438988 360.413269,40.123837 363.559235,38.767929 
	C363.096130,39.636761 362.259399,40.546345 360.886597,41.761398 
	C359.879639,42.696060 359.408752,43.325253 358.937897,43.954445 
	C358.206543,43.971565 357.475220,43.988686 356.378784,44.025539 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M303.489960,83.124863 
	C303.568848,82.483734 303.954102,81.777405 304.678894,81.036179 
	C304.611053,81.687401 304.203705,82.373543 303.489960,83.124863 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M334.626770,55.807411 
	C334.486023,56.148945 334.039093,56.546776 333.268951,56.953545 
	C333.403992,56.596226 333.862274,56.229965 334.626770,55.807411 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M438.738617,422.985565 
	C439.004852,423.097046 439.324707,423.156281 439.646698,423.370667 
	C440.242737,423.819946 440.516785,424.054901 440.790833,424.289856 
	C441.803467,427.902161 442.816132,431.514465 443.638763,435.758179 
	C443.584320,437.645233 443.719879,438.900848 443.855438,440.156494 
	C443.214783,454.429016 433.955475,460.597656 421.086304,463.372009 
	C421.145233,463.786407 421.204132,464.200806 421.263062,464.615234 
	C422.613281,465.140320 423.937225,465.750488 425.317932,466.176941 
	C435.138611,469.210266 439.642334,475.127869 439.907593,485.610870 
	C440.025543,490.271210 439.951874,494.936340 439.971466,499.599274 
	C440.013458,509.588928 440.015106,509.588928 449.378845,511.476013 
	C445.960907,519.973877 427.958252,521.192200 417.552856,513.318604 
	C419.450012,513.223755 421.303040,513.028442 422.639069,513.767578 
	C429.268890,517.435303 435.833923,516.148743 443.239990,514.241150 
	C436.592010,509.701843 437.602844,503.179077 437.044159,497.218536 
	C436.499329,491.406403 436.425293,485.542633 435.701599,479.756927 
	C434.985291,474.030151 431.531830,470.885803 425.717316,469.602203 
	C422.518555,468.896088 419.672607,466.591888 415.803802,464.553009 
	C420.271027,461.654694 423.051208,458.877716 426.367889,457.874573 
	C438.116089,454.321259 443.518768,445.712494 440.596863,433.518860 
	C439.925293,430.716064 438.889862,427.288757 436.828003,425.663055 
	C430.401276,420.595917 422.720734,418.524536 414.998657,418.302979 
	C415.685181,426.769135 416.877655,434.828491 416.821838,442.879181 
	C416.776825,449.376251 416.404266,456.176971 410.647614,461.135498 
	C409.648865,461.995758 409.931732,464.861053 410.291534,466.666962 
	C410.918823,469.815613 412.628387,472.814545 412.886108,475.958099 
	C413.549316,484.047058 413.395569,492.204742 414.110565,500.286987 
	C414.490234,504.578552 415.997833,508.770325 416.996277,513.007141 
	C411.832336,508.308502 411.581055,501.840393 411.148499,495.637390 
	C410.709320,489.339569 411.106537,482.987885 410.946686,476.664581 
	C410.833771,472.197021 409.621185,468.112244 405.269958,465.392120 
	C404.712006,465.205444 404.483063,465.065674 404.046265,464.448700 
	C405.143585,462.575928 406.448853,461.180328 408.063629,459.655457 
	C410.154755,456.410858 413.041992,453.455444 413.519897,450.151520 
	C415.265900,438.081207 417.351593,425.657166 406.616425,416.003815 
	C415.149811,416.979767 423.683167,417.955750 432.631287,418.966553 
	C434.144531,419.961884 435.243042,420.922394 436.687744,421.899475 
	C437.513397,422.235413 437.992889,422.554749 438.738617,422.985565 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M443.906433,439.729675 
	C443.719879,438.900848 443.584320,437.645233 443.664734,436.132751 
	C443.906281,437.018219 443.931854,438.160553 443.906433,439.729675 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M440.851593,424.044434 
	C440.516785,424.054901 440.242737,423.819946 439.809143,423.362000 
	C439.866425,423.133942 440.083282,423.128937 440.614441,423.208771 
	C440.923309,423.462036 440.917816,423.630493 440.851593,424.044434 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M100.995392,592.147705 
	C103.405121,584.718506 104.791939,577.122803 108.486557,570.896606 
	C112.642365,563.893188 119.397972,558.711243 127.024261,555.247437 
	C128.313263,554.661987 130.096512,555.164734 131.651428,555.164734 
	C131.884628,555.646729 132.117844,556.128662 132.351044,556.610657 
	C130.726898,558.150574 129.102737,559.690430 127.478592,561.230347 
	C127.740715,561.265625 128.002838,561.300903 128.264954,561.336243 
	C127.445091,564.158997 126.611267,566.977844 125.812416,569.806580 
	C125.503395,570.900818 125.075310,572.010864 125.038322,573.124939 
	C124.653191,584.724487 124.137650,596.325256 124.072601,607.927979 
	C124.030632,615.412903 124.442955,622.923950 125.149178,630.376099 
	C125.379623,632.807800 127.687859,635.004578 128.105225,637.468201 
	C128.453140,639.521851 128.060516,642.287415 126.859283,643.880737 
	C125.019630,646.320862 122.481575,646.445068 119.486450,644.412781 
	C112.422607,639.619629 107.810867,633.470825 104.400322,625.506348 
	C99.751793,614.650879 99.541954,603.821533 100.995392,592.147705 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M137.192825,653.371094 
	C136.672379,653.557556 136.033768,653.505371 135.201294,653.181580 
	C135.696503,652.984131 136.385590,653.058289 137.192825,653.371094 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M126.197868,611.347290 
	C126.486984,611.557251 126.709381,612.046082 126.895584,612.818604 
	C126.616646,612.610291 126.373901,612.118164 126.197868,611.347290 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M126.648743,591.982971 
	C126.870193,592.157715 126.791573,592.439026 126.412331,592.820618 
	C126.177475,592.639465 126.256454,592.362366 126.648743,591.982971 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M128.124298,626.274780 
	C128.310547,626.270813 128.537415,626.505066 128.772552,626.993286 
	C128.500671,627.065247 128.295364,626.820496 128.124298,626.274780 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M241.047516,169.016205 
	C240.550293,168.986954 239.981003,169.106430 239.566803,168.905777 
	C234.595642,166.497559 234.601639,166.485168 230.133850,169.592270 
	C228.840973,166.100327 228.846741,166.086243 232.072296,164.850708 
	C239.399826,162.043945 240.902664,162.286880 242.634705,167.039551 
	C242.211411,168.036514 241.629456,168.526367 241.047516,169.016205 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M241.028931,169.325516 
	C241.629456,168.526367 242.211411,168.036514 242.919739,167.284210 
	C243.742691,166.987747 244.439270,166.953751 245.477142,166.855789 
	C245.818436,166.791840 245.994965,167.000961 245.919220,167.383667 
	C244.326904,168.941528 242.810349,170.116684 241.293793,171.291840 
	C241.293793,171.291840 241.074814,171.076233 240.963135,170.968353 
	C240.904434,170.451920 240.957397,170.043365 241.028931,169.325516 
z"/>
<path fill="#F40C24" opacity="1.000000" stroke="none" 
	d="
M308.861694,466.148987 
	C308.781494,465.501068 308.807220,465.112518 308.783997,464.435974 
	C309.512543,462.387878 310.941010,460.636139 310.963531,458.866516 
	C311.131012,445.712128 311.137634,432.552216 310.915436,419.398712 
	C310.857025,415.941589 311.747406,414.604370 315.330536,414.739685 
	C321.484406,414.972168 327.654724,414.922272 333.814240,414.797607 
	C337.824738,414.716431 338.638916,416.163452 338.385864,420.243500 
	C337.821930,429.336700 338.159454,438.486633 338.154999,447.613464 
	C338.144806,468.432098 338.165161,489.250763 338.129120,510.069336 
	C338.124664,512.654907 337.853485,515.239990 337.342834,517.948242 
	C330.289490,518.053528 323.599213,518.035828 316.030029,517.995728 
	C310.991180,518.637817 310.663666,516.029419 310.872467,512.963135 
	C310.899170,501.945129 310.925873,490.927155 310.971069,479.077179 
	C310.931610,477.505798 310.873657,476.766357 310.815735,476.026947 
	C310.846161,475.569702 310.876617,475.112427 310.939484,473.950012 
	C310.936188,472.502441 310.900513,471.760010 310.864807,471.017578 
	C310.689941,470.158447 310.515076,469.299316 310.109985,467.931000 
	C309.700500,467.159851 309.454895,466.999481 309.142914,466.940704 
	C309.084473,466.763275 309.026062,466.585846 308.861694,466.148987 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M343.822510,516.805298 
	C344.515015,516.729553 345.234100,516.915344 346.057922,517.400757 
	C345.690552,517.808533 345.218414,517.916565 344.386230,518.071289 
	C343.967133,517.767578 343.908081,517.417175 343.822510,516.805298 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M301.021606,566.404785 
	C302.357788,564.610535 303.681885,563.189148 305.116241,561.441284 
	C306.775787,560.271851 308.263580,559.279358 309.885040,558.611511 
	C316.838898,555.747620 323.827820,552.968262 330.830048,550.224731 
	C331.059326,550.134888 331.570404,550.764099 331.869324,551.698486 
	C326.590546,555.659607 323.563324,560.440613 322.911560,566.508484 
	C322.141144,573.680969 321.717010,580.890686 321.087952,588.539856 
	C320.998657,601.004700 320.964203,613.013855 320.593018,624.816895 
	C319.844879,623.401062 319.035034,622.175903 319.081055,620.983826 
	C319.937866,598.788086 315.909973,576.194031 324.780151,554.200806 
	C314.547363,555.759949 308.192657,562.095825 302.796143,569.522339 
	C297.185852,577.242920 295.095551,586.396790 294.396179,595.638916 
	C293.324951,609.795410 294.315460,623.715393 303.155273,635.852905 
	C304.179047,637.258606 304.493011,639.181274 305.069763,640.932983 
	C305.003448,641.001648 304.872925,641.140991 304.872925,641.140991 
	C297.356079,634.720520 294.791504,625.806946 293.090057,616.680603 
	C289.826233,599.173401 292.799225,582.528442 301.021606,566.404785 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M348.777222,551.040466 
	C352.015869,551.164490 355.254486,551.288513 358.533875,551.970886 
	C356.113831,553.300659 356.177246,554.269043 357.011932,556.845825 
	C359.166901,563.498596 361.468170,570.404236 361.774231,577.294861 
	C362.467590,592.903015 362.014618,608.562927 361.971802,624.201660 
	C361.966400,626.175293 361.869354,628.208984 361.395233,630.110168 
	C359.767120,636.638977 357.952515,643.121216 355.952332,650.578064 
	C360.310516,648.395630 364.387970,646.380066 368.439819,644.314270 
	C370.605438,643.210144 372.726959,642.019470 374.929810,640.929932 
	C374.991180,640.991943 375.113342,641.116577 375.113342,641.116577 
	C369.967255,644.383545 364.980133,647.951416 359.607452,650.789429 
	C356.957825,652.188965 353.595978,652.240234 350.217712,652.498047 
	C351.229034,649.331726 352.579102,646.569031 353.942383,643.512085 
	C354.304688,642.526306 354.653748,641.834778 355.387817,641.104614 
	C356.512115,640.076111 357.697601,639.173950 357.912323,638.081055 
	C358.773926,633.696045 359.854004,629.259033 359.913788,624.828491 
	C360.133850,608.518311 360.255188,592.196289 359.860138,575.892212 
	C359.627258,566.279297 357.570190,557.043701 348.777222,551.040466 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M332.165894,652.983826 
	C321.841064,652.437012 312.618439,649.256592 305.048340,641.424683 
	C304.872925,641.140991 305.003448,641.001648 305.420105,640.984375 
	C307.215485,641.801758 308.688751,642.514038 309.956207,643.492737 
	C314.892578,647.304688 320.592010,648.769409 326.096191,649.754333 
	C324.218445,644.424438 322.202026,639.554626 320.858521,634.505737 
	C320.242340,632.190247 320.948242,629.523010 321.321411,627.299805 
	C321.738678,628.875183 321.812988,630.180237 322.052612,631.454224 
	C323.224640,637.685608 323.800751,644.216248 330.076599,648.375977 
	C330.819611,650.137817 331.492767,651.560791 332.165894,652.983826 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M332.455658,653.237183 
	C331.492767,651.560791 330.819611,650.137817 330.064331,648.042297 
	C329.982880,646.926758 329.983521,646.483765 330.328369,646.126587 
	C338.676117,651.824829 344.171936,651.557556 351.416748,645.271545 
	C352.136292,644.647217 353.086182,644.288391 353.929169,643.806396 
	C352.579102,646.569031 351.229034,649.331726 349.805389,652.444214 
	C344.069672,653.026245 338.407562,653.258423 332.455658,653.237183 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M385.804108,623.939026 
	C385.196442,626.008789 384.588806,628.078552 383.898132,630.496765 
	C383.532471,631.031189 383.309113,631.272156 382.864136,631.900330 
	C382.340881,632.421082 382.187744,632.665527 382.123840,632.965942 
	C380.008698,635.636292 377.893585,638.306641 375.445923,641.046753 
	C375.113342,641.116577 374.991180,640.991943 374.977417,640.579834 
	C378.027435,635.069519 381.070343,629.958496 384.189575,624.894531 
	C384.479858,624.423340 385.254395,624.250427 385.804108,623.939026 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M382.316742,632.863281 
	C382.187744,632.665527 382.340881,632.421082 382.780090,632.133972 
	C382.903992,632.330505 382.748230,632.572266 382.316742,632.863281 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M160.956558,551.934326 
	C165.118256,552.201172 169.250565,553.127319 173.426178,553.406982 
	C175.235565,553.528198 177.134186,552.316956 179.172333,551.383301 
	C180.227997,553.460083 181.722229,555.830078 181.858902,558.275818 
	C182.215973,564.665588 181.979187,571.088501 181.979187,577.498230 
	C181.463196,577.686218 180.947220,577.874268 180.431229,578.062256 
	C179.334717,576.291260 178.402420,574.389160 177.112106,572.772888 
	C172.494324,566.988586 167.739731,561.313660 163.076645,555.565186 
	C162.267227,554.567322 161.650467,553.413269 160.956558,551.934326 
z"/>
<path fill="#D4A548" opacity="1.000000" stroke="none" 
	d="
M483.029785,132.161774 
	C482.349792,132.493607 481.669800,132.825424 480.288086,133.116058 
	C477.496460,132.046555 475.406616,131.018234 473.316742,129.989899 
	C472.868530,128.574081 472.420319,127.158249 472.207458,125.224991 
	C472.139557,124.136009 471.836304,123.564445 471.533020,122.992889 
	C464.237213,101.150696 449.206024,87.436127 427.301208,81.003998 
	C411.878998,76.475410 396.313263,77.456291 381.138489,81.504692 
	C374.781860,83.200539 369.219086,87.856644 363.278442,91.136223 
	C361.451874,92.144623 359.569214,93.051445 357.388000,93.564896 
	C357.927612,91.017059 358.791443,88.909103 359.916016,87.082809 
	C361.476440,87.065987 362.867828,86.971344 364.060364,86.434799 
	C370.193176,83.675583 376.018097,79.426552 382.423309,78.231133 
	C400.698608,74.820396 419.121796,73.639694 436.968231,81.155579 
	C444.734558,84.426308 452.222076,87.961136 457.561371,94.873756 
	C460.751129,99.003372 464.722626,102.635513 467.322174,107.087219 
	C470.438019,112.423141 473.158905,118.207260 474.760284,124.145317 
	C476.196411,129.470703 476.977234,131.004837 482.969727,131.222565 
	C483.002045,131.744415 483.015930,131.953094 483.029785,132.161774 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M359.655273,86.801147 
	C358.791443,88.909103 357.927612,91.017059 357.019531,93.482559 
	C356.788940,93.891769 356.602661,93.943436 356.127228,94.034744 
	C355.436768,93.741196 355.035431,93.408005 354.389191,92.661102 
	C358.803375,79.930412 364.765381,68.415466 374.852722,59.564743 
	C378.898804,56.014645 383.183258,52.736202 387.517975,49.285206 
	C387.676727,49.236294 387.999725,49.143997 388.384033,49.163864 
	C392.857910,47.772198 396.947510,46.360664 400.973389,45.308800 
	C398.234833,47.413929 395.559906,49.159389 392.585236,51.050117 
	C390.888672,51.836189 389.491821,52.476986 387.761963,53.086731 
	C386.607178,53.692616 385.785431,54.329556 384.983643,54.978348 
	C385.003662,54.990204 384.967621,54.955563 384.618042,54.982742 
	C379.149078,60.000805 374.029694,64.991692 368.714844,70.103088 
	C368.280579,70.429214 368.113861,70.682289 367.701294,71.120758 
	C364.906189,75.838020 362.429016,80.417343 359.908905,85.298874 
	C359.795715,86.001106 359.725494,86.401131 359.655273,86.801147 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M483.404724,132.143219 
	C483.015930,131.953094 483.002045,131.744415 483.176727,130.961945 
	C486.888550,128.563217 490.411804,126.738289 494.155029,125.017334 
	C494.666656,125.296837 494.860352,125.547920 494.956055,125.874565 
	C491.230591,127.957932 487.505127,130.041306 483.404724,132.143219 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M458.998108,105.644424 
	C455.630219,103.948044 451.321106,102.789330 451.022461,97.355270 
	C453.677765,99.745491 456.321259,102.522354 458.998108,105.644424 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M495.290955,125.870132 
	C494.860352,125.547920 494.666656,125.296837 494.232300,124.685982 
	C496.061829,123.169861 498.034027,122.089073 500.003540,121.346848 
	C500.007477,122.136948 500.014099,122.588478 500.020721,123.040009 
	C498.555756,123.981895 497.090790,124.923798 495.290955,125.870132 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M473.232910,130.355896 
	C475.406616,131.018234 477.496460,132.046555 480.002563,133.130630 
	C476.767334,135.823364 474.866333,135.178909 473.232910,130.355896 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M513.179504,111.782005 
	C513.696655,112.016899 514.213806,112.251793 514.864624,112.744049 
	C513.333984,114.396759 511.669647,115.792107 509.569855,117.154617 
	C509.134430,117.121765 508.962982,116.951241 509.085602,116.701431 
	C509.468231,115.959816 509.728210,115.468002 510.200928,114.865173 
	C510.679199,114.557503 510.867859,114.303726 511.270508,113.865898 
	C512.100769,113.086662 512.640137,112.434334 513.179504,111.782005 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M509.224091,117.202881 
	C507.196320,118.572029 505.078857,119.860062 502.526550,121.116974 
	C502.091705,121.085846 501.945953,120.928726 502.074463,120.684601 
	C502.461670,119.953201 502.720367,119.465927 502.989532,118.988411 
	C503.000000,118.998169 502.979401,118.974030 503.283020,118.961845 
	C504.057770,118.619835 504.528900,118.290016 505.292725,117.935547 
	C506.053131,117.601555 506.520844,117.292206 507.317993,116.986397 
	C508.085938,116.977036 508.524475,116.964142 508.962982,116.951248 
	C508.962982,116.951241 509.134430,117.121765 509.224091,117.202881 
z"/>
<path fill="#F9F7FA" opacity="1.000000" stroke="none" 
	d="
M429.831665,84.653961 
	C428.719330,84.491272 427.405396,84.074104 426.048767,83.330307 
	C427.214111,83.468933 428.422089,83.934204 429.831665,84.653961 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M471.415253,123.261162 
	C471.836304,123.564445 472.139557,124.136009 472.228058,124.851135 
	C471.774658,124.506271 471.536072,124.017860 471.415253,123.261162 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M500.332794,123.010231 
	C500.014099,122.588478 500.007477,122.136948 500.000732,121.342865 
	C500.000641,121.000320 500.007935,121.006226 500.331512,121.001968 
	C501.085388,120.974724 501.515656,120.951721 501.945953,120.928726 
	C501.945953,120.928726 502.091705,121.085846 502.169678,121.162338 
	C501.713409,121.819374 501.179138,122.399910 500.332794,123.010231 
z"/>
<path fill="#FC0821" opacity="1.000000" stroke="none" 
	d="
M160.892075,426.880005 
	C160.892075,426.880005 160.957870,426.455688 161.365753,426.238159 
	C162.848740,424.987000 163.923843,423.953400 164.998932,422.919800 
	C164.998932,422.919800 165.324707,422.811890 165.894409,422.849396 
	C170.987869,420.612976 175.511627,418.339050 180.035400,416.065155 
	C180.465500,416.002930 180.895599,415.940704 181.766586,416.187195 
	C181.655853,417.985870 181.336563,419.621246 180.511246,420.939911 
	C177.418152,425.881958 173.388992,430.456024 175.049179,437.000305 
	C175.013336,437.440033 174.977493,437.879791 174.521057,438.739136 
	C173.404007,442.475861 172.185257,445.780731 172.106995,449.112366 
	C171.850708,460.021515 171.624847,470.957855 172.119125,481.850525 
	C172.590820,492.245209 173.890915,502.597382 181.570251,510.804871 
	C182.661880,511.971588 182.992203,513.850647 183.758362,515.589478 
	C164.966583,515.478088 146.343018,495.255127 145.988129,475.407135 
	C145.763809,462.860504 147.668076,450.705658 151.820145,438.883728 
	C151.820145,438.883728 151.867371,438.938385 152.178894,438.764130 
	C155.290970,434.686584 158.091522,430.783295 160.892075,426.880005 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M151.529114,438.936462 
	C147.668076,450.705658 145.763809,462.860504 145.988129,475.407135 
	C146.343018,495.255127 164.966583,515.478088 183.758362,515.589478 
	C182.992203,513.850647 182.661880,511.971588 181.570251,510.804871 
	C173.890915,502.597382 172.590820,492.245209 172.119125,481.850525 
	C171.624847,470.957855 171.850708,460.021515 172.106995,449.112366 
	C172.185257,445.780731 173.404007,442.475861 174.457855,439.064240 
	C174.613678,440.756042 174.412125,442.542358 174.137100,445.123535 
	C174.043564,447.604919 174.023483,449.291443 174.003387,450.977966 
	C173.929749,458.709534 173.856125,466.441132 173.678070,474.773926 
	C173.711426,476.243530 173.849228,477.111908 173.987030,477.980316 
	C173.972534,478.743073 173.958054,479.505859 173.963120,481.033691 
	C174.269577,483.259552 174.556473,484.720337 174.843384,486.181091 
	C175.047302,488.586304 175.251205,490.991547 175.720032,493.703796 
	C175.984940,494.010803 176.016373,494.033386 176.016388,494.033386 
	C176.267700,495.148621 176.519028,496.263855 176.930466,497.691467 
	C177.097336,498.315216 177.208450,498.583221 177.607208,498.928741 
	C177.842667,499.489746 177.894852,499.929932 178.063049,500.921509 
	C178.325348,501.777924 178.565689,501.956360 178.900055,502.008179 
	C178.900055,502.008179 178.895950,502.073059 178.909653,502.310486 
	C178.923355,502.547943 179.017960,502.974030 179.017960,502.974030 
	C179.815384,504.527740 180.612823,506.081451 181.667831,507.844360 
	C182.428894,508.660248 182.932388,509.266876 183.698547,510.022491 
	C186.079422,512.095154 188.220520,513.994629 190.302170,515.957092 
	C190.947678,516.565613 191.431778,517.345398 192.000092,518.390198 
	C189.912537,518.711365 187.814636,518.689087 185.199478,518.487305 
	C184.538773,518.189331 184.374084,518.135315 184.188126,518.145691 
	C174.814682,516.801453 166.124298,513.715820 158.951447,507.367798 
	C150.332184,499.739624 146.611435,489.703674 144.903824,478.538971 
	C142.977646,465.945190 144.943298,453.845551 148.455139,441.412292 
	C149.493103,440.340820 150.365585,439.665009 151.529114,438.936462 
z"/>
<path fill="#F60B23" opacity="1.000000" stroke="none" 
	d="
M212.983109,416.945404 
	C214.110779,417.220215 215.238449,417.494995 216.767334,418.239227 
	C219.910461,419.373505 222.652374,420.038300 226.086472,420.870941 
	C226.437302,425.880920 226.806671,431.155701 227.224075,437.116516 
	C224.804077,436.081299 223.485214,435.517120 222.166351,434.952942 
	C222.166351,434.952911 222.049210,434.488495 222.032196,433.864380 
	C221.375061,428.846893 219.422134,425.465271 214.926941,424.042908 
	C214.614609,423.938599 214.364258,423.751373 214.086975,422.875000 
	C213.659714,420.494293 213.321411,418.719849 212.983109,416.945404 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M222.150238,435.305969 
	C223.485214,435.517120 224.804077,436.081299 227.224075,437.116516 
	C226.806671,431.155701 226.437302,425.880920 226.086472,420.870941 
	C222.652374,420.038300 219.910461,419.373505 217.110504,418.305725 
	C219.709488,417.460205 222.366516,417.017639 225.279938,416.232544 
	C226.348404,416.713684 227.815720,417.496613 227.869965,418.367493 
	C228.350235,426.078583 228.595123,433.804749 228.853561,441.528351 
	C228.873291,442.117950 228.465256,442.721863 227.738541,444.796753 
	C225.488510,441.128143 223.811325,438.393585 222.150238,435.305969 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M175.271698,436.985107 
	C173.388992,430.456024 177.418152,425.881958 180.511246,420.939911 
	C181.336563,419.621246 181.655853,417.985870 182.092163,416.170135 
	C184.468842,415.556152 186.960831,415.267944 189.920776,415.035828 
	C188.755905,416.403656 187.081833,417.667755 185.497833,419.036102 
	C180.492706,423.359894 177.169983,428.697388 175.912750,435.847839 
	C175.921753,436.452454 175.930359,436.876556 175.930359,436.876556 
	C175.930359,436.876556 175.494202,436.969879 175.271698,436.985107 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M212.626770,416.767609 
	C213.321411,418.719849 213.659714,420.494293 213.984238,422.660645 
	C211.289780,420.926025 208.609100,418.799530 205.794464,416.343323 
	C207.863800,416.205658 210.067123,416.397736 212.626770,416.767609 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M215.057648,424.359192 
	C219.422134,425.465271 221.375061,428.846893 222.000854,433.626953 
	C219.720474,430.900940 217.454422,427.788208 215.057648,424.359192 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M174.271362,450.737915 
	C174.023483,449.291443 174.043564,447.604919 174.142456,445.508759 
	C174.327286,446.898712 174.433304,448.698273 174.271362,450.737915 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M174.889862,485.781281 
	C174.556473,484.720337 174.269577,483.259552 174.003326,481.413330 
	C174.328094,482.479065 174.632202,483.930267 174.889862,485.781281 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M174.243500,477.795441 
	C173.849228,477.111908 173.711426,476.243530 173.732529,475.171509 
	C174.094269,475.848816 174.297119,476.729675 174.243500,477.795441 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M176.108032,436.831360 
	C175.930359,436.876556 175.921753,436.452454 175.941376,436.241638 
	C176.137802,436.182800 176.323547,436.326599 176.479507,436.497589 
	C176.498077,436.517853 176.354248,436.686340 176.108032,436.831360 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M175.978424,493.992523 
	C176.075256,493.950531 176.178619,493.926788 176.149170,493.968201 
	C176.016373,494.033386 175.984940,494.010803 175.978424,493.992523 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M207.218109,517.094482 
	C208.640915,515.158875 210.519363,513.219788 212.729965,511.570251 
	C214.596756,513.784241 226.529190,513.872009 227.664459,511.913971 
	C227.972290,511.383057 227.962296,510.623901 227.967316,509.968536 
	C228.000397,505.645477 228.180893,501.309143 227.874802,497.008331 
	C227.800552,495.964813 226.084641,495.038116 225.123611,494.057709 
	C226.478851,492.383606 227.834076,490.709503 229.536087,489.030945 
	C229.882874,498.066956 229.882874,507.107422 229.882874,517.321167 
	C221.428741,511.986877 214.687668,517.520142 207.218109,517.094482 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M184.250000,518.277832 
	C184.374084,518.135315 184.538773,518.189331 184.757599,518.408813 
	C184.832993,518.510010 184.551376,518.568848 184.551376,518.568848 
	C184.551376,518.568848 184.311890,518.409912 184.250000,518.277832 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M158.020416,649.376892 
	C158.983063,646.676086 160.587158,644.329956 160.680511,641.925110 
	C161.014511,633.319641 160.978653,624.690491 160.742188,616.079590 
	C160.689651,614.166504 159.247528,612.291565 158.080139,610.264648 
	C159.434952,609.749939 161.149323,609.073608 162.875748,609.041260 
	C170.003235,608.907776 177.135452,608.902222 184.263367,609.013306 
	C185.783554,609.036987 187.293076,609.744934 188.589874,610.590820 
	C187.756378,612.193665 186.620270,613.336548 186.604996,614.494080 
	C186.448883,626.332642 186.474854,638.173645 186.449936,650.013916 
	C185.406372,650.207336 184.358063,650.588318 183.320038,650.562134 
	C178.519150,650.441162 173.692932,649.806763 168.931137,650.142212 
	C165.224258,650.403259 161.598907,651.821594 157.569397,652.779663 
	C157.457260,651.790649 157.713058,650.747620 158.020416,649.376892 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M186.719238,650.015686 
	C186.474854,638.173645 186.448883,626.332642 186.604996,614.494080 
	C186.620270,613.336548 187.756378,612.193665 188.709793,610.974548 
	C189.516083,611.510620 190.486069,612.244324 190.369476,612.700134 
	C189.762024,615.074463 188.259903,617.361816 188.198151,619.709106 
	C187.940735,629.496399 188.087646,639.294250 188.083221,649.560486 
	C187.716324,650.027588 187.352432,650.022522 186.719238,650.015686 
z"/>
<path fill="#F80A23" opacity="1.000000" stroke="none" 
	d="
M547.892639,630.226196 
	C547.851685,630.031860 547.810791,629.837463 547.791138,629.024231 
	C547.248108,627.557495 546.683655,626.709473 546.119263,625.861511 
	C545.483032,623.799194 544.846802,621.736816 544.016235,619.363403 
	C543.057068,615.868591 542.292114,612.684753 541.212708,609.295837 
	C540.898193,609.090698 540.880737,609.049072 540.922241,608.643433 
	C538.736816,602.130981 536.509827,596.024170 534.208984,589.570557 
	C534.010559,588.525696 533.885986,587.827698 533.828430,586.748657 
	C532.986755,584.567505 532.078003,582.767273 531.104614,580.731689 
	C531.039978,580.496338 530.894104,580.030518 530.894104,580.030518 
	C527.963135,576.677185 527.570923,572.903931 529.025757,568.412476 
	C529.010864,568.002075 529.111145,567.556885 529.485657,567.238708 
	C531.178772,563.244995 532.497314,559.569519 533.815918,555.894043 
	C533.815918,555.894043 533.899597,555.423523 534.203247,555.036621 
	C534.628784,554.081543 534.750610,553.513245 534.872437,552.945007 
	C534.872437,552.945007 534.935974,552.941101 535.261414,552.799194 
	C541.373718,547.885193 544.809631,548.706421 549.090759,555.884888 
	C549.090759,555.884888 549.105896,555.927612 549.092346,556.244141 
	C549.401794,557.019836 549.724792,557.479004 550.047791,557.938171 
	C550.405884,559.035034 550.764038,560.131836 551.148682,561.950439 
	C552.148071,565.067871 553.120972,567.463562 554.093872,569.859253 
	C554.393188,570.363220 554.692566,570.867126 555.095825,571.892578 
	C555.346741,572.703918 555.573242,572.903992 555.879150,573.014160 
	C555.925232,573.450317 555.971375,573.886536 556.039185,574.948608 
	C556.387878,576.031494 556.714844,576.488342 557.041809,576.945251 
	C565.328674,599.646545 573.669189,622.328552 581.868896,645.061279 
	C584.256714,651.681030 583.800354,652.015503 576.867432,652.130432 
	C570.956360,652.228394 565.050110,652.618713 558.906372,653.177368 
	C558.085144,653.512268 557.499268,653.549500 556.464172,653.275635 
	C554.996826,650.371582 553.978638,647.778625 552.960510,645.185608 
	C552.690308,644.645935 552.420105,644.106262 552.234070,643.194336 
	C552.183838,642.614441 552.049438,642.406860 551.914978,642.199219 
	C551.880127,641.994385 551.845337,641.789551 551.724976,641.077698 
	C551.507385,640.292297 551.288574,640.132263 550.983032,640.090576 
	C550.435364,638.265320 549.887756,636.440063 549.195190,633.939392 
	C548.664368,632.251404 548.278503,631.238770 547.892639,630.226196 
z"/>
<path fill="#C82232" opacity="1.000000" stroke="none" 
	d="
M602.196533,649.608154 
	C602.325195,649.325073 602.750183,649.150024 602.970337,649.082214 
	C603.824768,647.713074 605.008179,646.413635 605.012573,645.110229 
	C605.112305,615.658569 605.069763,586.206543 605.100098,556.754578 
	C605.102783,554.146545 604.660339,552.081604 601.070801,551.590881 
	C600.428772,551.224609 600.240906,551.150879 600.024658,551.148987 
	C599.835388,551.141357 599.646179,551.133789 599.171631,551.112671 
	C599.854248,550.424194 600.819214,549.166565 601.790466,549.161743 
	C616.406738,549.088440 631.023804,549.177124 645.640564,549.258423 
	C645.868958,549.259644 646.095215,549.647217 646.654541,550.157288 
	C645.863220,550.508606 645.233521,550.788208 644.234619,551.136169 
	C641.397644,551.456238 638.625610,551.107910 636.561157,552.154907 
	C634.989868,552.951721 633.391724,555.515991 633.382751,557.301819 
	C633.236145,586.510315 633.328064,615.720154 633.432251,644.929443 
	C633.436829,646.200562 634.073792,647.469360 634.728271,648.927551 
	C636.993103,649.601196 638.985962,650.627747 640.892334,650.486572 
	C656.271667,649.347778 664.592407,639.134216 671.227539,626.690369 
	C672.361572,624.563599 674.423584,622.931580 676.403320,621.096924 
	C676.130371,629.858582 675.605103,638.602478 674.843384,647.325745 
	C674.556458,650.611328 673.582764,653.207214 669.120483,653.161011 
	C646.695251,652.928711 624.267029,652.978210 601.367554,652.831848 
	C601.286072,651.786987 601.676941,650.839111 602.196533,649.608154 
M631.057007,606.500000 
	C631.054749,589.865906 631.113892,573.231262 630.947998,556.598816 
	C630.932678,555.062988 629.549561,552.259094 628.691650,552.215698 
	C621.620667,551.857849 614.523621,552.013428 606.835083,552.013428 
	C607.131348,555.369812 607.568970,557.956482 607.555847,560.540833 
	C607.408508,589.458984 607.216125,618.376953 606.954712,647.294189 
	C606.929321,650.091919 607.905518,651.148987 610.714111,651.069946 
	C615.866577,650.924988 621.044861,650.759521 626.176819,651.115234 
	C630.354980,651.404846 631.171326,649.669250 631.117920,645.923706 
	C630.935364,633.118164 631.053589,620.308228 631.057007,606.500000 
M669.494141,633.947205 
	C664.737915,639.528564 659.981628,645.109985 655.041870,650.906738 
	C659.825989,650.906738 665.192566,651.026306 670.542725,650.783447 
	C671.388611,650.745056 672.786926,649.120728 672.865723,648.143799 
	C673.429993,641.143982 673.718872,634.121948 674.148315,626.118042 
	C672.334229,629.136047 671.082947,631.217773 669.494141,633.947205 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M552.959595,645.576660 
	C553.978638,647.778625 554.996826,650.371582 556.168213,653.278931 
	C553.042786,653.590576 549.764099,653.587830 546.231812,653.299683 
	C547.502930,652.510620 549.027710,652.006958 550.788574,651.232178 
	C551.669373,649.296509 552.314026,647.632080 552.959595,645.576660 
z"/>
<path fill="#F60B23" opacity="1.000000" stroke="none" 
	d="
M528.332520,421.762360 
	C528.791443,421.333496 529.149170,421.236603 529.909790,421.287231 
	C530.780640,421.258423 531.248657,421.082092 531.716614,420.905762 
	C531.716614,420.905762 531.873108,420.859924 531.923706,420.792969 
	C532.828979,421.040466 533.683716,421.354950 534.868591,421.770721 
	C532.419800,426.386658 530.842468,431.079071 533.123779,436.974731 
	C533.465637,437.962708 533.743591,438.308685 534.021545,438.654663 
	C535.527954,439.912415 537.034363,441.170197 538.751953,442.658691 
	C542.049500,444.846100 544.914551,447.400177 548.265930,448.641541 
	C556.588623,451.724365 565.151733,454.152740 573.576538,456.968781 
	C575.889160,457.741821 578.054688,458.955017 580.663635,459.984009 
	C581.073059,460.170044 581.106628,460.338318 581.245239,460.937622 
	C581.488342,461.601959 581.692261,461.732788 581.961975,461.761169 
	C591.012634,468.775879 592.035583,478.720001 591.947754,489.526245 
	C591.888367,490.642609 591.810730,491.308868 591.455444,492.460114 
	C590.935059,493.641541 590.692383,494.337982 590.449768,495.034424 
	C588.194214,503.437500 582.305359,508.958984 575.222290,513.857300 
	C574.875122,513.182495 574.869080,512.454224 574.791870,511.367828 
	C579.654907,502.542328 579.445557,494.367859 572.559509,487.893188 
	C569.023010,484.567963 563.670959,483.039093 558.967896,481.118103 
	C550.106506,477.498657 540.857971,474.716400 532.306519,470.498993 
	C528.470764,468.607269 525.095154,464.549957 522.978088,460.673889 
	C519.077759,453.533020 516.392212,445.781342 519.932373,437.011414 
	C520.339417,436.100250 520.262573,435.567017 520.185730,435.033752 
	C520.524597,433.987518 520.863464,432.941284 521.296692,431.575714 
	C523.671143,428.202362 525.951294,425.148315 528.332520,421.762360 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M560.797119,416.317871 
	C566.006287,416.787659 571.368652,417.629120 576.748779,418.336060 
	C577.971008,418.496704 579.254578,418.191284 580.854492,417.887604 
	C582.444214,418.460876 584.733704,419.202179 584.769531,420.040009 
	C585.062378,426.875092 584.934387,433.728180 584.934387,442.484009 
	C577.649231,432.050537 571.141235,422.485962 560.797119,416.317871 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M533.994507,438.436646 
	C533.743591,438.308685 533.465637,437.962708 533.245361,437.374786 
	C533.524475,437.494781 533.745911,437.856689 533.994507,438.436646 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M386.657959,268.037903 
	C388.839661,269.402771 390.709534,270.649048 392.579437,271.895294 
	C392.540466,272.205597 392.501526,272.515930 392.462585,272.826263 
	C385.601929,272.826263 378.724213,272.528168 371.885956,272.919586 
	C366.310913,273.238708 360.783051,274.382477 354.842712,275.640503 
	C354.000519,276.157593 353.549896,276.194824 353.062073,275.857941 
	C352.682617,274.809845 352.192535,273.511505 352.019470,273.552490 
	C346.615967,274.831421 341.134521,275.925629 335.900909,277.731567 
	C331.811066,279.142883 331.218231,283.035339 334.469452,285.793243 
	C336.160706,287.227905 338.478333,287.918030 340.498627,288.972015 
	C346.224670,291.959290 351.998840,295.693146 352.484802,302.466278 
	C353.290222,313.692719 353.214111,325.040070 352.624573,336.290100 
	C352.181396,344.747253 342.199249,354.582428 332.234222,353.198608 
	C325.873718,352.315338 319.290039,353.093201 312.807648,353.020203 
	C304.813599,352.930176 296.887177,352.580475 291.082764,345.778412 
	C290.662689,345.286102 289.576508,345.362213 288.887390,345.099365 
	C288.974762,345.024658 288.823029,345.197357 288.864624,344.833008 
	C286.937866,342.311462 284.969482,340.154266 282.885376,337.871002 
	C282.769592,337.744904 282.738342,337.403961 282.815002,337.013885 
	C282.280792,335.134705 281.740570,333.611816 281.046844,332.162384 
	C278.082245,325.968750 273.824432,320.078583 272.351288,313.555389 
	C269.682037,301.735748 265.876129,289.939209 266.954773,277.504333 
	C267.026398,276.678894 267.015778,275.830505 266.915405,275.008392 
	C264.869141,258.245148 266.074188,241.601822 268.851440,225.033661 
	C269.301727,222.347229 271.345612,219.556122 270.880615,217.156921 
	C269.413910,209.589767 273.658813,200.404388 280.598419,197.656479 
	C283.100708,196.665619 285.507782,195.434280 288.491425,194.068680 
	C289.290436,195.073883 290.531586,196.197342 291.231140,197.592392 
	C294.025848,203.165558 295.940155,209.336243 299.527740,214.314819 
	C303.666931,220.058945 309.227325,224.778961 314.170563,229.943680 
	C314.170563,229.943680 314.377777,230.128632 314.269836,230.650253 
	C315.022339,232.522797 315.624359,234.174255 316.786896,235.173828 
	C322.554016,240.132568 328.384827,245.028351 334.401611,249.676453 
	C335.632538,250.627350 337.756439,250.422348 339.468689,250.750183 
	C339.468689,250.750183 339.787476,250.775940 339.914917,251.249847 
	C345.928131,258.244781 354.325378,259.952728 361.955383,263.110840 
	C365.577789,264.610168 370.188141,264.622253 370.966797,271.013092 
	C374.017914,265.582947 377.243408,267.189636 380.485443,268.969574 
	C382.301788,269.966797 384.163879,271.137360 385.016724,267.928711 
	C385.459839,267.925568 385.902985,267.922424 386.657959,268.037903 
M272.530914,305.777649 
	C272.408630,306.157227 272.265656,306.532288 272.181244,306.920105 
	C272.163879,306.999969 272.365204,307.127441 272.465759,307.232971 
	C272.587799,306.853363 272.709839,306.473785 272.530914,305.777649 
z"/>
<path fill="#0201F9" opacity="1.000000" stroke="none" 
	d="
M387.132080,234.863174 
	C387.649017,235.044113 388.165985,235.225067 388.843079,236.005920 
	C390.339630,237.729813 391.676025,238.853806 393.049500,240.348526 
	C394.463257,244.909821 397.980560,243.878418 401.318970,244.101440 
	C403.393677,244.513138 405.183868,244.800735 407.026550,245.434937 
	C408.207581,248.269257 409.816620,248.803436 412.376221,246.992493 
	C413.497498,247.002609 414.245026,247.012680 415.066772,247.387634 
	C418.100739,248.496307 421.060455,249.240051 424.393555,249.985321 
	C425.856598,249.616806 426.946259,249.246780 428.268250,248.794647 
	C428.792999,248.539093 429.021240,248.303467 429.185303,248.005661 
	C429.185303,248.005661 429.642914,248.036530 430.122101,248.035614 
	C430.780396,248.061508 430.959534,248.088303 431.059021,248.382217 
	C430.969238,248.834839 430.959106,249.020370 430.963928,249.513245 
	C431.655273,250.208847 432.331665,250.597122 433.451599,250.988068 
	C436.262695,250.992661 438.630280,250.994598 441.224792,251.264755 
	C443.746490,252.522903 445.022125,251.896225 445.426453,249.193573 
	C446.864624,249.147903 447.914886,249.180603 448.978027,249.535919 
	C449.363922,250.416519 449.736969,250.974503 450.110016,251.532471 
	C450.416809,250.746201 450.723602,249.959930 451.346619,249.103821 
	C452.092651,248.994003 452.522430,248.954010 453.139526,249.186966 
	C454.243744,249.003021 455.160675,248.546127 456.435303,248.053635 
	C457.509857,247.964050 458.226746,247.910034 459.134216,248.136551 
	C460.877502,248.274460 462.430206,248.131836 464.359833,248.034973 
	C467.915955,249.065170 471.095093,250.049576 475.015106,251.263382 
	C475.015106,248.239899 475.015106,246.142914 475.204834,243.972504 
	C475.394562,243.899094 475.796051,243.833267 476.162231,243.903046 
	C477.019958,243.998199 477.511505,244.023544 478.200195,244.311676 
	C479.628876,245.356094 480.860443,246.137711 483.182983,247.611694 
	C484.894684,244.568222 486.452728,241.797958 488.301941,238.894257 
	C489.396210,238.515701 490.199341,238.270584 491.036743,238.370117 
	C491.820923,241.538177 496.594910,242.939224 493.637573,246.971878 
	C492.214417,247.343277 491.135956,247.693970 489.705444,248.059875 
	C488.231293,249.040390 487.109222,250.005676 485.976746,251.324600 
	C485.972443,252.150879 485.978607,252.623505 485.984741,253.096130 
	C485.554474,253.386307 485.124207,253.676498 484.030731,253.969666 
	C482.580109,253.961411 481.792755,253.950165 480.750275,253.673859 
	C474.882416,253.246017 470.393524,255.998108 466.000000,258.998779 
	C466.000000,259.000000 465.997559,259.000000 465.603027,258.999054 
	C463.802673,259.655914 462.396820,260.313751 460.659973,260.981995 
	C459.887512,261.000336 459.446045,261.008270 458.803711,260.764862 
	C457.404358,261.653839 456.205902,262.794128 455.007446,263.934418 
	C455.007446,263.934418 455.044403,263.969666 454.661652,263.972656 
	C453.781921,264.026031 453.284973,264.076385 452.787994,264.126709 
	C452.172211,264.156586 451.556458,264.186462 450.197449,264.142914 
	C448.304779,264.050781 447.155334,264.032074 445.841003,263.785156 
	C445.118652,263.999420 444.561157,264.441833 443.572815,264.912415 
	C439.762543,265.297485 436.383087,265.654449 432.821960,265.677582 
	C427.519989,265.564575 422.399353,265.779358 417.281342,266.044556 
	C417.165344,266.050568 417.078949,266.628448 416.530640,266.954773 
	C413.385437,266.945770 410.688385,266.921539 407.911011,266.536072 
	C405.554169,266.115112 403.277649,266.055420 400.602905,265.995667 
	C399.143250,265.972931 398.081787,265.950256 396.981628,265.643066 
	C396.296295,265.234619 395.649719,265.110657 394.602539,264.941956 
	C393.085327,264.607178 391.982758,264.146179 390.849884,264.052856 
	C375.826477,262.815552 362.243256,256.930054 349.004120,250.431793 
	C342.582214,247.279678 336.478485,243.029800 331.112091,238.280899 
	C322.773285,230.901581 313.668488,224.113510 308.152954,213.955292 
	C307.418488,212.602600 305.313507,211.994034 303.718689,210.720291 
	C301.385681,205.797684 299.367889,201.091354 296.923035,196.618469 
	C293.421173,190.211807 293.711456,189.200516 301.202637,186.917511 
	C302.803528,186.895233 304.022980,187.055405 305.113159,186.751740 
	C317.534241,183.291748 329.891144,184.933502 342.331390,186.772736 
	C347.595398,187.550980 349.868866,191.110992 351.939606,195.014221 
	C353.526520,198.005524 354.040802,201.611908 355.827332,204.442932 
	C361.988373,214.206039 368.901428,223.447723 378.189148,230.504196 
	C380.779083,232.471924 384.130157,233.437744 387.132080,234.863174 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M314.172607,229.609558 
	C309.227325,224.778961 303.666931,220.058945 299.527740,214.314819 
	C295.940155,209.336243 294.025848,203.165558 291.231140,197.592392 
	C290.531586,196.197342 289.290436,195.073883 288.491425,194.068680 
	C285.507782,195.434280 283.100708,196.665619 280.598419,197.656479 
	C273.658813,200.404388 269.413910,209.589767 270.880615,217.156921 
	C271.345612,219.556122 269.301727,222.347229 268.851440,225.033661 
	C266.074188,241.601822 264.869141,258.245148 266.915405,275.008392 
	C267.015778,275.830505 267.026398,276.678894 266.954773,277.504333 
	C265.876129,289.939209 269.682037,301.735748 272.351288,313.555389 
	C273.824432,320.078583 278.082245,325.968750 281.046844,332.162384 
	C281.740570,333.611816 282.280792,335.134705 282.480408,336.917603 
	C280.465973,334.942871 278.606171,332.810669 277.312988,330.377319 
	C274.638885,325.345612 272.266449,320.153564 269.791809,314.699921 
	C269.662506,313.969910 269.511322,313.567474 269.484039,312.869507 
	C269.323883,312.066498 269.039825,311.559021 268.785431,310.729248 
	C268.576447,309.602722 268.337830,308.798462 268.253418,307.692810 
	C266.596069,295.936432 264.784485,284.481415 263.035339,272.561584 
	C262.890472,267.387939 262.683167,262.679077 262.650757,257.624176 
	C262.726379,256.528442 262.627045,255.778702 262.720428,254.630402 
	C262.935089,252.474274 262.957062,250.716736 263.259369,248.669525 
	C264.292969,240.564316 265.046265,232.748749 265.929016,224.628235 
	C266.053650,223.884354 266.048798,223.445419 266.322174,222.762070 
	C267.423340,218.791718 267.854980,214.922531 269.155182,211.371323 
	C271.464996,205.062653 274.301239,198.946716 277.104034,193.081589 
	C279.805328,192.823090 282.345581,192.323166 284.826080,191.614105 
	C286.731079,191.069565 288.560760,190.261551 290.483948,189.920929 
	C292.798645,195.405884 294.425873,200.949966 297.448914,205.583389 
	C302.511871,213.343506 308.465027,220.522873 314.033569,227.957199 
	C314.023560,227.967758 314.057892,227.960403 314.057892,227.960403 
	C314.096802,228.398758 314.135712,228.837097 314.172607,229.609558 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M493.982269,246.951172 
	C496.594910,242.939224 491.820923,241.538177 491.017334,238.061768 
	C490.929718,237.205841 490.895721,237.002899 491.095856,236.556396 
	C491.945679,235.198792 492.561371,234.084778 493.276245,232.918243 
	C493.375397,232.865707 493.591431,232.805145 493.979431,232.901031 
	C495.850677,232.623306 497.333923,232.249680 499.161652,231.891144 
	C500.003204,231.264908 500.500275,230.623550 501.160889,229.682831 
	C503.568237,226.894684 505.812042,224.405914 508.192810,221.763031 
	C508.531738,221.419739 508.755402,221.261047 509.366882,221.157593 
	C510.460846,221.105011 511.188660,221.027664 512.168030,220.867157 
	C512.593445,220.488129 512.767273,220.192245 513.095703,219.610001 
	C514.865356,217.907776 516.480408,216.491913 518.449097,215.060333 
	C519.745544,214.958649 520.688354,214.872696 522.785156,214.681534 
	C521.418396,219.190308 520.222290,223.136139 518.939575,227.292038 
	C518.685852,227.772461 518.450500,227.954880 517.786926,228.054443 
	C515.279724,230.381805 513.132629,232.704102 510.900452,235.236618 
	C510.670654,235.730133 510.449982,235.929199 509.770874,236.020782 
	C507.576080,237.696564 505.763733,239.395584 503.702942,241.287231 
	C502.943176,242.626648 502.431915,243.773468 501.933655,244.923584 
	C501.946655,244.926910 501.920166,244.913330 501.920166,244.913330 
	C501.207214,244.942596 500.494232,244.971878 499.311096,244.836426 
	C498.586243,244.849350 498.331573,245.027008 498.076874,245.204681 
	C497.594147,245.742874 497.111420,246.281082 496.024994,246.917679 
	C494.941620,246.994431 494.461945,246.972809 493.982269,246.951172 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M303.843201,211.040863 
	C305.313507,211.994034 307.418488,212.602600 308.152954,213.955292 
	C313.668488,224.113510 322.773285,230.901581 331.112091,238.280899 
	C336.478485,243.029800 342.582214,247.279678 349.004120,250.431793 
	C362.243256,256.930054 375.826477,262.815552 390.849884,264.052856 
	C391.982758,264.146179 393.085327,264.607178 394.768829,265.190033 
	C395.897217,265.631073 396.458771,265.779327 397.020325,265.927582 
	C398.081787,265.950256 399.143250,265.972931 400.831909,266.264587 
	C402.976349,267.006165 404.493530,267.478760 406.010742,267.951355 
	C391.015350,267.426025 376.534027,264.364990 362.442535,259.264465 
	C345.019684,252.958099 330.348267,242.470276 317.120026,229.740601 
	C316.416840,229.063950 315.550018,228.557312 314.408630,227.965973 
	C314.057892,227.960403 314.023560,227.967758 314.051086,227.596069 
	C313.238007,225.869888 312.408661,224.508240 311.554718,223.162201 
	C308.989410,219.118469 306.414429,215.080856 303.843201,211.040863 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M384.760864,267.652496 
	C384.163879,271.137360 382.301788,269.966797 380.485443,268.969574 
	C377.243408,267.189636 374.017914,265.582947 370.966797,271.013092 
	C370.188141,264.622253 365.577789,264.610168 361.955383,263.110840 
	C354.325378,259.952728 345.928131,258.244781 340.017181,251.372803 
	C348.270752,254.716171 356.407532,258.786835 364.874908,261.981079 
	C371.197021,264.365997 377.946075,265.619141 384.760864,267.652496 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M339.338348,250.424438 
	C337.756439,250.422348 335.632538,250.627350 334.401611,249.676453 
	C328.384827,245.028351 322.554016,240.132568 316.786896,235.173828 
	C315.624359,234.174255 315.022339,232.522797 314.257355,230.788574 
	C322.637878,236.969742 330.922943,243.534225 339.338348,250.424438 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M416.978760,266.939575 
	C417.078949,266.628448 417.165344,266.050568 417.281342,266.044556 
	C422.399353,265.779358 427.519989,265.564575 432.814148,266.002289 
	C432.971405,267.093445 432.954773,267.526093 432.938171,267.958710 
	C431.575409,267.982971 430.212616,268.007233 428.252319,267.762756 
	C426.755524,267.673035 425.856232,267.852051 424.956970,268.031036 
	C423.605530,268.060974 422.254059,268.090912 420.296967,267.848297 
	C418.787140,267.363678 417.882935,267.151611 416.978760,266.939575 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M514.163391,237.172012 
	C509.719147,240.794693 505.274872,244.417374 500.471191,248.034698 
	C500.703278,247.200455 501.294739,246.371567 501.903168,245.227997 
	C501.920166,244.913330 501.946655,244.926910 502.273468,244.937607 
	C503.838684,243.764999 505.077057,242.581696 506.333282,241.385941 
	C506.351105,241.373489 506.377411,241.339203 506.725525,241.343216 
	C509.329773,239.846603 511.585876,238.345993 513.922852,236.926514 
	C514.003723,237.007660 514.163391,237.172012 514.163391,237.172012 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M466.000000,258.997559 
	C470.393524,255.998108 474.882416,253.246017 480.537109,253.958511 
	C478.710022,255.701263 476.841003,256.894287 474.971985,258.087311 
	C474.319794,258.139679 473.667572,258.192078 472.271912,258.176575 
	C469.685638,258.404938 467.842834,258.701263 466.000000,258.997559 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M448.615906,268.124268 
	C445.686218,271.546783 441.624023,271.274506 437.202209,270.180054 
	C440.694000,269.255676 444.445435,268.647583 448.615906,268.124268 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M493.637573,246.971878 
	C494.461945,246.972809 494.941620,246.994431 495.782410,247.043396 
	C492.999420,249.050308 489.855316,251.029892 486.347961,253.052795 
	C485.978607,252.623505 485.972443,252.150879 486.323608,251.301331 
	C487.806427,249.964508 488.931976,249.004593 490.057495,248.044662 
	C491.135956,247.693970 492.214417,247.343277 493.637573,246.971878 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M433.357483,268.002808 
	C432.954773,267.526093 432.971405,267.093445 432.995850,266.336121 
	C436.383087,265.654449 439.762543,265.297485 443.588135,265.203186 
	C444.045715,265.658691 444.057159,265.851624 444.068634,266.044525 
	C440.638031,266.711975 437.207428,267.379425 433.357483,268.002808 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M416.530640,266.954773 
	C417.882935,267.151611 418.787140,267.363678 419.892334,267.872314 
	C416.338501,268.132843 412.583588,268.096802 408.402771,267.745300 
	C407.981628,267.252319 407.986450,267.074829 407.991302,266.897308 
	C410.688385,266.921539 413.385437,266.945770 416.530640,266.954773 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M499.409393,248.564056 
	C499.728943,253.033401 495.544891,252.315262 492.674377,253.633270 
	C494.534149,251.876099 496.795837,250.255783 499.409393,248.564056 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M407.911011,266.536072 
	C407.986450,267.074829 407.981628,267.252319 407.972321,267.695740 
	C407.532776,267.974487 407.097748,267.987274 406.336731,267.975708 
	C404.493530,267.478760 402.976349,267.006165 401.230103,266.264648 
	C403.277649,266.055420 405.554169,266.115112 407.911011,266.536072 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M475.348694,258.059570 
	C476.841003,256.894287 478.710022,255.701263 480.792206,254.223572 
	C481.792755,253.950165 482.580109,253.961411 483.764404,254.002899 
	C481.349335,255.366043 478.537354,256.698944 475.348694,258.059570 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M466.000000,258.998779 
	C467.842834,258.701263 469.685638,258.404938 471.944702,258.180908 
	C470.116302,259.414825 467.871704,260.576508 465.318359,261.523346 
	C465.338989,260.539001 465.668274,259.769501 465.997559,259.000000 
	C465.997559,259.000000 466.000000,259.000000 466.000000,258.998779 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M464.708435,264.459839 
	C462.564545,265.375336 460.070099,266.169281 457.145752,266.909790 
	C459.263214,266.016998 461.810547,265.177643 464.708435,264.459839 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M459.004578,261.016205 
	C459.446045,261.008270 459.887512,261.000336 460.979370,261.020447 
	C462.126892,261.414398 462.624054,261.780273 463.121185,262.146149 
	C461.294617,262.746979 459.468079,263.347809 457.330688,263.637665 
	C457.681427,262.556519 458.343018,261.786377 459.004578,261.016205 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M486.614136,255.892853 
	C485.179657,256.911896 483.386322,257.948944 481.188660,258.937805 
	C482.607971,257.896667 484.431641,256.903778 486.614136,255.892853 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M463.423065,262.116547 
	C462.624054,261.780273 462.126892,261.414398 461.310364,261.010071 
	C462.396820,260.313751 463.802673,259.655914 465.603027,258.999054 
	C465.668274,259.769501 465.338989,260.539001 464.968872,261.619873 
	C464.527039,261.983154 464.126007,262.035065 463.423065,262.116547 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M458.803711,260.764862 
	C458.343018,261.786377 457.681427,262.556519 456.958679,263.633179 
	C456.477081,263.967743 456.056610,263.995819 455.321808,263.979156 
	C456.205902,262.794128 457.404358,261.653839 458.803711,260.764862 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M455.627136,266.962830 
	C454.465027,267.537018 452.927216,268.028473 451.184631,268.243713 
	C452.403717,267.605042 453.827545,267.242554 455.627136,266.962830 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M446.005859,264.013367 
	C447.155334,264.032074 448.304779,264.050781 449.889160,264.141968 
	C449.107910,264.821167 447.891754,265.427887 446.316711,265.674805 
	C445.973816,264.881104 445.989838,264.447235 446.005859,264.013367 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M445.841003,263.785156 
	C445.989838,264.447235 445.973816,264.881104 445.935059,265.639526 
	C445.502777,265.994415 445.093170,266.024750 444.376099,266.049805 
	C444.057159,265.851624 444.045715,265.658691 444.018982,265.175018 
	C444.561157,264.441833 445.118652,263.999420 445.841003,263.785156 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M490.624420,253.827454 
	C490.334930,254.487183 489.698120,255.126022 488.674561,255.641907 
	C488.950928,254.948135 489.614044,254.377350 490.624420,253.827454 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M479.700317,259.105194 
	C479.362091,259.700867 478.695770,260.240234 477.667938,260.653015 
	C477.995026,260.033936 478.683655,259.541412 479.700317,259.105194 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M425.341248,268.059662 
	C425.856232,267.852051 426.755524,267.673035 427.843536,267.749573 
	C427.263367,268.032806 426.494415,268.060547 425.341248,268.059662 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M452.981110,264.339539 
	C453.284973,264.076385 453.781921,264.026031 454.651917,263.996277 
	C454.408020,264.195343 453.791107,264.373810 452.981110,264.339539 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M498.323669,245.159485 
	C498.331573,245.027008 498.586243,244.849350 498.952515,244.847992 
	C498.899536,245.054306 498.734985,245.084305 498.323669,245.159485 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M538.239258,614.857910 
	C531.727295,614.665649 525.215332,614.473389 518.336182,613.739624 
	C518.091553,612.098694 518.214050,610.999207 518.339355,609.848022 
	C518.342224,609.796326 518.255310,609.739868 518.529663,609.522705 
	C518.896057,608.860046 518.987976,608.414551 519.254211,607.725403 
	C519.680664,606.538879 519.932861,605.595947 520.462891,604.411255 
	C520.953735,603.234131 521.166748,602.298828 521.383179,601.352539 
	C521.386536,601.341614 521.408142,601.331299 521.658447,601.117188 
	C522.026184,600.268616 522.143494,599.634094 522.489563,598.708069 
	C525.220154,593.922607 524.029846,587.776123 529.112427,583.536072 
	C531.975281,592.227783 534.671753,600.166992 537.171021,608.167786 
	C537.837341,610.300903 537.899658,612.622681 538.239258,614.857910 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M538.682800,614.878601 
	C537.899658,612.622681 537.837341,610.300903 537.171021,608.167786 
	C534.671753,600.166992 531.975281,592.227783 529.112427,583.536072 
	C524.029846,587.776123 525.220154,593.922607 522.135010,598.708496 
	C521.315369,599.004272 521.079163,599.007935 520.842896,599.011658 
	C522.563538,593.315247 524.117981,587.558350 526.153076,581.976562 
	C526.533142,580.934082 528.840149,580.594116 530.575989,579.980225 
	C530.894104,580.030518 531.039978,580.496338 531.097534,581.077515 
	C532.023926,583.482361 532.892700,585.306030 533.761414,587.129639 
	C533.885986,587.827698 534.010559,588.525696 534.174072,589.949341 
	C536.435547,596.799683 538.658142,602.924377 540.880737,609.049072 
	C540.880737,609.049072 540.898193,609.090698 540.896362,609.114868 
	C540.305176,611.059204 539.715759,612.979248 538.682800,614.878601 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M518.255310,609.739868 
	C518.255310,609.739868 518.342224,609.796326 518.009644,610.051880 
	C517.454041,611.550476 517.230957,612.793518 517.007935,614.036560 
	C514.788330,613.710815 514.614441,613.541748 515.438232,611.241821 
	C516.636475,607.896484 517.992188,604.607483 519.591797,601.095520 
	C519.902832,600.896545 520.044800,601.049561 520.032227,601.498840 
	C520.074829,602.849731 520.129944,603.751404 520.185059,604.653015 
	C519.932861,605.595947 519.680664,606.538879 518.986328,607.945923 
	C518.447815,608.853333 518.351562,609.296631 518.255310,609.739868 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M517.233032,614.010132 
	C517.230957,612.793518 517.454041,611.550476 518.006836,610.103577 
	C518.214050,610.999207 518.091553,612.098694 517.939148,613.613464 
	C517.909241,614.028748 517.458191,613.983704 517.233032,614.010132 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M520.815613,599.180054 
	C521.079163,599.007935 521.315369,599.004272 521.906250,599.000122 
	C522.143494,599.634094 522.026184,600.268616 521.494385,600.711670 
	C520.989075,600.243164 520.898193,599.966125 520.807373,599.689148 
	C520.807373,599.689148 520.788330,599.348450 520.815613,599.180054 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M520.601013,599.831970 
	C520.898193,599.966125 520.989075,600.243164 521.244080,600.925781 
	C521.408142,601.331299 521.386536,601.341614 521.055908,601.245728 
	C520.498413,601.116394 520.271606,601.082947 520.044800,601.049561 
	C520.044800,601.049561 519.902832,600.896545 519.829346,600.819153 
	C519.968811,600.486145 520.181763,600.230469 520.601013,599.831970 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M432.331177,370.069427 
	C433.569153,368.402435 434.909515,365.828156 436.865631,365.213501 
	C443.506805,363.126709 444.960266,361.786621 444.981659,354.833832 
	C445.033936,337.866913 445.093231,320.898987 444.961487,303.932922 
	C444.895752,295.469299 446.852203,288.207458 454.661346,283.523224 
	C455.583954,282.969788 455.938049,281.468536 456.555695,280.406677 
	C455.401093,280.085632 454.259155,279.551361 453.089630,279.482086 
	C449.002594,279.240143 444.901459,278.964386 440.814209,279.061096 
	C434.362610,279.213684 427.915619,279.959503 421.470734,279.911133 
	C410.112915,279.825897 398.759186,279.275970 387.401123,279.059113 
	C385.390015,279.020721 383.366089,279.655426 380.638153,280.093262 
	C382.419647,282.771759 383.651581,284.623932 384.509277,286.756897 
	C382.752106,286.692566 381.369110,286.347412 379.993652,286.000519 
	C380.001221,285.998779 379.998413,286.014130 379.975647,285.655945 
	C378.941772,284.894684 377.930695,284.491547 376.876068,284.096863 
	C376.832520,284.105316 376.766296,284.046295 376.682404,283.781464 
	C376.088745,283.393677 375.578979,283.270691 374.780762,283.033691 
	C373.927826,282.662811 373.363403,282.405945 372.798401,281.961914 
	C372.540649,281.820038 372.283539,281.865326 371.681213,281.891235 
	C370.459778,281.630951 369.583496,281.390076 368.648132,280.818604 
	C368.092255,279.047241 367.595551,277.606476 367.098816,276.165710 
	C393.071442,276.114258 419.044067,276.060455 445.016693,276.012817 
	C452.342804,275.999390 459.668884,276.003998 467.473297,276.496948 
	C468.020752,278.429199 468.089935,279.864838 467.889374,281.402344 
	C467.245483,281.591461 466.871338,281.678680 466.139191,281.550659 
	C462.869995,282.906586 459.958801,284.477692 457.023804,286.024414 
	C457.000000,286.000000 457.048615,286.047394 456.699768,286.029724 
	C455.564392,287.003021 454.777924,287.993988 453.995789,288.991821 
	C454.000122,288.998718 453.984375,288.991089 453.984375,288.991089 
	C448.845917,290.354950 447.534210,295.001190 447.318085,298.976959 
	C446.701202,310.325348 447.093933,321.725067 446.902771,333.101440 
	C446.774994,340.705872 446.195862,348.302521 446.059174,355.906982 
	C445.900940,364.707672 446.021545,364.724243 437.836639,368.178436 
	C436.163025,368.884735 434.424377,369.436951 432.331177,370.069427 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M372.798981,282.149109 
	C373.363403,282.405945 373.927826,282.662811 374.887817,283.275513 
	C375.777679,283.769653 376.272003,283.907990 376.766296,284.046295 
	C376.766296,284.046295 376.832520,284.105316 377.012573,284.389282 
	C378.127930,285.120209 379.063171,285.567169 379.998413,286.014130 
	C379.998413,286.014130 380.001221,285.998779 380.081055,286.329498 
	C383.138367,291.781158 386.115845,296.902100 389.085205,302.329559 
	C389.128296,303.040955 389.179535,303.445831 389.115417,304.321289 
	C389.000305,316.039459 388.757233,327.294037 389.078613,338.532471 
	C389.330750,347.348999 387.597076,355.486084 382.995178,363.001709 
	C383.001221,363.001404 383.002319,362.989502 382.643066,362.997345 
	C378.516449,364.654236 374.895752,367.037384 370.956207,367.824280 
	C354.547455,371.101624 338.108551,371.312988 321.904175,366.407684 
	C316.060699,364.638763 310.312836,362.554016 304.430389,360.534912 
	C304.339417,360.456421 304.100311,360.480804 304.150543,360.199829 
	C304.069672,359.365173 303.938538,358.811523 304.017639,358.017761 
	C304.141479,353.848419 306.742676,355.071991 308.907166,355.720825 
	C310.397980,357.798187 311.528595,360.496429 313.333496,361.068939 
	C321.512939,363.663513 329.770813,366.696472 338.212280,367.606140 
	C348.793427,368.746368 359.679749,369.074615 370.364136,365.034912 
	C371.807953,364.694855 372.903229,364.360565 374.370819,364.030243 
	C376.615112,363.077789 378.721680,362.409790 380.316711,361.112701 
	C385.868256,356.598297 386.603088,349.871094 386.863770,343.554382 
	C387.376190,331.137451 387.422241,318.664734 386.832397,306.254486 
	C386.540619,300.115326 385.256714,293.645905 379.004303,289.994659 
	C379.000000,290.000000 379.010651,289.991425 378.869812,289.674683 
	C374.975281,287.564453 371.247040,285.714539 367.457642,283.999969 
	C365.398224,283.068207 363.166412,282.507812 361.135101,281.525940 
	C358.446594,280.226410 355.878265,278.678284 353.222900,276.985046 
	C353.158112,276.566071 353.128693,276.399048 353.099243,276.232056 
	C353.549896,276.194824 354.000519,276.157593 354.780396,276.029205 
	C357.367584,276.183777 359.625549,276.429535 362.322510,277.054260 
	C362.995239,281.469086 365.944397,281.160065 368.707245,281.149170 
	C369.583496,281.390076 370.459778,281.630951 371.856689,282.007385 
	C372.377319,282.142914 372.798981,282.149078 372.798981,282.149109 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M468.159088,281.300476 
	C468.089935,279.864838 468.020752,278.429199 467.932312,276.563049 
	C471.600555,275.205750 473.688873,275.965057 472.422241,280.698547 
	C471.928955,281.217102 471.361786,281.376556 470.142609,281.595581 
	C469.046783,281.536926 468.602936,281.418701 468.159088,281.300476 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M368.648132,280.818604 
	C365.944397,281.160065 362.995239,281.469086 362.703186,277.003387 
	C363.889771,276.321533 365.134705,276.069519 366.739227,275.991577 
	C367.595551,277.606476 368.092255,279.047241 368.648132,280.818604 
z"/>
<path fill="#F7E2C0" opacity="1.000000" stroke="none" 
	d="
M524.779541,202.684921 
	C525.023193,203.116501 524.999512,203.296265 524.568604,203.784088 
	C522.455017,204.453674 520.748657,204.815231 518.808594,204.865982 
	C506.091705,206.077866 493.608551,207.600555 481.125366,209.123245 
	C481.125366,209.123245 480.955933,208.960449 480.868286,208.879242 
	C482.877075,207.552185 484.858551,205.521179 487.089508,205.194626 
	C497.466278,203.675766 507.903198,202.539566 518.341064,201.490555 
	C520.346252,201.289047 522.452576,202.093002 524.779541,202.684921 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M444.103424,141.638580 
	C443.774323,143.401413 443.443512,144.832687 442.695190,146.588318 
	C440.775208,145.435486 439.449829,144.648346 437.544037,146.677094 
	C436.450073,147.841599 434.601288,148.297043 433.107544,148.779266 
	C433.183929,148.102814 433.245117,147.718796 433.306305,147.334763 
	C433.306305,147.334763 433.604675,147.131943 434.099884,146.979340 
	C437.587921,144.329224 438.079254,138.378860 444.042908,139.981995 
	C444.062500,140.423676 444.082092,140.865341 444.103424,141.638580 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M444.294006,139.791382 
	C438.079254,138.378860 437.587921,144.329224 434.247375,146.875427 
	C435.200897,144.175674 436.031891,141.027328 437.934357,138.790146 
	C439.874878,136.508194 442.861542,135.171997 444.294006,139.791382 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M428.008148,151.213837 
	C427.789398,151.114670 427.570648,151.015488 427.049622,150.719604 
	C427.845490,149.954391 428.943573,149.385895 430.504364,148.902466 
	C430.967041,148.987534 431.227936,149.286942 431.053009,149.480316 
	C430.526886,150.053040 430.175690,150.432388 429.516632,150.852295 
	C428.808563,150.999832 428.408356,151.106827 428.008148,151.213837 
z"/>
<path fill="#A696E4" opacity="1.000000" stroke="none" 
	d="
M432.983582,147.272324 
	C433.245117,147.718796 433.183929,148.102814 433.084229,148.768387 
	C433.045746,149.049927 433.066193,149.090668 432.757874,149.106384 
	C432.042358,149.177048 431.635132,149.231995 431.227936,149.286942 
	C431.227936,149.286942 430.967041,148.987534 430.831635,148.840057 
	C431.351105,148.198349 432.006012,147.704117 432.983582,147.272324 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M549.077942,555.526611 
	C544.809631,548.706421 541.373718,547.885193 535.241333,552.774536 
	C535.662292,550.855347 536.428833,548.818970 537.571899,546.694336 
	C540.349915,546.994141 542.751282,547.382141 545.577209,547.660767 
	C546.985352,549.127136 547.968994,550.702820 548.995117,552.645752 
	C549.046692,553.731445 549.055908,554.449890 549.077942,555.526611 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M554.078735,569.465698 
	C553.120972,567.463562 552.148071,565.067871 551.218079,562.302734 
	C552.195190,564.312927 553.129395,566.692505 554.078735,569.465698 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M557.022705,576.656372 
	C556.714844,576.488342 556.387878,576.031494 556.082153,575.274841 
	C556.403442,575.439209 556.703491,575.903381 557.022705,576.656372 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M550.024170,557.652832 
	C549.724792,557.479004 549.401794,557.019836 549.107666,556.260864 
	C549.424622,556.429871 549.712585,556.898621 550.024170,557.652832 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M555.826294,572.790039 
	C555.573242,572.903992 555.346741,572.703918 555.151855,572.187317 
	C555.407349,572.073669 555.630554,572.275391 555.826294,572.790039 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M520.126526,435.324951 
	C520.262573,435.567017 520.339417,436.100250 520.191956,436.714813 
	C520.000916,436.402771 520.034119,436.009460 520.126526,435.324951 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M518.147034,228.049377 
	C518.450500,227.954880 518.685852,227.772461 519.292542,227.234192 
	C520.915894,225.328918 522.099670,223.691574 523.511597,222.014862 
	C524.045044,221.912048 524.352966,221.865814 524.663452,221.836777 
	C524.406128,223.284302 524.148743,224.731842 523.879944,226.562119 
	C523.554749,227.041489 523.304199,227.224518 522.697510,227.720352 
	C521.126282,229.267487 519.974365,230.588211 518.822388,231.908951 
	C517.951355,232.743027 517.080322,233.577103 515.755981,234.678909 
	C514.852356,234.942001 514.401978,234.937347 513.972534,234.589447 
	C515.377991,232.180603 516.762512,230.114990 518.147034,228.049377 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M519.198730,231.941254 
	C519.974365,230.588211 521.126282,229.267487 522.620178,227.943634 
	C521.833130,229.284866 520.704102,230.629211 519.198730,231.941254 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M513.951599,234.932678 
	C514.401978,234.937347 514.852356,234.942001 515.641724,234.961548 
	C515.574036,235.638123 515.167419,236.299805 514.462097,237.066757 
	C514.163391,237.172012 514.003723,237.007660 513.940308,236.610138 
	C513.895081,235.790787 513.913269,235.368927 513.945190,234.958939 
	C513.958862,234.970795 513.951599,234.932678 513.951599,234.932678 
z"/>
<path fill="#E90F25" opacity="1.000000" stroke="none" 
	d="
M535.394287,505.262085 
	C535.916016,505.571655 536.116760,505.786255 536.506470,506.122803 
	C537.387512,507.336456 538.098877,508.410004 539.126465,509.992615 
	C539.955261,510.659790 540.467834,510.817902 540.980408,510.975983 
	C540.980408,510.975983 541.426086,511.069153 541.650635,511.097839 
	C541.875244,511.126526 541.955383,511.565491 542.030029,511.775818 
	C543.129700,513.428101 544.154724,514.870117 545.285339,516.663818 
	C541.478210,516.479858 537.578674,515.794556 533.649292,515.448486 
	C529.322388,515.067444 524.968018,514.997986 519.937500,514.760132 
	C519.660767,511.282990 519.323364,507.044128 518.926636,502.342529 
	C518.807129,498.811096 518.747009,495.742462 518.428955,492.385071 
	C518.192078,491.339813 518.213135,490.583282 518.279541,489.453033 
	C519.715271,489.971466 521.330322,490.643890 522.456238,491.794739 
	C526.741089,496.174530 530.867981,500.708771 535.394287,505.262085 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M590.736694,494.935791 
	C590.692383,494.337982 590.935059,493.641541 591.448975,492.795319 
	C591.488037,493.376068 591.255859,494.106598 590.736694,494.935791 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M211.527985,653.531372 
	C210.710251,652.263611 210.346497,651.108826 209.970123,649.649902 
	C210.046143,626.100220 210.145721,602.854736 210.217545,579.609131 
	C210.243195,571.305847 210.201660,563.002258 210.320847,554.016968 
	C210.210037,552.499939 209.969193,551.664917 209.728333,550.829834 
	C219.292694,550.766113 228.857071,550.702454 238.825424,550.820801 
	C238.841721,553.108582 238.121643,555.213623 238.116470,557.320374 
	C238.045288,586.409973 238.066360,615.499756 238.095093,644.589539 
	C238.096390,645.896606 238.385468,647.203369 238.827362,648.838989 
	C238.782684,650.404114 238.451157,651.640442 238.034668,653.173584 
	C229.293793,653.528259 220.637863,653.586304 211.527985,653.531372 
M212.976028,610.500000 
	C212.977448,622.499939 212.892670,634.501282 213.089233,646.498047 
	C213.113846,648.000244 214.556213,650.719727 215.457153,650.769165 
	C222.128387,651.135010 228.828888,650.967285 235.365356,650.967285 
	C235.706299,649.788513 235.959198,649.321594 235.959671,648.854431 
	C235.991043,617.688354 235.981308,586.522156 236.051682,555.356201 
	C236.057755,552.663208 234.895309,551.897644 232.432449,551.948242 
	C227.601181,552.047668 222.749939,552.227173 217.938309,551.905151 
	C213.917297,551.635986 212.853790,553.085938 212.896698,557.001282 
	C213.088409,574.499023 212.977554,592.000122 212.976028,610.500000 
z"/>
<path fill="#F60B23" opacity="1.000000" stroke="none" 
	d="
M471.948761,517.956177 
	C470.945587,517.549316 470.452026,517.154846 469.800507,516.566895 
	C470.060150,514.304749 470.835693,512.237122 470.841492,510.167389 
	C470.922699,481.114868 470.918304,452.062073 470.860901,423.009460 
	C470.856720,420.894867 470.283203,418.781403 469.805176,416.260345 
	C478.009521,415.530914 486.382080,415.173676 494.758057,414.938354 
	C495.626434,414.913910 496.515106,415.613892 497.668945,416.190918 
	C497.986450,448.994659 498.029327,481.585327 498.188354,514.649841 
	C496.645538,516.078125 495.019775,517.780701 493.321838,517.856018 
	C486.379333,518.163818 479.414795,517.974731 471.948761,517.956177 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M533.534912,556.094604 
	C532.497314,559.569519 531.178772,563.244995 529.531311,567.015137 
	C530.552917,563.504822 531.903442,559.900024 533.534912,556.094604 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M534.645020,553.124512 
	C534.750610,553.513245 534.628784,554.081543 534.247498,554.801880 
	C534.131226,554.403931 534.274414,553.854004 534.645020,553.124512 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M224.514130,193.653702 
	C224.631348,199.869522 219.897583,204.011597 217.207336,209.132492 
	C212.449463,218.189117 208.026031,227.309601 206.966751,237.688370 
	C206.346542,243.765137 205.145905,249.808807 204.925125,255.893448 
	C204.663239,263.110901 204.126205,270.577179 205.593155,277.538483 
	C207.852036,288.257812 210.928543,298.964630 215.113037,309.074615 
	C219.520828,319.724091 225.898026,329.594543 234.306961,337.655579 
	C242.023193,345.052521 249.804291,352.703094 258.707489,358.463379 
	C271.282043,366.598969 285.533417,371.346924 300.246338,374.693268 
	C322.938080,379.854218 345.930206,380.876556 368.990265,379.805450 
	C386.487640,378.992767 403.845825,376.427063 420.709778,371.285309 
	C421.575134,371.021484 422.628052,371.372833 423.854828,371.725098 
	C419.839203,373.610474 415.562408,375.208527 411.110229,377.154724 
	C410.616577,377.584656 410.293213,377.630676 409.319092,377.466370 
	C406.062531,378.090454 403.451630,378.889038 400.840729,379.687622 
	C400.431732,379.763947 400.022766,379.840210 399.033691,379.637329 
	C393.867859,380.127625 389.282074,380.897095 384.696320,381.666565 
	C384.696320,381.666565 384.236023,381.724335 384.236023,381.724335 
	C384.236023,381.724335 383.772430,381.707001 383.550232,381.648956 
	C375.693848,381.957336 368.059723,382.323730 360.174744,383.043030 
	C355.275909,383.408508 350.627869,383.421021 345.518311,383.404297 
	C341.235901,383.023041 337.415039,382.671051 333.302124,382.604614 
	C332.577759,382.894104 332.145416,382.898071 331.156311,382.612274 
	C329.955627,382.391235 329.311798,382.459930 328.667938,382.528625 
	C328.312347,382.526398 327.956757,382.524170 327.336365,382.492401 
	C325.243927,382.067474 323.416229,381.672089 321.322205,381.536255 
	C319.929962,381.708923 318.804108,381.622131 317.133484,381.119629 
	C315.235535,380.684265 313.882355,380.664612 312.529144,380.644958 
	C311.902985,380.587524 311.276855,380.530090 310.134155,380.079407 
	C305.750946,379.059509 301.884308,378.432831 298.017639,377.806183 
	C297.612457,377.756348 297.207275,377.706512 296.353729,377.251373 
	C289.850586,374.722961 283.812347,372.550446 277.730011,370.509430 
	C276.085846,369.957733 274.308014,369.804352 272.591553,369.468140 
	C272.591553,369.468140 272.203979,369.405243 271.916321,368.984253 
	C270.610565,368.222443 269.592468,367.881622 268.574371,367.540771 
	C268.235168,367.408264 267.895935,367.275757 267.271759,366.656189 
	C264.841736,365.301361 262.696686,364.433624 260.551605,363.565887 
	C260.551605,363.565887 260.159241,363.456268 259.981842,363.356567 
	C257.364410,361.564453 254.924362,359.872101 252.127640,358.267639 
	C251.770950,358.355530 251.728256,358.335266 251.706482,357.966492 
	C250.715652,357.157867 249.746613,356.717987 248.777588,356.278076 
	C248.777588,356.278076 248.959457,356.083862 248.838318,355.819702 
	C246.700470,354.136078 244.683746,352.716644 242.667007,351.297211 
	C242.667007,351.297211 242.351166,351.138824 242.194427,350.682861 
	C237.236084,345.639435 232.434464,341.051971 227.632843,336.464539 
	C227.632843,336.464539 227.604584,336.376129 227.703278,336.010376 
	C226.867081,334.757660 225.932205,333.870728 224.997314,332.983765 
	C224.997314,332.983765 224.777573,332.661987 224.706116,332.481140 
	C222.366791,328.180939 220.182144,324.013092 217.815964,319.950989 
	C209.639160,305.913544 204.033905,291.037872 202.769531,274.705750 
	C202.733780,274.244049 202.401199,273.805328 201.835236,273.150391 
	C201.420715,272.325928 201.377991,271.706970 201.553024,270.318512 
	C202.136002,262.444122 202.566528,255.341507 202.819290,248.232574 
	C202.880554,246.509384 202.355606,244.765366 202.100220,243.030914 
	C202.119308,242.603836 202.138397,242.176773 202.265594,241.446884 
	C202.915680,240.059448 203.718979,239.029724 203.960144,237.881912 
	C207.165680,222.624771 213.274826,208.628357 222.086166,195.796906 
	C222.624390,195.013153 223.468567,194.439514 224.514130,193.653702 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M456.155914,289.175201 
	C455.634003,289.766449 455.112061,290.357697 454.271423,290.963745 
	C453.982422,290.537018 454.012146,290.095459 454.013123,289.322510 
	C453.984375,288.991089 454.000122,288.998718 454.303864,289.024536 
	C455.009766,288.958374 455.411896,288.866425 455.899475,288.874664 
	C455.984924,288.974854 456.155914,289.175201 456.155914,289.175201 
z"/>
<path fill="#E81329" opacity="1.000000" stroke="none" 
	d="
M155.951599,609.894043 
	C155.634857,610.123657 155.137985,610.128052 154.267303,610.132690 
	C154.519470,609.978210 155.145477,609.823547 155.951599,609.894043 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M385.063660,381.851562 
	C389.282074,380.897095 393.867859,380.127625 398.720001,379.628052 
	C394.467926,380.610809 389.949432,381.323700 385.063660,381.851562 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M401.235199,379.797607 
	C403.451630,378.889038 406.062531,378.090454 409.073792,377.471802 
	C406.859283,378.403687 404.244476,379.155640 401.235199,379.797607 
z"/>
<path fill="#E90F25" opacity="1.000000" stroke="none" 
	d="
M281.715393,463.096741 
	C282.101471,463.575745 282.113617,463.900879 282.097351,464.835571 
	C282.019836,465.926788 281.986115,466.407928 281.952362,466.889038 
	C281.549316,467.924164 280.795624,468.959351 280.795868,469.994385 
	C280.799133,485.035889 280.903412,500.077393 281.002319,515.869873 
	C280.431854,517.094299 279.842560,517.567749 279.253235,518.041199 
	C277.681976,518.044250 276.110687,518.047363 274.018463,518.075500 
	C273.497528,518.100586 273.383850,518.137634 273.383850,518.137634 
	C267.149933,518.095459 260.915985,518.053223 254.175537,518.045044 
	C252.964111,517.311340 252.259232,516.543518 251.783691,515.016113 
	C252.058899,488.316620 252.130264,462.376709 252.133087,436.436798 
	C252.133804,429.792053 251.917999,423.147278 251.801666,416.502502 
	C252.237152,416.052307 252.678192,415.204498 253.107330,415.210510 
	C262.157227,415.336578 271.205719,415.558807 280.676270,415.824524 
	C281.034058,416.039490 280.915009,416.191742 280.913879,416.344879 
	C280.802338,431.658875 280.696655,446.972931 280.626953,462.287140 
	C280.625946,462.504822 281.082855,462.724640 281.715393,463.096741 
M255.039871,436.864471 
	C255.026184,447.178802 255.005890,457.493164 255.000443,467.807495 
	C254.993607,480.783875 254.966705,493.760345 255.012192,506.736542 
	C255.035950,513.513367 256.540649,514.998169 263.039185,515.004333 
	C265.867371,515.007019 268.695587,514.991150 271.523682,515.006653 
	C275.921051,515.030762 278.289368,513.377686 278.058289,508.459015 
	C277.537140,497.364014 277.361969,486.253021 277.007111,475.149780 
	C276.829712,469.597687 278.665955,464.277771 277.377319,458.434814 
	C276.253815,453.340698 277.952423,447.680817 277.932648,442.272614 
	C277.906921,435.242523 276.841888,428.172638 277.346832,421.201202 
	C277.631866,417.266479 276.277313,416.829681 273.413666,417.031189 
	C267.833435,417.423798 262.263306,417.960358 256.528809,418.451050 
	C256.349762,419.122101 256.075775,419.731964 256.038055,420.356140 
	C255.724243,425.551910 255.453995,430.750305 255.039871,436.864471 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M279.526123,518.264282 
	C279.842560,517.567749 280.431854,517.094299 281.122589,516.328491 
	C281.602631,515.454224 281.981262,514.872375 282.595520,514.166260 
	C284.671814,514.325989 286.512390,514.609985 288.730225,515.057617 
	C287.522766,520.636719 283.177185,518.183472 279.526123,518.264282 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M288.198425,466.179749 
	C290.075134,465.839905 292.239594,465.862793 294.400970,465.792725 
	C298.444183,465.661621 302.485596,465.476532 306.806946,465.596008 
	C300.885651,466.085541 294.685211,466.293030 288.198425,466.179749 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M310.589661,512.975159 
	C310.663666,516.029419 310.991180,518.637817 315.562073,518.044800 
	C313.167969,518.268616 310.362915,518.421021 307.269653,518.305481 
	C307.373688,516.980591 307.765869,515.923767 308.098877,514.512207 
	C308.087830,513.921875 308.135925,513.686279 308.396057,513.158875 
	C309.174316,512.907166 309.740601,512.947144 310.589661,512.975159 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M282.276062,466.902954 
	C281.986115,466.407928 282.019836,465.926788 282.103180,465.080933 
	C282.695801,464.931274 283.238800,465.146332 283.940308,465.611267 
	C283.599091,466.213074 283.099426,466.564972 282.276062,466.902954 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M310.604645,476.223328 
	C310.873657,476.766357 310.931610,477.505798 310.913574,478.610229 
	C310.689606,478.123383 310.541595,477.271576 310.604645,476.223328 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M310.644287,471.207092 
	C310.900513,471.760010 310.936188,472.502441 310.890320,473.609009 
	C310.680420,473.114288 310.552094,472.255432 310.644287,471.207092 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M309.209290,467.161530 
	C309.454895,466.999481 309.700500,467.159851 309.960571,467.629669 
	C309.707611,467.817902 309.452332,467.666168 309.209290,467.161530 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M201.776169,243.284637 
	C202.355606,244.765366 202.880554,246.509384 202.819290,248.232574 
	C202.566528,255.341507 202.136002,262.444122 201.542664,270.008362 
	C200.909821,267.596619 200.100616,264.713745 200.183838,261.856873 
	C200.361923,255.743561 201.001526,249.643723 201.776169,243.284637 
z"/>
<path fill="#EE0F25" opacity="1.000000" stroke="none" 
	d="
M473.480896,555.079956 
	C475.650970,556.751221 477.438110,558.383057 479.579254,560.040894 
	C479.933289,560.066895 479.944427,560.034729 479.975586,560.331482 
	C480.354187,561.053406 480.701599,561.478638 481.049011,561.903809 
	C481.049011,561.903809 481.052246,561.960815 481.048706,562.308960 
	C481.352905,563.423523 481.660706,564.190002 481.968506,564.956482 
	C483.941559,574.717468 484.392456,584.184753 475.387909,591.069885 
	C472.415100,593.343018 468.901978,594.909546 464.958374,596.903931 
	C463.261841,597.471313 462.242859,597.933899 459.757904,599.062134 
	C462.534485,599.963074 463.789703,600.370361 465.044922,600.777649 
	C465.773651,600.847046 466.502411,600.916443 467.594177,600.935059 
	C472.661499,603.503662 477.538177,605.860413 482.026825,608.808044 
	C487.771942,612.580872 488.474091,618.882446 488.422913,624.950500 
	C488.322723,636.829163 481.819885,644.304077 471.044067,648.545654 
	C469.865479,649.009521 469.013947,650.304321 467.972290,651.580627 
	C465.264404,651.940491 462.594940,651.933411 459.129181,651.931885 
	C457.726898,652.255859 457.120911,652.574402 456.514923,652.892883 
	C455.395935,652.832275 454.276947,652.771729 452.666504,652.473694 
	C451.911743,651.846069 451.648376,651.455811 451.385071,651.065613 
	C451.783905,650.181946 452.182709,649.298279 453.119965,648.159424 
	C453.801483,647.271057 453.944519,646.637939 454.087585,646.004761 
	C454.087585,646.004761 453.726929,645.741211 453.492493,645.532959 
	C453.258026,645.324646 453.474945,645.148987 453.474945,645.148987 
	C453.474945,645.148987 453.754883,645.153931 454.136414,645.151550 
	C455.380219,644.128418 456.242493,643.107666 457.102539,642.071411 
	C457.100281,642.055908 457.070129,642.063782 457.318909,641.900513 
	C457.920380,640.839233 458.273041,639.941162 458.738068,638.677002 
	C458.894348,637.548767 458.938293,636.786682 459.215027,635.797241 
	C459.343750,634.347839 459.239685,633.125793 459.201599,631.545654 
	C459.426208,629.754700 459.584839,628.321777 459.826935,626.496216 
	C459.863708,624.768005 459.816986,623.432495 459.805725,621.736755 
	C459.601013,619.952148 459.360901,618.527832 459.293335,616.910889 
	C459.315887,616.128723 459.165863,615.539124 459.033264,614.574829 
	C458.930847,613.463013 458.811005,612.725952 458.731323,611.668152 
	C458.220306,610.727234 457.669128,610.106995 457.190094,609.400879 
	C457.262238,609.315063 457.157349,609.116882 457.121643,608.754944 
	C456.413910,607.580322 455.741882,606.767700 455.055725,605.972778 
	C455.041595,605.990540 455.036499,605.945557 454.978729,605.694458 
	C454.632080,605.283447 454.343201,605.123474 454.032593,604.960083 
	C454.010864,604.956543 454.045441,604.929077 453.995728,604.590942 
	C452.983368,603.816711 452.020721,603.380676 451.048828,602.966370 
	C451.039581,602.988098 451.028870,602.942810 450.944427,602.701111 
	C450.559570,602.314087 450.259186,602.168701 449.662964,601.845093 
	C448.267883,601.413818 447.168640,601.160889 446.027527,600.542908 
	C447.100128,598.901489 447.986694,597.234802 449.373627,596.425293 
	C452.871460,594.383484 454.800018,591.426392 454.892212,587.545593 
	C455.088257,579.295288 455.516357,570.990417 454.772247,562.805847 
	C454.449707,559.257996 451.465454,555.952209 449.690216,552.536499 
	C451.765533,552.045593 453.955444,550.868591 455.894592,551.192566 
	C461.681793,552.159363 467.370667,553.714966 473.480896,555.079956 
z"/>
<path fill="#EE0F25" opacity="1.000000" stroke="none" 
	d="
M410.079285,653.589233 
	C409.475525,651.292664 409.107452,649.015869 409.104309,646.738586 
	C409.064636,617.797485 409.077362,588.856384 409.032288,559.445435 
	C409.013428,558.543518 409.039062,558.111450 409.074463,556.919189 
	C408.962921,554.407776 408.841553,552.656616 408.720215,550.905396 
	C417.933899,550.863098 427.147583,550.820801 436.721008,551.318848 
	C437.390106,568.253113 437.699463,584.647095 437.929260,601.289551 
	C437.820892,601.881775 437.792114,602.225586 437.508972,602.935547 
	C437.198547,618.755981 437.157684,634.210327 437.060608,649.664246 
	C437.051788,651.068542 436.682068,652.470642 436.479919,653.873718 
	C427.814819,653.785400 419.149719,653.697144 410.079285,653.589233 
M431.213257,553.006897 
	C425.326416,553.006897 419.439606,553.006897 413.192932,553.006897 
	C412.828491,555.575134 412.062286,558.500305 412.052490,561.427979 
	C411.959930,589.076172 412.085297,616.725403 411.898132,644.372620 
	C411.870483,648.453064 413.763763,649.771423 417.094574,649.926636 
	C421.415924,650.127991 425.753204,650.010986 430.083435,649.992310 
	C433.835480,649.976135 434.798035,647.700684 434.684814,644.397644 
	C434.125549,628.076782 433.347931,611.756958 433.180847,595.431274 
	C433.050079,582.654663 433.756653,569.870728 433.915588,557.087830 
	C433.932159,555.755371 432.756683,554.407959 431.213257,553.006897 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M438.008820,601.041016 
	C437.699463,584.647095 437.390106,568.253113 437.145447,551.382935 
	C441.171143,551.348206 445.132141,551.789734 449.391663,552.383850 
	C451.465454,555.952209 454.449707,559.257996 454.772247,562.805847 
	C455.516357,570.990417 455.088257,579.295288 454.892212,587.545593 
	C454.800018,591.426392 452.871460,594.383484 449.373627,596.425293 
	C447.986694,597.234802 447.100128,598.901489 445.776855,600.774780 
	C443.048279,601.261536 440.528534,601.151306 438.008820,601.041016 
M439.278687,593.207031 
	C438.643280,596.760071 440.485748,597.552063 443.555634,597.143311 
	C447.985382,596.553467 451.824524,592.882629 452.196075,587.002197 
	C452.717865,578.742981 452.410553,570.417236 452.120300,562.131714 
	C452.056854,560.320312 451.034973,557.755432 449.638855,556.948181 
	C447.055634,555.454468 443.869751,555.003113 440.934845,554.117554 
	C440.381958,556.181519 439.382690,558.238708 439.351166,560.310608 
	C439.189148,570.961182 439.275848,581.615601 439.278687,593.207031 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M408.379028,550.910339 
	C408.841553,552.656616 408.962921,554.407776 409.045715,556.595215 
	C408.030609,555.229248 407.054016,553.427063 406.019348,551.270752 
	C406.653503,550.916138 407.345703,550.915710 408.379028,550.910339 
z"/>
<path fill="#FC0821" opacity="1.000000" stroke="none" 
	d="
M385.873230,623.619507 
	C385.254395,624.250427 384.479858,624.423340 384.189575,624.894531 
	C381.070343,629.958496 378.027435,635.069519 374.916016,640.517822 
	C372.726959,642.019470 370.605438,643.210144 368.439819,644.314270 
	C364.387970,646.380066 360.310516,648.395630 355.952332,650.578064 
	C357.952515,643.121216 359.767120,636.638977 361.395233,630.110168 
	C361.869354,628.208984 361.966400,626.175293 361.971802,624.201660 
	C362.014618,608.562927 362.467590,592.903015 361.774231,577.294861 
	C361.468170,570.404236 359.166901,563.498596 357.011932,556.845825 
	C356.177246,554.269043 356.113831,553.300659 358.783875,552.251709 
	C359.557281,552.151123 360.121399,552.328003 361.002838,553.052368 
	C362.117157,554.176147 362.843018,554.910889 363.721710,555.304993 
	C378.005310,561.711853 384.677277,574.034546 386.533417,588.353027 
	C387.844635,598.468079 385.837280,609.010010 385.376434,619.364258 
	C385.337860,620.230042 385.794342,621.117920 386.020813,621.995544 
	C385.994659,622.430359 385.968506,622.865173 385.873230,623.619507 
z"/>
<path fill="#69191E" opacity="1.000000" stroke="none" 
	d="
M348.335510,551.019653 
	C357.570190,557.043701 359.627258,566.279297 359.860138,575.892212 
	C360.255188,592.196289 360.133850,608.518311 359.913788,624.828491 
	C359.854004,629.259033 358.773926,633.696045 357.912323,638.081055 
	C357.697601,639.173950 356.512115,640.076111 355.269714,640.671387 
	C355.756927,629.716003 357.475494,619.160828 357.557709,608.592957 
	C357.666260,594.637329 356.695557,580.658203 355.769501,566.717041 
	C355.395813,561.091309 352.475128,556.401489 346.761902,554.616760 
	C336.961456,551.555298 326.596771,554.259827 324.968262,569.136841 
	C323.667786,581.016663 323.554810,593.026428 322.640015,604.670166 
	C321.957794,598.935974 321.550293,593.510071 321.142761,588.084229 
	C321.717010,580.890686 322.141144,573.680969 322.911560,566.508484 
	C323.563324,560.440613 326.590546,555.659607 331.996063,552.033264 
	C337.433716,551.484863 342.663757,551.241882 348.335510,551.019653 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M386.306824,621.754395 
	C385.794342,621.117920 385.337860,620.230042 385.376434,619.364258 
	C385.837280,609.010010 387.844635,598.468079 386.533417,588.353027 
	C384.677277,574.034546 378.005310,561.711853 363.721710,555.304993 
	C362.843018,554.910889 362.117157,554.176147 361.196960,553.280762 
	C365.427765,554.680664 369.781738,556.399841 374.480225,558.231506 
	C375.581940,559.266113 376.339172,560.188171 377.424683,561.182129 
	C377.943420,561.716675 378.133881,562.179443 378.514435,562.800171 
	C379.901398,564.887024 381.098297,566.815857 382.583252,568.921265 
	C388.360413,581.658020 390.029358,594.844421 388.940155,608.386414 
	C388.585449,612.796387 387.398895,617.139526 386.306824,621.754395 
z"/>
<path fill="#F40C24" opacity="1.000000" stroke="none" 
	d="
M363.394684,425.581482 
	C363.570312,422.334290 363.725372,419.544769 363.840027,416.304077 
	C373.096527,415.947479 382.393463,416.042023 391.701416,416.707245 
	C391.523773,418.070892 391.173279,418.863312 391.170593,419.656952 
	C391.126495,432.716003 391.075684,445.775665 391.183624,458.833862 
	C391.196289,460.369019 392.136383,461.896576 392.655334,463.587402 
	C392.864594,464.025879 393.065094,464.304535 393.265594,464.583221 
	C392.543274,466.349091 391.215210,468.107849 391.194397,469.881958 
	C391.027649,484.075745 391.094147,498.272522 391.144653,512.468140 
	C391.148010,513.411499 391.605286,514.353333 392.112732,515.644531 
	C392.006775,516.484924 391.639496,516.976685 391.140839,517.742737 
	C390.562500,518.006287 390.115570,517.995483 388.963562,517.965698 
	C387.506989,517.995667 386.755493,518.044678 386.003967,518.093689 
	C379.655121,518.056885 373.306244,518.020081 366.494751,518.035522 
	C365.351410,516.822449 364.242126,515.610168 364.077484,514.280884 
	C363.712097,511.330933 363.820465,508.322296 363.521881,504.948242 
	C363.330139,478.385620 363.352142,452.212372 363.394684,425.581482 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M392.646576,463.427643 
	C392.136383,461.896576 391.196289,460.369019 391.183624,458.833862 
	C391.075684,445.775665 391.126495,432.716003 391.170593,419.656952 
	C391.173279,418.863312 391.523773,418.070892 392.028076,416.931763 
	C396.841858,416.384735 401.340027,416.183807 406.227295,415.993347 
	C417.351593,425.657166 415.265900,438.081207 413.519897,450.151520 
	C413.041992,453.455444 410.154755,456.410858 408.110107,459.286316 
	C407.842224,458.301819 407.837341,457.557159 408.000916,456.496948 
	C412.844543,449.349426 411.998444,441.527191 411.618164,434.011932 
	C410.911835,420.052124 407.276520,419.592194 394.233978,419.676758 
	C394.233978,432.405121 394.233978,445.134888 394.152771,458.193146 
	C394.135681,458.737915 394.199829,458.954224 394.292633,459.416260 
	C394.364655,459.988861 394.401520,460.316437 394.307556,460.973938 
	C393.670990,462.011261 393.158783,462.719452 392.646576,463.427643 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M600.113770,551.268860 
	C600.240906,551.150879 600.428772,551.224609 600.651001,551.501648 
	C600.504639,551.632874 600.334351,551.551392 600.113770,551.268860 
z"/>
<path fill="#FC0821" opacity="1.000000" stroke="none" 
	d="
M279.589478,647.109131 
	C279.601990,647.793518 279.614502,648.477905 279.175018,649.558716 
	C272.663177,650.420349 266.603363,650.885620 260.543518,651.350891 
	C260.384705,650.749878 260.225922,650.148804 260.067108,649.547729 
	C261.536102,648.445374 263.291260,647.575500 264.414856,646.192505 
	C266.786926,643.272705 268.828705,640.084534 271.006012,637.006470 
	C271.006012,637.006470 271.000000,637.000000 271.332550,636.965698 
	C272.079834,636.255249 272.494568,635.579163 272.909302,634.903015 
	C273.434113,633.727112 273.958923,632.551270 275.118256,631.157593 
	C277.806305,630.061707 279.059570,630.156921 279.078247,632.967102 
	C279.095978,635.639282 279.585602,638.308228 279.867737,640.978638 
	C279.872070,641.412659 279.876404,641.846619 279.536743,642.689575 
	C279.324982,644.435364 279.457245,645.772278 279.589478,647.109131 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M270.695374,637.134460 
	C268.828705,640.084534 266.786926,643.272705 264.414856,646.192505 
	C263.291260,647.575500 261.536102,648.445374 260.067108,649.547729 
	C260.225922,650.148804 260.384705,650.749878 260.543518,651.350891 
	C266.603363,650.885620 272.663177,650.420349 279.087128,649.891846 
	C279.564972,650.238525 279.678741,650.648499 279.773315,651.374268 
	C276.934082,652.373108 274.133820,653.524048 271.289978,653.644287 
	C264.873688,653.915588 258.435852,653.677124 251.555054,653.537964 
	C251.776184,652.256592 252.209351,650.333130 253.160004,650.029419 
	C260.398743,647.716736 265.067139,642.074341 270.695374,637.134460 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M280.178955,640.705383 
	C279.585602,638.308228 279.095978,635.639282 279.078247,632.967102 
	C279.059570,630.156921 277.806305,630.061707 275.379456,630.968262 
	C276.777069,627.518982 276.369598,622.299316 282.542908,622.094238 
	C282.759430,623.341736 282.600098,624.536194 282.679871,625.955200 
	C282.917084,626.552185 282.915161,626.924561 282.914154,627.575317 
	C282.267242,629.640564 281.365479,631.381958 281.035217,633.225708 
	C280.612671,635.584717 280.650635,638.026184 280.178955,640.705383 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M272.596405,634.967957 
	C272.494568,635.579163 272.079834,636.255249 271.336487,636.968628 
	C271.433075,636.348145 271.858307,635.690552 272.596405,634.967957 
z"/>
<path fill="#F40C24" opacity="1.000000" stroke="none" 
	d="
M225.071167,494.284546 
	C226.084641,495.038116 227.800552,495.964813 227.874802,497.008331 
	C228.180893,501.309143 228.000397,505.645477 227.967316,509.968536 
	C227.962296,510.623901 227.972290,511.383057 227.664459,511.913971 
	C226.529190,513.872009 214.596756,513.784241 213.052155,511.464783 
	C213.531708,510.473480 214.021240,509.877106 215.094910,509.111633 
	C216.123169,508.299347 216.567291,507.656158 217.011414,507.013000 
	C217.011414,507.013000 217.000610,507.000000 217.359940,506.972107 
	C219.141495,504.626099 220.563721,502.307953 221.985931,499.989807 
	C221.985931,499.989807 222.000000,500.000000 222.340302,499.950073 
	C223.118896,498.934479 223.557205,497.968811 223.995499,497.003174 
	C223.995499,497.003174 223.999390,496.999847 224.254700,496.835999 
	C224.665771,496.106506 224.821548,495.540894 224.977325,494.975281 
	C224.977310,494.975281 225.018738,494.511414 225.071167,494.284546 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M221.682190,500.132874 
	C220.563721,502.307953 219.141495,504.626099 217.367401,506.977936 
	C218.469818,504.766418 219.924118,502.521179 221.682190,500.132874 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M223.700592,497.131714 
	C223.557205,497.968811 223.118896,498.934479 222.333374,499.943237 
	C222.459320,499.077637 222.932495,498.168915 223.700592,497.131714 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M216.735214,507.117157 
	C216.567291,507.656158 216.123169,508.299347 215.361237,508.986206 
	C215.515289,508.427063 215.987152,507.824188 216.735214,507.117157 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M224.727448,495.141357 
	C224.821548,495.540894 224.665771,496.106506 224.255707,496.833374 
	C224.160110,496.432251 224.318832,495.869873 224.727448,495.141357 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M453.754883,645.153931 
	C453.754883,645.153931 453.474945,645.148987 453.474945,645.148987 
	C453.474945,645.148987 453.258026,645.324646 453.283295,645.454712 
	C449.429413,648.980347 445.198883,651.059875 439.526489,649.032288 
	C439.146362,648.901001 439.218201,648.908508 439.270752,648.448608 
	C439.323303,633.173950 439.323303,618.359192 439.323303,603.611877 
	C451.983765,603.188904 456.170685,608.606140 456.403900,615.356689 
	C456.710144,624.220398 455.748138,633.127991 455.107727,642.295044 
	C454.507568,643.433655 454.131226,644.293762 453.754883,645.153931 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M436.879883,653.903809 
	C436.682068,652.470642 437.051788,651.068542 437.060608,649.664246 
	C437.157684,634.210327 437.198547,618.755981 437.594299,603.441833 
	C437.964294,617.018494 437.953522,630.455383 438.077271,643.891113 
	C438.092743,645.566956 438.820496,647.236206 439.218201,648.908508 
	C439.218201,648.908508 439.146362,648.901001 439.179199,648.884460 
	C437.588928,650.183167 442.182159,653.869385 436.879883,653.903809 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M451.094299,651.071777 
	C451.648376,651.455811 451.911743,651.846069 452.337463,652.552368 
	C451.496033,652.623718 450.492279,652.378967 449.488495,652.134155 
	C449.926849,651.782104 450.365204,651.429993 451.094299,651.071777 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M453.963806,646.244324 
	C453.944519,646.637939 453.801483,647.271057 453.377075,647.900818 
	C453.343842,647.426208 453.591949,646.955078 453.963806,646.244324 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M456.876190,652.910034 
	C457.120911,652.574402 457.726898,652.255859 458.684509,651.965454 
	C458.436615,652.304749 457.837036,652.615967 456.876190,652.910034 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M444.546539,652.484741 
	C444.573578,652.533630 444.519501,652.435913 444.546539,652.484741 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M179.650238,416.046204 
	C175.511627,418.339050 170.987869,420.612976 166.065948,422.845184 
	C170.200211,420.544739 174.732635,418.286011 179.650238,416.046204 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M164.649399,422.906830 
	C163.923843,423.953400 162.848740,424.987000 161.412003,426.028503 
	C161.328690,425.194244 161.606995,424.352142 162.054367,423.207581 
	C162.915588,422.901367 163.607712,422.897583 164.649399,422.906830 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M209.489883,550.850708 
	C209.969193,551.664917 210.210037,552.499939 210.332703,553.531799 
	C209.551193,553.211914 208.887894,552.695251 208.124878,551.859192 
	C208.433914,551.317017 208.842667,551.094299 209.489883,550.850708 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M481.054047,209.454987 
	C493.608551,207.600555 506.091705,206.077866 518.814392,205.208801 
	C517.704590,207.595139 516.355225,209.327881 514.991821,210.657410 
	C514.870850,206.762390 512.501282,206.831833 510.037018,207.169632 
	C505.788574,207.752045 501.566681,208.646790 497.300690,208.956741 
	C489.379120,209.532333 482.952271,211.863312 476.932922,217.971329 
	C470.134094,224.870270 461.264587,229.737350 453.250610,235.424667 
	C450.838623,237.136398 448.276428,238.636490 445.783020,240.233536 
	C445.924713,240.604584 446.066406,240.975632 446.208069,241.346680 
	C449.818970,241.886734 453.429901,242.426773 456.779724,243.268951 
	C451.862091,244.033173 447.205566,244.495285 442.549042,244.957382 
	C441.020782,244.266953 439.492554,243.576538 437.964294,242.886108 
	C437.848175,242.281296 437.732086,241.676498 437.615967,241.071686 
	C442.531677,238.399765 447.444031,235.721603 452.363831,233.057190 
	C463.366364,227.098450 472.646576,219.023621 481.054047,209.454987 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M442.169739,245.224396 
	C447.205566,244.495285 451.862091,244.033173 457.085083,243.267670 
	C458.059113,242.940979 458.466705,242.917679 459.299835,242.945572 
	C465.487122,241.023071 471.248901,239.049377 477.328003,237.043396 
	C478.067688,236.960632 478.490021,236.910172 479.280029,236.878326 
	C482.813721,234.970306 485.979645,233.043686 489.348450,231.091965 
	C489.551270,231.066864 489.953186,230.992188 490.278687,230.971832 
	C491.115326,230.341278 491.626465,229.731079 492.337769,229.095474 
	C492.537933,229.070068 492.934998,228.998077 493.244568,229.002747 
	C494.044159,228.687393 494.534210,228.367371 495.309906,228.010086 
	C496.045837,227.649597 496.496124,227.326385 497.254700,226.882050 
	C499.060425,225.175552 500.557831,223.590149 502.295197,221.888794 
	C502.985413,221.487900 503.435669,221.202957 504.229248,220.926331 
	C505.708679,219.932434 506.844818,218.930252 508.244110,217.734314 
	C510.673492,215.380554 512.839661,213.220581 515.005859,211.060608 
	C516.355225,209.327881 517.704590,207.595139 519.048096,205.519592 
	C520.748657,204.815231 522.455017,204.453674 524.530884,204.053406 
	C525.180847,205.442047 525.461243,206.869385 525.580933,208.627151 
	C525.420227,208.957596 525.118347,208.929596 524.978394,208.573547 
	C523.958191,208.035767 522.627502,207.390976 522.265869,207.742645 
	C517.762390,212.121933 513.399658,216.646011 509.000702,221.132843 
	C508.755402,221.261047 508.531738,221.419739 507.830017,221.776749 
	C502.750702,225.564789 498.171051,229.184967 493.591431,232.805145 
	C493.591431,232.805145 493.375397,232.865707 492.890808,232.896805 
	C473.355347,243.523712 452.839081,248.208038 431.138641,248.115112 
	C430.959534,248.088303 430.780396,248.061508 430.339447,247.962524 
	C430.108215,247.529739 430.138824,247.169128 430.293427,246.564941 
	C434.208435,246.044693 437.999420,245.768051 442.169739,245.224396 
z"/>
<path fill="#D09419" opacity="1.000000" stroke="none" 
	d="
M357.066071,184.917877 
	C355.601379,184.431381 354.136658,183.944885 352.145477,183.128326 
	C350.422150,182.185165 349.225342,181.572067 347.931824,180.582642 
	C347.479462,179.877808 347.143005,179.286606 346.765411,179.259064 
	C336.191864,178.487946 325.534821,176.649368 315.061127,177.388916 
	C305.879761,178.037201 296.205444,179.433044 288.484711,185.888824 
	C287.524261,186.691925 285.782990,186.561234 284.403503,186.863235 
	C284.164032,186.435806 283.924561,186.008377 283.685089,185.580948 
	C285.393066,183.476074 287.226837,181.460098 288.787384,179.251099 
	C295.961639,169.095749 306.426758,163.210541 317.457489,158.888214 
	C334.948425,152.034500 353.274902,152.782913 371.363953,156.354416 
	C385.369171,159.119598 397.579346,166.267838 409.266327,174.229340 
	C411.428192,175.702072 413.910309,177.085648 415.377106,179.132156 
	C421.893066,188.223175 428.106262,197.531754 434.380157,206.794693 
	C434.821655,207.446518 434.868713,208.365540 435.099182,209.160324 
	C435.099152,209.160324 434.750854,209.304642 434.152466,209.236191 
	C429.534210,210.205658 424.882538,210.131149 423.098480,215.105865 
	C423.098480,215.105865 422.688843,215.218536 422.056274,215.168045 
	C414.975555,217.136551 408.513580,219.112900 402.084381,221.190598 
	C398.229584,222.436310 396.441223,220.510483 394.876801,217.301544 
	C394.197235,215.907669 392.323151,215.096146 390.991608,214.020142 
	C390.991608,214.020142 390.997559,214.002441 390.994568,213.651672 
	C390.315643,212.549774 389.639648,211.798660 388.963684,211.047546 
	C388.963684,211.047546 388.836914,210.663971 388.843689,210.086761 
	C387.157288,206.459076 386.982330,201.951782 381.843719,202.207291 
	C381.843719,202.207291 381.884979,202.121597 381.874817,201.781082 
	C378.605896,196.881363 375.163483,192.577789 368.936249,192.148987 
	C368.936249,192.148987 368.886871,192.136688 368.880737,191.762695 
	C364.938385,189.231750 361.002228,187.074814 357.066071,184.917877 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M435.425842,209.109589 
	C434.868713,208.365540 434.821655,207.446518 434.380157,206.794693 
	C428.106262,197.531754 421.893066,188.223175 415.377106,179.132156 
	C413.910309,177.085648 411.428192,175.702072 409.266327,174.229340 
	C397.579346,166.267838 385.369171,159.119598 371.363953,156.354416 
	C353.274902,152.782913 334.948425,152.034500 317.457489,158.888214 
	C306.426758,163.210541 295.961639,169.095749 288.787384,179.251099 
	C287.226837,181.460098 285.393066,183.476074 283.685089,185.580948 
	C283.924561,186.008377 284.164032,186.435806 284.403503,186.863235 
	C285.782990,186.561234 287.524261,186.691925 288.484711,185.888824 
	C296.205444,179.433044 305.879761,178.037201 315.061127,177.388916 
	C325.534821,176.649368 336.191864,178.487946 346.765411,179.259064 
	C347.143005,179.286606 347.479462,179.877808 347.542938,180.608337 
	C345.840912,181.335236 344.431061,181.660095 343.021210,181.984955 
	C336.031433,181.599792 329.041656,181.214630 321.197418,180.906219 
	C319.522614,181.031021 318.702271,181.079071 317.470551,180.993683 
	C313.277710,181.292435 309.302399,181.133438 305.760071,182.294907 
	C300.399994,184.052338 294.058350,184.082977 290.423920,189.570694 
	C288.560760,190.261551 286.731079,191.069565 284.826080,191.614105 
	C282.345581,192.323166 279.805328,192.823090 277.101440,192.728088 
	C284.562012,174.670258 297.673950,162.944321 315.108612,156.145325 
	C331.870453,149.608749 349.518829,149.503220 366.824982,152.411163 
	C398.067139,157.660736 421.831635,174.006699 436.107422,202.968750 
	C436.875916,204.527756 438.295654,205.765717 439.412262,207.153107 
	C439.312744,207.297653 439.213257,207.442215 438.963684,207.804886 
	C437.793274,208.368286 436.772888,208.713577 435.425842,209.109589 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M391.116547,214.349396 
	C392.323151,215.096146 394.197235,215.907669 394.876801,217.301544 
	C396.441223,220.510483 398.229584,222.436310 402.084381,221.190598 
	C408.513580,219.112900 414.975555,217.136551 421.845398,215.193283 
	C417.104797,217.595154 411.893524,219.819153 406.790833,222.269272 
	C397.009399,226.965881 395.212402,223.677658 391.116547,214.349396 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M423.463074,215.064148 
	C424.882538,210.131149 429.534210,210.205658 433.965942,209.263367 
	C430.861053,211.246796 427.344391,213.134598 423.463074,215.064148 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M381.907471,202.538132 
	C386.982330,201.951782 387.157288,206.459076 388.804138,209.888687 
	C387.806244,209.820572 386.854706,209.373306 385.610657,208.808228 
	C384.202484,206.749939 383.086823,204.809464 381.907471,202.538132 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M388.986816,211.366455 
	C389.639648,211.798660 390.315643,212.549774 390.985779,213.654602 
	C390.323273,213.233994 389.666595,212.459686 388.986816,211.366455 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M467.889374,281.402344 
	C468.602936,281.418701 469.046783,281.536926 469.778748,281.846527 
	C465.675323,284.378784 461.283783,286.719635 456.524048,289.117859 
	C456.155914,289.175201 455.984924,288.974854 456.042053,288.602203 
	C456.415680,287.502167 456.732147,286.774780 457.048615,286.047394 
	C457.048615,286.047394 457.000000,286.000000 457.385376,286.021912 
	C460.679565,284.617828 463.588379,283.191864 466.497192,281.765869 
	C466.871338,281.678680 467.245483,281.591461 467.889374,281.402344 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M391.340057,518.361023 
	C393.743958,518.062500 396.411713,518.048828 399.524292,518.042419 
	C397.180695,518.248413 394.392303,518.447144 391.340057,518.361023 
z"/>
<path fill="#700A15" opacity="1.000000" stroke="none" 
	d="
M386.191284,518.332642 
	C386.755493,518.044678 387.506989,517.995667 388.631104,518.012512 
	C388.128693,518.242798 387.253662,518.407166 386.191284,518.332642 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M272.733459,369.790710 
	C274.308014,369.804352 276.085846,369.957733 277.730011,370.509430 
	C283.812347,372.550446 289.850586,374.722961 296.064026,377.146515 
	C288.440247,375.002411 280.657806,372.557861 272.733459,369.790710 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M298.226471,378.122498 
	C301.884308,378.432831 305.750946,379.059509 309.825623,380.018127 
	C306.167511,379.713013 302.301422,379.075928 298.226471,378.122498 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M260.703918,363.864410 
	C262.696686,364.433624 264.841736,365.301361 267.037231,366.515015 
	C265.010529,365.961578 262.933380,365.062256 260.703918,363.864410 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M312.683563,380.927460 
	C313.882355,380.664612 315.235535,380.684265 316.798950,381.068115 
	C315.618774,381.358185 314.228363,381.284088 312.683563,380.927460 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M268.684509,367.860168 
	C269.592468,367.881622 270.610565,368.222443 271.735352,368.908142 
	C270.826202,368.895233 269.810425,368.537384 268.684509,367.860168 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M465.024170,600.405273 
	C463.789703,600.370361 462.534485,599.963074 459.757904,599.062134 
	C462.242859,597.933899 463.261841,597.471313 464.632874,597.013306 
	C464.991089,598.022949 464.997253,599.027954 465.024170,600.405273 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M481.978210,564.629028 
	C481.660706,564.190002 481.352905,563.423523 481.071106,562.326660 
	C481.394043,562.764709 481.690979,563.533142 481.978210,564.629028 
z"/>
<path fill="#94363D" opacity="1.000000" stroke="none" 
	d="
M481.042480,561.587769 
	C480.701599,561.478638 480.354187,561.053406 479.972534,560.342102 
	C480.114380,560.047852 480.290466,560.039673 480.730652,560.018555 
	C481.008484,560.427551 481.022217,560.849670 481.042480,561.587769 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M251.416473,416.549774 
	C251.917999,423.147278 252.133804,429.792053 252.133087,436.436798 
	C252.130264,462.376709 252.058899,488.316620 251.905884,514.739990 
	C251.517654,513.302490 250.995514,511.382050 250.991745,509.460541 
	C250.937119,481.653259 250.957687,453.845886 250.937515,426.038513 
	C250.936188,424.219421 250.750656,422.400421 250.355469,420.287537 
	C250.060120,419.993683 249.855911,419.635559 249.871979,419.429993 
	C249.616226,418.601837 249.344406,417.979187 248.870499,417.067657 
	C249.456039,416.718170 250.243652,416.657593 251.416473,416.549774 
z"/>
<path fill="#600507" opacity="1.000000" stroke="none" 
	d="
M248.313538,416.642029 
	C247.821609,416.794800 247.101746,416.642517 246.138046,416.201874 
	C246.624680,416.054626 247.355148,416.195801 248.313538,416.642029 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M439.784576,207.127136 
	C438.295654,205.765717 436.875916,204.527756 436.107422,202.968750 
	C421.831635,174.006699 398.067139,157.660736 366.824982,152.411163 
	C349.518829,149.503220 331.870453,149.608749 315.108612,156.145325 
	C297.673950,162.944321 284.562012,174.670258 276.914734,192.396667 
	C274.301239,198.946716 271.464996,205.062653 269.155182,211.371323 
	C267.854980,214.922531 267.423340,218.791718 266.070801,222.675476 
	C265.324554,222.554443 265.107941,222.275604 264.926056,221.669189 
	C264.979614,220.902939 264.998474,220.464279 265.160828,219.731293 
	C265.506226,218.605820 265.708069,217.774628 265.936584,216.603439 
	C265.911804,215.812134 265.860413,215.360840 265.809021,214.909546 
	C265.860168,214.495544 265.911346,214.081558 266.212189,213.192841 
	C266.628448,212.155411 266.795013,211.592697 267.115875,210.713104 
	C267.525269,209.269348 267.780334,208.142487 268.165924,206.702408 
	C268.577576,204.327362 268.858765,202.265549 269.446777,200.115219 
	C270.196808,199.364441 270.640015,198.702164 271.345093,197.777512 
	C271.805328,196.043777 272.003693,194.572433 272.202026,193.101089 
	C272.483154,192.612762 272.764252,192.124435 273.361389,191.146576 
	C274.459839,188.783234 275.242279,186.909439 276.024719,185.035629 
	C276.024719,185.035629 276.003632,185.010132 276.332520,184.877045 
	C278.804718,181.508530 280.947998,178.273117 283.091309,175.037720 
	C283.676544,174.272461 284.261810,173.507187 285.314087,172.402191 
	C287.222473,170.412613 288.663818,168.762756 290.105164,167.112915 
	C290.105164,167.112900 290.005615,167.000275 290.399414,167.030655 
	C291.959259,165.761673 293.125305,164.462311 294.291351,163.162964 
	C294.291351,163.162964 294.654968,163.180389 295.190521,163.114258 
	C304.515747,158.745132 313.305389,154.442139 322.095032,150.139130 
	C322.510620,150.124680 322.926239,150.110229 324.023865,150.087906 
	C325.831390,149.751038 326.956940,149.422043 328.082458,149.093048 
	C328.500519,149.087891 328.918610,149.082733 330.112061,149.081879 
	C336.266113,148.737900 341.644836,148.389633 347.023529,148.041382 
	C347.447968,148.062836 347.872437,148.084290 348.927704,148.375229 
	C354.053802,148.749832 358.549042,148.854965 363.460754,148.994461 
	C365.555054,149.072678 367.232880,149.116516 369.153015,149.423065 
	C371.285645,150.100235 373.175964,150.514679 375.373657,150.975098 
	C376.094727,151.042480 376.508362,151.063889 377.148376,151.347198 
	C378.935638,152.055298 380.496582,152.501526 382.365356,152.994003 
	C383.087646,153.060211 383.502014,153.080154 384.144989,153.372742 
	C388.586609,155.121445 392.799683,156.597504 397.190186,158.117035 
	C397.367676,158.160522 397.733032,158.150406 397.836884,158.482544 
	C399.962128,159.904495 401.983551,160.994308 404.239868,162.279175 
	C405.599304,163.344162 406.723846,164.214081 408.012482,165.366974 
	C409.104462,166.133194 410.032349,166.616440 411.161194,167.362244 
	C413.524109,169.110687 415.686096,170.596542 418.001526,172.372665 
	C419.736755,174.138474 421.318542,175.614029 422.937866,177.403168 
	C423.624817,178.490448 424.274200,179.264175 425.059235,180.332565 
	C426.395477,182.127304 427.596069,183.627380 428.869629,185.456421 
	C429.910461,186.596161 430.878357,187.406952 432.020935,188.515991 
	C433.063904,190.259964 433.932129,191.705688 434.863525,193.510254 
	C437.049286,197.074814 436.499268,202.897446 442.851044,201.962204 
	C443.097870,204.275406 444.106537,206.988022 439.784576,207.127136 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M442.929504,201.501495 
	C436.499268,202.897446 437.049286,197.074814 435.222839,193.496552 
	C436.471893,193.600861 437.424835,194.077728 439.999146,195.365952 
	C439.999146,180.727386 440.029022,167.194595 439.980927,153.662079 
	C439.966553,149.616837 437.862976,149.750854 435.151215,151.498428 
	C431.045563,154.144287 426.730957,155.026276 422.072571,153.077179 
	C423.994690,152.693192 425.916840,152.309204 427.923553,151.569519 
	C428.408356,151.106827 428.808563,150.999832 429.876099,150.909943 
	C431.384308,150.314911 432.225250,149.702789 433.066193,149.090668 
	C433.066193,149.090668 433.045746,149.049927 433.069031,149.060806 
	C434.601288,148.297043 436.450073,147.841599 437.544037,146.677094 
	C439.449829,144.648346 440.775208,145.435486 442.599030,146.970795 
	C442.975128,156.385178 443.029877,165.741425 442.865967,175.631378 
	C442.584625,176.272415 442.444885,176.443634 442.472046,176.476425 
	C442.618927,176.653534 442.807129,176.796341 442.981079,176.950974 
	C442.990021,184.980911 442.998993,193.010834 442.929504,201.501495 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M443.049835,176.723755 
	C442.807129,176.796341 442.618927,176.653534 442.472046,176.476425 
	C442.444885,176.443634 442.584625,176.272415 442.824036,176.100861 
	C443.000763,176.036621 443.118591,176.496552 443.049835,176.723755 
z"/>
<path fill="#83494C" opacity="1.000000" stroke="none" 
	d="
M273.376526,518.216919 
	C273.383850,518.137634 273.497528,518.100586 273.548981,518.119873 
	C273.600433,518.139099 273.369232,518.296204 273.376526,518.216919 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M241.213242,171.577774 
	C242.810349,170.116684 244.326904,168.941528 246.007950,167.487854 
	C253.810043,162.565750 261.424408,157.883163 269.102234,153.307007 
	C270.939545,152.211945 272.926453,151.291092 274.950134,150.598740 
	C284.059174,147.482315 284.072876,147.522369 283.906281,137.287933 
	C283.790039,136.621796 283.764557,136.324188 283.874573,135.620544 
	C284.283722,133.794250 284.511749,132.377899 284.739807,130.961533 
	C284.867920,130.226913 284.996002,129.492279 285.096436,128.384308 
	C288.301605,118.231575 291.534454,108.452194 294.705261,98.314270 
	C294.734314,97.814766 294.825378,97.673805 294.969452,97.286819 
	C295.514923,96.754570 296.007355,96.468338 296.781738,96.126274 
	C295.128937,101.847763 292.675873,107.511116 291.374969,113.427795 
	C289.147095,123.560402 287.763855,133.876251 285.728363,144.055649 
	C285.309784,146.148972 283.998199,149.202209 282.398407,149.743057 
	C267.865356,154.656235 255.452240,163.030411 243.601303,172.442520 
	C243.116577,172.827499 242.375443,172.889679 241.392487,173.062866 
	C241.065033,172.636871 241.098862,172.250305 241.213242,171.577774 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M407.832489,456.812531 
	C407.837341,457.557159 407.842224,458.301819 407.800598,459.415588 
	C406.448853,461.180328 405.143585,462.575928 403.695679,464.251282 
	C402.689545,464.574860 401.826111,464.618683 400.454834,464.512909 
	C399.537445,464.490204 399.127930,464.617126 398.718414,464.744019 
	C397.718384,464.728027 396.718323,464.712097 395.351990,464.781372 
	C394.599579,464.817932 394.213501,464.769257 393.546509,464.651917 
	C393.065094,464.304535 392.864594,464.025879 392.655334,463.587402 
	C393.158783,462.719452 393.670990,462.011261 394.699219,461.076233 
	C400.138855,461.747406 404.061951,459.518372 407.832489,456.812531 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M581.879822,461.608002 
	C581.692261,461.732788 581.488342,461.601959 581.315308,461.185181 
	C581.489624,461.110809 581.662048,461.261841 581.879822,461.608002 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M546.080444,626.203857 
	C546.683655,626.709473 547.248108,627.557495 547.696777,628.741638 
	C547.067871,628.233948 546.554749,627.390076 546.080444,626.203857 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M547.913818,630.568115 
	C548.278503,631.238770 548.664368,632.251404 549.038574,633.618042 
	C548.662964,632.951416 548.298950,631.930725 547.913818,630.568115 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M551.932190,642.392822 
	C552.049438,642.406860 552.183838,642.614441 552.163879,642.896362 
	C552.009521,642.970581 551.949402,642.586487 551.932190,642.392822 
z"/>
<path fill="#8B2129" opacity="1.000000" stroke="none" 
	d="
M551.045654,640.270996 
	C551.288574,640.132263 551.507385,640.292297 551.634155,640.779541 
	C551.403198,640.859924 551.229675,640.680908 551.045654,640.270996 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M240.674332,173.007935 
	C240.151917,174.355820 239.453247,175.844193 238.378784,176.975784 
	C234.206451,181.370071 229.914703,185.650970 225.317856,189.974213 
	C230.087341,184.311066 235.206390,178.647797 240.674332,173.007935 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M160.533646,426.908173 
	C158.091522,430.783295 155.290970,434.686584 152.184021,438.728394 
	C152.375763,433.312836 155.178619,429.361725 160.533646,426.908173 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M284.375854,131.077789 
	C284.511749,132.377899 284.283722,133.794250 283.763580,135.424896 
	C283.651611,134.157471 283.831726,132.675766 284.375854,131.077789 
z"/>
<path fill="#D11A2D" opacity="1.000000" stroke="none" 
	d="
M279.714752,646.704102 
	C279.457245,645.772278 279.324982,644.435364 279.511108,643.014160 
	C279.833008,644.052856 279.836517,645.175964 279.714752,646.704102 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M224.941223,333.307556 
	C225.932205,333.870728 226.867081,334.757660 227.663498,335.986450 
	C227.021118,336.124298 226.517212,335.920319 225.764099,335.598907 
	C225.304993,334.864777 225.095062,334.248047 224.941223,333.307556 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M399.114594,464.782654 
	C399.127930,464.617126 399.537445,464.490204 400.121094,464.524475 
	C400.033722,464.730896 399.772247,464.776123 399.114594,464.782654 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M227.692001,336.789429 
	C232.434464,341.051971 237.236084,345.639435 242.060364,350.567810 
	C240.951096,350.328308 239.819153,349.747894 238.391876,349.036438 
	C236.613129,347.336578 235.129715,345.767853 233.342484,344.080322 
	C231.276154,341.679138 229.513657,339.396729 227.692001,336.789429 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M178.826202,501.794525 
	C178.565689,501.956360 178.325348,501.777924 178.119095,501.249268 
	C178.357224,501.127045 178.588303,501.312134 178.826202,501.794525 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M179.178070,502.891479 
	C179.017960,502.974030 178.923355,502.547943 178.935150,502.330688 
	C179.077362,502.345276 179.207779,502.577087 179.178070,502.891479 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M242.694763,351.624573 
	C244.683746,352.716644 246.700470,354.136078 248.734497,355.902832 
	C248.104950,356.148743 247.458115,356.047333 246.488098,355.873962 
	C245.017456,354.518677 243.869980,353.235321 242.694763,351.624573 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M509.366882,221.157593 
	C513.399658,216.646011 517.762390,212.121933 522.265869,207.742645 
	C522.627502,207.390976 523.958191,208.035767 524.827515,208.558670 
	C524.537781,209.723923 524.259094,210.547974 523.603271,211.689423 
	C521.515991,213.029892 519.805725,214.052979 518.095520,215.076050 
	C516.480408,216.491913 514.865356,217.907776 512.870605,219.727448 
	C512.299438,220.404282 512.107971,220.677292 511.916443,220.950317 
	C511.188660,221.027664 510.460846,221.105011 509.366882,221.157593 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M518.449097,215.060333 
	C519.805725,214.052979 521.515991,213.029892 523.583862,212.026184 
	C523.946716,213.759552 523.951904,215.473541 523.712036,217.864120 
	C523.405823,219.711884 523.344666,220.883057 523.283508,222.054245 
	C522.099670,223.691574 520.915894,225.328918 519.379150,227.024109 
	C520.222290,223.136139 521.418396,219.190308 522.785156,214.681534 
	C520.688354,214.872696 519.745544,214.958649 518.449097,215.060333 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M523.511597,222.014862 
	C523.344666,220.883057 523.405823,219.711884 523.713684,218.293808 
	C524.124878,218.606384 524.289368,219.165848 524.696960,219.898895 
	C524.912292,220.470352 524.884521,220.868240 524.760132,221.551453 
	C524.352966,221.865814 524.045044,221.912048 523.511597,222.014862 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M328.914124,382.756134 
	C329.311798,382.459930 329.955627,382.391235 330.834961,382.564697 
	C330.433685,382.865845 329.796997,382.924744 328.914124,382.756134 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M248.830490,356.604187 
	C249.746613,356.717987 250.715652,357.157867 251.690643,357.948975 
	C250.758850,357.843536 249.821121,357.386902 248.830490,356.604187 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M540.830017,510.751282 
	C540.467834,510.817902 539.955261,510.659790 539.318542,510.260437 
	C539.689453,510.188263 540.184570,510.357422 540.830017,510.751282 
z"/>
<path fill="#AF4E57" opacity="1.000000" stroke="none" 
	d="
M531.441833,420.938477 
	C531.248657,421.082092 530.780640,421.258423 530.184326,421.227997 
	C530.426331,421.004578 530.796692,420.987915 531.441833,420.938477 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M363.044312,148.960083 
	C358.549042,148.854965 354.053802,148.749832 349.246429,148.366592 
	C351.163330,145.914841 356.525909,147.327621 356.181305,141.407150 
	C356.612915,140.562332 356.820587,140.262543 357.028259,139.962753 
	C357.763580,140.023590 358.498932,140.084412 359.604919,140.602341 
	C360.991577,141.362793 362.007629,141.666168 363.395691,141.965805 
	C364.511139,141.989349 365.254547,142.016647 366.209076,142.302002 
	C367.288971,142.697556 368.157745,142.835068 369.014557,142.979584 
	C369.002563,142.986618 369.028900,142.962326 369.017273,143.234161 
	C369.005066,143.687103 369.004456,143.868195 368.735626,144.321228 
	C366.473053,144.869690 364.478699,145.146240 362.314209,145.446365 
	C362.599457,146.819061 362.821899,147.889557 363.044312,148.960083 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M349.026093,99.969261 
	C349.056122,99.549629 349.086151,99.129990 349.373169,98.145294 
	C350.406403,96.120682 351.182678,94.661133 351.974060,93.594223 
	C352.011993,95.037186 352.034821,96.087502 352.057648,97.137810 
	C350.735565,104.860130 349.413452,112.582443 348.221619,120.749039 
	C348.306976,121.577301 348.262054,121.961281 348.225067,122.634834 
	C348.041077,122.935310 347.849182,122.946220 347.067566,122.727341 
	C346.300659,120.327408 346.123444,118.157280 345.946228,115.987144 
	C345.990967,114.937233 346.035736,113.887314 346.338989,112.276520 
	C346.700562,110.464432 346.803619,109.213226 346.906677,107.962021 
	C346.969452,107.248276 347.032227,106.534531 347.409943,105.421928 
	C348.158630,103.338470 348.592346,101.653862 349.026093,99.969261 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M345.588959,116.058273 
	C346.123444,118.157280 346.300659,120.327408 346.780457,122.754059 
	C347.012909,123.755775 346.942810,124.500977 346.386475,125.621140 
	C345.323212,126.464966 344.272675,126.907600 344.244995,127.406891 
	C344.048584,130.952576 344.061493,134.509857 344.007690,138.063431 
	C343.095337,137.429092 341.357666,136.734161 341.399139,136.169861 
	C341.786530,130.894882 342.360870,125.624290 343.157959,120.394638 
	C343.385437,118.902206 344.515503,117.547340 345.588959,116.058273 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M384.645294,144.722153 
	C384.993744,145.234604 385.032562,145.714050 384.653595,146.593903 
	C381.154999,146.340790 378.074158,145.687286 374.997528,144.773895 
	C375.007080,144.340851 375.012451,144.167664 375.298828,143.966705 
	C376.037842,143.664825 376.495789,143.390717 376.953766,143.116638 
	C377.389832,143.140350 377.825897,143.164062 378.707581,143.594940 
	C380.260010,144.177063 381.366821,144.352020 382.473633,144.526962 
	C383.094299,144.581024 383.714966,144.635086 384.645294,144.722153 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M344.270264,138.042847 
	C344.061493,134.509857 344.048584,130.952576 344.244995,127.406891 
	C344.272675,126.907600 345.323212,126.464966 346.246094,125.968483 
	C346.547241,128.027466 346.502533,130.114059 346.092194,132.777771 
	C345.503052,134.890503 345.279572,136.426132 345.056091,137.961746 
	C344.881683,137.981934 344.707245,138.002106 344.270264,138.042847 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M356.722595,139.710236 
	C356.820587,140.262543 356.612915,140.562332 356.177917,141.027985 
	C354.265259,141.125031 352.579865,141.056229 350.317108,140.750763 
	C349.198395,140.020859 348.657104,139.527618 348.115814,139.034363 
	C350.882843,139.175491 353.649902,139.316605 356.722595,139.710236 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M365.997986,142.043930 
	C365.254547,142.016647 364.511139,141.989349 363.379272,141.704559 
	C362.950958,141.101105 362.911011,140.755142 362.871094,140.409180 
	C365.743073,140.782822 368.615051,141.156448 371.774658,142.051666 
	C372.062286,142.573242 372.027893,143.066528 371.653931,143.021271 
	C370.529633,142.971451 369.779266,142.966888 369.028900,142.962326 
	C369.028900,142.962326 369.002563,142.986618 368.800476,142.723618 
	C367.731598,142.321716 366.864777,142.182816 365.997986,142.043930 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M372.027893,143.066528 
	C372.027893,143.066528 372.062286,142.573242 372.045593,142.326843 
	C372.733978,142.180374 373.439087,142.280304 374.505981,142.740601 
	C374.935181,143.395889 374.985199,143.693710 375.017792,143.994476 
	C375.012451,144.167664 375.007080,144.340851 374.800842,145.019913 
	C373.727966,145.051620 372.855988,144.577454 371.996735,143.844177 
	C372.015594,143.412216 372.021759,143.239365 372.027893,143.066528 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M345.367554,137.994965 
	C345.279572,136.426132 345.503052,134.890503 346.075806,133.169403 
	C346.611481,134.412033 346.797913,135.840149 346.956238,137.642654 
	C346.511780,138.020767 346.095398,138.024475 345.367554,137.994965 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M362.505707,140.322525 
	C362.911011,140.755142 362.950958,141.101105 363.007263,141.708313 
	C362.007629,141.666168 360.991577,141.362793 359.948486,140.738815 
	C360.661041,140.357422 361.400665,140.296646 362.505707,140.322525 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M382.284485,144.244598 
	C381.366821,144.352020 380.260010,144.177063 379.026123,143.671631 
	C379.964478,143.548172 381.029877,143.755188 382.284485,144.244598 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M375.298828,143.966705 
	C374.985199,143.693710 374.935181,143.395889 374.835236,142.880676 
	C375.401398,142.638138 376.000122,142.615906 376.776306,142.855148 
	C376.495789,143.390717 376.037842,143.664825 375.298828,143.966705 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M347.879791,138.938156 
	C348.657104,139.527618 349.198395,140.020859 349.894409,140.755142 
	C350.049103,140.996185 349.568939,141.088257 348.977631,141.067657 
	C347.920380,141.038849 347.454407,141.030640 346.988464,141.022446 
	C346.988464,141.022446 346.931183,140.543533 346.920715,140.047195 
	C346.885010,139.381912 346.859833,139.212967 346.834686,139.044037 
	C346.834686,139.044037 346.973419,138.921707 347.053833,138.875061 
	C347.304047,138.832932 347.473907,138.837448 347.879791,138.938156 
z"/>
<path fill="#0201F9" opacity="1.000000" stroke="none" 
	d="
M371.984039,144.103287 
	C372.855988,144.577454 373.727966,145.051620 374.796631,145.279785 
	C378.074158,145.687286 381.154999,146.340790 384.676056,146.953613 
	C390.776062,148.442474 396.360901,150.421967 402.115845,151.380157 
	C408.369080,152.421280 414.780457,152.512833 421.597717,153.047806 
	C426.730957,155.026276 431.045563,154.144287 435.151215,151.498428 
	C437.862976,149.750854 439.966553,149.616837 439.980927,153.662079 
	C440.029022,167.194595 439.999146,180.727386 439.999146,195.365952 
	C437.424835,194.077728 436.471893,193.600861 435.159668,193.137711 
	C433.932129,191.705688 433.063904,190.259964 432.045776,188.179779 
	C431.262146,186.698792 430.628387,185.852310 429.993347,184.630890 
	C429.694519,181.177383 427.925598,179.866776 424.923584,180.037903 
	C424.274200,179.264175 423.624817,178.490448 423.203796,177.207123 
	C423.111237,173.295303 421.105591,171.931519 417.848114,172.082413 
	C415.686096,170.596542 413.524109,169.110687 411.127625,166.995972 
	C409.878265,165.939407 408.863342,165.511703 407.848419,165.084000 
	C406.723846,164.214081 405.599304,163.344162 404.194885,161.921906 
	C401.854370,160.296509 399.793701,159.223465 397.733032,158.150406 
	C397.733032,158.150406 397.367676,158.160522 397.136780,157.753708 
	C393.608002,155.615311 390.364105,153.762772 386.969879,152.246933 
	C386.227203,151.915253 384.948334,152.784119 383.916412,153.100098 
	C383.502014,153.080154 383.087646,153.060211 382.297058,152.662689 
	C380.254578,151.885178 378.588318,151.485245 376.922028,151.085297 
	C376.508362,151.063889 376.094727,151.042480 375.291992,150.634491 
	C372.905548,149.885391 370.908112,149.522873 368.910706,149.160339 
	C367.232880,149.116516 365.555054,149.072678 363.460754,148.994446 
	C362.821899,147.889557 362.599457,146.819061 362.314209,145.446365 
	C364.478699,145.146240 366.473053,144.869690 369.108398,144.313202 
	C370.494324,144.056595 371.239166,144.079941 371.984039,144.103287 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M352.323639,96.918289 
	C352.034821,96.087502 352.011993,95.037186 352.113892,93.208061 
	C354.154449,86.957817 356.070312,81.486389 357.986176,76.014969 
	C357.986176,76.014969 358.011017,76.007271 358.327454,75.864540 
	C360.113892,73.156364 361.583923,70.590912 363.053986,68.025475 
	C363.364380,67.560303 363.674744,67.095123 364.312927,66.195282 
	C367.082611,63.167824 369.524475,60.575024 371.966339,57.982231 
	C372.561035,57.496120 373.155701,57.009998 374.219543,56.244259 
	C376.778046,54.306652 378.867371,52.648666 380.956696,50.990685 
	C380.956696,50.990692 381.003937,51.001663 381.369873,50.967335 
	C383.610291,50.400051 385.484741,49.867092 387.359222,49.334129 
	C383.183258,52.736202 378.898804,56.014645 374.852722,59.564743 
	C364.765381,68.415466 358.803375,79.930412 354.114288,92.606445 
	C353.586060,94.209923 353.087860,95.454346 352.323639,96.918289 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M317.881927,181.127136 
	C318.702271,181.079071 319.522614,181.031021 320.757874,181.037384 
	C322.671051,181.664551 324.128479,182.555923 325.674805,182.754318 
	C332.042938,183.571289 338.489899,183.878220 344.804504,184.970245 
	C348.814941,185.663803 351.890839,188.109299 353.368469,192.332886 
	C358.954865,208.300797 369.390289,220.618469 382.665070,230.755875 
	C384.102051,231.853210 385.423828,233.101410 386.965576,234.571396 
	C384.130157,233.437744 380.779083,232.471924 378.189148,230.504196 
	C368.901428,223.447723 361.988373,214.206039 355.827332,204.442932 
	C354.040802,201.611908 353.526520,198.005524 351.939606,195.014221 
	C349.868866,191.110992 347.595398,187.550980 342.331390,186.772736 
	C329.891144,184.933502 317.534241,183.291748 305.113159,186.751740 
	C304.022980,187.055405 302.803528,186.895233 301.414612,186.599579 
	C303.629303,185.434753 306.058167,184.570282 308.521698,183.819687 
	C311.626953,182.873535 314.760559,182.020401 317.881927,181.127136 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M406.974091,245.088333 
	C405.183868,244.800735 403.393677,244.513138 401.317230,243.749100 
	C401.079193,242.804337 401.127380,242.336014 401.175568,241.867691 
	C406.651550,243.373932 412.127502,244.880173 417.461243,246.683929 
	C416.543518,246.995224 415.768005,247.008987 414.992523,247.022736 
	C414.245026,247.012680 413.497498,247.002609 412.162079,246.727951 
	C410.040802,246.005020 408.507446,245.546677 406.974091,245.088333 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M343.257446,182.251831 
	C344.431061,181.660095 345.840912,181.335236 347.639648,180.984680 
	C349.225342,181.572067 350.422150,182.185165 351.774048,183.017471 
	C351.938507,183.547424 351.947906,183.858154 351.912689,184.633392 
	C349.076599,184.238159 346.285156,183.378433 343.257446,182.251831 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M401.144409,241.876038 
	C401.127380,242.336014 401.079193,242.804337 401.032745,243.625000 
	C397.980560,243.878418 394.463257,244.909821 393.435974,240.395477 
	C395.596436,240.338150 397.407562,240.604614 399.218658,240.871063 
	C399.218658,240.871063 399.604858,240.859558 399.905273,241.129333 
	C400.491547,241.574417 400.777374,241.749710 401.063202,241.925018 
	C401.063202,241.925018 401.113251,241.884369 401.144409,241.876038 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M398.981140,240.610626 
	C397.407562,240.604614 395.596436,240.338150 393.398865,240.024750 
	C391.676025,238.853806 390.339630,237.729813 389.019470,236.220337 
	C392.271698,237.339981 395.507629,238.845093 398.981140,240.610626 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M415.066772,247.387650 
	C415.768005,247.008987 416.543518,246.995224 417.707977,246.962372 
	C418.517029,246.976883 418.937073,247.010483 419.867737,247.329773 
	C421.592285,248.404892 422.806213,249.194351 424.020142,249.983810 
	C421.060455,249.240051 418.100739,248.496307 415.066772,247.387650 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M424.393555,249.985321 
	C422.806213,249.194351 421.592285,248.404892 420.181152,247.374969 
	C420.777588,247.066498 421.571289,246.998505 422.874146,247.149506 
	C423.917725,247.261002 424.452087,247.153473 424.986481,247.045944 
	C425.416504,247.082596 425.846497,247.119232 426.746277,247.378235 
	C427.489349,248.026001 427.762634,248.451385 428.035919,248.876755 
	C426.946259,249.246780 425.856598,249.616806 424.393555,249.985321 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M428.268250,248.794647 
	C427.762634,248.451385 427.489349,248.026001 427.069702,247.393036 
	C427.528076,247.305557 428.132812,247.425629 428.961426,247.775681 
	C429.021240,248.303467 428.792999,248.539093 428.268250,248.794647 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M424.682251,247.018097 
	C424.452087,247.153473 423.917725,247.261002 423.272583,247.154419 
	C423.567230,246.956985 423.972626,246.973618 424.682251,247.018097 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M400.974274,241.678619 
	C400.777374,241.749710 400.491547,241.574417 400.095001,241.165848 
	C400.284637,241.099121 400.584991,241.265686 400.974274,241.678619 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M369.123657,192.419556 
	C375.163483,192.577789 378.605896,196.881363 381.830750,201.799042 
	C377.634949,199.001724 373.473022,195.845932 369.123657,192.419556 
z"/>
<path fill="#BA8A59" opacity="1.000000" stroke="none" 
	d="
M357.167450,185.249023 
	C361.002228,187.074814 364.938385,189.231750 368.860901,191.746719 
	C364.987793,189.929871 361.128296,187.755005 357.167450,185.249023 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M389.230774,303.850708 
	C389.179535,303.445831 389.128296,303.040955 389.333984,302.128662 
	C389.723785,300.072906 389.856689,298.524597 389.989563,296.976288 
	C390.920563,299.879120 392.540405,302.753906 392.659973,305.689789 
	C393.110657,316.756927 393.196930,327.844391 393.155457,338.923279 
	C393.139679,343.137512 392.642212,347.386841 391.963013,351.554779 
	C389.899048,364.220551 379.552490,372.819824 366.602722,373.003204 
	C356.663147,373.143982 346.721802,373.712524 336.788208,373.576996 
	C325.383698,373.421478 315.071320,369.270691 305.140625,363.344269 
	C304.995270,362.999786 305.097443,362.877411 305.471008,362.909424 
	C321.631622,369.325897 337.916199,373.555908 355.046844,371.804810 
	C363.301849,370.960999 371.420837,368.599335 379.519348,366.593628 
	C380.893585,366.253265 381.851868,364.233429 383.002319,362.989502 
	C383.002319,362.989502 383.001221,363.001404 383.352478,363.000824 
	C389.732086,358.584290 390.525360,351.750183 390.806000,345.303131 
	C391.346558,332.885956 391.020294,320.428131 390.891052,307.988861 
	C390.876648,306.603882 389.809631,305.229828 389.230774,303.850708 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M303.807404,358.257874 
	C303.938538,358.811523 304.069672,359.365173 303.827057,360.305603 
	C301.976501,360.118805 300.499664,359.545258 299.022827,358.971710 
	C299.022827,358.971710 299.000000,358.989655 298.921509,358.739014 
	C298.565857,358.278137 298.288696,358.067932 298.011536,357.857758 
	C297.914825,357.593689 297.765533,357.363739 297.200073,356.804016 
	C296.263824,356.210632 295.691132,355.981171 294.933655,355.476440 
	C290.503021,351.127716 286.257202,347.054291 282.011383,342.980865 
	C282.011383,342.980865 282.000824,343.001556 281.986145,342.658569 
	C280.669006,340.553650 279.366577,338.791718 278.051697,336.687988 
	C277.367523,335.205078 276.695831,334.063934 275.986694,332.602051 
	C275.336365,331.177887 274.723541,330.074554 274.073181,328.664062 
	C273.674774,327.895813 273.313873,327.434723 272.757935,326.779175 
	C272.381348,326.070648 272.199860,325.556580 271.996338,324.711914 
	C271.374023,323.803345 270.773651,323.225372 270.075195,322.447113 
	C269.765320,321.859863 269.553558,321.472839 269.551880,320.868408 
	C269.537476,319.751740 269.312988,318.852539 269.068176,317.574829 
	C268.907013,316.451996 268.766205,315.707703 268.913940,314.935486 
	C269.391632,314.947540 269.580750,314.987518 269.769897,315.027496 
	C272.266449,320.153564 274.638885,325.345612 277.312988,330.377319 
	C278.606171,332.810669 280.465973,334.942871 282.403778,337.307678 
	C282.738342,337.403961 282.769592,337.744904 282.854248,338.223572 
	C284.900299,340.867279 286.861664,343.032318 288.823029,345.197357 
	C288.823029,345.197357 288.974762,345.024658 288.911194,345.442291 
	C293.834198,349.992584 298.820801,354.125244 303.807404,358.257874 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M401.037079,44.949135 
	C396.947510,46.360664 392.857910,47.772198 388.373505,48.810143 
	C388.001373,47.968517 388.024048,47.500477 388.046753,47.032440 
	C388.046753,47.032440 388.487457,47.008282 389.043884,46.979477 
	C390.701050,46.290169 391.801727,45.629662 392.925781,44.956272 
	C392.949127,44.943386 392.981323,44.900871 393.355042,44.904671 
	C396.820007,43.617790 399.911255,42.327106 403.019135,40.987087 
	C403.035767,40.937752 402.989227,40.844608 402.989227,40.844608 
	C403.417023,40.861904 403.844849,40.879200 404.981262,40.919983 
	C407.801422,40.274109 409.912964,39.604748 412.024536,38.935387 
	C412.445862,38.928120 412.867188,38.920853 414.037354,38.940983 
	C417.195984,38.617146 419.605774,38.265907 422.015533,37.914665 
	C426.033997,37.931274 430.052460,37.947887 434.549133,38.226574 
	C435.082947,38.866276 435.138489,39.243900 434.735596,39.763977 
	C431.325500,39.948872 428.319916,39.647884 425.432892,40.101471 
	C418.253571,41.229427 411.128326,42.701309 403.669312,44.052139 
	C402.583862,44.362514 401.810455,44.655827 401.037079,44.949135 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M357.633514,76.040352 
	C356.070312,81.486389 354.154449,86.957817 352.098785,92.815414 
	C351.182678,94.661133 350.406403,96.120682 349.369537,97.829659 
	C348.800995,97.705414 348.296387,97.370026 348.215088,96.952255 
	C347.250610,91.996696 352.928253,78.990402 357.633514,76.040352 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M321.864471,149.844620 
	C313.305389,154.442139 304.515747,158.745132 295.367798,163.072998 
	C294.510315,158.371307 299.155762,158.715424 301.404480,157.432465 
	C307.684723,153.849350 313.739624,149.160797 321.864471,149.844620 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M262.527740,255.028976 
	C262.627045,255.778702 262.726379,256.528442 262.311859,257.767639 
	C261.181244,261.510223 260.564484,264.763397 259.947754,268.016541 
	C260.007843,259.733551 260.067902,251.450546 260.547089,243.512848 
	C261.486725,247.581741 262.007233,251.305359 262.527740,255.028976 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M282.002960,343.367676 
	C286.257202,347.054291 290.503021,351.127716 294.891785,355.798462 
	C295.024078,356.610382 295.013397,356.824982 295.002747,357.039551 
	C293.110779,356.644073 290.379120,356.896423 289.481934,355.733490 
	C286.619537,352.023285 284.438629,347.787354 282.002960,343.367676 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M259.958771,268.430267 
	C260.564484,264.763397 261.181244,261.510223 262.136902,258.113647 
	C262.683167,262.679077 262.890472,267.387939 262.736694,272.824585 
	C262.235474,276.041290 262.095367,278.530243 261.677917,280.744293 
	C260.920990,277.975739 260.441437,275.482117 259.961884,272.988495 
	C259.964508,271.606995 259.967163,270.225494 259.958771,268.430267 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M389.956573,296.631592 
	C389.856689,298.524597 389.723785,300.072906 389.342102,301.822144 
	C386.115845,296.902100 383.138367,291.781158 380.073517,286.331238 
	C381.369110,286.347412 382.752106,286.692566 384.482300,287.115662 
	C384.829468,287.193604 384.915710,287.580322 385.064575,288.101593 
	C386.747284,290.485260 388.281189,292.347626 389.815063,294.210022 
	C389.851227,294.902313 389.887390,295.594604 389.956573,296.631592 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M269.341766,321.085846 
	C269.553558,321.472839 269.765320,321.859863 270.044800,322.811371 
	C270.747742,323.931427 271.383026,324.486969 272.018341,325.042511 
	C272.199860,325.556580 272.381348,326.070648 272.788452,327.090637 
	C273.379608,328.054749 273.745148,328.512970 274.110718,328.971191 
	C274.723541,330.074554 275.336365,331.177887 275.950562,332.895203 
	C275.916321,333.702271 275.880676,333.895386 275.845032,334.088501 
	C271.881805,332.918640 268.325348,327.347412 268.341125,321.417847 
	C268.899231,321.048462 269.120514,321.067139 269.341766,321.085846 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M389.968842,44.894291 
	C391.246796,39.997158 397.306519,37.745594 402.903290,40.482437 
	C402.989227,40.844608 403.035767,40.937752 402.602966,40.958015 
	C399.107208,42.285809 396.044250,43.593342 392.981323,44.900871 
	C392.981323,44.900871 392.949127,44.943386 392.560730,44.943539 
	C391.437866,44.927223 390.703339,44.910759 389.968842,44.894291 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M346.945374,147.659058 
	C341.644836,148.389633 336.266113,148.737900 330.424072,149.049530 
	C330.412842,148.388962 330.814789,147.269974 331.324799,147.218292 
	C336.018097,146.742859 340.728241,146.417618 345.439667,146.163147 
	C345.894165,146.138580 346.390381,146.887085 346.945374,147.659058 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M371.580933,57.979996 
	C369.524475,60.575024 367.082611,63.167824 364.340027,65.902428 
	C362.817627,61.612297 365.067902,59.075825 371.580933,57.979996 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M259.602905,273.061035 
	C260.441437,275.482117 260.920990,277.975739 261.702209,281.115051 
	C262.002991,282.504303 262.002106,283.247864 261.729492,283.992554 
	C261.276672,283.999420 261.095581,284.005188 260.914520,284.010925 
	C258.896027,281.995667 258.020447,276.294586 259.602905,273.061035 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M282.694336,175.022247 
	C280.947998,178.273117 278.804718,181.508530 276.348633,184.879333 
	C276.926392,180.930069 276.609833,176.090149 282.694336,175.022247 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M263.910980,300.063629 
	C259.780182,297.547485 259.396484,293.781952 260.967896,289.517914 
	C262.320770,291.334564 263.111755,293.185120 263.943390,295.358337 
	C264.003418,296.114441 264.022797,296.547913 263.835327,297.156586 
	C263.722656,298.242401 263.816803,299.153015 263.910980,300.063629 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M260.869324,241.024353 
	C256.870300,237.589859 260.232574,234.401611 261.587952,231.434464 
	C261.989258,233.827560 262.002747,235.919479 262.021820,238.381958 
	C262.007721,239.492966 261.988068,240.233368 261.693390,240.980896 
	C261.235382,241.000107 261.052368,241.012222 260.869324,241.024353 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M265.017334,220.025604 
	C264.998474,220.464279 264.979614,220.902939 264.682556,221.886566 
	C264.267517,223.288803 264.130676,224.146103 263.986694,225.372681 
	C263.933411,226.477554 263.887268,227.213165 263.528259,227.956833 
	C262.797852,227.977615 262.380280,227.990341 261.962738,228.003052 
	C262.370209,225.381866 262.777679,222.760696 263.819885,220.074432 
	C264.642181,220.014755 264.829742,220.020187 265.017334,220.025604 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M387.823608,46.754829 
	C388.024048,47.500477 388.001373,47.968517 387.989197,48.790276 
	C387.999725,49.143997 387.676727,49.236294 387.517975,49.285217 
	C385.484741,49.867092 383.610291,50.400051 381.363281,50.943989 
	C381.025787,46.261883 384.405914,46.506496 387.823608,46.754829 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M260.895996,241.341797 
	C261.052368,241.012222 261.235382,241.000107 261.721558,241.412460 
	C262.342834,244.211014 262.660919,246.585114 262.979034,248.959198 
	C262.957062,250.716736 262.935089,252.474274 262.720428,254.630402 
	C262.007233,251.305359 261.486725,247.581741 260.933167,243.394531 
	C260.907654,242.507050 260.915161,242.083145 260.895996,241.341797 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M269.791809,314.699921 
	C269.580750,314.987518 269.391632,314.947540 268.639832,314.955872 
	C267.704193,315.004456 267.331268,315.004700 266.958374,315.004974 
	C266.952728,314.572754 266.947113,314.140564 266.951721,313.002625 
	C266.294739,311.199341 265.627472,310.101715 264.960236,309.004120 
	C264.945709,307.942871 264.931152,306.881653 265.135864,305.400757 
	C265.521973,304.973572 265.688843,304.966034 265.947449,305.283905 
	C266.725861,306.404297 267.412537,307.199219 268.099243,307.994171 
	C268.337830,308.798462 268.576447,309.602722 268.566772,310.860992 
	C268.665710,311.931671 269.012909,312.548340 269.360138,313.165039 
	C269.511322,313.567474 269.662506,313.969910 269.791809,314.699921 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M269.139923,200.203720 
	C268.858765,202.265549 268.577576,204.327362 267.890137,206.696884 
	C267.300232,207.007568 267.116577,207.010559 266.932922,207.013550 
	C267.369415,204.379944 267.805878,201.746353 268.636047,199.348633 
	C269.066467,199.790924 269.103180,199.997314 269.139923,200.203720 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M362.691833,68.046768 
	C361.583923,70.590912 360.113892,73.156364 358.328644,75.851578 
	C357.893616,72.493462 358.729218,69.526718 362.691833,68.046768 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M263.902771,295.035706 
	C263.111755,293.185120 262.320770,291.334564 261.247498,289.236938 
	C260.962311,287.606567 260.959412,286.223206 260.935516,284.425415 
	C261.095581,284.005188 261.276672,283.999420 261.899414,284.355988 
	C262.861633,288.157471 263.382202,291.596588 263.902771,295.035706 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M261.966370,228.371735 
	C262.380280,227.990341 262.797852,227.977615 263.572968,228.366745 
	C263.292450,231.849518 262.654358,234.930450 262.016266,238.011383 
	C262.002747,235.919479 261.989258,233.827560 261.954681,231.341888 
	C261.945740,230.212234 261.957855,229.476334 261.966370,228.371735 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M380.589966,50.991119 
	C378.867371,52.648666 376.778046,54.306652 374.339966,55.977062 
	C376.068512,54.323509 378.145874,52.657536 380.589966,50.991119 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M325.334778,68.942238 
	C326.107178,67.075928 327.243988,65.182243 328.688995,63.142242 
	C327.897858,64.968903 326.798553,66.941887 325.334778,68.942238 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M389.880188,293.822144 
	C388.281189,292.347626 386.747284,290.485260 385.112061,288.293884 
	C388.602631,288.031403 389.436127,290.586517 389.880188,293.822144 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M289.731964,167.105103 
	C288.663818,168.762756 287.222473,170.412613 285.446533,172.138275 
	C284.514343,168.837585 286.759979,167.820877 289.731964,167.105103 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M271.815277,193.098999 
	C272.003693,194.572433 271.805328,196.043777 270.995453,197.758377 
	C269.918854,197.997513 269.453735,197.993393 268.988647,197.989273 
	C269.801941,196.358475 270.615234,194.727676 271.815277,193.098999 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M278.064178,337.029785 
	C279.366577,338.791718 280.669006,340.553650 281.996521,342.652344 
	C280.342804,341.251495 278.664001,339.513855 277.248718,337.402039 
	C277.696228,337.028534 277.880188,337.029144 278.064178,337.029785 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M299.046204,359.334412 
	C300.499664,359.545258 301.976501,360.118805 303.776825,360.586578 
	C304.100311,360.480804 304.339417,360.456421 304.346130,360.835876 
	C304.601044,361.769348 304.849243,362.323364 305.097443,362.877411 
	C305.097443,362.877411 304.995270,362.999786 304.942444,363.061523 
	C302.949585,361.981232 301.009583,360.839172 299.046204,359.334412 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M411.790955,38.676186 
	C409.912964,39.604748 407.801422,40.274109 405.301025,40.883087 
	C405.189545,40.377243 405.466888,39.931782 405.840515,39.241409 
	C407.810303,38.803329 409.683838,38.610157 411.790955,38.676186 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M369.215027,39.255943 
	C370.753845,38.333015 372.510895,37.677711 374.639648,37.029030 
	C373.152008,37.864956 371.292664,38.694260 369.215027,39.255943 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M275.682343,185.067245 
	C275.242279,186.909439 274.459839,188.783234 273.412201,190.860596 
	C273.877991,189.075729 274.608978,187.087296 275.682343,185.067245 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M379.315796,36.930992 
	C380.709137,35.977608 382.480927,35.002411 384.632355,34.032711 
	C383.239441,34.995197 381.466827,35.952187 379.315796,36.930992 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M348.685303,100.024734 
	C348.592346,101.653862 348.158630,103.338470 347.435944,105.064545 
	C347.546173,103.430740 347.945343,101.755470 348.685303,100.024734 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M265.855682,304.958466 
	C265.688843,304.966034 265.521973,304.973572 265.104065,305.003998 
	C264.388580,304.152527 263.924194,303.278137 263.938843,302.099609 
	C264.617432,301.525635 264.816956,301.255768 265.265991,301.232025 
	C265.628906,302.638245 265.742310,303.798340 265.855682,304.958466 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M264.724487,309.200623 
	C265.627472,310.101715 266.294739,311.199341 266.939331,312.678650 
	C266.107330,311.839294 265.298035,310.618225 264.724487,309.200623 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M293.918488,163.132126 
	C293.125305,164.462311 291.959259,165.761673 290.453278,167.083435 
	C291.257416,165.770996 292.401520,164.436142 293.918488,163.132126 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M320.293091,71.942825 
	C321.380127,70.966454 322.813782,69.974907 324.612549,68.977539 
	C323.531647,69.957031 322.085724,70.942337 320.293091,71.942825 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M266.712830,315.203735 
	C267.331268,315.004700 267.704193,315.004456 268.351227,314.983765 
	C268.766205,315.707703 268.907013,316.451996 268.775452,317.590546 
	C268.308380,317.993835 268.113647,318.002930 267.918884,318.011993 
	C267.434998,317.142181 266.951141,316.272339 266.712830,315.203735 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M346.571960,108.035202 
	C346.803619,109.213226 346.700562,110.464432 346.355621,111.882751 
	C346.154877,110.736038 346.196045,109.422211 346.571960,108.035202 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M327.861115,148.810638 
	C326.956940,149.422043 325.831390,149.751038 324.333282,150.047852 
	C325.187042,149.519867 326.413422,149.024048 327.861115,148.810638 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M266.900940,207.339355 
	C267.116577,207.010559 267.300232,207.007568 267.759644,207.010101 
	C267.780334,208.142487 267.525269,209.269348 266.794250,210.706116 
	C265.888916,211.008514 265.459473,211.001007 265.030060,210.993500 
	C265.643005,209.884048 266.255981,208.774597 266.900940,207.339355 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M265.016510,300.985931 
	C264.816956,301.255768 264.617432,301.525635 264.182159,301.897949 
	C263.947235,301.569366 263.948029,301.138245 263.929932,300.385376 
	C263.816803,299.153015 263.722656,298.242401 264.103851,297.390900 
	C264.724976,298.628662 264.870758,299.807281 265.016510,300.985931 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M267.938843,318.341064 
	C268.113647,318.002930 268.308380,317.993835 268.795776,317.969055 
	C269.312988,318.852539 269.537476,319.751740 269.551880,320.868408 
	C269.120514,321.067139 268.899231,321.048462 268.345337,321.013397 
	C268.012756,320.997040 268.000458,320.998199 267.998962,320.743256 
	C267.997437,320.488312 267.971405,319.989044 267.971405,319.989044 
	C267.967224,319.549408 267.963013,319.109802 267.938843,318.341064 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M265.500000,214.930908 
	C265.860413,215.360840 265.911804,215.812134 265.613586,216.616837 
	C264.830658,216.981644 264.397339,216.993042 263.964050,217.004425 
	C264.373047,216.320374 264.782013,215.636322 265.500000,214.930908 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M264.797211,211.161041 
	C265.459473,211.001007 265.888916,211.008514 266.639954,211.023010 
	C266.795013,211.592697 266.628448,212.155411 266.205627,212.879761 
	C265.487732,212.470444 265.026031,211.899521 264.797211,211.161041 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M388.195007,34.248573 
	C388.778870,33.670132 389.558990,33.347443 390.678040,33.039505 
	C390.141754,33.537613 389.266510,34.020973 388.195007,34.248573 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M389.729797,45.077877 
	C390.703339,44.910759 391.437866,44.927223 392.537415,44.956425 
	C391.801727,45.629662 390.701050,46.290169 389.260437,46.936852 
	C389.110596,46.369175 389.300659,45.815319 389.729797,45.077877 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M278.051697,336.687988 
	C277.880188,337.029144 277.696228,337.028534 277.236023,337.039093 
	C276.630829,336.269989 276.301788,335.489716 275.908905,334.398987 
	C275.880676,333.895386 275.916321,333.702271 275.988068,333.215973 
	C276.695831,334.063934 277.367523,335.205078 278.051697,336.687988 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M263.966705,217.376907 
	C264.397339,216.993042 264.830658,216.981644 265.586945,216.956848 
	C265.708069,217.774628 265.506226,218.605820 265.160828,219.731293 
	C264.829742,220.020187 264.642181,220.014755 264.172180,219.995224 
	C263.916321,219.237198 263.942841,218.493301 263.966705,217.376907 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M359.267517,43.931305 
	C359.408752,43.325253 359.879639,42.696060 360.669556,42.024406 
	C360.524750,42.624020 360.060944,43.266094 359.267517,43.931305 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M268.981201,198.231888 
	C269.453735,197.993393 269.918854,197.997513 270.733582,198.020752 
	C270.640015,198.702164 270.196808,199.364441 269.446777,200.115219 
	C269.103180,199.997314 269.066467,199.790924 268.990631,199.271988 
	C268.951477,198.959457 268.973785,198.474503 268.981201,198.231888 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M347.028076,141.316254 
	C347.454407,141.030640 347.920380,141.038849 348.734467,141.092712 
	C348.725250,141.618042 348.367920,142.097717 348.010559,142.577393 
	C347.696259,142.254959 347.381958,141.932526 347.028076,141.316254 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M346.596252,139.037476 
	C346.859833,139.212967 346.885010,139.381912 346.895386,139.807358 
	C346.467896,139.887436 346.055237,139.711014 345.642578,139.534592 
	C345.880981,139.366699 346.119415,139.198807 346.596252,139.037476 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M295.370087,357.039703 
	C295.013397,356.824982 295.024078,356.610382 295.076599,356.073761 
	C295.691132,355.981171 296.263824,356.210632 297.023560,356.696014 
	C296.719543,356.981262 296.228485,357.010559 295.370087,357.039703 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M267.809235,320.083313 
	C267.971405,319.989044 267.997437,320.488312 268.000275,320.738159 
	C267.884430,320.717834 267.765747,320.447693 267.809235,320.083313 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M298.090271,358.115723 
	C298.288696,358.067932 298.565857,358.278137 298.932800,358.736176 
	C298.738037,358.780579 298.453522,358.577148 298.090271,358.115723 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M403.981354,44.035088 
	C411.128326,42.701309 418.253571,41.229427 425.432892,40.101471 
	C428.319916,39.647884 431.325500,39.948872 435.033020,39.846588 
	C436.200104,39.779480 436.611237,39.772217 437.269653,40.090881 
	C442.058228,41.120964 446.820160,41.184574 451.096649,42.657589 
	C461.176483,46.129509 471.024963,50.273235 481.208557,54.251160 
	C481.886200,54.644272 482.322296,54.931438 482.845215,55.552483 
	C483.926422,56.282188 484.920837,56.678020 485.915222,57.073853 
	C485.915222,57.073853 486.022888,57.096645 486.177521,57.440796 
	C486.566986,57.929237 486.801849,58.073524 487.036682,58.217812 
	C486.941742,58.536251 487.079254,58.754154 487.649414,59.279789 
	C488.333527,60.695835 488.817413,61.703609 488.937531,62.777275 
	C486.043915,61.234699 483.514038,59.626232 480.992065,58.009033 
	C481.000000,58.000305 480.986084,58.016186 480.867981,57.685970 
	C477.421204,55.377819 474.179169,53.233231 470.749237,51.450108 
	C453.750122,42.612705 435.575836,40.687958 416.800232,42.552532 
	C412.520386,42.977558 408.253967,43.537334 403.981354,44.035088 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M480.967010,54.145214 
	C471.024963,50.273235 461.176483,46.129509 451.096649,42.657589 
	C446.820160,41.184574 442.058228,41.120964 437.536743,39.835968 
	C439.069031,39.102509 440.581451,38.949894 442.093872,38.797283 
	C442.515778,38.833981 442.937683,38.870678 443.929718,39.198997 
	C448.701202,40.250256 452.902527,41.009899 457.103882,41.769539 
	C457.520050,41.825626 457.936218,41.881714 458.875793,42.230186 
	C461.001434,43.004787 462.603729,43.487003 464.205994,43.969227 
	C464.921600,44.301243 465.637177,44.633259 466.869751,45.252983 
	C470.562622,46.737068 473.738556,47.933437 476.914490,49.129799 
	C477.966064,49.792408 479.017670,50.455013 480.550232,51.359379 
	C481.335938,52.055305 481.640656,52.509476 481.721863,53.100822 
	C481.321228,53.540409 481.144135,53.842812 480.967010,54.145214 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M515.901733,106.782898 
	C515.088501,105.891296 514.181641,104.656303 513.581604,103.286827 
	C510.322693,95.848686 507.139557,88.377342 503.533417,80.647552 
	C503.136505,80.377670 502.989532,80.018845 502.989349,79.654617 
	C502.554138,77.913483 502.119141,76.536583 502.041595,75.071564 
	C502.638000,74.927124 502.876892,74.870789 503.115784,74.814468 
	C503.301544,75.103233 503.487305,75.391998 503.943970,76.060089 
	C504.392548,76.737122 504.654633,76.894173 505.001038,76.910591 
	C505.001038,76.910591 505.045441,76.944328 505.055450,77.292900 
	C505.741150,78.703941 506.416870,79.766418 507.111511,80.851761 
	C507.130463,80.874641 507.182281,80.903702 507.221832,81.142815 
	C507.427063,81.702431 507.690308,81.863098 508.051117,81.863937 
	C508.051117,81.863937 508.096008,81.909988 508.097717,82.254974 
	C508.676025,83.138664 509.252686,83.677376 509.856873,84.555374 
	C509.986298,85.567444 510.088165,86.240234 510.156372,87.266129 
	C511.084686,89.405655 512.046631,91.192078 513.023682,93.351212 
	C513.115662,94.465332 513.192444,95.206741 513.282593,96.181679 
	C513.429565,96.729225 513.663818,96.919586 513.998352,96.992729 
	C513.998047,96.999176 514.012756,96.998787 514.012756,96.998779 
	C514.658447,100.125000 515.304138,103.251213 515.901733,106.782898 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M513.269226,95.948158 
	C513.192444,95.206741 513.115662,94.465332 513.039917,92.969360 
	C512.090637,90.447533 511.140350,88.680275 510.190063,86.913010 
	C510.088165,86.240234 509.986298,85.567444 509.929993,84.246109 
	C510.010071,83.390007 510.044556,83.182457 510.079071,82.974899 
	C512.450806,84.313179 514.822571,85.651459 517.570312,86.984947 
	C517.222107,90.412018 520.094177,94.980461 514.401611,96.981888 
	C514.012756,96.998787 513.998047,96.999176 513.934082,96.783264 
	C513.731323,96.301247 513.531006,96.094849 513.269226,95.948158 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M517.534180,46.084023 
	C517.942566,51.345821 517.964661,56.711349 517.967041,62.545601 
	C517.496033,62.824688 516.647217,62.626694 516.651001,62.446621 
	C516.764954,57.025906 516.963013,51.606968 517.534180,46.084023 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M489.301270,62.711388 
	C488.817413,61.703609 488.333527,60.695835 487.794434,59.350456 
	C489.012329,59.520584 490.285370,60.028320 491.908386,60.982094 
	C492.457520,61.728634 492.736786,61.864182 493.096252,61.834770 
	C493.919464,62.749191 494.742706,63.663624 495.889893,65.004288 
	C496.398743,65.727417 496.665527,65.889343 497.014252,65.916283 
	C497.014252,65.916283 497.045563,65.948730 497.037781,66.317093 
	C497.063385,67.407219 497.096710,68.128983 496.804260,68.764175 
	C494.964478,67.460464 493.450500,66.243332 491.769775,64.874634 
	C491.386658,64.514053 491.184326,64.292229 490.935425,63.790096 
	C490.568390,63.350643 490.261993,63.178692 489.796356,62.923218 
	C489.637146,62.839695 489.301270,62.711395 489.301270,62.711388 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M513.297119,37.997940 
	C512.964539,36.913013 513.000488,35.882969 513.061035,34.469025 
	C516.244080,33.660755 518.296753,34.541206 517.950195,38.625690 
	C516.507446,38.700737 515.086487,38.376778 513.297119,37.997940 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M476.956238,48.839520 
	C473.738556,47.933437 470.562622,46.737068 467.185699,45.256233 
	C467.458710,44.660801 467.932709,44.349834 468.704590,44.016121 
	C470.091888,44.315773 471.181335,44.638172 472.677368,45.328705 
	C474.394012,46.452209 475.704071,47.207577 477.014160,47.962936 
	C477.008789,48.158367 477.003387,48.353802 476.956238,48.839520 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M498.995178,65.908272 
	C500.205719,66.102180 502.302338,66.009033 502.474152,66.539429 
	C503.236542,68.892960 503.446899,71.425316 503.462158,73.667549 
	C502.687836,72.964806 502.308289,72.487495 501.905518,71.811111 
	C501.882324,71.612045 501.830322,71.214409 501.861206,70.867783 
	C500.926422,68.983521 499.960785,67.445892 498.995178,65.908272 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M498.677246,65.925369 
	C499.960785,67.445892 500.926422,68.983521 501.518616,70.804420 
	C500.446228,71.032547 499.747284,70.977409 498.844666,70.782318 
	C498.641022,70.642372 498.270966,70.314842 498.208557,70.096024 
	C497.823639,69.557991 497.501160,69.238770 497.155151,68.910545 
	C497.131622,68.901543 497.130066,68.850754 497.130066,68.850754 
	C497.096710,68.128983 497.063385,67.407219 497.059082,66.324577 
	C497.511841,65.956635 497.935608,65.949554 498.677246,65.925369 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M476.959900,47.628227 
	C475.704071,47.207577 474.394012,46.452209 473.019043,45.364777 
	C474.271332,45.786308 475.588470,46.539909 476.959900,47.628227 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M481.945374,52.963646 
	C481.640656,52.509476 481.335938,52.055305 480.898590,51.360935 
	C482.131927,51.491257 483.497833,51.861778 484.556732,52.604652 
	C483.481598,52.972546 482.713470,52.968098 481.945374,52.963646 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M507.046967,76.980629 
	C507.476562,78.061180 507.906158,79.141724 507.969299,80.552155 
	C507.602844,80.882034 507.182281,80.903702 507.182281,80.903702 
	C507.182281,80.903702 507.130463,80.874641 507.095367,80.471191 
	C507.055817,79.038704 507.051392,78.009666 507.046967,76.980629 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M506.720703,76.969902 
	C507.051392,78.009666 507.055817,79.038704 507.076416,80.448318 
	C506.416870,79.766418 505.741150,78.703941 505.079163,77.307541 
	C505.526733,76.968796 505.960602,76.963982 506.720703,76.969902 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M481.721863,53.100822 
	C482.713470,52.968098 483.481598,52.972546 484.633362,52.969948 
	C485.040314,53.383636 485.063599,53.804367 484.697815,54.595589 
	C483.791962,55.050251 483.275177,55.134426 482.758362,55.218605 
	C482.322296,54.931438 481.886200,54.644272 481.208557,54.251160 
	C481.144135,53.842812 481.321228,53.540409 481.721863,53.100822 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M503.197021,41.253227 
	C503.778168,40.671421 504.556580,40.344608 505.673889,40.027954 
	C505.139954,40.528152 504.267151,41.018188 503.197021,41.253227 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M501.928741,72.010193 
	C502.308289,72.487495 502.687836,72.964806 503.174805,73.746063 
	C503.282288,74.050026 503.170685,74.427956 503.143250,74.621208 
	C502.876892,74.870789 502.638000,74.927124 501.866089,74.845200 
	C501.110504,74.175407 500.887878,73.643867 500.937866,73.009277 
	C501.449921,72.607552 501.689331,72.308876 501.928741,72.010193 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M482.845215,55.552483 
	C483.275177,55.134426 483.791962,55.050251 484.698944,54.911301 
	C485.359253,55.394775 485.629364,55.933033 485.907349,56.772568 
	C484.920837,56.678020 483.926422,56.282188 482.845215,55.552483 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M509.907593,82.739037 
	C510.044556,83.182457 510.010071,83.390007 509.902435,83.906830 
	C509.252686,83.677376 508.676025,83.138664 508.119781,82.278336 
	C508.672150,82.138870 509.204102,82.321022 509.907593,82.739037 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M487.269287,58.192783 
	C486.801849,58.073524 486.566986,57.929237 486.203125,57.392113 
	C486.805542,57.088139 487.536987,57.177006 488.268402,57.265869 
	C488.012878,57.566494 487.757355,57.867123 487.269287,58.192783 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M473.086761,36.182987 
	C473.164948,36.013496 473.332550,36.009041 473.751495,36.013641 
	C473.727295,36.131134 473.451752,36.239574 473.086761,36.182987 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M507.221832,81.142815 
	C507.182281,80.903702 507.602844,80.882034 507.809631,80.838829 
	C508.035004,80.973633 508.053619,81.151642 508.061646,81.596802 
	C507.690308,81.863098 507.427063,81.702431 507.221832,81.142815 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M504.924316,76.693314 
	C504.654633,76.894173 504.392548,76.737122 504.156250,76.209656 
	C504.419495,76.036690 504.669464,76.202065 504.924316,76.693314 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M492.987793,61.636703 
	C492.736786,61.864182 492.457520,61.728634 492.195862,61.194069 
	C492.441406,61.027046 492.690094,61.186584 492.987793,61.636703 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M496.931885,65.695312 
	C496.665527,65.889343 496.398743,65.727417 496.146393,65.198471 
	C496.406464,65.028511 496.663300,65.197823 496.931885,65.695312 
z"/>
<path fill="#0201F9" opacity="1.000000" stroke="none" 
	d="
M413.023865,30.079010 
	C414.080444,30.060488 415.136993,30.041965 416.589355,30.033459 
	C416.738495,31.618765 416.952332,34.206287 416.154205,34.569378 
	C414.182281,35.466450 411.747742,35.430847 409.495087,35.460331 
	C409.322418,35.462593 409.117950,33.031624 408.966370,31.371769 
	C409.447052,31.003448 409.889618,30.995010 410.830292,31.215385 
	C411.893555,30.989138 412.458710,30.534075 413.023865,30.079010 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M456.997864,41.429146 
	C452.902527,41.009899 448.701202,40.250256 444.247406,39.187229 
	C448.293945,39.618813 452.592896,40.353783 456.997864,41.429146 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M421.735474,37.642078 
	C419.605774,38.265907 417.195984,38.617146 414.351196,38.902126 
	C416.429260,38.347076 418.942352,37.858280 421.735474,37.642078 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M441.981628,38.447807 
	C440.581451,38.949894 439.069031,39.102509 437.289490,39.510036 
	C436.611237,39.772217 436.200104,39.779480 435.491486,39.704132 
	C435.138489,39.243900 435.082947,38.866276 435.011108,38.199886 
	C437.286346,37.973526 439.577881,38.035931 441.981628,38.447807 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M464.350220,43.740997 
	C462.603729,43.487003 461.001434,43.004787 459.190948,42.237267 
	C460.819977,42.472233 462.657227,42.992500 464.350220,43.740997 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M412.724854,30.074266 
	C412.458710,30.534075 411.893555,30.989138 411.162292,31.214375 
	C411.472748,30.679541 411.949310,30.374531 412.724854,30.074266 
z"/>
<path fill="#D7A016" opacity="1.000000" stroke="none" 
	d="
M506.988556,116.982857 
	C506.520844,117.292206 506.053131,117.601555 505.118103,117.715508 
	C504.093628,118.004745 503.536499,118.489380 502.979401,118.974022 
	C502.979401,118.974030 503.000000,118.998169 502.638123,118.996124 
	C501.520111,119.664787 500.764038,120.335510 500.007935,121.006226 
	C500.007935,121.006226 500.000641,121.000320 500.003418,121.004303 
	C498.034027,122.089073 496.061829,123.169861 494.012329,124.582001 
	C490.411804,126.738289 486.888550,128.563217 483.158325,130.648773 
	C476.977234,131.004837 476.196411,129.470703 474.760284,124.145317 
	C473.158905,118.207260 470.438019,112.423141 467.322174,107.087219 
	C464.722626,102.635513 460.751129,99.003372 457.561371,94.873756 
	C452.222076,87.961136 444.734558,84.426308 436.968231,81.155579 
	C419.121796,73.639694 400.698608,74.820396 382.423309,78.231133 
	C376.018097,79.426552 370.193176,83.675583 364.060364,86.434799 
	C362.867828,86.971344 361.476440,87.065987 359.916016,87.082809 
	C359.725494,86.401131 359.795715,86.001106 360.258667,85.251770 
	C363.107300,80.262573 365.563232,75.622704 368.019196,70.982826 
	C368.113861,70.682289 368.280579,70.429214 369.069702,70.082520 
	C374.735901,64.946136 379.851746,59.950840 384.967590,54.955551 
	C384.967621,54.955563 385.003662,54.990204 385.335632,54.989605 
	C386.476715,54.365261 387.285858,53.741516 388.095001,53.117775 
	C389.491821,52.476986 390.888672,51.836189 392.971252,51.060501 
	C406.576416,45.524082 420.023041,42.774136 434.000153,44.078365 
	C445.130829,45.116993 456.367615,45.880337 466.478302,51.812447 
	C470.978424,54.452732 476.130127,55.982414 480.986084,58.016186 
	C480.986084,58.016186 481.000000,58.000305 481.018005,58.354797 
	C485.271881,63.206711 489.528961,67.684402 493.735413,72.209145 
	C496.154968,74.811806 498.492554,77.490662 500.895508,80.510498 
	C502.283783,82.930206 503.643616,84.974648 505.003479,87.019089 
	C505.003448,87.019096 505.069916,87.445389 505.138000,87.918030 
	C505.469940,88.606827 505.733795,88.822968 505.997620,89.039108 
	C506.045563,89.431969 506.093506,89.824821 506.130249,90.887840 
	C507.046661,93.078896 507.974274,94.599785 508.901855,96.120674 
	C509.236847,97.181656 509.571838,98.242638 509.671875,99.856110 
	C510.224731,101.994247 511.012543,103.579887 511.800354,105.165527 
	C511.877655,106.502151 511.954956,107.838783 511.778442,109.794495 
	C511.343018,111.606651 511.161346,112.799728 510.979675,113.992798 
	C510.867859,114.303726 510.679199,114.557503 509.849670,114.874237 
	C508.519989,115.657173 507.754272,116.320015 506.988556,116.982857 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M504.984650,86.653809 
	C503.643616,84.974648 502.283783,82.930206 500.952728,80.452347 
	C500.981506,80.018921 501.096222,79.902855 501.410217,79.940918 
	C502.145966,79.992271 502.567749,80.005554 502.989532,80.018845 
	C502.989532,80.018845 503.136505,80.377670 503.196899,80.561157 
	C503.826782,82.592613 504.396301,84.440575 504.984650,86.653809 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M511.840668,104.800400 
	C511.012543,103.579887 510.224731,101.994247 509.690491,100.190689 
	C510.589661,101.460266 511.235321,102.947762 511.840668,104.800400 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M508.918365,95.751770 
	C507.974274,94.599785 507.046661,93.078896 506.158966,91.183289 
	C507.110870,92.333344 508.022858,93.858109 508.918365,95.751770 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M511.270508,113.865898 
	C511.161346,112.799728 511.343018,111.606651 511.818909,110.194725 
	C512.437073,110.397278 512.760986,110.818680 513.132202,111.511047 
	C512.640137,112.434334 512.100769,113.086662 511.270508,113.865898 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M505.929077,88.791565 
	C505.733795,88.822968 505.469940,88.606827 505.154266,88.133148 
	C505.355133,88.098419 505.607849,88.321213 505.929077,88.791565 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M416.998138,513.003540 
	C415.997833,508.770325 414.490234,504.578552 414.110565,500.286987 
	C413.395569,492.204742 413.549316,484.047058 412.886108,475.958099 
	C412.628387,472.814545 410.918823,469.815613 410.291534,466.666962 
	C409.931732,464.861053 409.648865,461.995758 410.647614,461.135498 
	C416.404266,456.176971 416.776825,449.376251 416.821838,442.879181 
	C416.877655,434.828491 415.685181,426.769135 414.998657,418.302979 
	C422.720734,418.524536 430.401276,420.595917 436.828003,425.663055 
	C438.889862,427.288757 439.925293,430.716064 440.596863,433.518860 
	C443.518768,445.712494 438.116089,454.321259 426.367889,457.874573 
	C423.051208,458.877716 420.271027,461.654694 415.803802,464.553009 
	C419.672607,466.591888 422.518555,468.896088 425.717316,469.602203 
	C431.531830,470.885803 434.985291,474.030151 435.701599,479.756927 
	C436.425293,485.542633 436.499329,491.406403 437.044159,497.218536 
	C437.602844,503.179077 436.592010,509.701843 443.239990,514.241150 
	C435.833923,516.148743 429.268890,517.435303 422.639069,513.767578 
	C421.303040,513.028442 419.450012,513.223755 417.414062,512.995972 
	C416.992767,513.003662 417.000000,513.000000 416.998138,513.003540 
z"/>
<path fill="#FC0821" opacity="1.000000" stroke="none" 
	d="
M321.054596,627.015259 
	C320.948242,629.523010 320.242340,632.190247 320.858521,634.505737 
	C322.202026,639.554626 324.218445,644.424438 326.096191,649.754333 
	C320.592010,648.769409 314.892578,647.304688 309.956207,643.492737 
	C308.688751,642.514038 307.215485,641.801758 305.486420,640.915649 
	C304.493011,639.181274 304.179047,637.258606 303.155273,635.852905 
	C294.315460,623.715393 293.324951,609.795410 294.396179,595.638916 
	C295.095551,586.396790 297.185852,577.242920 302.796143,569.522339 
	C308.192657,562.095825 314.547363,555.759949 324.780151,554.200806 
	C315.909973,576.194031 319.937866,598.788086 319.081055,620.983826 
	C319.035034,622.175903 319.844879,623.401062 320.635498,625.147095 
	C321.028015,626.127319 321.041290,626.571289 321.054596,627.015259 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M321.321411,627.299805 
	C321.041290,626.571289 321.028015,626.127319 320.972260,625.353210 
	C320.964203,613.013855 320.998657,601.004700 321.087921,588.539856 
	C321.550293,593.510071 321.957794,598.935974 322.775116,605.096619 
	C323.416473,612.485535 323.416840,619.158020 323.942322,625.788879 
	C324.507385,632.919250 325.213409,640.116638 329.984131,646.040710 
	C329.983521,646.483765 329.982880,646.926758 329.994507,647.703491 
	C323.800751,644.216248 323.224640,637.685608 322.052612,631.454224 
	C321.812988,630.180237 321.738678,628.875183 321.321411,627.299805 
z"/>
<path fill="#FBF9F9" opacity="1.000000" stroke="none" 
	d="
M330.328369,646.126587 
	C325.213409,640.116638 324.507385,632.919250 323.942322,625.788879 
	C323.416840,619.158020 323.416473,612.485535 323.049805,605.404968 
	C323.554810,593.026428 323.667786,581.016663 324.968262,569.136841 
	C326.596771,554.259827 336.961456,551.555298 346.761902,554.616760 
	C352.475128,556.401489 355.395813,561.091309 355.769501,566.717041 
	C356.695557,580.658203 357.666260,594.637329 357.557709,608.592957 
	C357.475494,619.160828 355.756927,629.716003 354.884705,640.710022 
	C354.653748,641.834778 354.304688,642.526306 353.942383,643.512085 
	C353.086182,644.288391 352.136292,644.647217 351.416748,645.271545 
	C344.171936,651.557556 338.676117,651.824829 330.328369,646.126587 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M480.867981,57.685970 
	C476.130127,55.982414 470.978424,54.452732 466.478302,51.812447 
	C456.367615,45.880337 445.130829,45.116993 434.000153,44.078365 
	C420.023041,42.774136 406.576416,45.524082 393.270996,50.915226 
	C395.559906,49.159389 398.234833,47.413929 400.973389,45.308807 
	C401.810455,44.655827 402.583862,44.362514 403.669312,44.052143 
	C408.253967,43.537334 412.520386,42.977558 416.800232,42.552532 
	C435.575836,40.687958 453.750122,42.612705 470.749237,51.450108 
	C474.179169,53.233231 477.421204,55.377819 480.867981,57.685970 
z"/>
<path fill="#D4A548" opacity="1.000000" stroke="none" 
	d="
M384.618011,54.982735 
	C379.851746,59.950840 374.735901,64.946136 369.265198,69.962006 
	C374.029694,64.991692 379.149078,60.000805 384.618011,54.982735 
z"/>
<path fill="#D4A548" opacity="1.000000" stroke="none" 
	d="
M367.701294,71.120758 
	C365.563232,75.622704 363.107300,80.262573 360.301575,84.949554 
	C362.429016,80.417343 364.906189,75.838020 367.701294,71.120758 
z"/>
<path fill="#D4A548" opacity="1.000000" stroke="none" 
	d="
M387.761963,53.086723 
	C387.285858,53.741516 386.476715,54.365261 385.315613,54.977749 
	C385.785431,54.329556 386.607178,53.692616 387.761963,53.086723 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M507.317993,116.986389 
	C507.754272,116.320015 508.519989,115.657173 509.636963,114.985268 
	C509.728210,115.468002 509.468231,115.959816 509.085602,116.701431 
	C508.524475,116.964142 508.085938,116.977036 507.317993,116.986389 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M500.331512,121.001968 
	C500.764038,120.335510 501.520111,119.664787 502.627625,118.986359 
	C502.720367,119.465927 502.461670,119.953201 502.074463,120.684601 
	C501.515656,120.951721 501.085388,120.974724 500.331512,121.001968 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M503.283020,118.961838 
	C503.536499,118.489380 504.093628,118.004745 504.825378,117.740158 
	C504.528900,118.290016 504.057770,118.619835 503.283020,118.961838 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M540.922241,608.643433 
	C538.658142,602.924377 536.435547,596.799683 534.247864,590.296143 
	C536.509827,596.024170 538.736816,602.130981 540.922241,608.643433 
z"/>
<path fill="#B72838" opacity="1.000000" stroke="none" 
	d="
M533.828430,586.748657 
	C532.892700,585.306030 532.023926,583.482361 531.162231,581.312927 
	C532.078003,582.767273 532.986755,584.567505 533.828430,586.748657 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M631.057007,607.000000 
	C631.053589,620.308228 630.935364,633.118164 631.117920,645.923706 
	C631.171326,649.669250 630.354980,651.404846 626.176819,651.115234 
	C621.044861,650.759521 615.866577,650.924988 610.714111,651.069946 
	C607.905518,651.148987 606.929321,650.091919 606.954712,647.294189 
	C607.216125,618.376953 607.408508,589.458984 607.555847,560.540833 
	C607.568970,557.956482 607.131348,555.369812 606.835083,552.013428 
	C614.523621,552.013428 621.620667,551.857849 628.691650,552.215698 
	C629.549561,552.259094 630.932678,555.062988 630.947998,556.598816 
	C631.113892,573.231262 631.054749,589.865906 631.057007,607.000000 
z"/>
<path fill="#FC0821" opacity="1.000000" stroke="none" 
	d="
M669.662903,633.623291 
	C671.082947,631.217773 672.334229,629.136047 674.148315,626.118042 
	C673.718872,634.121948 673.429993,641.143982 672.865723,648.143799 
	C672.786926,649.120728 671.388611,650.745056 670.542725,650.783447 
	C665.192566,651.026306 659.825989,650.906738 655.041870,650.906738 
	C659.981628,645.109985 664.737915,639.528564 669.662903,633.623291 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M304.017639,358.017761 
	C298.820801,354.125244 293.834198,349.992584 288.823822,345.516998 
	C289.576508,345.362213 290.662689,345.286102 291.082764,345.778412 
	C296.887177,352.580475 304.813599,352.930176 312.807648,353.020203 
	C319.290039,353.093201 325.873718,352.315338 332.234222,353.198608 
	C342.199249,354.582428 352.181396,344.747253 352.624573,336.290100 
	C353.214111,325.040070 353.290222,313.692719 352.484802,302.466278 
	C351.998840,295.693146 346.224670,291.959290 340.498627,288.972015 
	C338.478333,287.918030 336.160706,287.227905 334.469452,285.793243 
	C331.218231,283.035339 331.811066,279.142883 335.900909,277.731567 
	C341.134521,275.925629 346.615967,274.831421 352.019470,273.552490 
	C352.192535,273.511505 352.682617,274.809845 353.062073,275.857941 
	C353.128693,276.399048 353.158112,276.566071 352.788666,276.968262 
	C351.554810,277.352600 350.719849,277.501801 349.612732,277.483948 
	C348.647247,277.611053 347.953888,277.905243 346.839264,278.178101 
	C345.888672,278.324524 345.359375,278.492249 344.565247,278.507935 
	C343.602020,278.581177 342.903595,278.806458 341.780518,278.997894 
	C340.526886,279.126221 339.697815,279.288391 338.595642,279.352570 
	C337.850586,279.769287 337.378662,280.284027 336.871277,280.817322 
	C336.835785,280.835876 336.800690,280.907898 336.488403,280.963989 
	C336.405914,281.986938 336.635681,282.953766 336.896484,284.002747 
	C336.927551,284.084869 337.092865,284.144165 337.188232,284.425110 
	C338.150208,284.960022 339.016876,285.214050 340.258148,285.609680 
	C341.164215,285.763733 341.695679,285.776123 342.313446,286.045776 
	C342.884155,286.329224 343.368561,286.355377 344.183411,286.574707 
	C346.059082,287.591553 347.604309,288.415253 349.159821,289.533600 
	C350.053009,290.435913 350.935944,291.043610 352.079987,291.934082 
	C353.277496,294.815918 355.079651,297.438721 355.009094,300.010162 
	C354.567657,316.102295 357.575104,332.373108 352.470032,348.237366 
	C350.796539,349.720703 349.430878,351.223846 347.666016,352.793823 
	C343.799866,353.795135 340.332947,354.729645 336.564636,355.493927 
	C335.242493,355.568665 334.221771,355.813660 332.724823,356.015381 
	C328.407196,356.038788 324.565796,356.105438 320.518341,355.879211 
	C318.587769,355.634552 316.863220,355.682770 314.719543,355.566956 
	C312.486115,355.389587 310.671753,355.376251 308.857422,355.362915 
	C306.742676,355.071991 304.141479,353.848419 304.017639,358.017761 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M288.864624,344.833008 
	C286.861664,343.032318 284.900299,340.867279 282.970032,338.349670 
	C284.969482,340.154266 286.937866,342.311462 288.864624,344.833008 
z"/>
<path fill="#E6DCF5" opacity="1.000000" stroke="none" 
	d="
M272.681396,305.935913 
	C272.709839,306.473785 272.587799,306.853363 272.465759,307.232971 
	C272.365204,307.127441 272.163879,306.999969 272.181244,306.920105 
	C272.265656,306.532288 272.408630,306.157227 272.681396,305.935913 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M317.470551,180.993683 
	C314.760559,182.020401 311.626953,182.873535 308.521698,183.819687 
	C306.058167,184.570282 303.629303,185.434753 300.973083,186.566589 
	C293.711456,189.200516 293.421173,190.211807 296.923035,196.618469 
	C299.367889,201.091354 301.385681,205.797684 303.718689,210.720306 
	C306.414429,215.080856 308.989410,219.118469 311.554718,223.162201 
	C312.408661,224.508240 313.238007,225.869888 314.061066,227.585510 
	C308.465027,220.522873 302.511871,213.343506 297.448914,205.583389 
	C294.425873,200.949966 292.798645,195.405884 290.483948,189.920929 
	C294.058350,184.082977 300.399994,184.052338 305.760071,182.294907 
	C309.302399,181.133438 313.277710,181.292435 317.470551,180.993683 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M431.059021,248.382217 
	C452.839081,248.208038 473.355347,243.523712 492.791687,232.949341 
	C492.561371,234.084778 491.945679,235.198792 490.732025,236.584442 
	C488.448547,237.592239 486.763062,238.328430 484.818207,239.292480 
	C482.393921,240.374588 480.228973,241.228867 477.735596,242.071625 
	C476.870148,242.651138 476.333099,243.242188 475.796021,243.833252 
	C475.796051,243.833267 475.394562,243.899094 474.880981,243.936798 
	C473.936218,244.018997 473.505035,244.063461 472.763245,244.075943 
	C471.954773,244.358704 471.456940,244.673462 470.645447,244.996643 
	C469.917023,245.058487 469.502228,245.111923 468.734009,245.104065 
	C467.481567,245.599030 466.582520,246.155289 465.404480,246.724075 
	C464.753815,246.760376 464.382233,246.784149 463.788177,246.598114 
	C462.025024,246.877548 460.484344,247.366791 458.943634,247.856018 
	C458.226746,247.910034 457.509857,247.964050 456.088928,247.996918 
	C454.573975,248.288528 453.763123,248.601273 452.952240,248.914032 
	C452.522430,248.954010 452.092651,248.994003 451.003754,249.028076 
	C449.884827,249.085892 449.424988,249.149582 448.965149,249.213287 
	C447.914886,249.180603 446.864624,249.147903 445.035400,249.094879 
	C443.192078,249.081772 442.127686,249.089020 440.616272,249.042908 
	C437.769775,249.005219 435.370331,249.020920 432.631042,249.017563 
	C431.843781,249.067642 431.396362,249.136780 430.948944,249.205933 
	C430.959106,249.020370 430.969238,248.834839 431.059021,248.382217 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M478.064026,242.083130 
	C480.228973,241.228867 482.393921,240.374588 485.183472,239.285706 
	C486.542297,239.043304 487.276550,239.035507 488.010773,239.027710 
	C486.452728,241.797958 484.894684,244.568222 483.182983,247.611694 
	C480.860443,246.137711 479.628876,245.356094 478.208069,243.982925 
	C478.033936,242.955307 478.048981,242.519211 478.064026,242.083130 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M473.073822,244.107941 
	C473.505035,244.063461 473.936218,244.018997 474.691284,244.010223 
	C475.015106,246.142914 475.015106,248.239899 475.015106,251.263382 
	C471.095093,250.049576 467.915955,249.065170 464.369385,247.739792 
	C464.004852,247.201859 464.007721,247.004898 464.010620,246.807922 
	C464.382233,246.784149 464.753815,246.760376 465.776672,246.810226 
	C467.314392,246.311020 468.200928,245.738190 469.087433,245.165344 
	C469.502228,245.111923 469.917023,245.058487 470.816010,245.227722 
	C471.891418,245.002899 472.482635,244.555435 473.073822,244.107941 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M432.970886,249.036621 
	C435.370331,249.020920 437.769775,249.005219 440.603821,249.358521 
	C441.024902,250.150528 441.011383,250.573532 440.997833,250.996552 
	C438.630280,250.994598 436.262695,250.992661 433.444702,250.663239 
	C432.986450,249.902695 432.978668,249.469650 432.970886,249.036621 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M441.224792,251.264755 
	C441.011383,250.573532 441.024902,250.150528 441.050903,249.411896 
	C442.127686,249.089020 443.192078,249.081772 444.647461,249.173218 
	C445.022125,251.896225 443.746490,252.522903 441.224792,251.264755 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M407.026550,245.434937 
	C408.507446,245.546677 410.040802,246.005020 411.788300,246.727905 
	C409.816620,248.803436 408.207581,248.269257 407.026550,245.434937 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M463.788177,246.598114 
	C464.007721,247.004898 464.004852,247.201859 463.992432,247.694000 
	C462.430206,248.131836 460.877502,248.274460 459.134216,248.136566 
	C460.484344,247.366791 462.025024,246.877548 463.788177,246.598114 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M488.301941,238.894257 
	C487.276550,239.035507 486.542297,239.043304 485.442810,239.057861 
	C486.763062,238.328430 488.448547,237.592239 490.497894,236.828003 
	C490.895721,237.002899 490.929718,237.205841 490.983063,237.717133 
	C490.199341,238.270584 489.396210,238.515701 488.301941,238.894257 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M489.705444,248.059906 
	C488.931976,249.004593 487.806427,249.964508 486.334015,250.947693 
	C487.109222,250.005676 488.231293,249.040390 489.705444,248.059906 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M448.978027,249.535919 
	C449.424988,249.149582 449.884827,249.085892 450.687531,249.097916 
	C450.723602,249.959930 450.416809,250.746201 450.110016,251.532471 
	C449.736969,250.974503 449.363922,250.416519 448.978027,249.535919 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M432.631042,249.017548 
	C432.978668,249.469650 432.986450,249.902695 433.001160,250.660583 
	C432.331665,250.597122 431.655273,250.208847 430.963928,249.513245 
	C431.396362,249.136780 431.843781,249.067642 432.631042,249.017548 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M453.139526,249.186981 
	C453.763123,248.601273 454.573975,248.288528 455.731232,248.032501 
	C455.160675,248.546127 454.243744,249.003021 453.139526,249.186981 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M477.735596,242.071609 
	C478.048981,242.519211 478.033936,242.955307 478.010986,243.720154 
	C477.511505,244.023544 477.019958,243.998199 476.162201,243.903046 
	C476.333099,243.242188 476.870148,242.651138 477.735596,242.071609 
z"/>
<path fill="#0602EC" opacity="1.000000" stroke="none" 
	d="
M396.981628,265.643066 
	C396.458771,265.779327 395.897217,265.631073 395.169373,265.234741 
	C395.649719,265.110657 396.296295,265.234619 396.981628,265.643066 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M265.265991,301.232025 
	C264.870758,299.807281 264.724976,298.628662 264.310699,297.215698 
	C264.022797,296.547913 264.003418,296.114441 263.943390,295.358337 
	C263.382202,291.596588 262.861633,288.157471 262.171143,284.354919 
	C262.002106,283.247864 262.002991,282.504303 261.979584,281.389954 
	C262.095367,278.530243 262.235474,276.041290 262.674255,273.289337 
	C264.784485,284.481415 266.596069,295.936432 268.253418,307.692810 
	C267.412537,307.199219 266.725861,306.404297 265.947449,305.283905 
	C265.742310,303.798340 265.628906,302.638245 265.265991,301.232025 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M262.021820,238.381958 
	C262.654358,234.930450 263.292450,231.849518 263.885803,228.358673 
	C263.887268,227.213165 263.933411,226.477554 264.288147,225.359329 
	C264.997681,224.962204 265.398590,224.947708 265.799530,224.933197 
	C265.046265,232.748749 264.292969,240.564316 263.259369,248.669525 
	C262.660919,246.585114 262.342834,244.211014 261.996582,241.405365 
	C261.988068,240.233368 262.007721,239.492966 262.021820,238.381958 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M265.929016,224.628250 
	C265.398590,224.947708 264.997681,224.962204 264.295288,224.990051 
	C264.130676,224.146103 264.267517,223.288803 264.647827,222.214127 
	C265.107941,222.275604 265.324554,222.554443 265.792542,222.919891 
	C266.048798,223.445419 266.053650,223.884354 265.929016,224.628250 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M269.484039,312.869507 
	C269.012909,312.548340 268.665710,311.931671 268.537140,311.183228 
	C269.039825,311.559021 269.323883,312.066498 269.484039,312.869507 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M493.979431,232.901031 
	C498.171051,229.184967 502.750702,225.564789 507.693115,221.930878 
	C505.812042,224.405914 503.568237,226.894684 500.838593,229.712051 
	C499.840912,230.652451 499.329041,231.264252 498.817200,231.876038 
	C497.333923,232.249680 495.850677,232.623306 493.979431,232.901031 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M517.786926,228.054443 
	C516.762512,230.114990 515.377991,232.180603 513.972534,234.589447 
	C513.951599,234.932678 513.958862,234.970795 513.577209,234.931244 
	C512.458862,234.936600 511.722229,234.981506 510.985565,235.026398 
	C513.132629,232.704102 515.279724,230.381805 517.786926,228.054443 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M510.900452,235.236618 
	C511.722229,234.981506 512.458862,234.936600 513.563477,234.919403 
	C513.913269,235.368927 513.895081,235.790787 513.859497,236.528992 
	C511.585876,238.345993 509.329773,239.846603 506.706909,241.006561 
	C507.611206,239.125275 508.882263,237.584641 510.153320,236.044006 
	C510.449982,235.929199 510.670654,235.730133 510.900452,235.236618 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M509.770874,236.020767 
	C508.882263,237.584641 507.611206,239.125275 506.358765,241.002563 
	C506.377411,241.339203 506.351105,241.373489 505.955200,241.245941 
	C505.023346,241.110489 504.487396,241.102570 503.951416,241.094620 
	C505.763733,239.395584 507.576080,237.696564 509.770874,236.020767 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M503.702942,241.287231 
	C504.487396,241.102570 505.023346,241.110489 505.937378,241.258392 
	C505.077057,242.581696 503.838684,243.764999 502.260437,244.934296 
	C502.431915,243.773468 502.943176,242.626648 503.702942,241.287231 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M499.161652,231.891144 
	C499.329041,231.264252 499.840912,230.652451 500.675049,230.011414 
	C500.500275,230.623550 500.003204,231.264908 499.161652,231.891144 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M512.167969,220.867157 
	C512.107971,220.677292 512.299438,220.404282 512.716064,220.013824 
	C512.767273,220.192245 512.593445,220.488129 512.167969,220.867157 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M520.032227,601.498840 
	C520.271606,601.082947 520.498413,601.116394 521.052490,601.256592 
	C521.166748,602.298828 520.953735,603.234131 520.462891,604.411255 
	C520.129944,603.751404 520.074829,602.849731 520.032227,601.498840 
z"/>
<path fill="#6D1D21" opacity="1.000000" stroke="none" 
	d="
M518.529663,609.522705 
	C518.351562,609.296631 518.447815,608.853333 518.812012,608.189575 
	C518.987976,608.414551 518.896057,608.860046 518.529663,609.522705 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M466.139191,281.550690 
	C463.588379,283.191864 460.679565,284.617828 457.409180,286.046326 
	C459.958801,284.477692 462.869995,282.906586 466.139191,281.550690 
z"/>
<path fill="#6856CE" opacity="1.000000" stroke="none" 
	d="
M456.699768,286.029724 
	C456.732147,286.774780 456.415680,287.502167 455.956635,288.502014 
	C455.411896,288.866425 455.009766,288.958374 454.299530,289.017639 
	C454.777924,287.993988 455.564392,287.003021 456.699768,286.029724 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M379.975647,285.655945 
	C379.063171,285.567169 378.127930,285.120209 377.056152,284.380829 
	C377.930695,284.491547 378.941772,284.894684 379.975647,285.655945 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M376.682404,283.781464 
	C376.272003,283.907990 375.777679,283.769653 375.176300,283.389526 
	C375.578979,283.270691 376.088745,283.393677 376.682404,283.781464 
z"/>
<path fill="#5544C2" opacity="1.000000" stroke="none" 
	d="
M372.798401,281.961914 
	C372.798981,282.149078 372.377319,282.142914 372.201843,282.026764 
	C372.283539,281.865326 372.540649,281.820038 372.798401,281.961914 
z"/>
<path fill="#D09419" opacity="1.000000" stroke="none" 
	d="
M373.998535,364.026245 
	C372.903229,364.360565 371.807953,364.694855 370.110748,364.744995 
	C368.704712,364.359467 367.846802,364.022339 367.104431,364.191742 
	C353.059326,367.396454 339.162384,365.955139 325.330688,362.870270 
	C323.784332,362.525421 322.365509,361.608704 320.887573,360.957153 
	C322.049561,359.504242 322.908478,359.082367 323.792175,359.021820 
	C329.476837,358.632416 335.192566,358.552216 340.847961,357.920990 
	C346.474823,357.292938 352.238739,355.396576 355.224518,350.653564 
	C357.647125,346.805176 358.619446,341.577454 358.831909,336.897766 
	C359.329529,325.938416 359.143402,314.937775 358.926727,303.959869 
	C358.777954,296.422913 356.594025,289.673615 349.674988,285.366058 
	C348.672760,284.742126 347.872925,283.793152 346.979462,282.994598 
	C347.087067,282.424622 347.194672,281.854675 347.302307,281.284729 
	C350.345703,281.178741 353.669647,280.227905 356.379150,281.127502 
	C364.057556,283.676819 371.484619,286.983215 379.010651,289.991425 
	C379.010651,289.991425 379.000000,290.000000 379.000916,290.371643 
	C381.006470,296.864716 384.225098,302.884125 384.749817,309.129852 
	C385.690643,320.329285 385.280548,331.674805 384.862274,342.938019 
	C384.717499,346.836212 383.458923,350.979706 381.638336,354.449310 
	C379.775146,358.000214 376.599609,360.862518 373.998535,364.026245 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M378.869812,289.674683 
	C371.484619,286.983215 364.057556,283.676819 356.379150,281.127502 
	C353.669647,280.227905 350.345703,281.178741 347.302307,281.284729 
	C347.194672,281.854675 347.087067,282.424622 346.979462,282.994598 
	C347.872925,283.793152 348.672760,284.742126 349.674988,285.366058 
	C356.594025,289.673615 358.777954,296.422913 358.926727,303.959869 
	C359.143402,314.937775 359.329529,325.938416 358.831909,336.897766 
	C358.619446,341.577454 357.647125,346.805176 355.224518,350.653564 
	C352.238739,355.396576 346.474823,357.292938 340.847961,357.920990 
	C335.192566,358.552216 329.476837,358.632416 323.792175,359.021820 
	C322.908478,359.082367 322.049561,359.504242 320.887573,360.957153 
	C322.365509,361.608704 323.784332,362.525421 325.330688,362.870270 
	C339.162384,365.955139 353.059326,367.396454 367.104431,364.191742 
	C367.846802,364.022339 368.704712,364.359467 369.762268,364.750702 
	C359.679749,369.074615 348.793427,368.746368 338.212280,367.606140 
	C329.770813,366.696472 321.512939,363.663513 313.333496,361.068939 
	C311.528595,360.496429 310.397980,357.798187 308.907166,355.720825 
	C310.671753,355.376251 312.486115,355.389587 314.821472,355.843140 
	C317.136444,356.246246 318.930420,356.209198 320.724365,356.172119 
	C324.565796,356.105438 328.407196,356.038788 332.884827,356.266846 
	C334.636017,356.262421 335.751038,355.963287 336.866028,355.664185 
	C340.332947,354.729645 343.799866,353.795135 347.988892,352.845276 
	C350.066589,351.305634 351.422241,349.781372 352.777893,348.257111 
	C357.575104,332.373108 354.567657,316.102295 355.009094,300.010162 
	C355.079651,297.438721 353.277496,294.815918 352.103027,291.639221 
	C350.959808,290.454041 350.054657,289.846497 349.149536,289.238953 
	C347.604309,288.415253 346.059082,287.591553 344.178253,286.364136 
	C343.304138,285.903107 342.765625,285.845825 342.227112,285.788513 
	C341.695679,285.776123 341.164215,285.763733 340.206787,285.329773 
	C338.884827,284.653503 337.988831,284.398834 337.092865,284.144165 
	C337.092865,284.144165 336.927551,284.084869 336.954437,283.630219 
	C336.921112,282.419678 336.860901,281.663788 336.800659,280.907898 
	C336.800690,280.907898 336.835785,280.835876 337.160522,280.859039 
	C337.946442,280.404968 338.407593,279.927765 338.868774,279.450562 
	C339.697815,279.288391 340.526886,279.126221 341.904236,279.237305 
	C343.245056,279.227051 344.037537,278.943512 344.830048,278.659973 
	C345.359375,278.492249 345.888672,278.324524 346.981079,278.393433 
	C348.324432,278.303711 349.104645,277.977356 349.884888,277.651031 
	C350.719849,277.501801 351.554810,277.352600 352.824036,277.220215 
	C355.878265,278.678284 358.446594,280.226410 361.135101,281.525940 
	C363.166412,282.507812 365.398224,283.068207 367.457642,283.999969 
	C371.247040,285.714539 374.975281,287.564453 378.869812,289.674683 
z"/>
<path fill="#CE963C" opacity="1.000000" stroke="none" 
	d="
M374.370819,364.030243 
	C376.599609,360.862518 379.775146,358.000214 381.638336,354.449310 
	C383.458923,350.979706 384.717499,346.836212 384.862274,342.938019 
	C385.280548,331.674805 385.690643,320.329285 384.749817,309.129852 
	C384.225098,302.884125 381.006470,296.864716 379.005249,290.366333 
	C385.256714,293.645905 386.540619,300.115326 386.832397,306.254486 
	C387.422241,318.664734 387.376190,331.137451 386.863770,343.554382 
	C386.603088,349.871094 385.868256,356.598297 380.316711,361.112701 
	C378.721680,362.409790 376.615112,363.077789 374.370819,364.030243 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M305.471008,362.909424 
	C304.849243,362.323364 304.601044,361.769348 304.437073,360.914368 
	C310.312836,362.554016 316.060699,364.638763 321.904175,366.407684 
	C338.108551,371.312988 354.547455,371.101624 370.956207,367.824280 
	C374.895752,367.037384 378.516449,364.654236 382.643036,362.997314 
	C381.851868,364.233429 380.893585,366.253265 379.519348,366.593628 
	C371.420837,368.599335 363.301849,370.960999 355.046844,371.804810 
	C337.916199,373.555908 321.631622,369.325897 305.471008,362.909424 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M389.115417,304.321289 
	C389.809631,305.229828 390.876648,306.603882 390.891052,307.988861 
	C391.020294,320.428131 391.346558,332.885956 390.806000,345.303131 
	C390.525360,351.750183 389.732086,358.584290 383.346436,363.001160 
	C387.597076,355.486084 389.330750,347.348999 389.078613,338.532471 
	C388.757233,327.294037 389.000305,316.039459 389.115417,304.321289 
z"/>
<path fill="#4530AD" opacity="1.000000" stroke="none" 
	d="
M432.757874,149.106384 
	C432.225250,149.702789 431.384308,150.314911 430.183960,150.869385 
	C430.175690,150.432388 430.526886,150.053040 431.053009,149.480316 
	C431.635132,149.231995 432.042358,149.177048 432.757874,149.106384 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M212.975998,610.000000 
	C212.977554,592.000122 213.088409,574.499023 212.896698,557.001282 
	C212.853790,553.085938 213.917297,551.635986 217.938309,551.905151 
	C222.749939,552.227173 227.601181,552.047668 232.432449,551.948242 
	C234.895309,551.897644 236.057755,552.663208 236.051682,555.356201 
	C235.981308,586.522156 235.991043,617.688354 235.959671,648.854431 
	C235.959198,649.321594 235.706299,649.788513 235.365356,650.967285 
	C228.828888,650.967285 222.128387,651.135010 215.457153,650.769165 
	C214.556213,650.719727 213.113846,648.000244 213.089233,646.498047 
	C212.892670,634.501282 212.977448,622.499939 212.975998,610.000000 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M255.105225,436.406189 
	C255.453995,430.750305 255.724243,425.551910 256.038055,420.356140 
	C256.075775,419.731964 256.349762,419.122101 256.528809,418.451050 
	C262.263306,417.960358 267.833435,417.423798 273.413666,417.031189 
	C276.277313,416.829681 277.631866,417.266479 277.346832,421.201202 
	C276.841888,428.172638 277.906921,435.242523 277.932648,442.272614 
	C277.952423,447.680817 276.253815,453.340698 277.377319,458.434814 
	C278.665955,464.277771 276.829712,469.597687 277.007111,475.149780 
	C277.361969,486.253021 277.537140,497.364014 278.058289,508.459015 
	C278.289368,513.377686 275.921051,515.030762 271.523682,515.006653 
	C268.695587,514.991150 265.867371,515.007019 263.039185,515.004333 
	C256.540649,514.998169 255.035950,513.513367 255.012192,506.736542 
	C254.966705,493.760345 254.993607,480.783875 255.000443,467.807495 
	C255.005890,457.493164 255.026184,447.178802 255.105225,436.406189 
z"/>
<path fill="#772026" opacity="1.000000" stroke="none" 
	d="
M439.270752,648.448608 
	C438.820496,647.236206 438.092743,645.566956 438.077271,643.891113 
	C437.953522,630.455383 437.964294,617.018494 437.848602,603.075623 
	C437.792114,602.225586 437.820892,601.881775 437.929260,601.289551 
	C440.528534,601.151306 443.048279,601.261536 445.818665,601.139893 
	C447.168640,601.160889 448.267883,601.413818 449.782288,602.060425 
	C450.410553,602.725952 450.687683,602.888855 451.028870,602.942810 
	C451.028870,602.942810 451.039581,602.988098 451.161072,603.274780 
	C452.203491,604.017273 453.124481,604.473206 454.045441,604.929077 
	C454.045441,604.929077 454.010864,604.956543 454.118225,605.191284 
	C454.420258,605.717285 454.690552,605.890442 455.036499,605.945557 
	C455.036499,605.945557 455.041595,605.990540 455.053345,606.311584 
	C455.762543,607.460693 456.459930,608.288818 457.157349,609.116882 
	C457.157349,609.116882 457.262238,609.315063 457.177856,609.751587 
	C457.626007,610.788391 458.158600,611.388611 458.691193,611.988831 
	C458.811005,612.725952 458.930847,613.463013 458.794556,614.738525 
	C458.732544,615.885742 458.926666,616.494629 459.120789,617.103455 
	C459.360901,618.527832 459.601013,619.952148 459.581421,621.970947 
	C459.462250,624.006592 459.602844,625.447754 459.743439,626.888916 
	C459.584839,628.321777 459.426208,629.754700 458.914001,631.752197 
	C458.701019,633.552734 458.841583,634.788635 458.982178,636.024597 
	C458.938293,636.786682 458.894348,637.548767 458.431671,638.744080 
	C457.698639,640.139465 457.384369,641.101624 457.070129,642.063782 
	C457.070129,642.063782 457.100281,642.055908 456.807373,642.037476 
	C456.120148,642.018311 455.725830,642.017517 455.331543,642.016724 
	C455.748138,633.127991 456.710144,624.220398 456.403900,615.356689 
	C456.170685,608.606140 451.983765,603.188904 439.323303,603.611877 
	C439.323303,618.359192 439.323303,633.173950 439.270752,648.448608 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M459.214996,635.797241 
	C458.841583,634.788635 458.701019,633.552734 458.848022,632.110229 
	C459.239685,633.125793 459.343750,634.347839 459.214996,635.797241 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M455.107727,642.295105 
	C455.725830,642.017517 456.120148,642.018311 456.809631,642.052979 
	C456.242493,643.107666 455.380219,644.128418 454.136414,645.151550 
	C454.131226,644.293762 454.507568,643.433655 455.107727,642.295105 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M459.826935,626.496216 
	C459.602844,625.447754 459.462250,624.006592 459.545990,622.331177 
	C459.816986,623.432495 459.863708,624.768005 459.826935,626.496216 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M457.121643,608.754944 
	C456.459930,608.288818 455.762543,607.460693 455.067505,606.293823 
	C455.741882,606.767700 456.413910,607.580322 457.121643,608.754944 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M453.995728,604.590942 
	C453.124481,604.473206 452.203491,604.017273 451.170288,603.253052 
	C452.020721,603.380676 452.983368,603.816711 453.995728,604.590942 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M457.318909,641.900513 
	C457.384369,641.101624 457.698639,640.139465 458.319305,639.110229 
	C458.273041,639.941162 457.920380,640.839233 457.318909,641.900513 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M459.293335,616.910889 
	C458.926666,616.494629 458.732544,615.885742 458.777161,615.113159 
	C459.165863,615.539124 459.315887,616.128723 459.293335,616.910889 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M458.731323,611.668152 
	C458.158600,611.388611 457.626007,610.788391 457.105713,609.837463 
	C457.669128,610.106995 458.220306,610.727234 458.731323,611.668152 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M454.978729,605.694458 
	C454.690552,605.890442 454.420258,605.717285 454.139954,605.194824 
	C454.343201,605.123474 454.632080,605.283447 454.978729,605.694458 
z"/>
<path fill="#74141C" opacity="1.000000" stroke="none" 
	d="
M450.944427,602.701111 
	C450.687683,602.888855 450.410553,602.725952 450.078094,602.238770 
	C450.259186,602.168701 450.559570,602.314087 450.944427,602.701111 
z"/>
<path fill="#FE0721" opacity="1.000000" stroke="none" 
	d="
M431.673767,553.037231 
	C432.756683,554.407959 433.932159,555.755371 433.915588,557.087830 
	C433.756653,569.870728 433.050079,582.654663 433.180847,595.431274 
	C433.347931,611.756958 434.125549,628.076782 434.684814,644.397644 
	C434.798035,647.700684 433.835480,649.976135 430.083435,649.992310 
	C425.753204,650.010986 421.415924,650.127991 417.094574,649.926636 
	C413.763763,649.771423 411.870483,648.453064 411.898132,644.372620 
	C412.085297,616.725403 411.959930,589.076172 412.052490,561.427979 
	C412.062286,558.500305 412.828491,555.575134 413.192932,553.006897 
	C419.439606,553.006897 425.326416,553.006897 431.673767,553.037231 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M439.277161,592.737854 
	C439.275848,581.615601 439.189148,570.961182 439.351166,560.310608 
	C439.382690,558.238708 440.381958,556.181519 440.934875,554.117554 
	C443.869751,555.003113 447.055634,555.454468 449.638855,556.948181 
	C451.034973,557.755432 452.056854,560.320312 452.120300,562.131714 
	C452.410553,570.417236 452.717865,578.742981 452.196075,587.002197 
	C451.824524,592.882629 447.985382,596.553467 443.555634,597.143311 
	C440.485748,597.552063 438.643280,596.760071 439.277161,592.737854 
z"/>
<path fill="#F8F3F2" opacity="1.000000" stroke="none" 
	d="
M408.000916,456.496948 
	C404.061951,459.518372 400.138855,461.747406 394.823608,460.747070 
	C394.401520,460.316437 394.364655,459.988861 394.438202,459.281708 
	C394.448059,458.555847 394.341034,458.210236 394.233978,457.864655 
	C394.233978,445.134888 394.233978,432.405121 394.233978,419.676758 
	C407.276520,419.592194 410.911835,420.052124 411.618164,434.011932 
	C411.998444,441.527191 412.844543,449.349426 408.000916,456.496948 
z"/>
<path fill="#B8696F" opacity="1.000000" stroke="none" 
	d="
M394.152771,458.193146 
	C394.341034,458.210236 394.448059,458.555847 394.409515,459.035980 
	C394.199829,458.954224 394.135681,458.737915 394.152771,458.193146 
z"/>
<path fill="#D7A016" opacity="1.000000" stroke="none" 
	d="
M503.885925,220.918030 
	C503.435669,221.202957 502.985413,221.487900 501.916901,221.883713 
	C499.847900,223.664108 498.397156,225.333649 496.946411,227.003174 
	C496.496124,227.326385 496.045837,227.649597 495.146057,227.768875 
	C494.109375,228.042648 493.522186,228.520370 492.934998,228.998077 
	C492.934998,228.998077 492.537933,229.070068 492.000610,229.091339 
	C490.959900,229.739120 490.456543,230.365662 489.953186,230.992188 
	C489.953186,230.992188 489.551270,231.066864 488.971008,231.081879 
	C485.231262,233.017838 482.071808,234.938782 478.912323,236.859711 
	C478.490021,236.910172 478.067688,236.960632 477.112335,236.784531 
	C470.801758,238.367996 465.021912,240.170837 459.256378,242.018311 
	C459.056030,242.082535 458.998444,242.592300 458.874298,242.894394 
	C458.466705,242.917679 458.059113,242.940979 457.346161,242.965546 
	C453.429901,242.426773 449.818970,241.886734 446.208069,241.346680 
	C446.066406,240.975632 445.924713,240.604584 445.783020,240.233536 
	C448.276428,238.636490 450.838623,237.136398 453.250610,235.424667 
	C461.264587,229.737350 470.134094,224.870270 476.932922,217.971329 
	C482.952271,211.863312 489.379120,209.532333 497.300690,208.956741 
	C501.566681,208.646790 505.788574,207.752045 510.037018,207.169632 
	C512.501282,206.831833 514.870850,206.762390 514.991821,210.657394 
	C512.839661,213.220581 510.673492,215.380554 507.884644,217.751160 
	C506.136658,218.947205 505.011292,219.932617 503.885925,220.918030 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M459.299835,242.945572 
	C458.998444,242.592300 459.056030,242.082535 459.256378,242.018311 
	C465.021912,240.170837 470.801758,238.367996 476.794983,236.816833 
	C471.248901,239.049377 465.487122,241.023071 459.299835,242.945572 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M479.280029,236.878326 
	C482.071808,234.938782 485.231262,233.017838 488.768158,231.106979 
	C485.979645,233.043686 482.813721,234.970306 479.280029,236.878326 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M497.254730,226.882065 
	C498.397156,225.333649 499.847900,223.664108 501.676941,221.999664 
	C500.557831,223.590149 499.060425,225.175552 497.254730,226.882065 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M504.229248,220.926331 
	C505.011292,219.932617 506.136658,218.947205 507.621460,217.944931 
	C506.844818,218.930252 505.708679,219.932434 504.229248,220.926331 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M490.278687,230.971832 
	C490.456543,230.365662 490.959900,229.739120 491.800415,229.116730 
	C491.626465,229.731079 491.115326,230.341278 490.278687,230.971832 
z"/>
<path fill="#D1A653" opacity="1.000000" stroke="none" 
	d="
M493.244568,229.002747 
	C493.522186,228.520370 494.109375,228.042648 494.860413,227.806137 
	C494.534210,228.367371 494.044159,228.687393 493.244568,229.002747 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M384.144989,153.372742 
	C384.948334,152.784119 386.227203,151.915253 386.969879,152.246933 
	C390.364105,153.762772 393.608002,155.615311 396.959290,157.710220 
	C392.799683,156.597504 388.586609,155.121445 384.144989,153.372742 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M418.001526,172.372665 
	C421.105591,171.931519 423.111237,173.295303 423.166260,176.893555 
	C421.318542,175.614029 419.736755,174.138474 418.001526,172.372665 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M397.836884,158.482544 
	C399.793701,159.223465 401.854370,160.296509 403.959991,161.726837 
	C401.983551,160.994308 399.962128,159.904495 397.836884,158.482544 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M425.059235,180.332565 
	C427.925598,179.866776 429.694519,181.177383 429.692566,184.648285 
	C429.194244,185.069550 428.995453,185.098511 428.796631,185.127472 
	C427.596069,183.627380 426.395477,182.127304 425.059235,180.332565 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M369.153015,149.423065 
	C370.908112,149.522873 372.905548,149.885391 374.984619,150.588531 
	C373.175964,150.514679 371.285645,150.100235 369.153015,149.423065 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M377.148376,151.347198 
	C378.588318,151.485245 380.254578,151.885178 381.989197,152.616425 
	C380.496582,152.501526 378.935638,152.055298 377.148376,151.347198 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M428.869629,185.456421 
	C428.995453,185.098511 429.194244,185.069550 429.693848,185.023224 
	C430.628387,185.852310 431.262146,186.698792 431.871094,187.881500 
	C430.878357,187.406952 429.910461,186.596161 428.869629,185.456421 
z"/>
<path fill="#0903DF" opacity="1.000000" stroke="none" 
	d="
M408.012482,165.366974 
	C408.863342,165.511703 409.878265,165.939407 410.926727,166.733398 
	C410.032349,166.616440 409.104462,166.133194 408.012482,165.366974 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M371.996735,143.844177 
	C371.239166,144.079941 370.494324,144.056595 369.376648,144.041275 
	C369.004456,143.868195 369.005066,143.687103 369.017273,143.234161 
	C369.779266,142.966888 370.529633,142.971451 371.653931,143.021271 
	C372.021759,143.239365 372.015594,143.412216 371.996735,143.844177 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M366.209076,142.302002 
	C366.864777,142.182816 367.731598,142.321716 368.812469,142.716583 
	C368.157745,142.835068 367.288971,142.697556 366.209076,142.302002 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M271.996338,324.711914 
	C271.383026,324.486969 270.747742,323.931427 270.142883,323.011627 
	C270.773651,323.225372 271.374023,323.803345 271.996338,324.711914 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M274.073181,328.664062 
	C273.745148,328.512970 273.379608,328.054749 272.983521,327.285095 
	C273.313873,327.434723 273.674774,327.895813 274.073181,328.664062 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M393.355042,44.904675 
	C396.044250,43.593342 399.107208,42.285809 402.586304,41.007351 
	C399.911255,42.327106 396.820007,43.617790 393.355042,44.904675 
z"/>
<path fill="#C99C4C" opacity="1.000000" stroke="none" 
	d="
M500.924255,80.077072 
	C498.492554,77.490662 496.154968,74.811806 493.735413,72.209145 
	C489.528961,67.684402 485.271881,63.206711 481.010101,58.363525 
	C483.514038,59.626232 486.043915,61.234699 488.937500,62.777283 
	C489.301270,62.711395 489.637146,62.839695 489.885681,63.170181 
	C490.421478,63.686306 490.708771,63.871944 490.996033,64.057587 
	C491.184326,64.292229 491.386658,64.514053 491.776398,65.240341 
	C493.568054,67.211037 495.186340,68.664467 496.903992,70.269287 
	C497.003387,70.420692 497.134674,70.758263 497.107910,71.127510 
	C498.419495,74.298790 499.757843,77.100822 501.096222,79.902855 
	C501.096222,79.902855 500.981506,80.018921 500.924255,80.077072 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M501.410217,79.940918 
	C499.757843,77.100822 498.419495,74.298790 497.411102,71.066513 
	C497.917694,70.529129 498.094330,70.421989 498.270966,70.314842 
	C498.270966,70.314842 498.641022,70.642372 498.830078,71.096252 
	C499.567841,72.070869 500.116516,72.591606 500.665222,73.112335 
	C500.887878,73.643867 501.110504,74.175407 501.508606,74.933311 
	C502.119141,76.536583 502.554138,77.913483 502.989349,79.654617 
	C502.567749,80.005554 502.145966,79.992271 501.410217,79.940918 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M510.156403,87.266121 
	C511.140350,88.680275 512.090637,90.447533 513.024719,92.596649 
	C512.046631,91.192078 511.084686,89.405655 510.156403,87.266121 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M513.282593,96.181679 
	C513.531006,96.094849 513.731323,96.301247 513.934326,96.776825 
	C513.663818,96.919586 513.429565,96.729225 513.282593,96.181679 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M496.804626,70.117889 
	C495.186340,68.664467 493.568054,67.211037 491.943115,65.391899 
	C493.450500,66.243332 494.964478,67.460464 496.804260,68.764175 
	C497.130066,68.850754 497.131622,68.901543 497.057556,69.209152 
	C496.923859,69.717140 496.864227,69.917519 496.804626,70.117889 
z"/>
<path fill="#9E7D79" opacity="1.000000" stroke="none" 
	d="
M490.935425,63.790089 
	C490.708771,63.871944 490.421478,63.686306 490.044861,63.253700 
	C490.261993,63.178692 490.568390,63.350643 490.935425,63.790089 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M500.937866,73.009277 
	C500.116516,72.591606 499.567841,72.070869 499.033722,71.236206 
	C499.747284,70.977409 500.446228,71.032547 501.487762,71.151047 
	C501.830322,71.214409 501.882324,71.612045 501.905518,71.811127 
	C501.689331,72.308876 501.449921,72.607552 500.937866,73.009277 
z"/>
<path fill="#402C90" opacity="1.000000" stroke="none" 
	d="
M496.903992,70.269295 
	C496.864227,69.917519 496.923859,69.717140 497.081055,69.218155 
	C497.501160,69.238770 497.823639,69.557991 498.208557,70.096024 
	C498.094330,70.421989 497.917694,70.529129 497.437866,70.697266 
	C497.134674,70.758263 497.003387,70.420692 496.903992,70.269295 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M352.470032,348.237366 
	C351.422241,349.781372 350.066589,351.305634 348.388092,352.778442 
	C349.430878,351.223846 350.796539,349.720703 352.470032,348.237366 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M320.518341,355.879242 
	C318.930420,356.209198 317.136444,356.246246 315.240601,356.007141 
	C316.863220,355.682770 318.587769,355.634552 320.518341,355.879242 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M336.564636,355.493958 
	C335.751038,355.963287 334.636017,356.262421 333.361023,356.310120 
	C334.221771,355.813660 335.242493,355.568665 336.564636,355.493958 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M349.159821,289.533600 
	C350.054657,289.846497 350.959808,290.454041 351.841888,291.356445 
	C350.935944,291.043610 350.053009,290.435913 349.159821,289.533600 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M349.612732,277.483948 
	C349.104645,277.977356 348.324432,278.303711 347.402344,278.414734 
	C347.953888,277.905243 348.647247,277.611053 349.612732,277.483948 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M344.565247,278.507935 
	C344.037537,278.943512 343.245056,279.227051 342.328857,279.271179 
	C342.903595,278.806458 343.602020,278.581177 344.565247,278.507935 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M336.488403,280.963989 
	C336.860901,281.663788 336.921112,282.419678 336.923401,283.548096 
	C336.635681,282.953766 336.405914,281.986938 336.488403,280.963989 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M337.188232,284.425110 
	C337.988831,284.398834 338.884827,284.653503 339.832153,285.188110 
	C339.016876,285.214050 338.150208,284.960022 337.188232,284.425110 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M342.313446,286.045776 
	C342.765625,285.845825 343.304138,285.903107 343.847778,286.170959 
	C343.368561,286.355377 342.884155,286.329224 342.313446,286.045776 
z"/>
<path fill="#AD8577" opacity="1.000000" stroke="none" 
	d="
M338.595642,279.352539 
	C338.407593,279.927765 337.946442,280.404968 337.196014,280.840485 
	C337.378662,280.284027 337.850586,279.769287 338.595642,279.352539 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M468.734009,245.104065 
	C468.200928,245.738190 467.314392,246.311020 466.055695,246.797699 
	C466.582520,246.155289 467.481567,245.599030 468.734009,245.104065 
z"/>
<path fill="#1407CA" opacity="1.000000" stroke="none" 
	d="
M472.763245,244.075958 
	C472.482635,244.555435 471.891418,245.002899 471.129639,245.219299 
	C471.456940,244.673462 471.954773,244.358704 472.763245,244.075958 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
